import convertStringDateToMilliseconds from "../date/convertStringDateToMilliseconds";
import calculations from "../calculations";
import statics from "../../singletons/statics";
// import percentageOfAdditions from "../../singletons/percentageOfAdditions";
import groupModelValue from "../../helpers/model/groupModelValue";
import { isModelVisible } from "../../singletons/modelVisibility";

export default (model, field, fieldModels) => {
    // fieldModels[0] = wpontautstandcata
    // fieldModels[1] = wpontautpercbijt
    // fieldModels[2] = wpontautstandper1
    // fieldModels[3] = wpontautstandper2
    // fieldModels[4] = wpontauttenaamgest
    // fieldModels[5] = wpontautnaambegin60mnd
    // fieldModels[6] = wpontauttoegel 1764238
    // fieldModels[7] = wpontautzeroemissie 1767683
    // fieldModels[8] = wpontautbouw
    // fieldModels[9] =  wpontautwaterstof 1767684
    return calculations["hlpmwpondautberprivegebr"](
        !isModelVisible(fieldModels[0], field) ? undefined : groupModelValue(fieldModels[0], model, field),
        !isModelVisible(fieldModels[1], field) ? undefined : groupModelValue(fieldModels[1], model, field),
        !isModelVisible(fieldModels[2], field)
            ? undefined
            : convertStringDateToMilliseconds(groupModelValue(fieldModels[2], model, field)),
        !isModelVisible(fieldModels[3], field)
            ? undefined
            : convertStringDateToMilliseconds(groupModelValue(fieldModels[3], model, field)),
        !isModelVisible(fieldModels[4], field) ? undefined : groupModelValue(fieldModels[4], model, field),
        !isModelVisible(fieldModels[5], field) ? undefined : groupModelValue(fieldModels[5], model, field),
        !isModelVisible(fieldModels[6], field) ? undefined : groupModelValue(fieldModels[6], model, field),
        !isModelVisible(fieldModels[7], field) ? undefined : groupModelValue(fieldModels[7], model, field),
        !isModelVisible(fieldModels[8], field) ? undefined : groupModelValue(fieldModels[8], model, field),
        !isModelVisible(fieldModels[9], field) ? undefined : groupModelValue(fieldModels[9], model, field),
        statics().BDRGJAAR,
    );
};
