<template>
    <div class="radio-list" :disabled="disabled" v-attributes="'wrapper'">
        <label
            v-for="(item, index) in items"
            :key="'radio-b-' + index"
            :class="getItemCssClasses(item)"
            v-attributes="'label'"
            :for="getStaticFieldId(schema, item)"
        >
            <div class="radio-input">
                <span class="radio-input-inner" />
                <input
                    :id="getStaticFieldId(schema, item)"
                    type="radio"
                    :disabled="isItemDisabled(item)"
                    :name="id"
                    @change="onSelection(item)"
                    :value="getItemValue(item)"
                    :checked="isItemChecked(item)"
                    :class="schema.fieldClasses"
                    :required="schema.required"
                    v-attributes="'input'"
                    @focus="setFocus(item)"
                    @blur="focusValue = null"
                />
            </div>
            <span class="label-value">{{ getItemName(item) }}</span></label
        >
    </div>
</template>

<script>
import isObject from "lodash/isObject";
import isFunction from "lodash/isFunction";
import * as objGet from "lodash/get";
import abstractField from "./abstractField";
import Vue from "vue";

export default {
    mixins: [abstractField],

    data() {
        return {
            focusValue: null,
            fieldIds: {},
        };
    },

    computed: {
        items() {
            let values = this.schema.values;
            if (typeof values == "function") {
                return values.apply(this, [this.model, this.schema]);
            } else {
                return values;
            }
        },
        id() {
            return this.schema.model;
        },
    },

    methods: {
        getStaticFieldId(schema, item) {
            if (this.fieldIds[item.originalValue]) {
                return this.fieldIds[item.originalValue];
            }

            Vue.set(this.fieldIds, item.originalValue, this.getFieldID(schema, true));

            return this.fieldIds[item.originalValue];
        },
        setFocus(item) {
            this.onSelection(item);
            this.focusValue = item.originalValue;
        },
        getItemValue(item) {
            if (isObject(item)) {
                if (
                    typeof this.schema["radiosOptions"] !== "undefined" &&
                    typeof this.schema["radiosOptions"]["value"] !== "undefined"
                ) {
                    return item[this.schema.radiosOptions.value];
                } else {
                    if (typeof item["value"] !== "undefined") {
                        return item.value;
                    } else {
                        throw "`value` is not defined. If you want to use another key name, add a `value` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
                    }
                }
            } else {
                return item;
            }
        },
        getItemName(item) {
            if (isObject(item)) {
                if (
                    typeof this.schema["radiosOptions"] !== "undefined" &&
                    typeof this.schema["radiosOptions"]["name"] !== "undefined"
                ) {
                    return item[this.schema.radiosOptions.name];
                } else {
                    if (typeof item["name"] !== "undefined") {
                        return item.name;
                    } else {
                        throw "`name` is not defined. If you want to use another key name, add a `name` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
                    }
                }
            } else {
                return item;
            }
        },
        getItemCssClasses(item) {
            return {
                "is-checked": this.isItemChecked(item),
                "is-disabled": this.isItemDisabled(item),
                "is-focussed": this.focusValue === item.originalValue,
            };
        },
        onSelection(item) {
            this.value = this.getItemValue(item);
        },
        isItemChecked(item) {
            let currentValue = this.getItemValue(item);
            return currentValue === this.value;
        },
        isItemDisabled(item) {
            if (this.disabled) {
                return true;
            }
            let disabled = objGet(item, "disabled", false);
            if (isFunction(disabled)) {
                return disabled(this.model);
            }
            return disabled;
        },
    },
};
</script>

<style lang="scss">
.vue-form-generator .field-radios {
    .radio-list {
        label {
            display: block;
            input[type="radio"] {
                margin-right: 5px;
            }
        }
    }
}
</style>
