import formulaHandler from "../formulas/handler";
import statics from "../singletons/statics";
import groupModelValue from "../helpers/model/groupModelValue";
import { notExistsWarning } from "../singletons/registeredModels";

export default (value, field, model) => {
    const errors = [];

    field.customValidators.forEach(customValidator => {
        if (formulaHandler(model, field, customValidator, statics(), "terms")) {
            const messageVariables = customValidator.message.match(/#{\s*[\w.]+\s*}/g);

            if (!messageVariables) {
                return customValidator.message;
            }

            let errorMessage = customValidator.message;

            messageVariables.forEach(variable => {
                variable = variable.replace("#{", "").replace("}", "");

                const modelValue = groupModelValue(variable, model, field);

                if (typeof modelValue !== "undefined") {
                    errorMessage = errorMessage.replace("#{" + variable + "}", modelValue);
                } else if (statics()[variable] !== "undefined") {
                    errorMessage = errorMessage.replace("#{" + variable + "}", statics()[variable]);
                } else {
                    notExistsWarning(variable, field, "");
                    errorMessage = errorMessage.replace("#{" + variable + "}", "");
                }
            });

            errors.push(errorMessage);
        }
    });

    return errors;
};
