export default (startDate, endDate) => {
    // date as in new Date
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    // added plus one because the start date should count as well
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay + 1;
};

const treatAsUTC = date => {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
};
