import * as objGet from "lodash/get";
import forEach from "lodash/forEach";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import filter from "lodash/filter";
import Vue from "vue";

export default {
    methods: {
        // Get style classes of field
        getFieldRowClasses(field) {
            const hasErrors = this.fieldErrors(field).length > 0;
            let baseClasses = {
                [objGet(this.options, "validationErrorClass", "error")]: hasErrors,
                [objGet(this.options, "validationSuccessClass", "valid")]: !hasErrors,
                disabled: this.fieldDisabled(field),
                readonly: this.fieldReadonly(field),
                featured: this.fieldFeatured(field),
                required: this.fieldRequired(field),
            };

            if (isArray(field.styleClasses)) {
                forEach(field.styleClasses, c => (baseClasses[c] = true));
            } else if (isString(field.styleClasses)) {
                baseClasses[field.styleClasses] = true;
            }

            if (!isNil(field.type)) {
                baseClasses["field-" + field.type] = true;
            }

            if (this.isMultiColumn(field)) {
                baseClasses["multi-column"] = true;
            }

            return baseClasses;
        },
        fieldErrors(field) {
            let res = this.errors.filter(e => e.field === field);
            return res.map(item => item.error);
        },
        // Is group multi column
        isMultiColumn(group) {
            if (!group || !group.styleClasses) {
                return false;
            }

            const columnsClasses = filter(group.styleClasses, styleClass => {
                return styleClass.startsWith("multiple-fields-");
            });

            return columnsClasses.length > 0;
        },
        // Is next or previous multi column
        isNextPreviousMultiColumn(group, groupIndex, groups, next) {
            if (!group || !group.styleClasses) {
                return false;
            }

            if (!next) {
                if (groups[groupIndex - 1] && this.isMultiColumn(groups[groupIndex - 1])) {
                    return true;
                }
                return false;
            }

            if (next) {
                if (groups[groupIndex + 1] && this.isMultiColumn(groups[groupIndex + 1])) {
                    return true;
                }
                return false;
            }
            return false;
        },
        // Get disabled attr of field
        fieldDisabled(field) {
            if (isFunction(field.disabled)) return field.disabled.call(this, this.model, field, this);

            if (isNil(field.disabled)) return false;

            return field.disabled;
        },
        // Get readonly prop of field
        fieldReadonly(field) {
            if (isFunction(field.readonly)) return field.readonly.call(this, this.model, field, this);

            if (isNil(field.readonly)) return false;

            return field.readonly;
        },
        // Get featured prop of field
        fieldFeatured(field) {
            if (isFunction(field.featured)) return field.featured.call(this, this.model, field, this);

            if (isNil(field.featured)) return false;

            return field.featured;
        },
        // Get required prop of field
        fieldRequired(field) {
            if (isFunction(field.required)) return field.required.call(this, this.model, field, this);

            if (isNil(field.required)) return false;

            return field.required;
        },
        scrollGroup(event, subjectRef) {
            Vue.set(this.scrollGroups, subjectRef, {
                left: event.target.scrollLeft,
                max: event.target.scrollWidth - event.target.clientWidth,
            });
        },
        scrollGroupButton(subjectRef, left) {
            const scrollContainer = this.$refs[subjectRef][0];
            if (left) {
                const newScrollDistance = scrollContainer.scrollLeft - 25;
                scrollContainer.scrollLeft = newScrollDistance >= 0 ? newScrollDistance : 0;
            }
            if (!left) {
                const newScrollDistance = scrollContainer.scrollLeft + 25;
                scrollContainer.scrollLeft = newScrollDistance;
            }
        },
        showScrollArrow(subjectRef, left) {
            if (!this.scrollGroups[subjectRef]) {
                return left ? false : true;
            }

            if (this.scrollGroups[subjectRef].left > 10 && left) return true;
            if (this.scrollGroups[subjectRef].left < this.scrollGroups[subjectRef].max - 10 && !left) return true;

            return false;
        },
    },
};
