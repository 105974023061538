import groupModelValue from "../../helpers/model/groupModelValue";

export default (model, field, fieldModels, parameters) => {
    let result = undefined;

    if (typeof fieldModels[0] === "undefined") {
        return result;
    }

    const fieldModelValue = groupModelValue(fieldModels[0], model, field);

    if (parameters.percent && fieldModelValue) {
        result = (fieldModelValue * (parameters.percent / 100)).toFixed(2) * 1;
    }

    if (parameters.round && parameters.round === "down" && fieldModelValue) {
        result = Math.floor(result);
    }

    if (parameters.round && parameters.round === "up" && fieldModelValue) {
        result = Math.ceil(result);
    }

    return result;
};
