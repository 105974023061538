<template>
    <div>
        <main class="tax-profit-content">
            <bldc-grid-column col="12">
                <bldc-heading level="1" id="page-title">{{ pageTitle }}</bldc-heading>
            </bldc-grid-column>
            <div class="white-card">
                <bldc-paragraph :margin="false">
                    {{ introText }}
                </bldc-paragraph>
            </div>
            <div class="white-card">
                <template v-for="(subject, subjectIndex) in screenArray">
                    <bldc-grid-row
                        :containerColumns="12"
                        :key="subjectIndex"
                        v-if="
                            !subject.isSubtotal &&
                                (!subject.showOnlyIfHasValue ||
                                    (subject.showOnlyIfHasValue && subjectTotal(subject) !== 0))
                        "
                    >
                        <bldc-grid-column col="8" sm="12">
                            <bldc-paragraph margin="false">
                                <div class="flex-row">
                                    <div
                                        :class="{
                                            'question-button': hasHelpText(subject.refId),
                                            'no-question-button': !hasHelpText(subject.refId),
                                        }"
                                        @click.stop="openHelpText(subject.refId, subject)"
                                        @keydown.enter.stop="openHelpText(subject.refId, subject)"
                                        tabindex="0"
                                    >
                                        <div v-if="hasHelpText(subject.refId)" class="question-wrapper">
                                            <bldc-icon icon="help" font-size="12"></bldc-icon>
                                        </div>
                                    </div>
                                    {{ subject.title }}
                                </div>
                            </bldc-paragraph>
                        </bldc-grid-column>
                        <bldc-grid-column col="6" sm="6" v-if="screenSize === 'sm'" />
                        <bldc-grid-column col="3" sm="6">
                            <bldc-paragraph margin="false">
                                <amount :amount="subjectTotal(subject)" :rounded="rounded" />
                            </bldc-paragraph>
                        </bldc-grid-column>
                    </bldc-grid-row>

                    <bldc-grid-row
                        :containerColumns="12"
                        :key="subjectIndex + 'help'"
                        v-if="showHelpText(subject.refId) && hasHelpText(subject.refId)"
                    >
                        <bldc-grid-column col="12">
                            <div class="help-box" @click.stop.prevent="">
                                <button class="help-box-close" @click.stop.prevent="closeHelpText()">
                                    <bldc-icon
                                        aria-label="sluit informatie"
                                        icon="sluiten"
                                        class="hydrated"
                                    ></bldc-icon>
                                </button>
                                <bldc-paragraph margin="false">
                                    <div :ref="'ref-id-' + subject.refId">{{ $t("global.laden") }}</div>
                                </bldc-paragraph>
                            </div>
                        </bldc-grid-column>
                    </bldc-grid-row>
                    <bldc-grid-row
                        :containerColumns="12"
                        :key="subjectIndex + 'sub-total'"
                        v-if="subject.isSubtotal && showOnlyIf(subject, subjectIndex, 'line')"
                    >
                        <bldc-grid-column col="5" sm="12"></bldc-grid-column>
                        <bldc-grid-column col="3" sm="6"> </bldc-grid-column>
                        <bldc-grid-column col="3" sm="6">
                            <div style="padding-left: 10px;">
                                <div class="calc-icon-wrapper">
                                    <div class="calc-icon" v-if="subject.calculation === 'plus'">
                                        <bldc-icon icon="plus" font-size="12"></bldc-icon>
                                    </div>
                                    <div class="calc-icon" v-if="subject.calculation === 'min'">
                                        <bldc-icon icon="min" font-size="12"></bldc-icon>
                                    </div>
                                </div>
                            </div>
                        </bldc-grid-column>
                    </bldc-grid-row>
                    <bldc-grid-row
                        :containerColumns="12"
                        :key="subjectIndex"
                        v-if="subject.isSubtotal && showOnlyIf(subject, subjectIndex, 'total')"
                        style="margin-bottom: 1rem;"
                    >
                        <bldc-grid-column col="8" sm="12">
                            <bldc-paragraph margin="false">
                                <div class="flex-row">
                                    <div class="no-question-button"></div>
                                    <strong v-if="showOnlyIf(subject, subjectIndex, 'label')">{{
                                        subject.title
                                    }}</strong>
                                </div>
                            </bldc-paragraph>
                        </bldc-grid-column>
                        <bldc-grid-column col="6" sm="6" v-if="screenSize === 'sm'" />
                        <bldc-grid-column col="3" sm="6">
                            <bldc-paragraph margin="false">
                                <amount :amount="getCalculatedUntilSubject(subject, subjectIndex)" :rounded="rounded" />
                            </bldc-paragraph>
                        </bldc-grid-column>
                    </bldc-grid-row>
                </template>
            </div>
        </main>

        <footer-navigation v-on:nav-next="setStatusForSheet" />

        <handle-forms
            ref="handleForms"
            :statics="statics"
            v-on:final-post-key-data="setFinalPostKeyData"
            v-on:final-post-data="setFinalPostData"
        />
    </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import footerNavigation from "../components/footerNavigation";
import amount from "../components/yearOverview/amount";
// import yoSwitch from "../components/yearOverview/switch";
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
import { getHelpTextsList, getCustomHelpTextsList } from "../modules/singletons/helpTextsList";
import { getYearOverview } from "../modules/singletons/yearOverview";
import getHelpText from "../modules/utils/getHelpText";
import getStatics from "../modules/singletons/statics";
import profit from "../modules/calculations/rgs/profit";
import { getMBmgAwaData } from "../modules/singletons/mBmgAwaData";
import removeObservers from "../modules/utils/removeObservers";
import { saveCurrentState } from "../modules/singletons/currentState";

import HandleForms from "../components/HandleForms";
import {
    getSubjectTotal,
    getSubjectTotalCosts,
    getSubjectTotalRevenue,
    getCostsUntilSubject,
    getTotalUntilSubject,
    isLastRevenue,
    isLastCosts,
    costsRevenueBoth,
    getTotal,
    getRepeatSubjectTotal,
} from "../modules/utils/annualAccountCalculation";
import shepherd from "../mixins/shepherd";
import resumeDeclaration from "../mixins/resumeDeclaration";

export default {
    name: "taxProfit",
    mixins: [shepherd, resumeDeclaration],
    components: {
        footerNavigation,
        HandleForms,
        amount,
        // yoSwitch,
    },
    data() {
        return {
            currentHelpText: null,
            currentRgsRules: null,
            closedCards: {},
            statics: getStatics(),
            roundedSwitch: false,
            highlightSwitch: false,
            finalPostKeyData: {},
            finalPostData: {},
            roundingHelp: false,
            invalidAccountancyToggle: false,
            currentRepeatInfo: null,
        };
    },
    computed: {
        ...mapGetters({
            rgsHeaders: "rgsLedger/headers",
            ebValues: "rgsLedger/ebValues",
            sbValues: "rgsLedger/sbValues",
            dbValues: "rgsLedger/dbValues",
            ecgValues: "rgsLedger/ecgValues",
            mappedRules: "rgsLedger/mappedRules",
            hasMutataionsByGelId: "rgsLedger/hasMutataionsByGelId",
            getMutationsByGelId: "rgsLedger/getMutationsByGelId",
            getMutataionGroupsByGelId: "rgsLedger/getMutataionGroupsByGelId",
            getInitialValueByGelId: "rgsLedger/getInitialValueByGelId",
            getValuesWithoutMutations: "rgsLedger/values",
            getWithAllMutations: "rgsLedger/getWithAllMutations",
            getMutationsWith: "rgsLedger/getMutationsWith",
            getTitle: "rgsLedger/getTitle",
            firstPossibleRoute: "navigation/firstPossibleRoute",
            screenSize: "screen/size",
        }),
        introText() {
            if (this.yearOverviewOrCheck === "check") {
                if (this.$route.name === "tax-profit") {
                    return this.$t("check.intro_text_fiscalewinstberekening");
                }
                return this.$t("check.intro_text");
            }
            if (this.$route.params.sheet === "profit-and-loss-account") {
                return this.$t("year_overview.intro_tekst_winst_verliesrekening");
            } else if (this.$route.params.sheet === "balance-assets") {
                return this.$t("year_overview.intro_tekst_balans_activa");
            } else if (this.$route.params.sheet === "balance-liabilities") {
                return this.$t("year_overview.intro_tekst_balans_passiva");
            }
            return this.$t("year_overview.intro_tekst_priveonttrekkingen_en_stortingen");
        },
        rounded() {
            return this.roundedSwitch || this.$route.name === "check";
        },
        yearOverviewOrCheck() {
            if (this.$route.name === "check" || this.$route.name === "tax-profit") {
                return "check";
            }
            return "yearOverview";
        },
        screenName() {
            if (this.$route.name === "check" || this.$route.name === "tax-profit") {
                return "fiscal";
            }
            return "accounting";
        },
        values() {
            return this.getWithAllMutations();
        },
        roundedValues() {
            if (this.yearOverviewOrCheck === "yearOverview") {
                return this.getMutationsWith("rgs_rounding");
            }
            return this.getWithAllMutations();
        },
        subjects() {
            let subjects = removeObservers(getYearOverview()[this.numbersTag]);

            if (this.$route.name === "check") {
                subjects.forEach(subject => {
                    remove(subject.rules, rule => {
                        return rule.gelId === "resultaat";
                    });
                });
            }
            return subjects;
        },
        screenArray() {
            return removeObservers(getYearOverview()["winstberekening"]);
        },
        numbersTag() {
            const currentCheck = this.$store.getters["navigation/currentCheckObject"](this.$route.params.sheet);

            return currentCheck.numbersTag;
        },
        pageTitle() {
            return this.$t("year_overview.tax_profit");
        },
        totalProfit: function() {
            return profit(getMBmgAwaData(), removeObservers(this.values));
        },
        lowerPageTitle() {
            return this.pageTitle.toLowerCase();
        },
    },
    methods: {
        getMutations: function(gelId) {
            // if (this.$route.params.sheet !== "profit-calculation") {
            return this.getMutationsByGelId(gelId);
            // }
            // return this.getMutataionGroupsByGelId(gelId);
        },
        profit: (mBmgAwaData, values) => profit(mBmgAwaData, values),
        subjectTotal: function(subject) {
            return this.hasRepeatRule(subject)
                ? this.getRepeatSubjectTotal(subject, this.values, this.finalPostKeyData)
                : this.getSubjectTotal(subject, this.values, this.totalProfit) || 0;
        },
        getSubjectTotal: function(subject, values, profit) {
            return getSubjectTotal(subject, values, profit, null, this.finalPostKeyData);
        },
        getRepeatSubjectTotal: function(subject, values, finalPostKeyData) {
            const repeatTotal = getRepeatSubjectTotal(subject, values, finalPostKeyData, this.screenName);

            if (this.finalPostData["wpontauttot"] && this.yearOverviewOrCheck === "check") {
                return this.finalPostData["wpontauttot"] + repeatTotal;
            }

            return repeatTotal;
        },
        getSubjectTotalCosts: (subject, values) => getSubjectTotalCosts(subject, values),
        getSubjectTotalRevenue: (subject, values) => getSubjectTotalRevenue(subject, values),
        getCostsUntilSubject: (subjects, subjectIndex, values) => getCostsUntilSubject(subjects, subjectIndex, values),
        getTotalUntilSubject: (subjects, subjectIndex, values) => getTotalUntilSubject(subjects, subjectIndex, values),
        isLastRevenue: (index, rules) => isLastRevenue(index, rules),
        isLastCosts: (index, rules) => isLastCosts(index, rules),
        costsRevenueBoth: rules => costsRevenueBoth(rules),
        getTotal: (subjects, values, finalPostKeyData, profit) => getTotal(subjects, values, finalPostKeyData, profit),
        getHelpText: refId => getHelpText(refId),
        isLastColumn: (subject, rule) => {
            const columnIndex = findIndex(subject.columns, column => column.title === rule.column);
            return columnIndex === subject.columns.length - 1;
        },
        getCalculatedUntilSubject: function(subject, subjectIndex) {
            let counter = 0;
            let total = 0;

            this.screenArray.forEach((scrSubject, scrSubjectIndex) => {
                if (scrSubjectIndex < subjectIndex) {
                    if (scrSubject.isSubtotal) {
                        total = this.getCalculatedUntilSubject(scrSubject, scrSubjectIndex);
                        return;
                    }

                    const subjectTotal = this.hasRepeatRule(scrSubject)
                        ? this.getRepeatSubjectTotal(scrSubject, this.values, this.finalPostKeyData)
                        : this.getSubjectTotal(scrSubject, this.values, this.totalProfit) || 0;

                    if (counter < 1) {
                        total += subjectTotal.toFixed(2) * 1;
                    }

                    if (counter >= 1 && subject.calculation === "plus") {
                        total += subjectTotal.toFixed(2) * 1;
                    }

                    if (counter >= 1 && subject.calculation === "min") {
                        total -= subjectTotal.toFixed(2) * 1;
                    }

                    counter++;
                }
            });

            return total.toFixed(2) * 1;
        },
        showOnlyIf: function(subject, subjectIndex, type) {
            let showOnlyIf;
            let showOnlyIfNot;

            if (type === "total") {
                if (!subject.showOnlyIf && !subject.showOnlyIfNot) {
                    return true;
                }

                showOnlyIf = subject.showOnlyIf;
                showOnlyIfNot = subject.showOnlyIfNot;
            }

            if (type === "label") {
                if (!subject.showLabelOnlyIf && !subject.showLabelOnlyIfNot) {
                    return true;
                }
                showOnlyIf = subject.showLabelOnlyIf;
                showOnlyIfNot = subject.showLabelOnlyIfNot;
            }

            if (type === "line") {
                if (!subject.showLineOnlyIf && !subject.showLineOnlyIfNot) {
                    return true;
                }

                showOnlyIf = subject.showLineOnlyIf;
                showOnlyIfNot = subject.showLineOnlyIfNot;
            }

            if (showOnlyIf === "next") {
                if (!this.screenArray[subjectIndex + 1]) {
                    return false;
                }
                const nextSubjectTotal = this.hasRepeatRule(this.screenArray[subjectIndex + 1])
                    ? this.getRepeatSubjectTotal(this.screenArray[subjectIndex + 1], this.values, this.finalPostKeyData)
                    : this.getSubjectTotal(this.screenArray[subjectIndex + 1], this.values, this.totalProfit) || 0;

                return nextSubjectTotal !== 0;
            }

            if (showOnlyIf === "previous") {
                if (!this.screenArray[subjectIndex - 1]) {
                    return false;
                }
                const previousSubjectTotal = this.hasRepeatRule(this.screenArray[subjectIndex - 1])
                    ? this.getRepeatSubjectTotal(this.screenArray[subjectIndex - 1], this.values, this.finalPostKeyData)
                    : this.getSubjectTotal(this.screenArray[subjectIndex - 1], this.values, this.totalProfit) || 0;

                return previousSubjectTotal !== 0;
            }

            if (showOnlyIfNot === "next") {
                if (!this.screenArray[subjectIndex + 1]) {
                    return false;
                }
                const nextSubjectTotal = this.hasRepeatRule(this.screenArray[subjectIndex + 1])
                    ? this.getRepeatSubjectTotal(this.screenArray[subjectIndex + 1], this.values, this.finalPostKeyData)
                    : this.getSubjectTotal(this.screenArray[subjectIndex + 1], this.values, this.totalProfit) || 0;

                return nextSubjectTotal === 0;
            }

            if (showOnlyIfNot === "previous") {
                if (!this.screenArray[subjectIndex - 1]) {
                    return false;
                }
                const previousSubjectTotal = this.hasRepeatRule(this.screenArray[subjectIndex - 1])
                    ? this.getRepeatSubjectTotal(this.screenArray[subjectIndex - 1], this.values, this.finalPostKeyData)
                    : this.getSubjectTotal(this.screenArray[subjectIndex - 1], this.values, this.totalProfit) || 0;

                return previousSubjectTotal === 0;
            }

            return true;
        },
        saveAndLeave: function() {},
        hasRepeatRule: subject => {
            const columnIndex = findIndex(subject.rules, rule => rule.position === "herhaal");
            return columnIndex >= 0;
        },
        getColumnValue: function(subject, rule, column) {
            const columnIndex = findIndex(subject.rules, otherRule => {
                return otherRule.column === column.title && rule.title === otherRule.title;
            });
            if (columnIndex >= 0) {
                return this.getValue(subject.rules[columnIndex].gelId, subject.rules[columnIndex].dimension) || 0;
            }
            return null;
        },
        showColumnValue: function(subject, rule, column) {
            const columnIndex = findIndex(subject.columns, otherColumn => {
                return otherColumn.title === column.title;
            });
            return columnIndex === subject.columns.length - 1 || this.getColumnValue(subject, rule, column);
        },
        setFinalPostKeyData: function(postData) {
            Vue.set(this, "finalPostKeyData", postData);
        },
        setFinalPostData: function(postData) {
            if (this.$route.name === "check") {
                Vue.set(this, "finalPostData", postData);
            }
        },

        openRoundingHelp: function() {
            this.roundingHelp = true;
        },

        closeRoundingHelp: function() {
            this.roundingHelp = false;
        },

        closeInvalidAccountancyModal: function() {
            this.invalidAccountancyToggle = false;
        },

        openInvalidAccountancyModal: function() {
            this.invalidAccountancyToggle = true;
        },

        helpTextList: function() {
            return getHelpTextsList();
        },

        customHelpTextList: function() {
            return getCustomHelpTextsList();
        },

        hasHelpText: function(refId) {
            if (this.customHelpTextList().indexOf(refId) >= 0) {
                return true;
            }

            return this.helpTextList().indexOf(refId) >= 0;
        },

        hasRepeatableHelpText: function(subject, columnTitle) {
            const ruleIndex = findIndex(subject.rules, rule => {
                return rule.column === columnTitle && rule.refId;
            });
            if (ruleIndex < 0) {
                return false;
            }

            if (this.customHelpTextList().indexOf(subject.rules[ruleIndex].refId) >= 0) {
                return true;
            }

            return this.helpTextList().indexOf(subject.rules[ruleIndex].refId) >= 0;
        },

        firstRepeatColumnRule: function(subject, ruleIndex) {
            if (
                subject.rules[ruleIndex - 1] &&
                subject.rules[ruleIndex - 1].position === "herhaal" &&
                !subject.rules[ruleIndex - 1].column
            ) {
                return true;
            }
            return false;
        },
        getRepeatValues: function(subject, ruleIndex) {
            let groupGelId;
            if (subject.rules[ruleIndex - 1] && subject.rules[ruleIndex - 1].gelId) {
                groupGelId = subject.rules[ruleIndex - 1].gelId;
            } else {
                return [];
            }
            if (this.finalPostKeyData[groupGelId]) {
                return this.finalPostKeyData[groupGelId];
            }

            return [];
        },
        hasRepeatValues: function(subject) {
            let hasRepeatValues = false;
            if (subject.rules) {
                subject.rules.forEach(rule => {
                    if (rule.position === "herhaal" && !rule.column && this.finalPostKeyData[rule.gelId]) {
                        hasRepeatValues = true;
                    }
                });
            }

            return hasRepeatValues;
        },
        getRepeatValue: function(subject, ruleIndex, columnIndex, repeatValueIndex) {
            const repeatValues = this.getRepeatValues(subject, ruleIndex, columnIndex);

            if (!repeatValues[repeatValueIndex]) {
                return null;
            }

            if (columnIndex === 0) {
                return repeatValues[repeatValueIndex][subject.rules[ruleIndex].gelId];
            }

            if (columnIndex === 1) {
                return repeatValues[repeatValueIndex][subject.rules[ruleIndex + 1].gelId];
            }

            return null;
        },

        getRepeatableHelpTextRefId: function(subject, columnTitle) {
            const ruleIndex = findIndex(subject.rules, rule => {
                return rule.column === columnTitle && rule.refId;
            });
            if (ruleIndex < 0) {
                return null;
            }

            return subject.rules[ruleIndex].refId;
        },

        isCardClosed: function(subjectIndex) {
            return this.closedCards[subjectIndex] !== true;
        },

        toggleCard: function(subjectIndex, subject) {
            if (this.showSubjectMutations(subject)) {
                return;
            }
            if (this.closedCards[subjectIndex]) {
                Vue.set(this.closedCards, subjectIndex, false);
                return;
            }
            Vue.set(this.closedCards, subjectIndex, true);
        },

        openHelpText: function(refId, subject) {
            if (this.showSubjectMutations(subject)) {
                return;
            }
            if (this.currentHelpText === refId) {
                this.closeHelpText();
                return;
            }
            this.currentHelpText = refId;
            this.getHelpText(refId)
                .then(element => {
                    this.$refs["ref-id-" + refId][0].innerHtml = "";
                    this.$refs["ref-id-" + refId][0].textContent = "";
                    this.$refs["ref-id-" + refId][0].appendChild(element);
                })
                .catch(() => {
                    this.$refs["ref-id-" + refId][0].textContent = "Help text not found for ref id: " + refId;
                });
        },

        openRepeatableHelpText: function(subject, columnTitle) {
            const ruleIndex = findIndex(subject.rules, rule => {
                return rule.column === columnTitle && rule.refId;
            });
            if (ruleIndex < 0) {
                return false;
            }
            this.openHelpText(subject.rules[ruleIndex].refId);
        },

        closeHelpText: function() {
            this.currentHelpText = null;
        },

        showHelpText: function(refId) {
            return this.currentHelpText === refId;
        },

        getValue: function(gelId, dimension) {
            if (gelId === "resultaat") {
                return this.totalProfit;
            }

            if (gelId === "wpontauttot") {
                return this.finalPostData["wpontauttot"];
            }

            if (dimension) {
                return this.values[gelId + "-" + dimension];
            }
            return this.values[gelId] || 0;
        },

        getRoundedValue: function(gelId, dimension) {
            if (gelId === "resultaat") {
                return this.totalProfit;
            }

            if (gelId === "wpontauttot") {
                return this.finalPostData["wpontauttot"];
            }

            if (dimension) {
                return this.roundedValues[gelId + "-" + dimension];
            }
            return this.roundedValues[gelId] || 0;
        },

        getRuleValue: function(rule) {
            return this.getValue(rule.gelId, rule.dimension);
        },

        getRoundedRuleValue: function(rule) {
            return this.getRoundedValue(rule.gelId, rule.dimension);
        },

        getRuleGelId: function(rule) {
            if (rule.dimension) {
                return rule.gelId + "-" + rule.dimension;
            }
            return rule.gelId;
        },

        setStatusForSheet: function() {
            this.$store.dispatch("navigation/setCheckStatus", {
                sheet: "tax-profit",
                status: true,
            });

            saveCurrentState();
            return;
        },

        resumeDeclaration: function() {
            if (this.firstPossibleRoute.routeParams) {
                this.$router.push({
                    name: this.firstPossibleRoute.routeName,
                    params: this.firstPossibleRoute.routeParams,
                });
            } else if (this.firstPossibleRoute.routeName !== "download") {
                this.$router.push({ name: this.firstPossibleRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.firstPossibleRoute.routeName);
        },

        openRgsRules: function(rule) {
            if (this.currentRgsRules === this.getRuleGelId(rule)) {
                this.closeRgsRules();
                return;
            }
            this.currentRgsRules = this.getRuleGelId(rule);
        },

        showRgsRules: function(rule) {
            return this.currentRgsRules === this.getRuleGelId(rule);
        },

        hasRgsRules: function(rule) {
            return !!this.mappedRules[this.getRuleGelId(rule)] && this.yearOverviewOrCheck !== "check";
        },

        hasRgsMutations: function(rule) {
            return this.hasMutataionsByGelId(this.getRuleGelId(rule)) && this.yearOverviewOrCheck === "check";
        },

        closeRgsRules: function() {
            this.currentRgsRules = null;
        },

        openRepeatInfo: function(rule, repeatValueIndex) {
            if (
                this.currentRepeatInfo &&
                this.currentRepeatInfo.gelId === this.getRuleGelId(rule) &&
                this.currentRepeatInfo.index === repeatValueIndex
            ) {
                this.closeRepeatInfo();
                return;
            }
            Vue.set(this, "currentRepeatInfo", { gelId: this.getRuleGelId(rule), index: repeatValueIndex });
        },

        showRepeatInfo: function(rule, repeatValueIndex) {
            if (!this.currentRepeatInfo) {
                return false;
            }
            return (
                this.currentRepeatInfo.gelId === this.getRuleGelId(rule) &&
                this.currentRepeatInfo.index === repeatValueIndex
            );
        },

        closeRepeatInfo: function() {
            this.currentRepeatInfo = null;
        },

        positiveValue: function(value) {
            if (value < 0) {
                return value * -1;
            }
            return value;
        },

        mappedRuleValue: function(mappedRule) {
            if (typeof mappedRule.value === "undefined") {
                return 0;
            }
            if (mappedRule.value < 0) {
                return mappedRule.value * -1;
            }

            return mappedRule.value;
        },

        getTotalCredit: function(mappedRules) {
            let total = 0;
            mappedRules.forEach(mappedRule => {
                if (mappedRule.debitCredit === "C") {
                    total += mappedRule.value;
                }
            });

            return Number(total).toFixed(2) * 1;
        },

        getTotalDebit: function(mappedRules) {
            let total = 0;
            mappedRules.forEach(mappedRule => {
                if (mappedRule.debitCredit === "D") {
                    total += mappedRule.value;
                }
            });

            if (total < 0) {
                total = total * -1;
            }

            return Number(total).toFixed(2) * 1;
        },

        getTotalMutationCredit: function(mutationRules) {
            let total = 0;
            mutationRules.forEach(mutationRule => {
                if (mutationRule.value > 0) {
                    total += mutationRule.value;
                }
            });

            return Number(total).toFixed(2) * 1;
        },

        getTotalMutationDebit: function(mutationRules) {
            let total = 0;
            mutationRules.forEach(mutationRule => {
                if (mutationRule.value < 0) {
                    total += mutationRule.value;
                }
            });

            if (total < 0) {
                total = total * -1;
            }

            return Number(total).toFixed(2) * 1;
        },

        hasSubjectMutations: function(subject) {
            let hasMutations = false;
            if (subject.rules) {
                subject.rules.forEach(rule => {
                    if (this.hasRgsMutations(rule)) {
                        hasMutations = true;
                    }
                });
            }

            return hasMutations;
        },

        showSubjectMutations: function(subject) {
            if (!this.highlightSwitch) {
                return false;
            }

            if (this.hasRepeatValues(subject)) {
                return false;
            }

            return !this.hasSubjectMutations(subject);
        },
    },
    watch: {
        "$route.params.sheet": function() {
            if (this.$route.name === "check") {
                this.$store.dispatch("navigation/setCurrentCheck", this.$route.params.sheet);
            } else if (this.$route.name === "tax-profit") {
                this.$store.dispatch("navigation/setCurrentCheck", "tax-profit");
            } else {
                this.$store.dispatch("navigation/setCurrentYearOverview", this.$route.params.sheet);
            }
            Vue.set(this, "closedCards", {});
            this.$refs.handleForms.download();
            Vue.nextTick(() => {
                this.initShepherd();
            });
        },
        highlightSwitch: function(newValue) {
            Vue.set(this, "closedCards", {});
            this.currentHelpText = null;

            if (newValue) {
                const firstChangedEl = document.getElementsByClassName("has-changes")[0];
                if (firstChangedEl) {
                    firstChangedEl.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.$route.name === "check") {
                vm.$store.dispatch("navigation/setCurrentItem", `checks`);
                vm.$store.dispatch("navigation/setCurrentCheck", vm.$route.params.sheet);
            } else if (vm.$route.name === "tax-profit") {
                vm.$store.dispatch("navigation/setCurrentItem", `checks`);
                vm.$store.dispatch("navigation/setCurrentCheck", "tax-profit");
            } else {
                vm.$store.dispatch("navigation/setCurrentItem", `year-overviews`);
                vm.$store.dispatch("navigation/setCurrentYearOverview", vm.$route.params.sheet);
            }

            Vue.set(vm, "closedCards", {});
            vm.$refs.handleForms.download();
        });
    },

    mounted: function() {
        this.$nextTick(() => {
            this.initShepherd();
        });
    },
};
</script>
<style lang="scss" scoped>
.tippy-box {
    font-family: ROregular, Verdana, Arial, sans-serif;
}
.tax-profit-content {
    .white-card {
        background-color: #ffffff;
        padding: 0.875rem 1.25rem 0.005rem;
        margin-bottom: 10px;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .question-button {
        border: 1px solid rgb(1, 104, 155);
        height: 16px;
        width: 16px;
        text-align: center;
        margin-top: 4px;
        float: left;
        margin-right: 5px;
        cursor: pointer;
        flex-shrink: 0;

        .question-wrapper {
            margin-top: -6px;
            color: rgb(1, 104, 155);
        }
    }
    .no-question-button {
        height: 16px;
        width: 18px;
        text-align: center;
        margin-top: 3px;
        float: left;
        margin-right: 5px;
        flex-shrink: 0;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .calc-icon-wrapper {
        border-bottom: 2px solid black;
        float: right;
        position: relative;
        width: 80%;
        .calc-icon {
            position: absolute;
            right: -15px;
            bottom: -8px;
            font-weight: 600;
        }
    }

    .help-box {
        margin-top: 0px;
        margin-bottom: 0.5rem;
        display: block;
        background-color: rgb(221, 239, 248);
        width: calc(100% - 4.5rem);
        max-width: 700px;
        padding: 12px;
        z-index: 2000;
        font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
        @media only screen and (max-width: 767px) {
            width: calc(100% - 1rem);
        }
    }
    .help-box:before {
        position: absolute;
        margin-top: -20px;
        width: 0;
        height: 0;
        content: " ";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgb(221, 239, 248);
    }
    .help-box-close {
        display: block;
        float: right;
        cursor: pointer;
        padding: 0.7rem;
        border: 1px solid transparent;
        margin: -9px -10px;
        background-color: transparent;
    }
    .help-box-close:hover {
        border: 1px solid rgb(1, 104, 155);
    }

    .rgs-rules-box {
        margin-top: 10px;
        display: block;
        background-color: #efefef;
        color: #000000;
        width: calc(100% - 20px);
        font-size: 86%;
        padding: 10px;
        margin-bottom: 10px;

        &:before {
            position: absolute;
            margin-top: -20px;
            right: 200px;
            width: 0;
            height: 0;
            content: " ";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-bottom: 10px solid #efefef;
        }
    }

    .rgs-rules-box {
        background-color: rgba(238, 247, 251, 1);
        &:before {
            border-bottom: 10px solid rgba(238, 247, 251, 1);
        }
    }
}
</style>
