export default {
    methods: {
        resumeDeclaration: function() {
            if (this.$store.getters["navigation/firstPossibleRoute"].routeParams) {
                this.$router
                    .push({
                        name: this.$store.getters["navigation/firstPossibleRoute"].routeName,
                        params: this.$store.getters["navigation/firstPossibleRoute"].routeParams,
                    })
                    .catch(() => {});
            } else {
                this.$router
                    .push({ name: this.$store.getters["navigation/firstPossibleRoute"].routeName })
                    .catch(() => {});
            }

            this.$store.dispatch(
                "navigation/setCurrentItem",
                this.$store.getters["navigation/firstPossibleRoute"].routeName,
            );
        },
    },
};
