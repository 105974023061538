import Vue from "vue";

export default {
    methods: {
        // Parse XML data and add to statics
        setConstantsFormXml(xmlString) {
            const parser = new DOMParser();
            let xmlDoc = parser.parseFromString(xmlString, "text/xml");
            let xmlVariables = xmlDoc.getElementsByTagName("variable");

            xmlVariables.forEach(xmlVariable => {
                const varName = xmlVariable.getElementsByTagName("name")[0];
                const varValue = xmlVariable.getElementsByTagName("value")[0];

                if (!varName || !varValue) {
                    console.warn("Name or value not set for variable: ", xmlVariable);
                } else {
                    this.addStatic(varName.innerHTML, varValue.innerHTML);
                }
            });
        },
        // Check for variable type and add to statics
        addStatic(key, value) {
            // Do not overwrite manual added statics
            if (typeof this.static[key] !== "undefined") {
                return;
            }

            const possibleNummberValue = String(value)
                .replace(/\./g, "")
                .replace(/,/g, ".");

            if (+possibleNummberValue === +possibleNummberValue) {
                // Value is number
                value = possibleNummberValue * 1;
            }

            Vue.set(this.static, key, value);
        },
    },
};
