import removeObservers from "../../utils/removeObservers";
import { getMBmgAwaData } from "../../singletons/mBmgAwaData";
import profit from "../rgs/profit";
import groupModelValue from "../../helpers/model/groupModelValue";
import { isModelVisible } from "../../singletons/modelVisibility";

export default (model, field, fieldModels, parameters, mutatedUntil) => {
    const currentValues = removeObservers(mutatedUntil);
    const result = profit(getMBmgAwaData(), currentValues);

    const filtered = fieldModels
        .filter(fieldModel => isModelVisible(fieldModel, field))
        .map(fieldModel => groupModelValue(fieldModel, model, field))
        .filter(value => value >= 0 || value < 0);

    filtered.push(result);

    if (filtered.length <= 0) {
        return undefined;
    }

    return filtered.reduce((acc, curr) => acc + curr);
};
