<template>
    <div class="year-overview-content">
        <main>
            <bldc-grid-row :containerColumns="12">
                <bldc-grid-column col="12">
                    <bldc-heading level="1" id="page-title">{{ pageTitle }}</bldc-heading>
                </bldc-grid-column>
            </bldc-grid-row>
            <bldc-grid-row :containerColumns="12">
                <bldc-grid-column col="12">
                    <yo-switch
                        v-if="yearOverviewOrCheck === 'yearOverview'"
                        style="float: right;"
                        v-model="roundedSwitch"
                        :label="$t('year_overview.toon_afgeronde_cijfers')"
                        :help-text="$t('year_overview.afronden_help_tekst')"
                    />
                    <yo-switch
                        v-if="yearOverviewOrCheck === 'check'"
                        style="float: right;"
                        v-model="highlightSwitch"
                        :label="$t('year_overview.toon_alleen_aangepaste_cijfers')"
                        :help-text="$t('year_overview.mutatie_verschillen_help_tekst')"
                    />
                </bldc-grid-column>
            </bldc-grid-row>
            <div style="background-color: #FFFFFF; padding: 0.875rem 1.25rem 0.005rem; margin-bottom: 10px;">
                <bldc-paragraph :margin="false">
                    {{ introText }}
                </bldc-paragraph>
            </div>
            <div v-if="currentSheet === 'balance-liabilities' || currentSheet === 'balance-assets'">
                <bldc-grid-row :containerColumns="12">
                    <!-- help icon and title -->
                    <bldc-grid-column col="5" sm="11"> </bldc-grid-column>
                    <!-- end help icon and title -->

                    <!-- subject total -->
                    <bldc-grid-column col="3" sm="6" v-if="currentSheet === 'balance-liabilities'">
                        <div style="padding-left: 1.4rem;">
                            <bldc-paragraph margin="false">
                                {{ $t("year_overview.boekwaarde_begin_boekjaar") }}
                            </bldc-paragraph>
                        </div>
                    </bldc-grid-column>
                    <bldc-grid-column col="3" sm="6" v-if="currentSheet !== 'balance-liabilities'"> </bldc-grid-column>

                    <bldc-grid-column col="3" sm="6">
                        <div style="padding-left: 1.4rem;">
                            <bldc-paragraph margin="false">
                                {{ $t("year_overview.boekwaarde_einde_boekjaar") }}
                            </bldc-paragraph>
                        </div>
                    </bldc-grid-column>

                    <bldc-grid-column col="1"> </bldc-grid-column>
                </bldc-grid-row>
            </div>
            <div>
                <div
                    v-for="(subject, subjectIndex) in subjects"
                    :key="'subject' + subjectIndex"
                    class="bd-card"
                    :class="{
                        'no-changes': showSubjectMutations(subject),
                        'has-changes': !showSubjectMutations(subject),
                    }"
                >
                    <div v-if="!subject.isSubtotal">
                        <!-- card title -->
                        <div
                            class="bd-card-title"
                            @click="toggleCard(subjectIndex, subject)"
                            @keydown.enter="toggleCard(subjectIndex, subject)"
                            :tabindex="!showSubjectMutations(subject) ? 0 : -1"
                        >
                            <bldc-grid-row :containerColumns="12">
                                <!-- help icon and title -->
                                <bldc-grid-column col="5" sm="11">
                                    <div class="flex-row">
                                        <div
                                            style="margin-top: 14px;"
                                            :class="{
                                                'question-button': hasHelpText(subject.refId),
                                                'no-question-button': !hasHelpText(subject.refId),
                                            }"
                                            @click.stop="openHelpText(subject.refId, subject)"
                                            @keydown.enter.stop="openHelpText(subject.refId, subject)"
                                            :tabindex="!showSubjectMutations(subject) ? 0 : -1"
                                        >
                                            <div v-if="hasHelpText(subject.refId)" class="question-wrapper">
                                                <bldc-icon icon="help" font-size="12"></bldc-icon>
                                            </div>
                                        </div>
                                        <h4 class="bd-card-title-header" v-if="subject.title">{{ subject.title }}</h4>
                                    </div>
                                </bldc-grid-column>
                                <!-- end help icon and title -->

                                <!-- toggle small screen -->
                                <bldc-grid-column col="1" v-if="screenSize === 'sm'">
                                    <div class="bd-toggle" :class="{ 'bd-toggle-rotate': isCardClosed(subjectIndex) }">
                                        <bldc-icon
                                            icon="delta_omlaag"
                                            style="color: rgb(1, 104, 155)"
                                            font-size="22"
                                        ></bldc-icon>
                                    </div>
                                </bldc-grid-column>
                                <!-- end toggle small screen -->

                                <bldc-grid-column
                                    col="3"
                                    sm="6"
                                    v-if="costsRevenueBoth(subject.rules) !== 'costs' && !subject.showColumns"
                                >
                                </bldc-grid-column>

                                <bldc-grid-column col="3" sm="6" v-if="subject.showColumns">
                                    <bldc-paragraph margin="false">
                                        <div class="card-total-amount">
                                            <amount
                                                :amount="getSubjectBeginYearTotal(subject, values, totalProfit) || 0"
                                                :rounded="rounded"
                                                :isTotal="true"
                                            />
                                        </div>
                                    </bldc-paragraph>
                                </bldc-grid-column>

                                <!-- subject total -->
                                <bldc-grid-column
                                    col="3"
                                    sm="6"
                                    :class="{
                                        revenue: costsRevenueBoth(subject.rules) !== 'costs',
                                        costs: costsRevenueBoth(subject.rules) === 'costs',
                                    }"
                                >
                                    <bldc-paragraph margin="false">
                                        <div class="card-total-amount">
                                            <amount
                                                :amount="
                                                    hasRepeatRule(subject)
                                                        ? getRepeatSubjectTotal(subject, values, finalPostKeyData)
                                                        : getSubjectTotal(subject, values, totalProfit) || 0
                                                "
                                                :rounded="rounded"
                                                :isTotal="true"
                                            />
                                        </div>
                                    </bldc-paragraph>
                                </bldc-grid-column>
                                <!-- end subject total -->

                                <bldc-grid-column col="3" sm="6" v-if="costsRevenueBoth(subject.rules) === 'costs'">
                                </bldc-grid-column>

                                <!-- toggle -->
                                <bldc-grid-column col="1" v-if="screenSize !== 'sm'">
                                    <div class="bd-toggle" :class="{ 'bd-toggle-rotate': isCardClosed(subjectIndex) }">
                                        <bldc-icon
                                            icon="delta_omlaag"
                                            style="color: rgb(1, 104, 155)"
                                            font-size="22"
                                        ></bldc-icon>
                                    </div>
                                </bldc-grid-column>
                                <!-- end toggle -->
                            </bldc-grid-row>
                            <bldc-grid-row
                                :containerColumns="12"
                                v-if="showHelpText(subject.refId) && hasHelpText(subject.refId)"
                            >
                                <bldc-grid-column col="12">
                                    <div class="help-box" @click.stop.prevent="">
                                        <button
                                            class="help-box-close"
                                            @click.stop.prevent="closeHelpText()"
                                            @keydown.enter.stop="closeHelpText()"
                                        >
                                            <bldc-icon
                                                aria-label="sluit informatie"
                                                icon="sluiten"
                                                class="hydrated"
                                            ></bldc-icon>
                                        </button>
                                        <bldc-paragraph margin="false">
                                            <div :ref="'ref-id-' + subject.refId">{{ $t("global.laden") }}</div>
                                        </bldc-paragraph>
                                    </div>
                                </bldc-grid-column>
                            </bldc-grid-row>
                        </div>
                        <!-- end card title -->
                        <!-- card content -->
                        <div
                            class="card-open"
                            :class="{
                                'card-closed': isCardClosed(subjectIndex, subject),
                                'scroll-three-columns-container': subject.columns && subject.columns.length === 3,
                            }"
                            v-on:scroll="scrollSubject($event, 'subject-' + subjectIndex)"
                            :ref="'subject-' + subjectIndex"
                        >
                            <div :class="{ 'scroll-three-columns': subject.columns && subject.columns.length === 3 }">
                                <a
                                    href="#"
                                    class="scroll-arrow left"
                                    :aria-label="$t('global.scroll_naar_links')"
                                    v-if="
                                        subject.columns &&
                                            subject.columns.length === 3 &&
                                            screenSize === 'sm' &&
                                            showScrollArrow('subject-' + subjectIndex, true)
                                    "
                                    @click.prevent="scrollSubjectButton('subject-' + subjectIndex, true)"
                                >
                                    <bldc-icon icon="delta_links" font-size="24" />
                                </a>
                                <a
                                    href="#"
                                    class="scroll-arrow right"
                                    :aria-label="$t('global.scroll_naar_rechts')"
                                    v-if="
                                        subject.columns &&
                                            subject.columns.length === 3 &&
                                            screenSize === 'sm' &&
                                            showScrollArrow('subject-' + subjectIndex, false)
                                    "
                                    @click.prevent="scrollSubjectButton('subject-' + subjectIndex, false)"
                                >
                                    <bldc-icon icon="delta_rechts" font-size="24" />
                                </a>
                                <div>
                                    <bldc-grid-row
                                        :containerColumns="12"
                                        v-if="subject.columns.length > 0 && !hasRepeatRule(subject)"
                                    >
                                        <bldc-grid-column
                                            :col="subject.columns.length > 2 ? 2 : 3"
                                            v-if="screenSize !== 'sm'"
                                        />
                                        <bldc-grid-column
                                            :col="2"
                                            :sm="2"
                                            v-if="screenSize === 'sm' && subject.columns.length === 3"
                                        />

                                        <bldc-grid-column
                                            :col="subject.columns.length === 2 ? 2 : 5"
                                            :sm="6"
                                            v-if="
                                                subject.columns.length !== 3 &&
                                                    !(screenSize === 'sm' && subject.columns.length === 2)
                                            "
                                        ></bldc-grid-column>
                                        <bldc-grid-column
                                            col="3"
                                            :sm="subject.columns.length === 3 ? 3 : 6"
                                            v-for="(column, columnIndex) in subject.columns"
                                            :key="'column' + columnIndex"
                                        >
                                            <bldc-paragraph margin="false">
                                                <div class="column-title" style="padding-left: 1.5rem;">
                                                    {{ column.title }}
                                                </div>
                                            </bldc-paragraph>
                                        </bldc-grid-column>
                                    </bldc-grid-row>
                                </div>
                                <template v-for="(rule, ruleIndex) in subject.rules">
                                    <div
                                        :key="'rule' + ruleIndex"
                                        class="bd-card-rule"
                                        :class="{
                                            'has-rgs-rules': hasRgsRules(rule) || hasRgsMutations(rule),
                                        }"
                                        @click="openRgsRules(rule)"
                                        @keydown.enter="openRgsRules(rule)"
                                        :tabindex="
                                            isCardClosed(subjectIndex, subject) ||
                                            (!hasRgsRules(rule) && !hasRgsMutations(rule))
                                                ? -1
                                                : 0
                                        "
                                        v-if="showRule(rule, ruleIndex, subject)"
                                    >
                                        <bldc-grid-row
                                            :containerColumns="12"
                                            v-if="subject.columns.length > 0 && hasRepeatRule(subject) && !rule.column"
                                        >
                                            <bldc-grid-column col="5" :sm="subject.columns.length === 3 ? 5 : 11">
                                                <bldc-paragraph margin="false">
                                                    <div class="flex-row">
                                                        <div class="no-question-button"></div>
                                                        <template v-if="rule.position !== 'herhaal'">
                                                            {{ rule.title }}
                                                        </template>
                                                        <strong v-if="rule.position === 'herhaal' && !rule.column">
                                                            {{ rule.title }}
                                                        </strong>
                                                    </div>
                                                </bldc-paragraph>
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="3"
                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                v-if="rule.position === 'omzet'"
                                            >
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="3"
                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                v-if="rule.position === 'omzet'"
                                            >
                                                <bldc-paragraph margin="false">
                                                    <amount :amount="getRuleValue(rule)" :rounded="rounded" />
                                                </bldc-paragraph>
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="3"
                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                v-if="rule.position !== 'omzet'"
                                            >
                                            </bldc-grid-column>
                                            <bldc-grid-column col="1" v-if="hasRgsRules(rule) || hasRgsMutations(rule)">
                                                <div
                                                    class="bd-toggle"
                                                    :class="{
                                                        'bd-toggle-rotate':
                                                            !showRgsRules(rule) &&
                                                            (hasRgsRules(rule) || hasRgsMutations(rule)),
                                                    }"
                                                >
                                                    <bldc-icon
                                                        icon="delta_omlaag"
                                                        style="color: rgb(1, 104, 155)"
                                                        font-size="22"
                                                    ></bldc-icon>
                                                </div>
                                            </bldc-grid-column>
                                        </bldc-grid-row>
                                        <template
                                            v-if="
                                                subject.columns.length > 0 &&
                                                    hasRepeatRule(subject) &&
                                                    rule.position === 'herhaal' &&
                                                    yearOverviewOrCheck === 'check' &&
                                                    rule.column &&
                                                    firstRepeatColumnRule(subject, ruleIndex)
                                            "
                                        >
                                            <div
                                                v-for="(repeatValue, repeatValueIndex) in getRepeatValues(
                                                    subject,
                                                    ruleIndex,
                                                )"
                                                :key="'reapeatVal' + repeatValueIndex"
                                                class="bd-card-rule has-rgs-rules"
                                                @click="openRepeatInfo(rule, repeatValueIndex)"
                                                @keydown.enter="openRepeatInfo(rule, repeatValueIndex)"
                                                tabindex="0"
                                            >
                                                <bldc-grid-row :containerColumns="12">
                                                    <bldc-grid-column
                                                        v-for="(column, columnIndex) in subject.columns.slice(0, 2)"
                                                        :key="'column' + columnIndex"
                                                        :col="columnIndex === 0 ? 8 : 3"
                                                    >
                                                        <bldc-paragraph margin="false">
                                                            <div class="flex-row" v-if="columnIndex === 0">
                                                                <div class="question-button">
                                                                    <div class="question-wrapper-i">
                                                                        i
                                                                    </div>
                                                                </div>

                                                                {{
                                                                    getRepeatValue(
                                                                        subject,
                                                                        ruleIndex,
                                                                        columnIndex,
                                                                        repeatValueIndex,
                                                                    ) || 0
                                                                }}
                                                            </div>

                                                            <amount
                                                                v-if="columnIndex === 1"
                                                                :amount="
                                                                    getRepeatValue(
                                                                        subject,
                                                                        ruleIndex,
                                                                        columnIndex,
                                                                        repeatValueIndex,
                                                                    ) || 0
                                                                "
                                                                :rounded="rounded"
                                                            />
                                                        </bldc-paragraph>
                                                    </bldc-grid-column>

                                                    <bldc-grid-column col="1">
                                                        <div
                                                            class="bd-toggle"
                                                            :class="{
                                                                'bd-toggle-rotate': !showRepeatInfo(
                                                                    rule,
                                                                    repeatValueIndex,
                                                                ),
                                                            }"
                                                        >
                                                            <bldc-icon
                                                                icon="delta_omlaag"
                                                                style="color: rgb(1, 104, 155)"
                                                                font-size="22"
                                                            ></bldc-icon>
                                                        </div>
                                                    </bldc-grid-column>
                                                </bldc-grid-row>
                                                <bldc-grid-row
                                                    :containerColumns="12"
                                                    v-if="showRepeatInfo(rule, repeatValueIndex)"
                                                    @click.stop.prevent=""
                                                >
                                                    <bldc-grid-column col="12">
                                                        <div class="rgs-rules-box">
                                                            <button
                                                                class="help-box-close"
                                                                @click.stop.prevent="closeRepeatInfo()"
                                                            >
                                                                <bldc-icon
                                                                    aria-label="sluit informatie"
                                                                    icon="sluiten"
                                                                    class="hydrated"
                                                                ></bldc-icon>
                                                            </button>
                                                            <div style="width: 94%;">
                                                                <bldc-paragraph margin="false">
                                                                    {{
                                                                        $t(
                                                                            "year_overview.informatie_tekst_" +
                                                                                rule.refId,
                                                                        )
                                                                    }}
                                                                </bldc-paragraph>
                                                            </div>
                                                        </div>
                                                    </bldc-grid-column>
                                                </bldc-grid-row>
                                            </div>
                                        </template>
                                        <bldc-grid-row
                                            :containerColumns="12"
                                            v-if="subject.columns.length <= 0 && !hasRepeatRule(subject)"
                                        >
                                            <bldc-grid-column col="5" sm="11">
                                                <bldc-paragraph margin="false">
                                                    <div class="flex-row">
                                                        <div class="no-question-button"></div>
                                                        {{ rule.title }}
                                                    </div>
                                                </bldc-paragraph>
                                            </bldc-grid-column>
                                            <!-- toggle small screen -->
                                            <bldc-grid-column
                                                col="1"
                                                v-if="
                                                    screenSize === 'sm' && (hasRgsRules(rule) || hasRgsMutations(rule))
                                                "
                                            >
                                                <div
                                                    class="bd-toggle"
                                                    :class="{
                                                        'bd-toggle-rotate':
                                                            !showRgsRules(rule) &&
                                                            (hasRgsRules(rule) || hasRgsMutations(rule)),
                                                    }"
                                                >
                                                    <bldc-icon
                                                        icon="delta_omlaag"
                                                        style="color: rgb(1, 104, 155)"
                                                        font-size="22"
                                                    ></bldc-icon>
                                                </div>
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="3"
                                                sm="6"
                                                v-if="rule.position === 'omzet' && subject.columns.length <= 0"
                                            >
                                            </bldc-grid-column>
                                            <bldc-grid-column col="3" sm="6" v-if="subject.columns.length <= 0">
                                                <bldc-paragraph margin="false">
                                                    <amount :amount="getRuleValue(rule)" :rounded="rounded" />
                                                </bldc-paragraph>
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="3"
                                                sm="6"
                                                v-if="rule.position !== 'omzet' && subject.columns.length <= 0"
                                            >
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="1"
                                                v-if="
                                                    screenSize !== 'sm' && (hasRgsRules(rule) || hasRgsMutations(rule))
                                                "
                                            >
                                                <div
                                                    class="bd-toggle"
                                                    :class="{
                                                        'bd-toggle-rotate':
                                                            !showRgsRules(rule) &&
                                                            (hasRgsRules(rule) || hasRgsMutations(rule)),
                                                    }"
                                                >
                                                    <bldc-icon
                                                        icon="delta_omlaag"
                                                        style="color: rgb(1, 104, 155)"
                                                        font-size="22"
                                                    ></bldc-icon>
                                                </div>
                                            </bldc-grid-column>
                                        </bldc-grid-row>
                                        <bldc-grid-row
                                            :containerColumns="12"
                                            v-if="
                                                subject.columns.length > 0 &&
                                                    isLastColumn(subject, rule) &&
                                                    !hasRepeatRule(subject)
                                            "
                                        >
                                            <bldc-grid-column
                                                :col="getColumnsLength(subject.columns.length)"
                                                :sm="subject.columns.length === 3 ? 2 : 11"
                                            >
                                                <bldc-paragraph margin="false">
                                                    <div class="flex-row">
                                                        <div class="no-question-button"></div>
                                                        {{ rule.title }}
                                                    </div>
                                                </bldc-paragraph>
                                            </bldc-grid-column>
                                            <bldc-grid-column
                                                col="1"
                                                v-if="
                                                    screenSize === 'sm' &&
                                                        subject.columns.length !== 3 &&
                                                        (hasRgsRules(rule) || hasRgsMutations(rule))
                                                "
                                            >
                                                <div
                                                    class="bd-toggle"
                                                    :class="{
                                                        'bd-toggle-rotate':
                                                            !showRgsRules(rule) &&
                                                            (hasRgsRules(rule) || hasRgsMutations(rule)),
                                                    }"
                                                >
                                                    <bldc-icon
                                                        icon="delta_omlaag"
                                                        style="color: rgb(1, 104, 155)"
                                                        font-size="22"
                                                    />
                                                </div>
                                            </bldc-grid-column>

                                            <bldc-grid-column
                                                :col="subject.columns.length === 2 ? 2 : 5"
                                                :sm="6"
                                                v-if="
                                                    subject.columns.length !== 3 &&
                                                        !(screenSize === 'sm' && subject.columns.length === 2)
                                                "
                                            />
                                            <bldc-grid-column
                                                col="3"
                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                v-for="(column, columnIndex) in subject.columns"
                                                :key="'column' + columnIndex"
                                            >
                                                <bldc-paragraph
                                                    margin="false"
                                                    v-if="showColumnValue(subject, rule, column)"
                                                >
                                                    <amount
                                                        :amount="getColumnValue(subject, rule, column) || 0"
                                                        :rounded="rounded"
                                                    />
                                                </bldc-paragraph>
                                            </bldc-grid-column>

                                            <bldc-grid-column
                                                col="1"
                                                v-if="
                                                    (screenSize !== 'sm' || subject.columns.length === 3) &&
                                                        (hasRgsRules(rule) || hasRgsMutations(rule))
                                                "
                                            >
                                                <div
                                                    class="bd-toggle"
                                                    :class="{
                                                        'bd-toggle-rotate':
                                                            !showRgsRules(rule) &&
                                                            (hasRgsRules(rule) || hasRgsMutations(rule)),
                                                    }"
                                                >
                                                    <bldc-icon
                                                        icon="delta_omlaag"
                                                        style="color: rgb(1, 104, 155)"
                                                        font-size="22"
                                                    />
                                                </div>
                                            </bldc-grid-column>
                                        </bldc-grid-row>
                                        <bldc-grid-row
                                            :containerColumns="12"
                                            v-if="showRgsRules(rule) && (hasRgsRules(rule) || hasRgsMutations(rule))"
                                            @click.stop.prevent=""
                                        >
                                            <bldc-grid-column col="12">
                                                <div class="rgs-rules-box">
                                                    <button
                                                        class="help-box-close"
                                                        @click.stop.prevent="closeRgsRules()"
                                                    >
                                                        <bldc-icon
                                                            aria-label="sluit informatie"
                                                            icon="sluiten"
                                                            class="hydrated"
                                                        ></bldc-icon>
                                                    </button>
                                                    <template
                                                        v-if="hasRgsRules(rule) && yearOverviewOrCheck !== 'check'"
                                                    >
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column col="9">
                                                                <bldc-paragraph margin="false">
                                                                    {{
                                                                        $t(
                                                                            "year_overview.deze_gegevens_komen_uit_uw_boekhouding",
                                                                        )
                                                                    }}
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column
                                                                col="6"
                                                                :sm="subject.columns.length === 3 ? 6 : 12"
                                                            >
                                                                <bldc-paragraph margin="false">
                                                                    <span style="font-weight: 900;">
                                                                        {{ $t("year_overview.grootboekrekening") }}
                                                                    </span>
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <bldc-paragraph margin="false">
                                                                    <span
                                                                        style="font-weight: 900; padding-left: 20px;"
                                                                        >{{ $t("year_overview.debet") }}</span
                                                                    >
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <bldc-paragraph margin="false">
                                                                    <span
                                                                        style="font-weight: 900; padding-left: 20px;"
                                                                        >{{ $t("year_overview.credit") }}</span
                                                                    >
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row
                                                            :containerColumns="12"
                                                            v-for="(mappedRule, mappedRuleIndex) in mappedRules[
                                                                getRuleGelId(rule)
                                                            ]"
                                                            :key="mappedRuleIndex"
                                                        >
                                                            <bldc-grid-column
                                                                col="6"
                                                                :sm="subject.columns.length === 3 ? 6 : 12"
                                                            >
                                                                <bldc-paragraph margin="false" v-if="mappedRule.id">
                                                                    {{ mappedRule.key }} ({{ mappedRule.id }})
                                                                </bldc-paragraph>
                                                                <bldc-paragraph margin="false" v-if="!mappedRule.id">
                                                                    {{ mappedRule.key }}
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <bldc-paragraph
                                                                    margin="false"
                                                                    v-if="mappedRule.debitCredit === 'D'"
                                                                >
                                                                    <amount
                                                                        :amount="mappedRuleValue(mappedRule)"
                                                                        :rounded="false"
                                                                    />
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <bldc-paragraph
                                                                    margin="false"
                                                                    v-if="mappedRule.debitCredit === 'C'"
                                                                >
                                                                    <amount
                                                                        :amount="mappedRuleValue(mappedRule)"
                                                                        :rounded="false"
                                                                    />
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column
                                                                col="6"
                                                                :sm="subject.columns.length === 3 ? 6 : 12"
                                                            />
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <div
                                                                    style="float: right; width: 85%; border-top: 1px solid black;"
                                                                />
                                                                <div
                                                                    style="position: absolute; right: -2px; top: -9px;"
                                                                >
                                                                    <bldc-icon icon="plus" font-size="12"></bldc-icon>
                                                                </div>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <div
                                                                    style="float: right; width: 85%; border-top: 1px solid black;"
                                                                />
                                                                <div
                                                                    style="position: absolute; right: -2px; top: -9px;"
                                                                >
                                                                    <bldc-icon icon="plus" font-size="12"></bldc-icon>
                                                                </div>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column
                                                                col="6"
                                                                :sm="subject.columns.length === 3 ? 6 : 12"
                                                            />
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <bldc-paragraph margin="false">
                                                                    <amount
                                                                        :amount="
                                                                            positiveValue(
                                                                                getTotalDebit(
                                                                                    mappedRules[getRuleGelId(rule)],
                                                                                ),
                                                                            )
                                                                        "
                                                                        :rounded="false"
                                                                    />
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <bldc-paragraph margin="false">
                                                                    <amount
                                                                        :amount="
                                                                            positiveValue(
                                                                                getTotalCredit(
                                                                                    mappedRules[getRuleGelId(rule)],
                                                                                ),
                                                                            )
                                                                        "
                                                                        :rounded="false"
                                                                    />
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column
                                                                col="6"
                                                                :sm="subject.columns.length === 3 ? 6 : 12"
                                                            >
                                                                <bldc-paragraph margin="false">
                                                                    <div class="total-amount-info-box">
                                                                        {{ $t("year_overview.saldo_afgerond") }}
                                                                    </div>
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                                v-if="
                                                                    getTotalCredit(mappedRules[getRuleGelId(rule)]) >
                                                                        getTotalDebit(mappedRules[getRuleGelId(rule)])
                                                                "
                                                            >
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="3"
                                                                :sm="subject.columns.length === 3 ? 3 : 6"
                                                            >
                                                                <div style="padding-right: 1.4rem;">
                                                                    <bldc-paragraph margin="false">
                                                                        <amount
                                                                            :amount="
                                                                                positiveValue(getRoundedRuleValue(rule))
                                                                            "
                                                                            :rounded="true"
                                                                            style="font-weight: 900"
                                                                        />
                                                                    </bldc-paragraph>
                                                                </div>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row
                                                            :containerColumns="12"
                                                            v-if="
                                                                yearOverviewOrCheck !== 'check' &&
                                                                    statics['BEivKapPro-CB'] &&
                                                                    rule.gelId === 118315
                                                            "
                                                        >
                                                            <bldc-grid-column col="12">
                                                                <bldc-paragraph margin="false" style="font-weight: 900">
                                                                    {{
                                                                        $t(
                                                                            "year_overview.prive_opname_gemapt_naar_onttrekkingen_kapitaal",
                                                                        )
                                                                    }}
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <bldc-grid-row
                                                            :containerColumns="12"
                                                            v-if="yearOverviewOrCheck !== 'check'"
                                                        >
                                                            <bldc-grid-column col="12">
                                                                <bldc-button
                                                                    type="minimal"
                                                                    @click.stop="openInvalidAccountancyModal"
                                                                    :elem-title="
                                                                        $t(
                                                                            'year_overview.dit_klopt_niet_ga_terug_naar_boekhouden',
                                                                        )
                                                                    "
                                                                    style="float: left;"
                                                                ></bldc-button>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                    </template>
                                                    <template v-if="hasRgsMutations(rule)">
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column col="9">
                                                                <bldc-heading :level="3" :margin="true">
                                                                    {{ $t("year_overview.gemuteerde_gegevens") }}
                                                                </bldc-heading>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>

                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column col="9" sm="12">
                                                                <bldc-paragraph margin="false">
                                                                    {{ $t("year_overview.waarde_uit_boekhouding") }}
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column
                                                                col="6"
                                                                sm="6"
                                                                v-if="screenSize === 'sm'"
                                                            />
                                                            <bldc-grid-column col="3" sm="6">
                                                                <bldc-paragraph margin="false">
                                                                    <amount
                                                                        :amount="
                                                                            getInitialValueByGelId(getRuleGelId(rule))
                                                                                .value
                                                                        "
                                                                        :rounded="false"
                                                                    />
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                        <template
                                                            v-for="(mutationValues, mutationKey) in getMutations(
                                                                getRuleGelId(rule),
                                                            )"
                                                        >
                                                            <bldc-grid-row
                                                                :containerColumns="12"
                                                                :key="mutationKey + rule.gelId"
                                                            >
                                                                <bldc-grid-column col="9" sm="12">
                                                                    <bldc-paragraph margin="false">
                                                                        <template v-if="!mutationValues.translate">
                                                                            {{ mutationValues.name }}
                                                                        </template>
                                                                        <template v-if="mutationValues.translate">
                                                                            {{ $t(mutationValues.name) }}
                                                                        </template>
                                                                    </bldc-paragraph>
                                                                </bldc-grid-column>
                                                                <bldc-grid-column
                                                                    col="6"
                                                                    sm="6"
                                                                    v-if="screenSize === 'sm'"
                                                                />
                                                                <bldc-grid-column col="3" sm="6">
                                                                    <bldc-paragraph margin="false">
                                                                        <amount
                                                                            :amount="mutationValues.value"
                                                                            :rounded="false"
                                                                        />
                                                                    </bldc-paragraph>
                                                                </bldc-grid-column>
                                                            </bldc-grid-row>
                                                        </template>
                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column col="9" sm="12" />
                                                            <bldc-grid-column
                                                                col="6"
                                                                sm="6"
                                                                v-if="screenSize === 'sm'"
                                                            />
                                                            <bldc-grid-column col="3" sm="6">
                                                                <div
                                                                    style="float: right; width: 85%; border-top: 1px solid black;"
                                                                />
                                                                <div
                                                                    style="position: absolute; right: -2px; top: -14px;"
                                                                >
                                                                    <bldc-icon icon="plus" font-size="12"></bldc-icon>
                                                                </div>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>

                                                        <bldc-grid-row :containerColumns="12">
                                                            <bldc-grid-column col="9" sm="6">
                                                                <bldc-paragraph margin="false">
                                                                    <div class="total-amount-info-box">
                                                                        {{ $t("year_overview.totaal") }}
                                                                    </div>
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                            <bldc-grid-column col="3" sm="6">
                                                                <bldc-paragraph margin="false">
                                                                    <amount
                                                                        :amount="getRuleValue(rule)"
                                                                        :rounded="false"
                                                                        style="font-weight: 900"
                                                                    />
                                                                </bldc-paragraph>
                                                            </bldc-grid-column>
                                                        </bldc-grid-row>
                                                    </template>
                                                </div>
                                            </bldc-grid-column>
                                        </bldc-grid-row>

                                        <!-- card subtotal -->
                                        <div
                                            class="bd-card-total"
                                            v-if="
                                                costsRevenueBoth(subject.rules) === 'both' &&
                                                    (isLastCosts(ruleIndex, subject.rules) ||
                                                        isLastRevenue(ruleIndex, subject.rules))
                                            "
                                        >
                                            <bldc-grid-row :containerColumns="12">
                                                <bldc-grid-column col="5" sm="11" />
                                                <bldc-grid-column
                                                    col="3"
                                                    sm="6"
                                                    v-if="isLastRevenue(ruleIndex, subject.rules)"
                                                />
                                                <bldc-grid-column col="3" sm="6">
                                                    <div style="padding-left: 10px;">
                                                        <div class="bd-total-amount">
                                                            <div class="icon">
                                                                <bldc-icon icon="plus" font-size="12"></bldc-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </bldc-grid-column>
                                                <bldc-grid-column
                                                    col="3"
                                                    sm="6"
                                                    v-if="isLastRevenue(ruleIndex, subject.rules)"
                                                />
                                            </bldc-grid-row>
                                            <bldc-grid-row :containerColumns="12">
                                                <bldc-grid-column col="5" sm="11">
                                                    <bldc-paragraph margin="false">
                                                        <div class="flex-row">
                                                            <div class="no-question-button"></div>
                                                            <div style="font-weight: 900;"></div>
                                                        </div>
                                                    </bldc-paragraph>
                                                </bldc-grid-column>
                                                <bldc-grid-column
                                                    col="3"
                                                    sm="6"
                                                    v-if="isLastRevenue(ruleIndex, subject.rules)"
                                                />
                                                <bldc-grid-column col="3" sm="6">
                                                    <bldc-paragraph margin="false">
                                                        <amount
                                                            :amount="
                                                                isLastRevenue(ruleIndex, subject.rules)
                                                                    ? getSubjectTotalRevenue(subject, values)
                                                                    : getSubjectTotalCosts(subject, values) || 0
                                                            "
                                                            :rounded="rounded"
                                                        />
                                                    </bldc-paragraph>
                                                </bldc-grid-column>
                                                <bldc-grid-column
                                                    col="3"
                                                    sm="6"
                                                    v-if="isLastRevenue(ruleIndex, subject.rules)"
                                                >
                                                </bldc-grid-column>
                                            </bldc-grid-row>
                                            <bldc-grid-row
                                                :containerColumns="12"
                                                v-if="isLastCosts(ruleIndex, subject.rules)"
                                            >
                                                <bldc-grid-column col="5" sm="11">
                                                    <bldc-paragraph margin="false">
                                                        <div class="no-question-button" />
                                                    </bldc-paragraph>
                                                </bldc-grid-column>
                                                <bldc-grid-column col="3" sm="6">
                                                    <bldc-paragraph margin="false">
                                                        <div class="arrow-to-right">
                                                            <img
                                                                :src="$t('check.totaal_pijl_afbeelding')"
                                                                :alt="$t('check.totaal_pijl_afbeelding_text')"
                                                            />
                                                        </div>
                                                    </bldc-paragraph>
                                                </bldc-grid-column>
                                                <bldc-grid-column col="3" sm="6">
                                                    <bldc-paragraph margin="false">
                                                        <amount
                                                            :amount="getSubjectTotalCosts(subject, values) || 0"
                                                            :rounded="rounded"
                                                            style="padding-top: 0.625rem"
                                                        />
                                                    </bldc-paragraph>
                                                </bldc-grid-column>
                                            </bldc-grid-row>
                                        </div>
                                        <!-- end card subtotal -->
                                    </div>
                                </template>
                                <!-- end card content -->

                                <!-- card total -->
                                <div class="bd-card-total">
                                    <bldc-grid-row :containerColumns="12">
                                        <bldc-grid-column col="5" sm="11" />
                                        <bldc-grid-column
                                            col="3"
                                            :sm="subject.columns && subject.columns.length > 2 ? 4 : 6"
                                            v-if="costsRevenueBoth(subject.rules) !== 'costs'"
                                        />

                                        <bldc-grid-column
                                            col="3"
                                            :sm="3"
                                            v-if="subject.columns.length === 3 && screenSize === 'sm'"
                                        ></bldc-grid-column>
                                        <bldc-grid-column
                                            col="3"
                                            :sm="subject.columns && subject.columns.length > 2 ? 4 : 6"
                                        >
                                            <div style="padding-left: 10px;">
                                                <div class="bd-total-amount">
                                                    <div class="icon">
                                                        <bldc-icon
                                                            v-if="costsRevenueBoth(subject.rules) !== 'both'"
                                                            icon="plus"
                                                            font-size="12"
                                                        ></bldc-icon>
                                                        <bldc-icon
                                                            v-if="costsRevenueBoth(subject.rules) === 'both'"
                                                            icon="min"
                                                            font-size="12"
                                                        ></bldc-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </bldc-grid-column>
                                        <bldc-grid-column col="2" v-if="costsRevenueBoth(subject.rules) === 'costs'" />
                                    </bldc-grid-row>
                                    <bldc-grid-row :containerColumns="12">
                                        <bldc-grid-column col="5" :sm="subject.columns.length === 3 ? 5 : 11">
                                            <bldc-paragraph margin="false">
                                                <div class="flex-row">
                                                    <div class="no-question-button"></div>
                                                    <div style="font-weight: 900;">
                                                        {{ $t("year_overview.totaal") }}
                                                        <span style="text-transform: lowercase;">{{
                                                            subject.title
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </bldc-paragraph>
                                        </bldc-grid-column>
                                        <bldc-grid-column
                                            col="3"
                                            :sm="subject.columns && subject.columns.length > 2 ? 3 : 6"
                                            v-if="costsRevenueBoth(subject.rules) !== 'costs'"
                                        />
                                        <bldc-grid-column
                                            col="3"
                                            :sm="subject.columns && subject.columns.length > 2 ? 3 : 6"
                                        >
                                            <bldc-paragraph margin="false">
                                                <amount
                                                    :amount="
                                                        hasRepeatRule(subject)
                                                            ? getRepeatSubjectTotal(subject, values, finalPostKeyData)
                                                            : getSubjectTotal(subject, values, totalProfit) || 0
                                                    "
                                                    :rounded="rounded"
                                                />
                                            </bldc-paragraph>
                                        </bldc-grid-column>
                                        <bldc-grid-column
                                            col="3"
                                            :sm="subject.columns && subject.columns.length > 2 ? 3 : 6"
                                            v-if="costsRevenueBoth(subject.rules) === 'costs'"
                                        >
                                        </bldc-grid-column>
                                    </bldc-grid-row>
                                </div>
                                <!-- end card total -->
                            </div>
                        </div>
                    </div>
                    <div v-if="subject.isSubtotal">
                        <div class="bd-card-subtotal">
                            <bldc-grid-row :containerColumns="12">
                                <bldc-grid-column col="5" sm="11">
                                    <bldc-paragraph margin="false">
                                        <div style="padding-top: 0.6rem;">
                                            <div class="no-question-button"></div>
                                            <bldc-heading level="2">{{ subject.name }}</bldc-heading>
                                        </div>
                                    </bldc-paragraph>
                                </bldc-grid-column>
                                <bldc-grid-column col="3" sm="6">
                                    <bldc-paragraph margin="false">
                                        <div class="arrow-to-right">
                                            <img
                                                :src="$t('check.totaal_pijl_afbeelding')"
                                                :alt="$t('check.totaal_pijl_afbeelding_text')"
                                            />
                                        </div>
                                    </bldc-paragraph>
                                </bldc-grid-column>
                                <bldc-grid-column col="3" sm="6">
                                    <bldc-paragraph margin="false">
                                        <amount
                                            :amount="getCostsUntilSubject(subjects, subjectIndex, values) || 0"
                                            :rounded="rounded"
                                            :isTotal="true"
                                        />
                                    </bldc-paragraph>
                                </bldc-grid-column>
                            </bldc-grid-row>
                            <bldc-grid-row :containerColumns="12">
                                <bldc-grid-column col="5" sm="11"> </bldc-grid-column>
                                <bldc-grid-column col="3" sm="6"> </bldc-grid-column>
                                <bldc-grid-column col="3" sm="6">
                                    <div style="padding-left: 10px;">
                                        <div class="calc-icon-wrapper">
                                            <div class="calc-icon">
                                                <bldc-icon
                                                    v-if="subject.position === 'omzet'"
                                                    icon="plus"
                                                    font-size="12"
                                                ></bldc-icon>
                                                <bldc-icon
                                                    v-if="subject.position === 'kosten'"
                                                    icon="min"
                                                    font-size="12"
                                                ></bldc-icon>
                                            </div>
                                        </div>
                                    </div>
                                </bldc-grid-column>
                            </bldc-grid-row>
                            <bldc-grid-row :containerColumns="12">
                                <bldc-grid-column col="5" sm="11">
                                    <bldc-paragraph margin="false">
                                        <div style="padding-top: 0.6rem;">
                                            <div class="no-question-button"></div>
                                            <bldc-heading level="2">{{ $t("year_overview.subtotaal") }}</bldc-heading>
                                        </div>
                                    </bldc-paragraph>
                                </bldc-grid-column>
                                <bldc-grid-column col="3" sm="6"> </bldc-grid-column>
                                <bldc-grid-column col="3" sm="6">
                                    <bldc-paragraph margin="false">
                                        <amount
                                            :amount="getTotalUntilSubject(subjects, subjectIndex, values) || 0"
                                            :rounded="rounded"
                                            :isTotal="true"
                                        />
                                    </bldc-paragraph>
                                </bldc-grid-column>
                            </bldc-grid-row>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style="background-color: #FFFFFF; margin-bottom: 10px; padding: 1.5rem 0.5rem;"
                v-if="numbersTag !== 'prive'"
            >
                <bldc-grid-row :containerColumns="12">
                    <bldc-grid-column col="5" sm="11"> </bldc-grid-column>
                    <bldc-grid-column col="3" sm="6"> </bldc-grid-column>
                    <bldc-grid-column col="3" sm="6">
                        <div style="padding-left: 10px;">
                            <div class="calc-icon-wrapper">
                                <div class="calc-icon">
                                    <bldc-icon icon="plus" font-size="12"></bldc-icon>
                                </div>
                            </div>
                        </div>
                    </bldc-grid-column>
                </bldc-grid-row>
                <bldc-grid-row :containerColumns="12">
                    <bldc-grid-column col="8" sm="11">
                        <div class="flex-row">
                            <div class="no-question-button"></div>
                            <h4 class="bd-total-saldo">
                                {{
                                    numbersTag !== "winstenverlies"
                                        ? $t("year_overview.totaal") + " " + lowerPageTitle
                                        : $t("year_overview.saldo_winst_en_verliesrekening")
                                }}
                            </h4>
                        </div>
                    </bldc-grid-column>
                    <bldc-grid-column v-if="screenSize === 'sm'" col="3" sm="6"></bldc-grid-column>
                    <bldc-grid-column col="3" sm="6">
                        <bldc-paragraph margin="false">
                            <amount
                                :amount="getTotal(subjects, values, finalPostKeyData, totalProfit) || 0"
                                :rounded="rounded"
                                :isTotal="true"
                                style="font-weight: 900;"
                            />
                        </bldc-paragraph>
                    </bldc-grid-column>
                </bldc-grid-row>
            </div>
            <footer-navigation
                :invalid-accountancy-button="yearOverviewOrCheck !== 'check'"
                v-on:invalid-accountancy="openInvalidAccountancyModal"
                v-on:nav-next="setStatusForSheet"
            />
        </main>

        <bldc-modal
            :visible="invalidAccountancyToggle"
            id="invalidAccountancyConfirm"
            v-on:bldcModalClose="closeInvalidAccountancyModal()"
            v-on:bldcModalConfirm="$store.dispatch('save/saveAndLeave')"
            :buttons="
                JSON.stringify([
                    { type: 'secondary', label: $t('global.annuleren'), event: 'close' },
                    {
                        type: 'primary',
                        label: $t('year_overview.opslaan_en_naar_boekhouding'),
                        event: 'confirm',
                    },
                ])
            "
            modalId="invalidAccountancyConfirm"
        >
            <bldc-heading level="3">
                {{ $t("year_overview.er_zijn_cijfers_die_niet_kloppen") }}
            </bldc-heading>
            <bldc-paragraph :margin="false">
                {{ $t("year_overview.er_zijn_cijfers_die_niet_kloppen_tekst") }}
            </bldc-paragraph>
            <bldc-paragraph :margin="false">
                {{ $t("year_overview.eerder_ingevulde_gegevens_blijven_bewaard") }}
            </bldc-paragraph>
        </bldc-modal>
        <handle-forms
            ref="handleForms"
            :statics="statics"
            v-on:final-post-key-data="setFinalPostKeyData"
            v-on:final-post-data="setFinalPostData"
        />
    </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import footerNavigation from "../components/footerNavigation";
import amount from "../components/yearOverview/amount";
import yoSwitch from "../components/yearOverview/switch";
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
import { getHelpTextsList, getCustomHelpTextsList } from "../modules/singletons/helpTextsList";
import { getYearOverview } from "../modules/singletons/yearOverview";
import getHelpText from "../modules/utils/getHelpText";
import getStatics from "../modules/singletons/statics";
import profit from "../modules/calculations/rgs/profit";
import { getMBmgAwaData } from "../modules/singletons/mBmgAwaData";
import removeObservers from "../modules/utils/removeObservers";
import { saveCurrentState } from "../modules/singletons/currentState";
import tippy from "tippy.js";
import HandleForms from "../components/HandleForms";
import {
    getSubjectTotal,
    getSubjectTotalCosts,
    getSubjectTotalRevenue,
    getCostsUntilSubject,
    getTotalUntilSubject,
    isLastRevenue,
    isLastCosts,
    costsRevenueBoth,
    getTotal,
    getRepeatSubjectTotal,
} from "../modules/utils/annualAccountCalculation";
import shepherd from "../mixins/shepherd";
import resumeDeclaration from "../mixins/resumeDeclaration";
export default {
    name: "yearOverview",
    mixins: [shepherd, resumeDeclaration],
    components: {
        footerNavigation,
        HandleForms,
        amount,
        yoSwitch,
    },
    data() {
        return {
            currentHelpText: null,
            currentRgsRules: null,
            closedCards: {},
            statics: getStatics(),
            roundedSwitch: false,
            highlightSwitch: false,
            finalPostKeyData: {},
            finalPostData: {},
            roundingHelp: false,
            invalidAccountancyToggle: false,
            currentRepeatInfo: null,
            scrollSubjects: {},
        };
    },
    computed: {
        ...mapGetters({
            rgsHeaders: "rgsLedger/headers",
            ebValues: "rgsLedger/ebValues",
            sbValues: "rgsLedger/sbValues",
            dbValues: "rgsLedger/dbValues",
            ecgValues: "rgsLedger/ecgValues",
            mappedRules: "rgsLedger/mappedRules",
            hasMutataionsByGelId: "rgsLedger/hasMutataionsByGelId",
            getMutationsByGelId: "rgsLedger/getMutationsByGelId",
            getMutataionGroupsByGelId: "rgsLedger/getMutataionGroupsByGelId",
            getInitialValueByGelId: "rgsLedger/getInitialValueByGelId",
            getValuesWithoutMutations: "rgsLedger/values",
            getWithAllMutations: "rgsLedger/getWithAllMutations",
            getMutationsWith: "rgsLedger/getMutationsWith",
            getTitle: "rgsLedger/getTitle",
            firstPossibleRoute: "navigation/firstPossibleRoute",
            screenSize: "screen/size",
        }),
        introText() {
            if (this.yearOverviewOrCheck === "check") {
                return this.$t("check.intro_text");
            }
            if (this.$route.params.sheet === "profit-and-loss-account") {
                return this.$t("year_overview.intro_tekst_winst_verliesrekening");
            } else if (this.$route.params.sheet === "balance-assets") {
                return this.$t("year_overview.intro_tekst_balans_activa");
            } else if (this.$route.params.sheet === "balance-liabilities") {
                return this.$t("year_overview.intro_tekst_balans_passiva");
            }
            return this.$t("year_overview.intro_tekst_priveonttrekkingen_en_stortingen");
        },
        currentSheet() {
            if (this.$route.params && this.$route.params.sheet) {
                return this.$route.params.sheet;
            }
            return null;
        },
        rounded() {
            return this.roundedSwitch || this.$route.name === "check";
        },
        yearOverviewOrCheck() {
            if (this.$route.name === "check") {
                return "check";
            }
            return "yearOverview";
        },
        screenName() {
            if (this.$route.name === "check") {
                return "fiscal";
            }
            return "accounting";
        },
        values() {
            if (this.yearOverviewOrCheck === "yearOverview") {
                if (this.rounded) {
                    return this.getMutationsWith("rgs_rounding");
                }
                return this.getValuesWithoutMutations;
            }
            return this.getWithAllMutations();
        },
        roundedValues() {
            if (this.yearOverviewOrCheck === "yearOverview") {
                return this.getMutationsWith("rgs_rounding");
            }
            return this.getWithAllMutations();
        },
        subjects() {
            let subjects = removeObservers(getYearOverview()[this.numbersTag]);
            if (this.$route.name === "check") {
                subjects.forEach(subject => {
                    remove(subject.rules, rule => {
                        return rule.gelId === "resultaat";
                    });
                });
            }
            return subjects;
        },
        numbersTag() {
            const currentCheck = this.$store.getters["navigation/currentCheckObject"](this.$route.params.sheet);
            return currentCheck.numbersTag;
        },
        pageTitle() {
            if (this.$route.name === "check") {
                const translationTag = this.$store.getters["navigation/currentCheckObject"](this.$route.params.sheet);
                return this.$t("check." + translationTag.tag);
            }
            const translationTag = this.$store.getters["navigation/currentYearOverviewObject"](
                this.$route.params.sheet,
            );
            return this.$t("year_overview." + translationTag.tag);
        },
        totalProfit: function() {
            return profit(getMBmgAwaData(), removeObservers(this.values));
        },
        lowerPageTitle() {
            return this.pageTitle.toLowerCase();
        },
    },
    methods: {
        scrollSubject(event, subjectRef) {
            Vue.set(this.scrollSubjects, subjectRef, {
                left: event.target.scrollLeft,
                max: event.target.scrollWidth - event.target.clientWidth,
            });
        },
        scrollSubjectButton(subjectRef, left) {
            const scrollContainer = this.$refs[subjectRef][0];
            if (left) {
                const newScrollDistance = scrollContainer.scrollLeft - 25;
                scrollContainer.scrollLeft = newScrollDistance >= 0 ? newScrollDistance : 0;
            }
            if (!left) {
                const newScrollDistance = scrollContainer.scrollLeft + 25;
                scrollContainer.scrollLeft = newScrollDistance;
            }
        },
        showScrollArrow(subjectRef, left) {
            if (!this.scrollSubjects[subjectRef]) {
                return left ? false : true;
            }

            if (this.scrollSubjects[subjectRef].left > 10 && left) return true;
            if (this.scrollSubjects[subjectRef].left < this.scrollSubjects[subjectRef].max - 10 && !left) return true;

            return false;
        },
        showRule(rule, ruleIndex, subject) {
            return (
                (subject.columns.length > 0 && this.hasRepeatRule(subject) && !rule.column) ||
                (subject.columns.length > 0 &&
                    this.hasRepeatRule(subject) &&
                    rule.position === "herhaal" &&
                    this.yearOverviewOrCheck === "check" &&
                    rule.column &&
                    this.firstRepeatColumnRule(subject, ruleIndex)) ||
                (subject.columns.length <= 0 && !this.hasRepeatRule(subject)) ||
                (subject.columns.length > 0 && this.isLastColumn(subject, rule) && !this.hasRepeatRule(subject)) ||
                (this.showRgsRules(rule) && (this.hasRgsRules(rule) || this.hasRgsMutations(rule))) ||
                (costsRevenueBoth(subject.rules) === "both" &&
                    (isLastCosts(ruleIndex, subject.rules) || isLastRevenue(ruleIndex, subject.rules)))
            );
        },
        getMutations: function(gelId) {
            // if (this.$route.params.sheet !== "profit-calculation") {
            return this.getMutationsByGelId(gelId);
            // }
            // return this.getMutataionGroupsByGelId(gelId);
        },
        profit: (mBmgAwaData, values) => profit(mBmgAwaData, values),
        getSubjectTotal: (subject, values, profit) => getSubjectTotal(subject, values, profit),
        getSubjectBeginYearTotal: (subject, values, profit) =>
            getSubjectTotal(subject, values, profit, null, null, true),
        getRepeatSubjectTotal: function(subject, values, finalPostKeyData) {
            let repeatTotal;
            if (this.yearOverviewOrCheck === "yearOverview") {
                repeatTotal = getRepeatSubjectTotal(subject, values, {}, this.screenName);
            } else {
                repeatTotal = getRepeatSubjectTotal(subject, values, finalPostKeyData, this.screenName);
            }
            if (this.finalPostData["wpontauttot"] && this.yearOverviewOrCheck === "check") {
                return this.finalPostData["wpontauttot"] + repeatTotal;
            }
            return repeatTotal;
        },
        getSubjectTotalCosts: (subject, values) => getSubjectTotalCosts(subject, values),
        getSubjectTotalRevenue: (subject, values) => getSubjectTotalRevenue(subject, values),
        getCostsUntilSubject: (subjects, subjectIndex, values) => getCostsUntilSubject(subjects, subjectIndex, values),
        getTotalUntilSubject: (subjects, subjectIndex, values) => getTotalUntilSubject(subjects, subjectIndex, values),
        isLastRevenue: (index, rules) => isLastRevenue(index, rules),
        isLastCosts: (index, rules) => isLastCosts(index, rules),
        costsRevenueBoth: rules => costsRevenueBoth(rules),
        getTotal: (subjects, values, finalPostKeyData, profit) => getTotal(subjects, values, finalPostKeyData, profit),
        getHelpText: refId => getHelpText(refId),
        isLastColumn: (subject, rule) => {
            const columnIndex = findIndex(subject.columns, column => column.title === rule.column);
            return columnIndex === subject.columns.length - 1;
        },
        saveAndLeave: function() {},
        hasRepeatRule: subject => {
            const columnIndex = findIndex(subject.rules, rule => rule.position === "herhaal");
            return columnIndex >= 0;
        },
        getColumnValue: function(subject, rule, column) {
            const columnIndex = findIndex(subject.rules, otherRule => {
                return otherRule.column === column.title && rule.title === otherRule.title;
            });
            if (columnIndex >= 0) {
                return this.getValue(subject.rules[columnIndex].gelId, subject.rules[columnIndex].dimension) || 0;
            }
            return null;
        },
        showColumnValue: function(subject, rule, column) {
            const columnIndex = findIndex(subject.columns, otherColumn => {
                return otherColumn.title === column.title;
            });
            return columnIndex === subject.columns.length - 1 || this.getColumnValue(subject, rule, column);
        },
        setFinalPostKeyData: function(postData) {
            if (this.$route.name === "check") {
                Vue.set(this, "finalPostKeyData", postData);
            }
        },
        setFinalPostData: function(postData) {
            if (this.$route.name === "check") {
                Vue.set(this, "finalPostData", postData);
            }
        },
        openRoundingHelp: function() {
            this.roundingHelp = true;
        },
        closeRoundingHelp: function() {
            this.roundingHelp = false;
        },
        closeInvalidAccountancyModal: function() {
            this.invalidAccountancyToggle = false;
        },
        openInvalidAccountancyModal: function() {
            this.invalidAccountancyToggle = true;
        },
        helpTextList: function() {
            return getHelpTextsList();
        },
        customHelpTextList: function() {
            return getCustomHelpTextsList();
        },
        hasHelpText: function(refId) {
            if (this.customHelpTextList().indexOf(refId) >= 0) {
                return true;
            }
            return this.helpTextList().indexOf(refId) >= 0;
        },
        hasRepeatableHelpText: function(subject, columnTitle) {
            const ruleIndex = findIndex(subject.rules, rule => {
                return rule.column === columnTitle && rule.refId;
            });
            if (ruleIndex < 0) {
                return false;
            }
            if (this.customHelpTextList().indexOf(subject.rules[ruleIndex].refId) >= 0) {
                return true;
            }
            return this.helpTextList().indexOf(subject.rules[ruleIndex].refId) >= 0;
        },
        firstRepeatColumnRule: function(subject, ruleIndex) {
            if (
                subject.rules[ruleIndex - 1] &&
                subject.rules[ruleIndex - 1].position === "herhaal" &&
                !subject.rules[ruleIndex - 1].column
            ) {
                return true;
            }
            return false;
        },
        getRepeatValues: function(subject, ruleIndex) {
            let groupGelId;
            if (subject.rules[ruleIndex - 1] && subject.rules[ruleIndex - 1].gelId) {
                groupGelId = subject.rules[ruleIndex - 1].gelId;
            } else {
                return [];
            }
            if (this.finalPostKeyData[groupGelId]) {
                return this.finalPostKeyData[groupGelId];
            }
            return [];
        },
        hasRepeatValues: function(subject) {
            let hasRepeatValues = false;
            if (subject.rules) {
                subject.rules.forEach(rule => {
                    if (rule.position === "herhaal" && !rule.column && this.finalPostKeyData[rule.gelId]) {
                        hasRepeatValues = true;
                    }
                });
            }
            return hasRepeatValues;
        },
        getRepeatValue: function(subject, ruleIndex, columnIndex, repeatValueIndex) {
            const repeatValues = this.getRepeatValues(subject, ruleIndex, columnIndex);
            if (!repeatValues[repeatValueIndex]) {
                return null;
            }
            if (columnIndex === 0) {
                return repeatValues[repeatValueIndex][subject.rules[ruleIndex].gelId];
            }
            if (columnIndex === 1) {
                return repeatValues[repeatValueIndex][subject.rules[ruleIndex + 1].gelId];
            }
            return null;
        },
        getRepeatableHelpTextRefId: function(subject, columnTitle) {
            const ruleIndex = findIndex(subject.rules, rule => {
                return rule.column === columnTitle && rule.refId;
            });
            if (ruleIndex < 0) {
                return null;
            }
            return subject.rules[ruleIndex].refId;
        },
        isCardClosed: function(subjectIndex) {
            return this.closedCards[subjectIndex] !== true;
        },
        toggleCard: function(subjectIndex, subject) {
            if (this.showSubjectMutations(subject)) {
                return;
            }
            if (this.closedCards[subjectIndex]) {
                Vue.set(this.closedCards, subjectIndex, false);
                return;
            }
            Vue.set(this.closedCards, subjectIndex, true);
        },
        openHelpText: function(refId, subject) {
            if (this.showSubjectMutations(subject)) {
                return;
            }
            if (this.currentHelpText === refId) {
                this.closeHelpText();
                return;
            }
            this.currentHelpText = refId;
            this.getHelpText(refId)
                .then(element => {
                    this.$refs["ref-id-" + refId][0].innerHtml = "";
                    this.$refs["ref-id-" + refId][0].textContent = "";
                    this.$refs["ref-id-" + refId][0].appendChild(element);
                    Vue.nextTick(() => {
                        this.addTippyToHelp(this.$refs["ref-id-" + refId][0]);
                    });
                })
                .catch(() => {
                    this.$refs["ref-id-" + refId][0].textContent = "Help text not found for ref id: " + refId;
                });
        },
        addTippyToHelp(ref) {
            tippy(ref.querySelectorAll(".tooltip"), {
                theme: "light",
                trigger: "mouseenter focus click",
            });
        },
        openRepeatableHelpText: function(subject, columnTitle) {
            const ruleIndex = findIndex(subject.rules, rule => {
                return rule.column === columnTitle && rule.refId;
            });
            if (ruleIndex < 0) {
                return false;
            }
            this.openHelpText(subject.rules[ruleIndex].refId);
        },
        closeHelpText: function() {
            this.currentHelpText = null;
        },
        showHelpText: function(refId) {
            return this.currentHelpText === refId;
        },
        getValue: function(gelId, dimension) {
            if (gelId === "resultaat") {
                return this.totalProfit;
            }
            if (gelId === "wpontauttot") {
                return this.finalPostData["wpontauttot"];
            }
            if (dimension) {
                return this.values[gelId + "-" + dimension];
            }
            return this.values[gelId] || 0;
        },
        getRoundedValue: function(gelId, dimension) {
            if (gelId === "resultaat") {
                return this.totalProfit;
            }
            if (gelId === "wpontauttot") {
                return this.finalPostData["wpontauttot"];
            }
            if (dimension) {
                return this.roundedValues[gelId + "-" + dimension];
            }
            return this.roundedValues[gelId] || 0;
        },
        getRuleValue: function(rule) {
            return this.getValue(rule.gelId, rule.dimension);
        },
        getRoundedRuleValue: function(rule) {
            return this.getRoundedValue(rule.gelId, rule.dimension);
        },
        getRuleGelId: function(rule) {
            if (rule.dimension) {
                return rule.gelId + "-" + rule.dimension;
            }
            return rule.gelId;
        },
        setStatusForSheet: function() {
            if (this.yearOverviewOrCheck !== "check") {
                this.$store.dispatch("navigation/setYearOverviewStatus", {
                    sheet: this.$route.params.sheet,
                    status: true,
                });
                saveCurrentState();
                return;
            }
            this.$store.dispatch("navigation/setCheckStatus", { sheet: this.$route.params.sheet, status: true });
            saveCurrentState();
        },
        resumeDeclaration: function() {
            if (this.firstPossibleRoute.routeParams) {
                this.$router.push({
                    name: this.firstPossibleRoute.routeName,
                    params: this.firstPossibleRoute.routeParams,
                });
            } else if (this.firstPossibleRoute.routeName !== "download") {
                this.$router.push({ name: this.firstPossibleRoute.routeName });
            }
            this.$store.dispatch("navigation/setCurrentItem", this.firstPossibleRoute.routeName);
        },
        openRgsRules: function(rule) {
            if (this.currentRgsRules === this.getRuleGelId(rule)) {
                this.closeRgsRules();
                return;
            }
            this.currentRgsRules = this.getRuleGelId(rule);
        },
        showRgsRules: function(rule) {
            return this.currentRgsRules === this.getRuleGelId(rule);
        },
        hasRgsRules: function(rule) {
            return !!this.mappedRules[this.getRuleGelId(rule)] && this.yearOverviewOrCheck !== "check";
        },
        hasRgsMutations: function(rule) {
            return this.hasMutataionsByGelId(this.getRuleGelId(rule)) && this.yearOverviewOrCheck === "check";
        },
        closeRgsRules: function() {
            this.currentRgsRules = null;
        },
        openRepeatInfo: function(rule, repeatValueIndex) {
            if (
                this.currentRepeatInfo &&
                this.currentRepeatInfo.gelId === this.getRuleGelId(rule) &&
                this.currentRepeatInfo.index === repeatValueIndex
            ) {
                this.closeRepeatInfo();
                return;
            }
            Vue.set(this, "currentRepeatInfo", { gelId: this.getRuleGelId(rule), index: repeatValueIndex });
        },
        showRepeatInfo: function(rule, repeatValueIndex) {
            if (!this.currentRepeatInfo) {
                return false;
            }
            return (
                this.currentRepeatInfo.gelId === this.getRuleGelId(rule) &&
                this.currentRepeatInfo.index === repeatValueIndex
            );
        },
        closeRepeatInfo: function() {
            this.currentRepeatInfo = null;
        },
        positiveValue: function(value) {
            if (value < 0) {
                return value * -1;
            }
            return value;
        },
        mappedRuleValue: function(mappedRule) {
            if (typeof mappedRule.value === "undefined") {
                return 0;
            }
            if (mappedRule.value < 0) {
                return mappedRule.value * -1;
            }
            return mappedRule.value;
        },
        getTotalCredit: function(mappedRules) {
            let total = 0;
            mappedRules.forEach(mappedRule => {
                if (mappedRule.debitCredit === "C") {
                    total += mappedRule.value;
                }
            });
            return Number(total).toFixed(2) * 1;
        },
        getTotalDebit: function(mappedRules) {
            let total = 0;
            mappedRules.forEach(mappedRule => {
                if (mappedRule.debitCredit === "D") {
                    total += mappedRule.value;
                }
            });
            if (total < 0) {
                total = total * -1;
            }
            return Number(total).toFixed(2) * 1;
        },
        getTotalMutationCredit: function(mutationRules) {
            let total = 0;
            mutationRules.forEach(mutationRule => {
                if (mutationRule.value > 0) {
                    total += mutationRule.value;
                }
            });
            return Number(total).toFixed(2) * 1;
        },
        getTotalMutationDebit: function(mutationRules) {
            let total = 0;
            mutationRules.forEach(mutationRule => {
                if (mutationRule.value < 0) {
                    total += mutationRule.value;
                }
            });
            if (total < 0) {
                total = total * -1;
            }
            return Number(total).toFixed(2) * 1;
        },
        hasSubjectMutations: function(subject) {
            let hasMutations = false;
            if (subject.rules) {
                subject.rules.forEach(rule => {
                    if (this.hasRgsMutations(rule)) {
                        hasMutations = true;
                    }
                });
            }
            return hasMutations;
        },
        showSubjectMutations: function(subject) {
            if (this.yearOverviewOrCheck === "yearOverview") {
                return false;
            }
            if (!this.highlightSwitch) {
                return false;
            }
            if (this.hasRepeatValues(subject)) {
                return false;
            }
            return !this.hasSubjectMutations(subject);
        },
        getColumnsLength: function(count) {
            if (count > 2) {
                if (this.screenSize !== "sm") {
                    return 2;
                } else {
                    return 4;
                }
            }
            if (this.screenSize !== "sm") {
                return 3;
            }
            return 6;
        },
    },
    watch: {
        "$route.params.sheet": function() {
            if (this.$route.name === "check") {
                this.$store.dispatch("navigation/setCurrentCheck", this.$route.params.sheet);
            } else {
                this.$store.dispatch("navigation/setCurrentYearOverview", this.$route.params.sheet);
            }
            Vue.set(this, "closedCards", {});
            this.$refs.handleForms.download();
            Vue.nextTick(() => {
                this.initShepherd();
            });
        },
        highlightSwitch: function(newValue) {
            Vue.set(this, "closedCards", {});
            this.currentHelpText = null;
            if (newValue) {
                const firstChangedEl = document.getElementsByClassName("has-changes")[0];
                if (firstChangedEl) {
                    firstChangedEl.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.$route.name === "check") {
                vm.$store.dispatch("navigation/setCurrentItem", `checks`);
                vm.$store.dispatch("navigation/setCurrentCheck", vm.$route.params.sheet);
            } else {
                vm.$store.dispatch("navigation/setCurrentItem", `year-overviews`);
                vm.$store.dispatch("navigation/setCurrentYearOverview", vm.$route.params.sheet);
            }
            Vue.set(vm, "closedCards", {});
            vm.$refs.handleForms.download();
        });
    },
    mounted: function() {
        this.$nextTick(() => {
            this.initShepherd();
        });
    },
};
</script>
<style lang="scss">
.tippy-box {
    font-family: ROregular, Verdana, Arial, sans-serif;
}
</style>
<style lang="scss" scoped>
.bd-toggle {
    line-height: 1.5rem;
    margin: 0.5rem 0px;
    transform: rotateX(-180deg);
    transition: transform 0.5s ease-in-out;

    @media only screen and (max-width: 767px) {
        margin-left: -0.5rem;
    }
}
.bd-toggle-rotate {
    transform: rotateX(0deg);
    transition: transform 0.5s ease-in-out;
}
.card-open {
    .bd-toggle {
        line-height: 1rem;
        margin: 0rem 0px;
        @media only screen and (max-width: 767px) {
            margin-left: -0.5rem;
        }
    }
    .bd-toggle-rotate {
        transform: rotateX(0deg);
    }
}
.bd-card {
    background-color: #ffffff;
    margin-bottom: 10px;
    .bd-card-title {
        padding: 0.875rem 0.5rem;
        cursor: pointer;
        .bd-subject-total {
            line-height: 1.5rem;
            font-size: 110%;
            margin: 0.625rem 0px;
            .bd-amount {
                display: block;
                float: right;
            }
        }
        .bd-card-title-header {
            font-family: RObold, Verdana, Arial, sans-serif;
            font-size: 1.3125rem;
            line-height: 1.75rem;
            font-weight: normal;
            white-space: normal;
            margin: 0.625rem 0px;
        }
        &:hover {
            .bd-card-title-header {
                color: rgb(1, 104, 155) !important;
            }
        }
    }
    &.no-changes {
        // filter: blur(0.08rem);
        opacity: 0.25;
        .bd-card-title {
            cursor: default;
        }
        .question-button {
            cursor: default;
        }
        &:hover {
            .bd-card-title-header {
                color: black !important;
            }
        }
    }
    .bd-card-rule {
        padding: 0 0.5rem 0.5rem;
        &.has-rgs-rules {
            transition: none;
            cursor: pointer;
            &:hover {
                color: rgb(1, 104, 155);
            }
        }
    }
    .bd-card-total {
        padding: 0 0.5rem 1.5rem;
        .bd-total-amount {
            border-bottom: 2px solid black;
            float: right;
            position: relative;
            width: 80%;
            .icon {
                position: absolute;
                right: -15px;
                bottom: -8px;
                font-weight: 600;
            }
        }
    }
    .flex-row {
        display: flex;
        flex-direction: row;
    }
}
.bd-total-saldo {
    font-family: RObold, Verdana, Arial, sans-serif;
    font-size: 1.3125rem;
    line-height: 1.75rem;
    font-weight: normal;
    white-space: normal;
    margin: 0.625rem 0px;
}
.bd-card-subtotal {
    padding: 1.5rem 0.5rem;
    .title {
        line-height: 1.5rem;
        font-size: 110%;
        margin: 0.625rem 0px;
    }
    .amount {
        display: block;
        float: right;
    }
    .total-amount {
        line-height: 1.5rem;
        font-size: 110%;
        margin: 0.625rem 0px;
    }
}
.calc-icon-wrapper {
    border-bottom: 2px solid black;
    float: right;
    position: relative;
    width: 80%;
    .calc-icon {
        position: absolute;
        right: -15px;
        bottom: -8px;
        font-weight: 600;
    }
}
.question-button {
    border: 1px solid rgb(1, 104, 155);
    height: 16px;
    width: 16px;
    text-align: center;
    margin-top: 4px;
    float: left;
    margin-right: 5px;
    cursor: pointer;
    flex-shrink: 0;
    .question-wrapper {
        margin-top: -6px;
        color: rgb(1, 104, 155);
    }
    .question-wrapper-i {
        color: rgb(1, 104, 155);
        font-weight: 900;
        margin-top: -5px;
        margin-left: 1px;
    }
}
.no-question-button {
    height: 16px;
    width: 18px;
    text-align: center;
    margin-top: 3px;
    float: left;
    margin-right: 5px;
    flex-shrink: 0;
}
.arrow-to-right {
    border-top: 2px solid black;
    float: right;
    position: relative;
    width: 70%;
    font-size: 220%;
    text-align: right;
    line-height: 2.1rem;
    padding-right: 2.2rem;
    margin-top: -1rem;
    img {
        width: 20px;
        height: 20px;
    }
}
.year-overview-content,
.tax-profit-content {
    .help-box {
        margin-top: 5px;
        display: block;
        background-color: rgb(221, 239, 248);
        width: calc(100% - 1.75rem);
        padding: 12px;
        z-index: 2000;
        font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
    }
    .help-box:before {
        position: absolute;
        margin-top: -20px;
        width: 0;
        height: 0;
        content: " ";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgb(221, 239, 248);
    }
    .help-box-close {
        display: block;
        float: right;
        cursor: pointer;
        padding: 0.7rem;
        border: 1px solid transparent;
        margin: -9px -10px;
        background-color: transparent;
    }
    .help-box-close:hover {
        border: 1px solid rgb(1, 104, 155);
    }
    .rgs-rules-box {
        margin-top: 10px;
        display: block;
        background-color: #efefef;
        color: #000000;
        width: calc(100% - 20px);
        font-size: 86%;
        padding: 10px;
        margin-bottom: 10px;
        &:before {
            position: absolute;
            margin-top: -20px;
            right: 200px;
            width: 0;
            height: 0;
            content: " ";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #efefef;
        }
    }
    .rgs-rules-box {
        background-color: rgba(238, 247, 251, 1);
        &:before {
            border-bottom: 10px solid rgba(238, 247, 251, 1);
        }
    }
}
.card-open {
    max-height: 999999px;
    transition: all 0.5s ease-in-out 0s;
    overflow: hidden;
    > div {
        opacity: 1;
        transition: all 0.5s ease-in-out 0s;
    }
}
.card-closed {
    max-height: 0 !important;
    transition: all 0.5s ease-in-out 0s;
    div {
        opacity: 0;
        transition: all 0.5s ease-in-out 0s;
    }
}
.total-amount-info-box {
    width: 100%;
    text-align: right;
    font-weight: 900;
    @media only screen and (max-width: 767px) {
        text-align: left;
    }
}
.column-title {
    font-family: RObold, Verdana, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
}
.scroll-three-columns-container {
    overflow-x: auto;
    .scroll-three-columns {
        @media only screen and (max-width: 767px) {
            min-width: 720px;
        }
    }
    .scroll-arrow {
        display: none;

        @media only screen and (max-width: 720px) {
            display: flex;
        }
        align-items: center;
        justify-content: center;
        text-decoration: none;
        position: absolute;
        text-align: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding-left: 12px;
        color: white;
        background-color: rgba(143, 202, 231, 0.85);
        z-index: 3;
        font-size: 24px;
        margin-top: 5rem;
        &.left {
            left: 2px;
            width: 40px;
            padding-left: 10px;
        }
        &.right {
            right: 2px;
            width: 38px;
            padding-left: 12px;
        }
    }
}
</style>
