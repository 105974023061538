import errorMessage from "./messages";

export default value => {
    const regex = "^[EM][0-9]{9}$";

    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = [];

    const re = new RegExp(regex);

    if (!re.test(value)) {
        errors.push(errorMessage("both_report_numbers_invalid"));
    }

    return errors;
};
