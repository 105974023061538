import statics from "../../singletons/statics";

const hlpmwpontautpercbijttoegel = (wpontauttoegel, wpontautzeroemissie, wpontautwaterstof, wpontautstandcata) => {
    // hlpmwpontautpercbijttoegel

    // is 0,04 als {wpontauttoegel} = 2,3 EN {wpontautzeroemissie} = Ja
    if ((wpontauttoegel === 2 || wpontauttoegel === 3) && wpontautzeroemissie === "J") {
        return statics().BDRGPERCBIJTAUTOEMISSIEMIN1 / 100;
    }

    // is 0,22 als {wpontauttoegel} = 2,3 EN {wpontautzeroemissie} = Nee
    if ((wpontauttoegel === 2 || wpontauttoegel === 3) && wpontautzeroemissie === "N") {
        return statics().BDRGPERCBIJTAUTOLAAG / 100;
    }

    // is 0,22 als {wpontauttoegel} = 4,5 EN {wpontautwaterstof} = Nee
    if ((wpontauttoegel === 4 || wpontauttoegel === 5) && wpontautwaterstof === "N") {
        return statics().BDRGPERCBIJTAUTOLAAG / 100;
    }

    // is 0,04 als {wpontauttoegel} = 4 EN {wpontautzeroemissie} = Ja EN {wpontautstandcata} <= {BDRGBIJTAUTOMAXEMISSIEMIN1}
    if (
        wpontauttoegel === 4 &&
        wpontautzeroemissie === "J" &&
        wpontautstandcata <= statics().BDRGBIJTAUTOMAXEMISSIEMIN1
    ) {
        return statics().BDRGPERCBIJTAUTOEMISSIEMIN1 / 100;
    }

    // is 0,08 als {wpontauttoegel} = 5 EN {wpontautzeroemissie} = Ja EN {wpontautstandcata} <= {BDRGBIJTAUTOMAXEMISSIE}
    if (wpontauttoegel === 5 && wpontautzeroemissie === "J" && wpontautstandcata <= statics().BDRGBIJTAUTOMAXEMISSIE) {
        return statics().BDRGPERCBIJTAUTOEMISSIE / 100;
    }

    // is 0,04 als {wpontautwaterstof} = Ja EN {wpontauttoegel} = 4
    if (wpontautwaterstof === "J" && wpontauttoegel === 4) {
        return statics().BDRGPERCBIJTAUTOEMISSIEMIN1 / 100;
    }

    // is 0,08 als (wpontautwaterstof} = Ja EN {wpontauttoegel} = 5
    if (wpontautwaterstof === "J" && wpontauttoegel === 5) {
        return statics().BDRGPERCBIJTAUTOEMISSIE / 100;
    }

    // is ((({BDRGPERCBIJTAUTOEMISSIEMIN1}* {BDRGBIJTAUTOMAXEMISSIEMIN1}) +
    //     (({wpontautstandcata} -/- {BDRGBIJTAUTOMAXEMISSIEMIN1}) * {BDRGPERCBIJTAUTOLAAG})) /
    //     {wpontautstandcata}
    //    )/100
    // als {wpontauttoegel} = 4 EN {wpontautzeroemissie} = Ja EN {wpontautstandcata} > {BDRGBIJTAUTOMAXEMISSIEMIN1}
    if (
        wpontauttoegel === 4 &&
        wpontautzeroemissie === "J" &&
        wpontautstandcata > statics().BDRGPERCBIJTAUTOEMISSIEMIN1
    ) {
        return (
            (statics().BDRGPERCBIJTAUTOEMISSIEMIN1 * statics().BDRGBIJTAUTOMAXEMISSIEMIN1 +
                (wpontautstandcata - statics().BDRGBIJTAUTOMAXEMISSIEMIN1) * statics().BDRGPERCBIJTAUTOLAAG) /
            wpontautstandcata /
            100
        );
    }

    // is ((({BDRGPERCBIJTAUTOEMISSIE}* {BDRGBIJTAUTOMAXEMISSIE}) +
    //     (({wpontautstandcata} -/- {BDRGBIJTAUTOMAXEMISSIE}) * {BDRGPERCBIJTAUTOLAAG})) /
    //     {wpontautstandcata}
    //    )/100
    // als {wpontauttoegel} = 5 EN {wpontautzeroemissie} = Ja EN {wpontautstandcata} > {BDRGBIJTAUTOMAXEMISSIE}
    if (wpontauttoegel === 5 && wpontautzeroemissie === "J" && wpontautstandcata > statics().BDRGBIJTAUTOMAXEMISSIE) {
        return (
            (statics().BDRGPERCBIJTAUTOEMISSIE * statics().BDRGBIJTAUTOMAXEMISSIE +
                (wpontautstandcata - statics().BDRGBIJTAUTOMAXEMISSIE) * statics().BDRGPERCBIJTAUTOLAAG) /
            wpontautstandcata /
            100
        );
    }

    console.warn("is not covered in hlpmwpontautpercbijttoegel");
    return; //return hlpmwpontautpercbijttoegel
};
export default hlpmwpontautpercbijttoegel;
