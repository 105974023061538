<template>
    <div class="bd-forms">
        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
        <a href="#main-content" id="go-to-main-content" class="sr-only" v-if="showPage">{{
            $t("global.ga_naar_hoofdinhoud")
        }}</a>
        <div style="background-color: #ffffff;">
            <bldc-logo domain="belastingdienst" url="https://www.belastingdienst.nl"></bldc-logo>
        </div>
        <div :style="headerFix" />
        <div
            class="backdrop"
            v-if="screenSize !== 'all' && (showSideBarMenu || showUserMenu)"
            @click="closeMenus"
            :style="backdropStyles"
        />
        <div
            class="header-bar blue-bar bd-primary-color"
            v-if="showHeader"
            role="contentinfo"
            :class="{ fixed: fixedNavbar }"
        >
            <bldc-grid :disable-padding="screenSize !== 'all'">
                <bldc-grid-row :containerColumns="12">
                    <bldc-grid-column
                        :col="0"
                        :md="2"
                        class="menu-button-container"
                        :disable-padding="screenSize !== 'all'"
                    >
                        <button
                            class="menu-button"
                            @click="toggleMenu('sidebar')"
                            aria-haspopup="true"
                            aria-controls="sidebar-menu"
                            :aria-expanded="showSideBarMenu"
                        >
                            <bldc-icon icon="menu" v-if="!showSideBarMenu"></bldc-icon>
                            <bldc-icon icon="afsluiten" v-if="showSideBarMenu"></bldc-icon>
                            <span class="menu-button-text">{{ $t("global.menu") }}</span>
                        </button>
                    </bldc-grid-column>
                    <bldc-grid-column :col="8" :lg="8" :disable-padding="screenSize !== 'all'">
                        <h2 class="title">
                            {{
                                $t("global.voorbereiden_ondernemingsgegevens_voor_aangifte_inkomstenbelasting", {
                                    year: this.year,
                                })
                            }}
                        </h2>
                    </bldc-grid-column>
                    <bldc-grid-column
                        :col="0"
                        :md="2"
                        class="menu-button-container right"
                        :disable-padding="screenSize !== 'all'"
                    >
                        <div class="menu-flex-container">
                            <button
                                class="menu-button"
                                :aria-label="$t('global.gebruiker')"
                                @click="toggleMenu('user')"
                                aria-haspopup="true"
                                aria-controls="user-menu"
                                :aria-expanded="showUserMenu"
                            >
                                <bldc-icon icon="gebruiker" v-if="!showUserMenu"></bldc-icon>
                                <bldc-icon icon="afsluiten" v-if="showUserMenu"></bldc-icon>
                            </button>
                        </div>
                    </bldc-grid-column>
                    <bldc-grid-column
                        :col="4"
                        :md="12"
                        :sm="12"
                        class="user-menu"
                        v-if="screenSize === 'all' || showUserMenu"
                    >
                        <h3 class="title right" id="user-menu" aria-labelledby="user-menu">
                            {{ $store.getters["formValues/values"]["wondnaam"] || null }}
                        </h3>
                    </bldc-grid-column>
                </bldc-grid-row>
            </bldc-grid>
        </div>
        <div class="main-content">
            <div v-if="error">
                <bldc-grid>
                    <bldc-grid-row :containerColumns="12">
                        <bldc-grid-column :col="12" :sm="12">
                            <bldc-notification type="storing" :elem-title="error.title">
                                <bldc-paragraph :margin="false">
                                    {{ error.message }}
                                </bldc-paragraph>
                                <bldc-paragraph :margin="false" id="error-code">
                                    {{ $t("global.foutcode") }}: <strong>{{ error.code }}</strong>
                                </bldc-paragraph>
                            </bldc-notification>
                        </bldc-grid-column>
                        <bldc-grid-column :col="12" :sm="12" style="text-align: center; margin-bottom: 1rem;">
                            <bldc-button type="primary" @click="closePlugin()" elem-title="Sluiten"></bldc-button>
                        </bldc-grid-column>
                    </bldc-grid-row>
                </bldc-grid>
            </div>
            <div v-if="showPage">
                <bldc-grid :disable-padding="true">
                    <bldc-grid-row :containerColumns="12">
                        <bldc-grid-column :col="1" :sm="0" />
                        <bldc-grid-column :col="10" :sm="12">
                            <navigation v-if="navLoaded" />
                        </bldc-grid-column>
                    </bldc-grid-row>
                    <bldc-grid-row :containerColumns="12">
                        <bldc-grid-column :col="12" :sm="12" :disable-padding="true">
                            <bldc-grid-row :containerColumns="12">
                                <bldc-grid-column col="4" md="12" id="side-navigation">
                                    <div style="margin-top: 3.5rem;" v-if="screenSize === 'all' || showSideBarMenu">
                                        <sidebar @clicked="showSideBarMenu = !showSideBarMenu" />
                                    </div>
                                </bldc-grid-column>
                                <bldc-grid-column col="8" md="12" id="main-content">
                                    <transition name="fade">
                                        <router-view></router-view>
                                    </transition>
                                </bldc-grid-column>
                            </bldc-grid-row>
                        </bldc-grid-column>
                    </bldc-grid-row>
                </bldc-grid>
            </div>
        </div>

        <handle-forms
            ref="handleForms"
            :statics="statics"
            v-on:form-mutations="saveMutations"
            v-on:finished="formHandlerFinished"
        />

        <bldc-modal :visible="showSavingModal" id="savingModal" modalId="savingModal" v-if="showPage">
            <bldc-heading level="3">
                {{ $t("global.opslaan") }}
            </bldc-heading>
            <bldc-paragraph :margin="false">
                {{ $t("global.gegevens_worden_opgeslagen_een_moment_aub") }}
            </bldc-paragraph>
        </bldc-modal>

        <bldc-modal
            close-btn="false"
            :visible="showDesktopModal"
            id="desktopModal"
            modalId="desktopModal"
            v-if="showPage"
        >
            <bldc-heading level="3">
                {{ $t("global.alleen_beschikbaar_op_desktop") }}
            </bldc-heading>
            <bldc-paragraph :margin="false">
                {{ $t("global.alleen_beschikbaar_op_desktop_tekst") }}
            </bldc-paragraph>
            <bldc-paragraph :margin="false">
                {{ $t("global.alleen_beschikbaar_op_desktop_tekst_2") }}
            </bldc-paragraph>
        </bldc-modal>
    </div>
</template>

<script>
import Vue from "vue";
import navigation from "./components/navigation";
import router from "./router";
import { i18n } from "@/locale";
import isPromise from "./modules/utils/isPromise";
import axios from "axios";
import errors, { setErrors } from "./data/errors";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import mapKeys from "lodash/mapKeys";
import { mapGetters } from "vuex";
import { setForm } from "./modules/singletons/jsonForms";
// import { setTaxCheck, setHelpText } from "./modules/singletons/jsonTaxChecks";
import { setCurrentStateFn } from "./modules/singletons/currentState";
import { setClosePluginFn, closePlugin } from "./modules/singletons/closePlugin";
import { setStatusFn, fireStatus } from "./modules/singletons/statusFn";
import { setConstantsFile, getConstants } from "./modules/singletons/constantsFile";
import { setTourFile } from "./modules/singletons/tour";
import { setMBmgAwaData } from "./modules/singletons/mBmgAwaData";
import { setYearOverview } from "./modules/singletons/yearOverview";
import { setHelpTextsList, setCustomHelpTextsList } from "./modules/singletons/helpTextsList";
import removeObservers from "./modules/utils/removeObservers";
import filePaths from "./data/filePaths";
import { setStatics } from "./modules/singletons/statics";
import { setMessageData, setAppConstants } from "./data/constants";
import HandleForms from "./components/HandleForms";
import { RGS_LEDGER_VERSION, RGS_VERSION } from "./data/constants";
import resumeDeclaration from "./mixins/resumeDeclaration";
import sidebar from "./components/sidebar";

Vue.mixin({ router, i18n });

export default {
    name: "app",
    mixins: [resumeDeclaration],
    props: ["getRGSLedger", "getECG", "saveCurrentState", "close", "getCurrentState", "save", "status"],
    components: {
        navigation,
        HandleForms,
        sidebar,
    },
    data() {
        return {
            error: null,
            navLoaded: null,
            mutLoading: true,
            loading: true,
            startFormHandler: false,
            statics: {},
            year: null,
            showDesktopModal: false,
            showUserMenu: false,
            showSideBarMenu: false,
        };
    },
    computed: {
        ...mapGetters({
            showSavingModal: "save/showModal",
            screenSize: "screen/size",
            fixedNavbar: "screen/fixedNavbar",
            headerBarOffset: "screen/headerBarOffset",
        }),
        backdropStyles() {
            return "top: " + this.headerBarOffset + "px; height: calc(100vh - " + this.headerBarOffset + "px);";
        },
        showHeader: function() {
            return !this.loading;
        },
        headerFix: function() {
            let styles = "";
            if (this.fixedNavbar) {
                if (this.screenSize === "all") {
                    styles + "height: 60px;";
                } else {
                    styles + "height: 50px;";
                }
            }
            return styles;
        },
        showPage: function() {
            return !this.error && !this.loading && !this.mutLoading;
        },
    },
    methods: {
        toggleMenu: function(menu) {
            if (menu === "user") {
                this.showUserMenu = !this.showUserMenu;
                this.showSideBarMenu = false;
            }
            if (menu === "sidebar") {
                this.showSideBarMenu = !this.showSideBarMenu;
                this.showUserMenu = false;
            }
        },
        closeMenus: function() {
            this.showSideBarMenu = false;
            this.showUserMenu = false;
        },
        browserCheck: function() {
            let check = false;
            (function(a) {
                if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                        a,
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                        a.substr(0, 4),
                    )
                )
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        closePlugin: function() {
            closePlugin();
        },
        setError: function(error = null) {
            const fallbackError = errors("fallback");
            if (error) {
                error = Object.assign(fallbackError, error);
            } else {
                error = fallbackError;
            }
            Vue.set(this, "error", error);
            console.error(removeObservers(error));
        },

        saveMutations: function(mutations) {
            // if (mutations.isTaxCheck) {
            //     this.$store.dispatch("rgsLedger/setByMutationKey", {
            //         mutationKey: mutations.formKey + "TaxCheck",
            //         values: removeObservers(mutations.mutations),
            //     });
            // } else {

            this.$store.dispatch("rgsLedger/setByMutationKey", {
                mutationKey: mutations.formKey + "Form",
                values: removeObservers(mutations.mutations),
            });
            // }
        },

        formHandlerFinished: function() {
            this.mutLoading = false;
        },

        unsetError: function() {
            this.error = null;
        },

        validateProps: function() {
            const requiredProperties = ["getRGSLedger", "getECG", "saveCurrentState", "close", "getCurrentState"];
            let isValid = true;

            requiredProperties.forEach(prop => {
                if (!isValid) {
                    return;
                }
                if (!this[prop] || !isFunction(this[prop])) {
                    this.setError(errors(100, { name: prop }));
                    isValid = false;
                }
            });

            return isValid;
        },

        init: function() {
            // if (this.browserCheck()) {
            //     this.showDesktopModal = true;
            // }
            // Get static data promises
            const staticDataPromises = [
                axios.get(filePaths().formNavigation),
                // axios.get(filePaths().taxCheckNavigation),
                axios.get(filePaths().rgsMapping),
                axios.get(filePaths().mBmgAwaData),
                axios.get(filePaths().filterRgs),
                axios.get(filePaths().helpTexts),
                axios.get(filePaths().constantsFile),
                axios.get(filePaths().application),
                // axios.get(filePaths().slides),
                axios.get(filePaths().yearOverview),
                axios.get(filePaths().customHelpTexts),
                axios.get(filePaths().tourFile),
                axios.get(filePaths().helpNavigation),
            ];

            let formNavigationData;
            // let taxCheckNavigationData;
            let rgsMappingData;
            let mBmgAwaData;
            let filterRgsData;
            let formNavigationItems;
            // let taxCheckNavigationItems;
            let helpTextsList;
            let constantsFile;
            let applicationFile;
            // let slides;
            let yearOverview;
            let jsonCurrentState;
            let customHelpTextsList;
            let tourFile;
            let helpNavigationFile;

            // Set current state save callback function
            setCurrentStateFn(this.saveCurrentState);
            setClosePluginFn(this.close);

            if (this.status) {
                setStatusFn(this.status);
            }

            // Get RGS ledger and initialize
            let getRGSLedgerPromise = this.getRGSLedger();

            if (!isPromise(getRGSLedgerPromise)) {
                getRGSLedgerPromise = Promise.resolve(getRGSLedgerPromise);
            }

            // Get ECG and initialize
            let getECGPromise = this.getECG();

            if (!isPromise(getECGPromise)) {
                getECGPromise = Promise.resolve(getECGPromise);
            }

            // Get RGS ledger and initialize
            let getCurrentStatePromise = this.getCurrentState();

            if (!isPromise(getCurrentStatePromise)) {
                getCurrentStatePromise = Promise.resolve(getCurrentStatePromise);
            }

            Promise.all(staticDataPromises)
                .then(responses => {
                    formNavigationData = responses[0].data;
                    // taxCheckNavigationData = responses[1].data;
                    rgsMappingData = responses[1].data;
                    mBmgAwaData = responses[2].data;
                    filterRgsData = responses[3].data;
                    helpTextsList = responses[4].data;
                    constantsFile = responses[5].data;
                    applicationFile = responses[6].data;
                    // slides = responses[7].data;
                    yearOverview = responses[7].data;
                    customHelpTextsList = responses[8].data;
                    tourFile = responses[9].data;
                    helpNavigationFile = responses[10].data;
                    setHelpTextsList(helpTextsList);
                    setCustomHelpTextsList(customHelpTextsList);
                    setConstantsFile(constantsFile);
                    setMBmgAwaData(mBmgAwaData);
                    setMessageData(applicationFile);
                    setAppConstants(applicationFile);
                    setYearOverview(yearOverview);
                    setTourFile(tourFile);

                    this.$store.dispatch("helpNavigation/setItems", helpNavigationFile);

                    return getRGSLedgerPromise;
                })
                .then(xmlString =>
                    this.$store.dispatch("rgsLedger/parseRgs", {
                        xmlString,
                        filterRgsData,
                        rgsConstants: {
                            year: getConstants().BDRGJAAR,
                            rgsLedgerVersion: RGS_LEDGER_VERSION,
                            rgsVersion: RGS_VERSION,
                        },
                    }),
                )
                .then(() => {
                    formNavigationItems = removeObservers(formNavigationData);
                    const formPromises = [];
                    formNavigationItems.forEach((formNavigationItem, formNavigationIndex) => {
                        if (formNavigationItem.header) {
                            return;
                        }
                        formPromises[formNavigationIndex] = axios.get(formNavigationItem.json);
                        this.$store.dispatch("rgsLedger/addMutationKey", formNavigationItem.form + "Form");
                        if (formNavigationItem.mutationGroup) {
                            this.$store.dispatch("rgsLedger/setMutationKeyInGroup", {
                                mutationGroup: formNavigationItem.mutationGroup,
                                mutationKey: formNavigationItem.form,
                            });
                        }
                    });

                    this.$store.dispatch("rgsLedger/addMutationKey", "process_result");
                    this.$store.dispatch("rgsLedger/setMutationKeyInGroup", {
                        mutationGroup: "process_result",
                        mutationKey: "process_result",
                    });
                    return Promise.all(formPromises);
                })
                .then(formResponses => {
                    formNavigationItems.forEach((formNavigationItem, formNavigationIndex) => {
                        if (formNavigationItem.header) {
                            return;
                        }
                        setForm(formNavigationItem.form, formResponses[formNavigationIndex].data);
                    });

                    return Promise.resolve();
                })
                .then(() => this.$store.dispatch("navigation/initForm", [...formNavigationData]))
                .then(() => getCurrentStatePromise)
                .then(currentStateResponse => {
                    if (!isEmpty(currentStateResponse)) {
                        this.$store.dispatch("currentState/setCurrentState", currentStateResponse);
                        fireStatus("current_state_initiated");
                    } else {
                        fireStatus("not_started_before");
                    }

                    jsonCurrentState = currentStateResponse;

                    return Promise.resolve();
                })
                .then(() => this.$store.dispatch("rgsLedger/mapRgs", { rgsMappingData, filterRgsData }))
                .then(() => this.$store.dispatch("rgsLedger/checkMappedRgs", { mBmgAwaData }))
                .then(() => this.$store.dispatch("rgsLedger/roundRgsValues", mBmgAwaData))
                .then(() =>
                    this.$store.dispatch("rgsLedger/checkMappedRgs", { mBmgAwaData, mutationKey: "rgs_rounding" }),
                )
                .then(() => {
                    if (isEmpty(jsonCurrentState)) {
                        return this.$store.dispatch("rgsLedger/assignFormValues");
                    }

                    return Promise.resolve();
                })
                .then(() => getECGPromise)
                .then(xmlString => this.$store.dispatch("formValues/parseEcg", xmlString))
                .then(() => {
                    let roundedRgsLedgerValues = removeObservers(
                        this.$store.getters["rgsLedger/getMutationsWith"]("rgs_rounding"),
                    );
                    roundedRgsLedgerValues = mapKeys(
                        roundedRgsLedgerValues,
                        (roundedRgsLedgerValue, roundedRgsLedgerKey) => {
                            return roundedRgsLedgerKey + "Rounded";
                        },
                    );

                    const windowStatics = window.bdForms && window.bdForms.static ? window.bdForms.static : {};

                    const statics = Object.assign(
                        Object.assign(windowStatics, removeObservers(this.$store.getters["rgsLedger/rgsCodeValue"])),
                        Object.assign(
                            Object.assign(
                                removeObservers(this.$store.getters["rgsLedger/values"]),
                                roundedRgsLedgerValues,
                            ),
                            getConstants(),
                        ),
                    );

                    this.year = statics.BDRGJAAR;

                    setStatics(statics);
                    Vue.set(this, "statics", statics);

                    this.$store.dispatch("formValues/setConflictedValues");

                    this.startFormHandler = true;
                    this.$refs.handleForms.download();
                    this.navLoaded = true;

                    this.loading = false;

                    if (!this.$store.getters["navigation/canAccessRoute"](this.$route)) {
                        this.resumeDeclaration();
                        return;
                    }
                })
                .catch(error => {
                    console.error("rawError", error);

                    // Catch request error for pretty error log
                    if (error && error.request && error.request instanceof XMLHttpRequest) {
                        const params = {};
                        if (error.config) {
                            params["filePath"] = error.config.url;
                        }
                        error = errors(112, params);
                    }

                    this.loading = false;
                    this.setError(error);
                })
                .then(() => {
                    fireStatus("files_successfully_initiated");
                });
        },
    },
    watch: {
        "$route.fullPath": function() {
            if (!this.loading && !this.$store.getters["navigation/canAccessRoute"](this.$route)) {
                this.$nextTick(() => {
                    this.resumeDeclaration();
                });
                return;
            }
        },
        showHeader: function() {
            Vue.nextTick(() => {
                this.$store.dispatch("screen/setScreenOffsets");
            });
        },
        fixedNavbar: function() {
            Vue.nextTick(() => {
                this.$store.dispatch("screen/setHeaderBarOffset");
            });
        },
    },
    created() {
        this.$store.dispatch("screen/setScreenSize");
        axios
            .get(filePaths().errors)
            .then(response => {
                setErrors(response.data);
                if (this.validateProps()) {
                    this.init();
                }
            })
            .catch(() => {
                this.loading = false;
                this.setError({
                    code: "NO_ERRORS_FILE",
                    explain: "Failed to load error file.",
                });
            });
    },
};
</script>

<style lang="scss">
body {
    margin: 0;
    background-color: #eef7fb;
    :focus {
        outline: rgb(0, 123, 199) solid 2px;
        outline-offset: 2px;
    }
}
.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.bd-forms {
    position: relative;
    margin: 0;
    font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
    font-size: 1.125rem;
    line-height: 1.75rem;
    background-color: #eef7fb;
    clear: both;
}
.icon,
.bld-icon {
    font-family: bldiconfont !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
    font-feature-settings: "liga" 1;
    white-space: nowrap;
}

.main-content {
    background-color: #eef7fb;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    min-height: calc(100vh - 230px);
}
.backdrop {
    position: fixed;
    height: 100vh;
    width: 100vh;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 100;
}
.header-bar {
    font-family: RObold, "Segoe UI", "Helvetica Neue", sans-serif;
    font-weight: normal;
    white-space: normal;
    margin: 0px;
    color: rgb(0, 0, 0);
    height: 60px;
    z-index: 200;
    @media only screen and (max-width: 991px) {
        height: 50px;
    }
    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 200;
        // @media only screen and (max-width: 767px) {
        //     top: 5px;
        // }
    }
    &.blue-bar {
        padding: 0;
    }
    &.bd-primary-color {
        background-color: #8fcae7;
    }
    .menu-button-container {
        font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
        font-size: 1.125rem;
        @media only screen and (min-width: 991px) {
            display: none;
        }
        .menu-button {
            height: 50px;
            min-width: 50px;
            padding-left: 10px;
            border: 0;
            background-color: rgba(255, 255, 255, 0.3);
        }
        .menu-button-text {
            display: none;
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 4px;
            @media only screen and (min-width: 769px) and (max-width: 991px) {
                display: block;
                float: right;
            }
        }
        &.right {
            .menu-flex-container {
                display: flex;
                align-items: right;
                justify-content: right;

                .menu-button {
                    background-color: transparent;
                }
            }
        }
    }
    .title {
        font-size: 1.25rem;
        line-height: 60px;
        padding: 0;
        @media only screen and (max-width: 991px) {
            font-size: 1.125rem;
            line-height: 50px;
        }
        margin: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.right {
            text-align: right;
            @media only screen and (max-width: 991px) {
                font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
                font-weight: normal;
                font-size: 1.125rem;
                line-height: 50px;
                max-width: 255px;
                background-color: #ffffff;
                float: right;
                margin-right: -10px;
                padding-right: 10px;
                padding-left: 10px;
                text-align: left;
                z-index: 200;
                position: relative;
            }
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s ease;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
