let helpTextsList = [];
let customHelpTextsList = [];

export const setHelpTextsList = list => {
    helpTextsList = list;
};

export const setCustomHelpTextsList = list => {
    customHelpTextsList = list;
};

export const getHelpTextsList = () => {
    return helpTextsList;
};

export const getCustomHelpTextsList = () => {
    return customHelpTextsList;
};
