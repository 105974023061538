export default {
    methods: {
        groupLoop: function(callback) {
            this.schema.groups.forEach((group, groupIndex) => {
                callback(group, groupIndex);
            });
        },
        fieldLoop: function(callback) {
            this.schema.groups.forEach((group, groupIndex) => {
                group.fields.forEach((field, fieldIndex) => {
                    callback(field, group, fieldIndex, groupIndex);
                });
            });
        },
        fieldComponentLoop: function(callback) {
            if (this.$refs.vfg) {
                this.$refs.vfg.$children.forEach(fieldComponent => {
                    callback(fieldComponent);
                });
            }
        },
    },
};
