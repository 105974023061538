import { modelExists, repeatModelExists } from "../../singletons/registeredModels";
let showedErrors = [];

const rmExists = (field, groupModelKey) => {
    if (!repeatModelExists(field.repeatableGroup, groupModelKey)) {
        if (field.model) {
            console.warn(
                "Repeat model '" +
                    groupModelKey +
                    "' in group '" +
                    field.repeatableGroup +
                    "' called but does not exists in field with model: " +
                    field.model,
            );
        } else if (field.refid) {
            console.warn(
                "Repeat model '" +
                    groupModelKey +
                    "' in group '" +
                    field.repeatableGroup +
                    "' called but does not exists in field with refid: " +
                    field.refid,
            );
        } else {
            console.warn(
                "Repeat model '" +
                    groupModelKey +
                    "' in group '" +
                    field.repeatableGroup +
                    "' called but does not exists in field without model or refid",
            );
        }
    }
};

export default (
    value,
    staticData,
    modelData,
    field,
    staticAffix = "Static",
    modelAffix = "Model",
    groupAffix = "RepeatModel",
    showValueWarnings = false,
    importedAffix = "Imported",
) => {
    if (
        (typeof value !== "string" && !(value instanceof String)) ||
        (!value.endsWith(staticAffix) &&
            !value.endsWith(modelAffix) &&
            !value.endsWith(groupAffix) &&
            !value.endsWith(importedAffix))
    ) {
        return value;
    }

    if (value.endsWith(importedAffix)) {
        let modelKey = value.substring(0, value.lastIndexOf(importedAffix));

        if (typeof staticData[value] === "undefined" && showedErrors.indexOf(value) < 0) {
            showedErrors.push(value);
            if (showValueWarnings) {
                // console.warn("No imported value for: " + value);
            }
        }

        return modelData[modelKey];
    }

    if (value.endsWith(staticAffix)) {
        let staticKey = value.substring(0, value.lastIndexOf(staticAffix));

        if (typeof staticData[staticKey] === "undefined" && showedErrors.indexOf(staticKey) < 0) {
            showedErrors.push(staticKey);
            if (showValueWarnings) {
                // console.warn("No static value set with key: " + staticKey);
            }
        }

        return staticData[staticKey];
    }

    if (
        value.endsWith(groupAffix) &&
        modelData[field.repeatableGroup] &&
        modelData[field.repeatableGroup][field.repeatableIndex]
    ) {
        let groupModelKey = value.substring(0, value.lastIndexOf(groupAffix));

        rmExists(field, groupModelKey);

        return modelData[field.repeatableGroup][field.repeatableIndex][groupModelKey];
    } else if (value.endsWith(groupAffix)) {
        let groupModelKey = value.substring(0, value.lastIndexOf(groupAffix));
        rmExists(field, groupModelKey);
        return undefined;
    }

    let modelKey = value.substring(0, value.lastIndexOf(modelAffix));

    if (!modelExists(modelKey)) {
        if (field.model) {
            console.warn("Model '" + modelKey + "' called but does not exists in field with model: " + field.model);
        } else if (field.refid) {
            console.warn("Model '" + modelKey + "' called but does not exists in field with refid: " + field.refid);
        } else {
            // console.warn("Model '" + modelKey + "' called but does not exists in field without model or refid");
        }
    }

    return modelData[modelKey];
};
