import forEach from "lodash/forEach";
import findLastIndex from "lodash/findLastIndex";
import findIndex from "lodash/findIndex";

export const getValue = (gelID, values) => {
    if (values[gelID]) {
        return values[gelID];
    }
    return null;
};

export const getGroupTotal = (group, values) => {
    let total = 0;
    forEach(group.rules, rule => {
        total += getValue(rule.gelID, values);
    });

    return total;
};

// export const getSubjectTotal = (subject, values) => {
//     let total = 0;
//     forEach(subject.groups, group => {
//         if (group.calc === "plus") {
//             total += getGroupTotal(group, values);
//         } else if (group.calc === "min") {
//             total -= getGroupTotal(group, values);
//         }
//     });

//     return total;
// };

export const getSubjectTotalCalc = subject => {
    let calc = "plus";
    forEach(subject.groups, group => {
        calc = group.calc;
    });

    return calc;
};

export const getTopicTotalUntilSubject = (topic, untilSubjectIndex, values) => {
    let total = 0;
    forEach(topic.subjects, (subject, subjectIndex) => {
        if (subjectIndex > untilSubjectIndex) {
            return;
        }

        let subTotal = getSubjectTotal(subject, values);

        if (subject.category === "kosten") {
            total -= subTotal;
            // total -= Math.ceil(subTotal);
        } else if (subject.category === "omzet") {
            total += subTotal;
            // total += Math.floor(subTotal);
        }
    });
    total = total.toFixed(2) * 1;

    return total;
};

export const getTopicCostsUntilSubject = (topic, untilSubjectIndex, values) => {
    let total = 0;
    forEach(topic.subjects, (subject, subjectIndex) => {
        if (subjectIndex > untilSubjectIndex) {
            return;
        }

        let subTotal = getSubjectTotal(subject, values);

        if (subject.category === "kosten") {
            total += subTotal;
        }
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getTopicTotal = (topic, values) => {
    let total = 0;

    forEach(topic.subjects, subject => {
        let subTotal = getSubjectTotal(subject, values);

        if (subject.category === "kosten") {
            total -= subTotal;
            // total -= Math.ceil(subTotal);
        } else if (subject.category === "omzet") {
            total += subTotal;
            // total += Math.floor(subTotal);
        }
    });
    total = total.toFixed(2) * 1;

    return total;
};

/////////////////// NEW!
export const costsRevenueBoth = rules => {
    let hasCosts = false;
    let hasRevenue = false;
    let hasAssets = false;
    let hasLiabilities = false;
    let hasPostKeys = false;
    rules.forEach(rule => {
        if (rule.position === "kosten") {
            hasCosts = true;
        }
        if (rule.position === "omzet") {
            hasRevenue = true;
        }
        if (rule.position === "activa") {
            hasAssets = true;
        }
        if (rule.position === "passiva") {
            hasLiabilities = true;
        }
        if (rule.postKey) {
            hasPostKeys = true;
        }
    });

    if (hasAssets) {
        return "assets";
    }

    if (hasLiabilities) {
        return "liabilities";
    }

    if (hasCosts && hasRevenue) {
        return "both";
    }

    if (hasPostKeys) {
        return "both";
    }

    if (hasCosts) {
        return "costs";
    }
    return "revenue";
};

export const getSubjectTotalCosts = (subject, values, screen = null) => {
    let total = 0;
    forEach(subject.rules, rule => {
        if (rule.screen && rule.screen !== screen) {
            return;
        }
        if (rule.position === "kosten") {
            total += rule.dimension
                ? getValue(rule.gelId + "-" + rule.dimension, values)
                : getValue(rule.gelId, values);
        }
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getSubjectTotalRevenue = (subject, values, screen = null) => {
    let total = 0;
    forEach(subject.rules, rule => {
        if (rule.screen && rule.screen !== screen) {
            return;
        }
        if (rule.position === "omzet") {
            total += rule.dimension
                ? getValue(rule.gelId + "-" + rule.dimension, values)
                : getValue(rule.gelId, values);
        }
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getSubjectTotalPostKeys = (subject, values, screen = null, finalPostKeyData = null) => {
    let total = 0;
    forEach(subject.rules, rule => {
        if (rule.screen && rule.screen !== screen) {
            return;
        }
        if (rule.postKey && finalPostKeyData[rule.postKey]) {
            total += finalPostKeyData[rule.postKey];
        }
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getSubjectTotalAssetsLiabilities = (subject, values, type, profit, screen = null, beginYear = null) => {
    let total = 0;

    forEach(subject.rules, rule => {
        if (beginYear && rule.dimension !== "SB") {
            return;
        }
        if (rule.screen && rule.screen !== screen) {
            return;
        }
        if (rule.gelId === "resultaat") {
            total += profit;
            return;
        }
        const columnIndex = findIndex(subject.columns, column => {
            return rule.column === column.title;
        });
        const columnIndexByPosition = beginYear ? subject.columns.length - 2 : subject.columns.length - 1;

        if (rule.position === type && columnIndex === columnIndexByPosition) {
            total += rule.dimension
                ? getValue(rule.gelId + "-" + rule.dimension, values)
                : getValue(rule.gelId, values);
        }
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getSubjectTotal = (
    subject,
    values,
    profit = null,
    screen = null,
    finalPostKeyData = null,
    beginYear = null,
) => {
    let total = 0;

    if (costsRevenueBoth(subject.rules) === "costs") {
        total += getSubjectTotalCosts(subject, values, screen);
    }

    if (costsRevenueBoth(subject.rules) === "revenue") {
        total += getSubjectTotalRevenue(subject, values, screen);
    }

    if (costsRevenueBoth(subject.rules) === "both") {
        total += getSubjectTotalRevenue(subject, values, screen);
        total -= getSubjectTotalCosts(subject, values, screen);
        total += getSubjectTotalPostKeys(subject, values, screen, finalPostKeyData);
    }

    if (costsRevenueBoth(subject.rules) === "assets") {
        total += getSubjectTotalAssetsLiabilities(subject, values, "activa", profit, screen, beginYear);
    }

    if (costsRevenueBoth(subject.rules) === "liabilities") {
        total += getSubjectTotalAssetsLiabilities(subject, values, "passiva", profit, screen, beginYear);
    }

    if (subject.postKey && finalPostKeyData && finalPostKeyData[subject.postKey]) {
        total += finalPostKeyData[subject.postKey];
    }

    total = total.toFixed(2) * 1;

    return total;
};

export const firstRepeatColumnRule = (subject, ruleIndex) => {
    if (
        subject.rules[ruleIndex - 1] &&
        subject.rules[ruleIndex - 1].position === "herhaal" &&
        !subject.rules[ruleIndex - 1].column
    ) {
        return true;
    }
    return false;
};

export const getRepeatValues = (subject, ruleIndex, postKeyData) => {
    let groupGelId;
    if (subject.rules[ruleIndex - 1] && subject.rules[ruleIndex - 1].gelId) {
        groupGelId = subject.rules[ruleIndex - 1].gelId;
    } else {
        return [];
    }
    if (postKeyData[groupGelId]) {
        return postKeyData[groupGelId];
    }

    return [];
};

export const getRepeatValue = (subject, ruleIndex, columnIndex, repeatValueIndex, postKeyData) => {
    const repeatValues = getRepeatValues(subject, ruleIndex, postKeyData);

    if (!repeatValues[repeatValueIndex]) {
        return null;
    }

    if (columnIndex === 0) {
        return repeatValues[repeatValueIndex][subject.rules[ruleIndex].gelId];
    }

    if (columnIndex === 1) {
        return repeatValues[repeatValueIndex][subject.rules[ruleIndex + 1].gelId];
    }

    return null;
};

export const getRepeatSubjectTotal = (subject, values, postKeyData, screen) => {
    let total = 0;
    total += getSubjectTotal(subject, values, null, screen);
    let groupValues = [];

    forEach(subject.rules, (rule, ruleIndex) => {
        if (rule.screen && rule.screen !== screen) {
            return;
        }
        if (firstRepeatColumnRule(subject, ruleIndex)) {
            groupValues = getRepeatValues(subject, ruleIndex, postKeyData);
            forEach(groupValues, (groupValue, groupValueIndex) => {
                const repeatValue = getRepeatValue(subject, ruleIndex, 1, groupValueIndex, postKeyData);
                if (repeatValue) {
                    total += getRepeatValue(subject, ruleIndex, 1, groupValueIndex, postKeyData);
                }
            });
        }
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getCostsUntilSubject = (subjects, untilSubjectIndex, values) => {
    let total = 0;
    forEach(subjects, (subject, subjectIndex) => {
        if (subjectIndex > untilSubjectIndex) {
            return;
        }

        let subTotal = getSubjectTotalCosts(subject, values);

        total += subTotal;
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getRevenueUntilSubject = (subjects, untilSubjectIndex, values) => {
    let total = 0;
    forEach(subjects, (subject, subjectIndex) => {
        if (subjectIndex > untilSubjectIndex) {
            return;
        }

        let subTotal = getSubjectTotalRevenue(subject, values);

        total += subTotal;
    });

    total = total.toFixed(2) * 1;

    return total;
};

export const getTotalUntilSubject = (subjects, untilSubjectIndex, values) => {
    let total = 0;
    total += getRevenueUntilSubject(subjects, untilSubjectIndex, values);
    total -= getCostsUntilSubject(subjects, untilSubjectIndex, values);

    return total;
};

export const isLastRevenue = (index, rules) => {
    const lastIndex = findLastIndex(rules, function(rule) {
        return rule.position == "omzet";
    });

    return lastIndex === index;
};

export const isLastCosts = (index, rules) => {
    const lastIndex = findLastIndex(rules, function(rule) {
        return rule.position == "kosten";
    });

    return lastIndex === index;
};

export const hasRepeatRule = subject => {
    const columnIndex = findIndex(subject.rules, rule => rule.position === "herhaal");
    return columnIndex >= 0;
};

export const getTotal = (subjects, values, postKeyData, profit) => {
    let total = 0;

    forEach(subjects, subject => {
        if (subject.isSubtotal) {
            return;
        }

        let subTotal = hasRepeatRule(subject)
            ? getRepeatSubjectTotal(subject, values, postKeyData)
            : getSubjectTotal(subject, values, profit);

        if (costsRevenueBoth(subject.rules) === "costs") {
            total -= subTotal;
        }

        if (costsRevenueBoth(subject.rules) === "revenue") {
            total += subTotal;
        }

        if (costsRevenueBoth(subject.rules) === "both") {
            total += subTotal;
        }

        if (costsRevenueBoth(subject.rules) === "assets") {
            total += subTotal;
        }

        if (costsRevenueBoth(subject.rules) === "liabilities") {
            total += subTotal;
        }
    });
    total = total.toFixed(2) * 1;

    return total;
};
