import forEach from "lodash/forEach";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import isNaN from "lodash/isNaN";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";

export const getMutationDelta = (currentValues, newValues) => {
    let delta = {};

    forEach(newValues, (newValueOrGroups, newValueKey) => {
        if (isObject(newValueOrGroups)) {
            // If complete group does not exists add it
            if (isUndefined(currentValues[newValueKey])) {
                delta[newValueKey] = newValueOrGroups;
                return;
            }

            delta[newValueKey] = [];

            // Check if all old exists or make delete object
            forEach(currentValues[newValueKey], currentValue => {
                if (!find(newValueOrGroups, newValueOrGroup => newValueOrGroup.id === currentValue.id)) {
                    delta[newValueKey].push({ id: currentValue.id, deleted: true });
                }
            });

            // Check for new and add object
            forEach(newValueOrGroups, newValueOrGroup => {
                if (!find(currentValues[newValueKey], currentValue => currentValue.id === newValueOrGroup.id)) {
                    delta[newValueKey].push(newValueOrGroup);
                }
            });

            // Check for differences within groups
            forEach(currentValues[newValueKey], currentValue => {
                const groupDelta = {};
                let addGroup = false;
                const newGroupValues = find(
                    newValueOrGroups,
                    newValueOrGroup => newValueOrGroup.id === currentValue.id,
                );

                if (!newGroupValues) {
                    return;
                }

                forEach(newGroupValues, (newGroupValue, newGroupKey) => {
                    if (newGroupKey === "id") {
                        groupDelta[newGroupKey] = newGroupValue;
                        return;
                    }
                    if (
                        (isUndefined(currentValue[newGroupKey]) || isNaN(+currentValue[newGroupKey])) &&
                        currentValue[newGroupKey] !== newGroupValue
                    ) {
                        groupDelta[newGroupKey] = newGroupValue;
                        addGroup = true;
                    } else if (currentValue[newGroupKey] !== newGroupValue) {
                        groupDelta[newGroupKey] = (newGroupValue - currentValue[newGroupKey]).toFixed(2) * 1;
                        addGroup = true;
                    }
                });

                if (addGroup) {
                    delta[newValueKey].push(groupDelta);
                }
            });

            delta[newValueKey] = sortBy(delta[newValueKey], "id");

            return;
        }

        if (
            (isUndefined(currentValues[newValueKey]) || isNaN(+currentValues[newValueKey])) &&
            currentValues[newValueKey] !== newValueOrGroups
        ) {
            delta[newValueKey] = newValueOrGroups;
        } else if (currentValues[newValueKey] !== newValueOrGroups) {
            delta[newValueKey] = (newValueOrGroups - currentValues[newValueKey]).toFixed(2) * 1;
        }
    });

    return delta;
};

export default (currentValues, newValues) => {
    const values = currentValues;

    forEach(newValues, (newValueOrGroups, newValueKey) => {
        if (isObject(newValueOrGroups)) {
            // If complete group does not exists add it
            if (isUndefined(currentValues[newValueKey])) {
                values[newValueKey] = newValueOrGroups;
                return;
            }

            const mutatedGroups = [];

            // add objects with ids which does not exists
            forEach(newValueOrGroups, newValueOrGroup => {
                if (newValueOrGroup.deleted === true) {
                    return;
                }
                if (!find(values[newValueKey], currentValue => currentValue.id === newValueOrGroup.id)) {
                    mutatedGroups.push(newValueOrGroup);
                }
            });

            // add objects which are not deleted
            forEach(values[newValueKey], currentValue => {
                if (
                    !find(
                        newValueOrGroups,
                        newValueOrGroup => currentValue.id === newValueOrGroup.id && newValueOrGroup.deleted === true,
                    )
                ) {
                    mutatedGroups.push(currentValue);
                }
            });

            // Check for differences within groups
            forEach(newValueOrGroups, newValueOrGroup => {
                if (newValueOrGroup.deleted === true) {
                    return;
                }

                const valuesGroupsIndex = findIndex(values[newValueKey], currentValue => {
                    return currentValue.id === newValueOrGroup.id;
                });

                if (valuesGroupsIndex <= 0) {
                    return;
                }

                const newGroepData = values[newValueKey][valuesGroupsIndex];

                forEach(newValueOrGroup, (newGroupValue, newGroupKey) => {
                    if (newGroupKey === "id") {
                        return;
                    }
                    if (isUndefined(newGroepData[newGroupKey]) || isNaN(+newGroepData[newGroupKey])) {
                        newGroepData[newGroupKey] = newGroupValue;
                    } else {
                        newGroepData[newGroupKey] += newGroupValue;
                        newGroepData[newGroupKey] = newGroepData[newGroupKey].toFixed(2) * 1;
                    }
                });

                const mutatedGroupsIndex = findIndex(mutatedGroups, mutatedGroup => {
                    return mutatedGroup.id === newValueOrGroup.id;
                });

                mutatedGroups[mutatedGroupsIndex] = newGroepData;
            });

            values[newValueKey] = sortBy(mutatedGroups, "id");

            return;
        }

        if (isUndefined(values[newValueKey]) || isNaN(+values[newValueKey])) {
            values[newValueKey] = newValueOrGroups;
        } else {
            values[newValueKey] += newValueOrGroups;
            values[newValueKey] = values[newValueKey].toFixed(2) * 1;
        }
    });

    return values;
};
