import groupModelValue from "../../helpers/model/groupModelValue";

export default (model, field, fieldModels, parameters) => {
    let result = undefined;

    if (typeof fieldModels[0] === "undefined") {
        return result;
    }

    const fieldModelValue = groupModelValue(fieldModels[0], model, field);

    if (parameters.value && fieldModelValue) {
        result = (fieldModelValue - parameters.value).toFixed(2) * 1;
    }

    if (parameters.round && parameters.round === "down") {
        result = Math.floor(result);
    }

    if (parameters.round && parameters.round === "up") {
        result = Math.ceil(result);
    }

    return result;
};
