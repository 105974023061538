import { setModelVisibility } from "../modules/singletons/modelVisibility";
import { setRegisteredModels, setRegisteredRepeatModels } from "../modules/singletons/registeredModels";

export default {
    methods: {
        setModelVisibility: function(newValue) {
            setModelVisibility(newValue);
        },
        setRegisteredModels: function(newValue) {
            setRegisteredModels(newValue);
        },
        setRegisteredRepeatModels: function(newValue) {
            setRegisteredRepeatModels(newValue);
        },
    },
    watch: {
        modelVisibility: function(newValue) {
            this.setModelVisibility(newValue);
        },
        registeredModels: function(newValue) {
            this.setRegisteredModels(newValue);
        },
        registeredRepeatModels: function(newValue) {
            this.setRegisteredRepeatModels(newValue);
        },
    },
};
