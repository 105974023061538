import groupModelValue from "../../helpers/model/groupModelValue";
import valueFilter from "../../helpers/model/valueFilter";
import getStatics from "../../singletons/statics";

export default (model, field, fieldModels, parameters) => {
    let result = undefined;
    const statics = getStatics();

    const multiplier = valueFilter(parameters.multiplier, statics, model, field, "Static", "Model", "RepeatModel");

    fieldModels.forEach(fieldModel => {
        const fieldModelValue = groupModelValue(fieldModel, model, field);
        if (fieldModelValue) {
            result = (fieldModelValue * multiplier).toFixed(2) * 1;
        }
    });

    return result;
};
