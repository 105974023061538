import find from "lodash/find";
import isEqual from "lodash/isEqual";
let tourFile = [];

export const setTourFile = tourFileData => {
    tourFile = tourFileData;
};

export const getScreenTour = (route, params) => {
    return find(
        tourFile,
        tour => tour.steps.length > 0 && tour.route === route && (!tour.params || isEqual(params, tour.params)),
    );
};
