<template>
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
        class="form-control"
        type="text"
        v-model="value"
        :id="getFieldID(schema)"
        :disabled="disabled"
        :maxlength="schema.max"
        :placeholder="schema.placeholder"
        :readonly="schema.readonly"
        v-attributes="'input'"
        @blur="onBlur"
        :aria-label="hiddenLabel"
    />
</template>
<script>
import abstractField from "./abstractField";
import validatorBugFix from "./validatorBugFix";

export default {
    mixins: [abstractField, validatorBugFix],
    methods: {
        onBlur: function() {
            if (new RegExp("^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$").test(this.value)) {
                const valueParts = this.value.split("-");
                if (valueParts[0].length === 1) {
                    valueParts[0] = "0" + valueParts[0];
                }
                if (valueParts[1].length === 1) {
                    valueParts[1] = "0" + valueParts[1];
                }
                this.value = valueParts.join("-");
            }
        },
    },
    created() {
        if (new RegExp("^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$").test(this.value)) {
            const valueParts = this.value.split("-");
            const newValueParts = [];
            if (valueParts[2].length === 1) {
                valueParts[2] = "0" + valueParts[2];
            }
            if (valueParts[1].length === 1) {
                valueParts[1] = "0" + valueParts[1];
            }
            newValueParts[0] = valueParts[2];
            newValueParts[1] = valueParts[1];
            newValueParts[2] = valueParts[0];
            this.value = newValueParts.join("-");
        }
    },
};
</script>
