var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.schema != null)?_c('div',{staticClass:"vue-form-generator"},[(_vm.schema.fields)?_c(_vm.tag,{tag:"fieldset"},[_vm._l((_vm.fields),function(field,index){return [(_vm.fieldVisible(field))?_c('form-group',{key:'form-fi-' + index,attrs:{"vfg":_vm.vfg,"field":field,"errors":_vm.errors,"model":_vm.model,"options":_vm.options},on:{"validated":_vm.onFieldValidated,"model-updated":_vm.onModelUpdated}}):_vm._e()]})],2):_vm._e(),_vm._l((_vm.groupedGroups),function(groupedGroup,groupedGroupsIndex){return _c('div',{key:'group-gr-' + groupedGroupsIndex,ref:'grouped-group-' + groupedGroupsIndex,refInFor:true,class:{ 'scroll-container': groupedGroup.scroll },on:{"scroll":function($event){return _vm.scrollGroup($event, 'grouped-group-' + groupedGroupsIndex)}}},[(
                groupedGroup.scroll &&
                    _vm.screenSize === 'sm' &&
                    _vm.showScrollArrow('grouped-group-' + groupedGroupsIndex, true)
            )?_c('a',{staticClass:"scroll-arrow left",attrs:{"href":"#","aria-label":_vm.$t('global.scroll_naar_links')},on:{"click":function($event){$event.preventDefault();return _vm.scrollGroupButton('grouped-group-' + groupedGroupsIndex, true)}}},[_c('bldc-icon',{attrs:{"icon":"delta_links","font-size":"24"}})],1):_vm._e(),(
                groupedGroup.scroll &&
                    _vm.screenSize === 'sm' &&
                    _vm.showScrollArrow('grouped-group-' + groupedGroupsIndex, false)
            )?_c('a',{staticClass:"scroll-arrow right",attrs:{"href":"#","aria-label":_vm.$t('global.scroll_naar_rechts')},on:{"click":function($event){$event.preventDefault();return _vm.scrollGroupButton('grouped-group-' + groupedGroupsIndex, false)}}},[_c('bldc-icon',{attrs:{"icon":"delta_rechts","font-size":"24"}})],1):_vm._e(),_vm._l((groupedGroup.groups),function(group,grIndex){return [_c(_vm.tag,{key:'form-gr-' + grIndex + '-' + groupedGroupsIndex,tag:"fieldset",class:_vm.getFieldRowClasses(group)},[(group.legend)?_c('div',{staticClass:"legend-head"},[_vm._v(" "+_vm._s(group.legend)+" ")]):_vm._e(),_vm._l((group.fields),function(field,index){return [(_vm.fieldVisible(field))?_c('form-group',{key:'form-gr-' + index,attrs:{"vfg":_vm.vfg,"field":field,"statics":_vm.statics,"errors":_vm.errors,"model":_vm.model,"options":_vm.options},on:{"validated":_vm.onFieldValidated,"model-updated":_vm.onModelUpdated}}):_vm._e()]})],2)]})],2)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }