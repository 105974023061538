import axios from "axios";
import filePaths from "../../data/filePaths";
// import tippy from "tippy.js";
import { getCustomHelpTextsList } from "../singletons/helpTextsList";

const setTooltips = content => {
    const tooltips = content.getElementsByClassName("tooltip");
    tooltips.forEach(tooltip => {
        let span = tooltip.getElementsByTagName("span")[0];
        tooltip.dataset.tippyContent = span.textContent;
        span.remove();
    });

    return content;
};

const setAbsolutePaths = content => {
    let helpBaseUrl = "https://www.belastingdienst.nl/data/online_aangifte/ih2019/contextafhankelijkehelp/nlw";
    if (window.bdForms && window.bdForms.config && window.bdForms.config.helpBaseUrl) {
        helpBaseUrl = window.bdForms.config.helpBaseUrl;
    }

    const links = content.getElementsByTagName("a");
    links.forEach(link => {
        if (link.className !== "tooltip") {
            link.setAttribute("href", link.getAttribute("href").replace("..", helpBaseUrl));
            link.target = "_blank";
        }
    });

    return content;
};

const loadedRefIds = {};

export default refId => {
    return new Promise((resolve, reject) => {
        let helpTextPath = filePaths().pathToHelpTexts;

        if (getCustomHelpTextsList().indexOf(refId) >= 0) {
            helpTextPath = filePaths().pathToCustomHelpTexts;
        }

        if (loadedRefIds[helpTextPath + refId + ".htm"]) {
            resolve(loadedRefIds[helpTextPath + refId + ".htm"]);
            return;
        }

        let element = document.createElement("div");

        // get html help text
        axios
            .get(helpTextPath + refId + ".htm")
            .then(response => {
                const doc = new DOMParser().parseFromString(response.data, "text/xml");
                let content = doc.getElementsByClassName("topiccontent")[0];
                content = setTooltips(content);
                content = setAbsolutePaths(content);
                element.innerHTML = content.innerHTML;

                loadedRefIds[helpTextPath + refId + ".htm"] = element;
                resolve(element);
            })
            .catch(reason => {
                reject(reason);
                console.error(reason);
            });
    });
};
