import formatNumber from "../modules/utils/formatNumber";

export default {
    methods: {
        formatNumber: value => formatNumber(value),
        option(optionKey) {
            return this.$store.getters["options/" + optionKey];
        },
    },
};
