import Vue from "vue";
import slugifyFormId from "../modules/helpers/model/slugifyFormId";
import union from "lodash/union";
import pull from "lodash/pull";
import { v4 as uuid } from "uuid";
export default {
    methods: {
        // add classes to repeatable groups for event triggers
        initRepeatableGroup(group, groupIndex) {
            if (group.repeatModel) {
                let newStyleClasses = [];
                if (group.styleClasses) {
                    group.originalStyleClasses = group.styleClasses;
                    if (typeof group.styleClasses === "string" || group.styleClasses instanceof String) {
                        newStyleClasses.push(group.styleClasses);
                    } else {
                        newStyleClasses = group.styleClasses;
                    }
                }

                if (!group.repeatableIndex) {
                    group.repeatableIndex = 0;
                }

                if (group.removeLink) {
                    if (!group.removeLinkAdded) {
                        const highestIndexes = this.getRepeatableGroupsIndexes(group.repeatModel);
                        if (highestIndexes.lowestRepeatableIndex !== group.repeatableIndex) {
                            if (group.removeLink === "bucket") {
                                group.fields = union(group.fields, [this.getRemoveLinkForGroup(group)]);
                            } else {
                                group.fields = union([this.getRemoveLinkForGroup(group)], group.fields);
                            }

                            group.removeLinkAdded = true;
                        }
                    }
                }

                if (group.addLink) {
                    if (!group.linkAdded) {
                        group.fields.push({
                            type: "label",
                            isAddLink: true,
                            styleClasses: ["add-group-label"],
                            label: "<a href='#' data-add-group='" + group.repeatModel + "'>" + group.addLink + "</a>",
                        });

                        group.linkAdded = true;
                    }
                }

                const newClasses = ["model-" + group.repeatModel + "-" + group.repeatableIndex, "repeatable-group"];

                if (group.removeLink !== "bucket" && (typeof group.legend === "undefined" || group.legend === "")) {
                    newClasses.push("repeatable-group-no-legend");
                }

                Vue.set(this.schema.groups[groupIndex], "styleClasses", union(newStyleClasses, newClasses));

                if (group.radioModel) {
                    Vue.set(this.repeatableRadioModels, group.radioModel, group.repeatModel);
                }
            }
        },
        getRemoveLinkForGroup(group) {
            if (group.removeLink === "bucket") {
                return {
                    type: "label",
                    isRemoveLink: true,
                    styleClasses: ["remove-group-bucket-label"],
                    label:
                        "<a href='#' data-remove-group='" +
                        group.repeatModel +
                        "' data-remove-group-index='" +
                        group.repeatableIndex +
                        "' aria-label='" +
                        this.$t("global.verwijder_regel") +
                        "'></a>",
                };
            }

            return {
                type: "label",
                isRemoveLink: true,
                styleClasses: ["remove-group-label"],
                label:
                    "<a href='#' data-remove-group='" +
                    group.repeatModel +
                    "' data-remove-group-index='" +
                    group.repeatableIndex +
                    "'>" +
                    group.removeLink +
                    "</a>",
            };
        },

        // get the highest group schema index in repeatable group index
        getRepeatableGroupsIndexes(repeatModel) {
            let highestSchemaIndex = 0;
            let highestRepeatableIndex = 0;
            let lowestSchemaIndex = 0;
            let lowestRepeatableIndex = 0;
            // run field update loop
            this.groupLoop(function(group, groupIndex) {
                if (group.repeatModel === repeatModel) {
                    lowestSchemaIndex = groupIndex < lowestSchemaIndex ? groupIndex : lowestSchemaIndex;
                    lowestRepeatableIndex =
                        group.repeatableIndex < lowestRepeatableIndex ? group.repeatableIndex : lowestRepeatableIndex;
                    highestSchemaIndex = groupIndex > highestSchemaIndex ? groupIndex : highestSchemaIndex;
                    highestRepeatableIndex =
                        group.repeatableIndex > highestRepeatableIndex ? group.repeatableIndex : highestRepeatableIndex;
                }
            });

            return {
                highestSchemaIndex: highestSchemaIndex,
                highestRepeatableIndex: highestRepeatableIndex,
                lowestSchemaIndex: lowestSchemaIndex,
                lowestRepeatableIndex: lowestRepeatableIndex,
            };
        },

        // rewrite the models for a repeatable group (used in field init loop)
        rewriteRepeatableGroupModels(field, group, fieldIndex, groupIndex) {
            if (typeof group.repeatModel !== "undefined" && typeof group.repeatableIndex !== "undefined") {
                field.repeatableGroup = group.repeatModel;
                field.repeatableGroupPostKey = group.repeatModelPostKey;
                field.repeatableIndex = group.repeatableIndex;
            }
            if (group.repeatModel && field.model) {
                if (!field.originalModel) {
                    field.originalModel = field.model;
                }

                if (!group.repeatableIndex) {
                    group.repeatableIndex = 0;
                }

                // check if the field not is the radio trigger to add new group
                if (group.radioModel !== field.originalModel) {
                    const newModel = group.repeatModel + "[" + group.repeatableIndex + "]." + field.originalModel;

                    if (typeof this.model[group.repeatModel] === "undefined") {
                        Vue.set(this.model, group.repeatModel, []);
                    }
                    if (typeof this.model[group.repeatModel][group.repeatableIndex] === "undefined") {
                        Vue.set(this.model[group.repeatModel], group.repeatableIndex, {});
                    }

                    if (!this.model[group.repeatModel][group.repeatableIndex]["id"]) {
                        Vue.set(this.model[group.repeatModel][group.repeatableIndex], "id", uuid());
                    }

                    if (group.repeatModelId) {
                        Vue.set(
                            this.model[group.repeatModel][group.repeatableIndex],
                            "repeatModelId",
                            group.repeatModelId,
                        );
                    }

                    if (field.model !== newModel) {
                        const newField = this.duplicateField(field);
                        newField.originalModel = field.originalModel;
                        newField.model = newModel;
                        newField.id = slugifyFormId(newField, group.repeatModel + group.repeatableIndex);
                        newField.repeatableGroup = group.repeatModel;
                        newField.repeatableIndex = group.repeatableIndex;

                        Vue.set(this.schema.groups[groupIndex].fields, fieldIndex, newField);
                        this.fieldInit(
                            this.schema.groups[groupIndex].fields[fieldIndex],
                            group,
                            fieldIndex,
                            groupIndex,
                        );
                    }
                }

                if (group.radioModel === field.originalModel) {
                    field.multiplierGroup = group.repeatModel;
                    field.multiplierIndex = group.repeatableIndex;
                    Vue.set(this.schema.groups[groupIndex].fields, fieldIndex, field);
                }
            }
        },

        // duplicate group and add to the schema
        addRepeatableGroup(group) {
            // get highest indexes of this group
            const highestIndexes = this.getRepeatableGroupsIndexes(group.repeatModel);

            // deplicate group
            const newGroup = this.duplicateGroup(group);

            // add correct repeatable group
            newGroup.repeatableIndex = highestIndexes.highestRepeatableIndex + 1;

            // slice groups before
            const groupsBefore = this.schema.groups.slice(0, highestIndexes.highestSchemaIndex + 1);
            const groupAfter = this.schema.groups.slice(highestIndexes.highestSchemaIndex + 1);

            const newGroups = groupsBefore.concat(newGroup, groupAfter);

            // set new schema
            Vue.set(this.schema, "groups", newGroups);

            // initialize new schema
            this.init();
        },

        // check radioModel if we have to duplicate group
        isAddGroupRadioTriggered(group) {
            // if radio model add group triggered add group
            if (group.repeatModel && group.radioModel && this.model[group.radioModel]) {
                Vue.delete(this.model, group.radioModel);
                this.addRepeatableGroup(group);
            }
        },

        // add a group is is clicked
        addGroupOnClick: function(event) {
            this.addRepeatableGroup(this.getGroupByRepeatModel(event.target.getAttribute("data-add-group")));
        },

        // remove a group is is clicked
        removeGroupOnClick: function(event) {
            // remove help boxes before remove group
            const repeatModel = event.target.getAttribute("data-remove-group");
            const repeatModelIndex = event.target.getAttribute("data-remove-group-index");

            this.removeRepeatableGroup(this.getGroupByRepeatModel(repeatModel, repeatModelIndex));
        },

        // remove a repeatable group
        removeRepeatableGroup(groupIndex) {
            if (typeof groupIndex === "undefined") {
                return;
            }

            if (this.schema.groups[groupIndex].repeatModel && this.schema.groups[groupIndex].repeatableIndex) {
                if (typeof this.model[this.schema.groups[groupIndex].repeatModel] !== "undefined") {
                    delete this.model[this.schema.groups[groupIndex].repeatModel][
                        this.schema.groups[groupIndex].repeatableIndex
                    ];
                    // Vue.delete(
                    //     this.model[this.schema.groups[groupIndex].repeatModel],
                    //     this.schema.groups[groupIndex].repeatableIndex,
                    // );
                }
            }

            // remove the group
            Vue.delete(this.schema.groups, groupIndex);

            // run update
            this.update();
        },

        // get group by the repeat model
        getGroupByRepeatModel(repeatModel, repeatableIndex = false) {
            let returnGroup;

            this.groupLoop((group, groupIndex) => {
                if (group.repeatModel === repeatModel && !repeatableIndex) {
                    returnGroup = group;
                } else if (
                    group.repeatModel === repeatModel &&
                    Number(group.repeatableIndex) === Number(repeatableIndex)
                ) {
                    returnGroup = groupIndex;
                }
            });

            return returnGroup;
        },

        repeatableGroupClass(group, groupIndex) {
            const highestIndexes = this.getRepeatableGroupsIndexes(group.repeatModel);
            let newStyleClasses = group.styleClasses;
            pull(newStyleClasses, "repeatable-group-add-link");
            if (highestIndexes.highestRepeatableIndex === group.repeatableIndex && group.addLink) {
                newStyleClasses = union(newStyleClasses, ["repeatable-group-add-link"]);
            }

            Vue.set(this.schema.groups[groupIndex], "styleClasses", newStyleClasses);
        },

        initNewGroupsByPrefilledData() {
            const entries = Object.entries(this.model);

            for (const [key, value] of entries) {
                if (Array.isArray(value) && value.length > 1) {
                    const group = this.getGroupByRepeatModel(key);
                    if (group) {
                        let first = true;
                        value.forEach(() => {
                            if (!first) {
                                this.addRepeatableGroup(group);
                            }
                            first = false;
                        });
                    }
                }
            }
        },
    },
};
