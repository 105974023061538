import Vue from "vue";
import Vuex from "vuex";
import rgsLedger from "./rgsLedger";
import navigation from "./navigation";
import helpNavigation from "./helpNavigation";
import currentState from "./currentState";
import slides from "./slides";
import save from "./save";
import options from "./options";
import tour from "./tour";
import formValues from "./formValues";
import screen from "./screen";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        rgsLedger: rgsLedger,
        navigation,
        formValues,
        currentState,
        slides,
        save,
        tour,
        options,
        helpNavigation,
        screen,
    },
});
