<template>
    <main>
        <bldc-grid-column col="12">
            <bldc-heading level="1" id="page-title">{{ currentItem.title }}</bldc-heading>
        </bldc-grid-column>
        <div class="custom-content" ref="helpText" v-html="getHelp()"></div>
    </main>
</template>

<script>
import { mapGetters } from "vuex";
import find from "lodash/find";
import getHelpText from "../modules/utils/getHelpText";
import tippy from "tippy.js";
import Vue from "vue";
import { getHelpTextsList, getCustomHelpTextsList } from "../modules/singletons/helpTextsList";

export default {
    name: "custom",
    mixins: [],
    components: {},
    data() {
        return {
            loadedHelpTexts: {},
        };
    },
    computed: {
        ...mapGetters({
            customItems: "helpNavigation/items",
        }),
        currentItem: function() {
            return find(this.customItems, customItem => customItem.slug === this.$route.params.slug);
        },

        helpTextList: function() {
            return getHelpTextsList();
        },

        customHelpTextList: function() {
            return getCustomHelpTextsList();
        },
    },
    methods: {
        getItemBySlug: function(slug) {
            return find(this.customItems, customItem => customItem.slug === slug);
        },
        checkAndRedirect: function() {
            if (!this.getItemBySlug(this.$route.params.slug)) {
                this.$router.push({ name: "intro" }).catch(() => {});
            }
        },
        addTippyToHelp() {
            if (!this.$refs.helpText) {
                return;
            }
            tippy(this.$refs.helpText.querySelectorAll(".tooltip"), {
                theme: "light",
                trigger: "mouseenter focus click",
            });
        },
        addInterLinksToHelp() {
            if (!this.$refs.helpText) {
                return;
            }
            var licenseLinks = this.$refs.helpText.querySelectorAll(".licenses-page-link");
            if (licenseLinks && licenseLinks.length > 0) {
                licenseLinks.forEach(licenseLinkElement => {
                    licenseLinkElement.addEventListener("click", event => {
                        event.preventDefault();
                        this.$router.push({ name: "licenses" });
                    });
                });
            }
        },
        loadHelp(helpFileName) {
            getHelpText(helpFileName)
                .then(helpElement => {
                    Vue.set(this.loadedHelpTexts, helpFileName, helpElement);
                    Vue.nextTick(() => {
                        this.addTippyToHelp();
                        this.addInterLinksToHelp();
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },

        getHelp() {
            if (this.whichHelp() === "customHelpTextList" || this.whichHelp() === "helpTextList") {
                if (this.loadedHelpTexts[this.currentItem.helpFile]) {
                    return this.loadedHelpTexts[this.currentItem.helpFile].innerHTML;
                }
                this.loadHelp(this.currentItem.helpFile);
                return this.$t("global.laden");
            }

            return "";
        },

        whichHelp() {
            if (!this.currentItem.helpFile) {
                return false;
            }
            if (this.customHelpTextList.indexOf(this.currentItem.helpFile) >= 0) {
                return "customHelpTextList";
            } else if (this.helpTextList.indexOf(this.currentItem.helpFile) >= 0) {
                return "helpTextList";
            }

            return false;
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.checkAndRedirect();
        });
    },
    watch: {
        "$route.params.slug": function() {
            this.checkAndRedirect();
        },
    },
};
</script>

<style lang="scss" scoped="true">
.custom-content {
    background-color: rgb(255, 255, 255);
    padding: 0.875rem 1.25rem 0.005rem;
    margin-bottom: 10px;
}
</style>
