export default field => {
    let fieldModel;

    if (
        field &&
        typeof field.originalModel !== "undefined" &&
        typeof field.repeatableGroup !== "undefined" &&
        typeof field.repeatableIndex !== "undefined" &&
        !field.isRadioModel
    ) {
        fieldModel = field.originalModel + "RepeatModel";
    } else if (field && typeof field.model !== "undefined") {
        fieldModel = field.model;
    }

    return fieldModel;
};
