import merge from "lodash/merge";
import forEach from "lodash/forEach";
import mapValues from "lodash/mapValues";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isNaN from "lodash/isNaN";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
import max from "lodash/max";

export const getKeys = keyValueObject => {
    return mapValues(keyValueObject, value => {
        if (Array.isArray(value)) {
            return mapValues(merge({}, ...value), () => null);
        }

        return null;
    });
};

export const getValueByRules = (
    newPrefilledValue,
    existingPrefilledValue,
    filledValue,
    changed = false,
    log = false,
) => {
    if (newPrefilledValue !== existingPrefilledValue) {
        if (log) console.log("filled (diff), filled (diff) = newPrefilledValue");
        if (changed) return { changed: true, newPrefilledValue, existingPrefilledValue, filledValue };
        return newPrefilledValue;
    }

    if (newPrefilledValue === existingPrefilledValue) {
        if (log) console.log("filled (equal), filled (equal) = filledValue");
        if (changed) return undefined;
        return filledValue;
    }

    console.error("There are other ECG map possibilities", newPrefilledValue, existingPrefilledValue, filledValue);
};

export const getGroupedValueByRules = (
    groupKey,
    groupKeys,
    newPrefilledGroups,
    existingPrefilledGroups,
    filledGroups,
    changed = false,
    log = false,
) => {
    const newValues = [];
    const iterations = max([newPrefilledGroups.length, existingPrefilledGroups.length, filledGroups.length]);
    let i;
    for (i = 0; i < iterations; i++) {
        forEach(groupKeys, (_, key) => {
            const newValue = getValueByRules(
                newPrefilledGroups[i] ? newPrefilledGroups[i][key] : undefined,
                existingPrefilledGroups[i] ? existingPrefilledGroups[i][key] : undefined,
                filledGroups[i] ? filledGroups[i][key] : undefined,
                changed,
                log,
            );

            if (isUndefined(newValues[i])) newValues[i] = {};

            if (!isUndefined(newValue)) {
                newValues[i][key] = newValue;
                if (!isNaN(+newValue) && newValue !== false && newValue !== true) {
                    newValues[i][key] = newValue * 1;
                }
            }

            if (groupKey === "108724") {
                newValues[i]["repeatModelId"] = "boete-toevoegen";
            }
        });
    }

    return newValues;
};

export const ecgMapper = (newPrefilledSet, existingPrefilledSet, filledSet, changed = false) => {
    const values = {};

    const allPossibleKeys = getKeys(merge({}, newPrefilledSet, existingPrefilledSet, filledSet));

    forEach(allPossibleKeys, (groupKeys, key) => {
        if (isObject(groupKeys)) {
            // repeatable group
            const newValue = getGroupedValueByRules(
                key,
                groupKeys,
                newPrefilledSet[key] || [],
                existingPrefilledSet[key] || [],
                filledSet[key] || [],
                changed,
            );

            if (!isUndefined(newValue)) {
                // if (key === "108724") {
                //     values["boeteToevoegen"] = newValue;
                // } else {
                values[key] = newValue;
                // }
            }
            return;
        }

        const newValue = getValueByRules(newPrefilledSet[key], existingPrefilledSet[key], filledSet[key], changed);

        if (!isUndefined(newValue)) {
            values[key] = newValue;
            if (!isNaN(+newValue) && newValue !== false && newValue !== true) {
                values[key] = newValue * 1;
            }
        }
    });

    return values;
};

export const ecgChanged = (newPrefilledSet, existingPrefilledSet, filledSet) => {
    return ecgMapper(newPrefilledSet, existingPrefilledSet, filledSet, true);
};

export const ecgChangedMessages = (fieldMap, newPrefilledSet, existingPrefilledSet, filledSet) => {
    const changed = ecgChanged(newPrefilledSet, existingPrefilledSet, filledSet, true);
    const changes = {};

    forEach(changed, (value, key) => {
        if (isArray(value)) {
            forEach(value, (group, grIndex) => {
                const groupChanges = {};
                forEach(group, (groupValue, groupKey) => {
                    if (groupKey === "repeatModelId") {
                        return;
                    }
                    groupValue.field = fieldMap[key]["group"][groupKey];
                    groupChanges[groupKey] = groupValue;
                });

                if (isEmpty(groupChanges)) {
                    return;
                }

                if (!changes[key]) {
                    changes[key] = { formKey: fieldMap[key]["formKey"], fields: [] };
                }

                changes[key]["fields"].push({ groupChanges, index: grIndex });
            });
            return;
        }

        // Do not check year id/key
        if (!fieldMap[key]) {
            return;
        }

        value.formKey = fieldMap[key].formKey;
        value.field = fieldMap[key].field;

        changes[key] = value;
    });

    const changesPerFormKey = {};

    forEach(changes, (value, key) => {
        if (!changesPerFormKey[value.formKey]) {
            changesPerFormKey[value.formKey] = {};
        }

        changesPerFormKey[value.formKey][key] = value;
    });

    return changesPerFormKey;
};

export const mergeConflicts = (newConflicts, oldConflicts, fieldMap) => {
    forEach(oldConflicts, (formConflicts, formKey) => {
        if (!newConflicts[formKey]) {
            newConflicts[formKey] = formConflicts;
            return;
        }

        forEach(formConflicts, (conflict, key) => {
            if (conflict.fields) {
                // is group
                forEach(conflict.fields, field => {
                    let groupValueIndex = -1;
                    if (newConflicts[formKey][key]) {
                        groupValueIndex = findIndex(newConflicts[formKey][key]["fields"], n => n.index === field.index);
                    }

                    if (groupValueIndex >= 0) {
                        forEach(field.groupChanges, (groupChange, groupChangeKey) => {
                            if (newConflicts[formKey][key]["fields"][groupValueIndex][groupChangeKey]) {
                                newConflicts[formKey][key]["fields"][groupValueIndex][groupChangeKey].filledValue =
                                    groupChange.filledValue;
                            } else {
                                newConflicts[formKey][key]["fields"][groupValueIndex][groupChangeKey] = groupChange;
                            }
                        });

                        return;
                    }

                    if (!newConflicts[formKey][key]) {
                        newConflicts[formKey][key] = { formKey: fieldMap[key]["formKey"], fields: [field] };
                        return;
                    }

                    newConflicts[formKey][key]["fields"].push(field);
                });

                return;
            }
            if (newConflicts[formKey][key]) {
                newConflicts[formKey][key].filledValue = conflict.filledValue;
            } else {
                newConflicts[formKey][key] = conflict;
            }
        });
    });

    return newConflicts;
};

export default ecgMapper;
