import getStatics from "../../singletons/statics";

export default (model, field, fieldModels, parameters) => {
    const statics = getStatics();

    const filtered = fieldModels
        .filter(fieldModel => !!statics[fieldModel]) // || statics[fieldModel] === 0
        .map(fieldModel => statics[fieldModel])
        .filter(value => value >= 0 || value < 0);

    if (filtered.length <= 0) {
        if (parameters && typeof parameters.defaultValue !== "undefined") {
            return parameters.defaultValue;
        }
        return undefined;
    }

    return filtered.reduce((acc, curr) => (acc.toFixed(2) * 1 + curr.toFixed(2) * 1).toFixed(2) * 1);
};
