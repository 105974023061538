<template>
    <div class="curr-wrapper">
        <div class="total-symbol" v-if="schema.total">
            <hr />
            <span v-if="schema.totalSymbol">{{ schema.totalSymbol }}</span>
        </div>
        <div class="container-currency-input">
            <span class="currency-symbol">
                {{ this.getCurrencySymbol(schema.currency) }}
            </span>
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <input
                class="form-control currency-input"
                type="text"
                v-model="displayValue"
                :id="getFieldID(schema)"
                @blur="onBlur"
                :disabled="disabled"
                :placeholder="schema.placeholder"
                :readonly="schema.readonly"
                v-attributes="'input'"
                ref="currencyInput"
                :aria-label="hiddenLabel"
            />
        </div>
    </div>
</template>
<script>
import abstractField from "./abstractField";
import reformatNumber from "./reformatNumber";

export default {
    mixins: [abstractField, reformatNumber],
    methods: {
        getCurrencySymbol: function(type) {
            if (type.toLowerCase() === "eur") {
                return "€";
            }
            return type;
        },
        onBlur: function() {
            this.reformatValue();
        },
    },
};
</script>

<style lang="scss" scoped>
.currency-symbol {
    margin-right: -37px;
    z-index: 1000;
    background-color: rgb(243, 243, 243);
    height: 44px;
    text-align: center;
    line-height: 46px;
    width: 36px;
    font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
    font-size: 1.125rem;
}
.container-currency-input {
    width: 100%;
    display: inline-flex;
    align-items: center;
}
.curr-wrapper {
    position: relative;
}
.total-symbol {
    width: 90%;
    position: absolute;
    margin-top: -16px;
    hr {
        border: 1px solid black;
    }

    span {
        display: block;
        margin-top: -11px;
        margin-right: -15px;
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 0px;
        line-height: 0;
        float: right;
    }
}
/* .currency-symbol {
    margin-right: 7px;
} */
</style>
