import Vue from "vue";

export default {
    namespaced: true,
    state: () => ({
        size: "sm",
        headerBarOffset: 0,
        offsetsSet: false,
        fixedNavbar: false,
    }),
    mutations: {
        setSize(state, size) {
            Vue.set(state, "size", size);
        },
        setOffsetsSet(state, offsetsSet) {
            Vue.set(state, "offsetsSet", offsetsSet);
        },
        setHeaderBarOffset(state, headerBarOffset) {
            Vue.set(state, "headerBarOffset", headerBarOffset);
        },
        setFixedNavbar(state, fixedNavbar) {
            Vue.set(state, "fixedNavbar", fixedNavbar);
        },
    },
    actions: {
        setSize({ commit }) {
            let sm = 768;
            let md = 992;

            let screenSize = "sm";
            let width = Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth,
            );
            switch (true) {
                case width <= sm:
                    screenSize = "sm";
                    break;
                case width <= md:
                    screenSize = "md";
                    break;
                default:
                    screenSize = "all";
            }

            commit("setSize", screenSize);
        },
        setHeaderBarOffset({ state, commit }) {
            const headerBarEl = document.querySelector(".header-bar");

            if (headerBarEl) {
                let headerBarOffset = headerBarEl.getBoundingClientRect().top;
                if (state.size === "all") {
                    headerBarOffset = headerBarOffset + 60;
                } else {
                    headerBarOffset = headerBarOffset + 50;
                }
                commit("setHeaderBarOffset", headerBarOffset);
            }
        },
        setFixedNavbar({ state, commit }) {
            var doc = document.documentElement;
            var scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            let headerBarOffset = state.headerBarOffset * 1;
            if (state.size === "all") {
                headerBarOffset = headerBarOffset - 60;
            } else {
                headerBarOffset = headerBarOffset - 50;
            }

            if (scrollTop > headerBarOffset) {
                commit("setFixedNavbar", true);
            } else {
                commit("setFixedNavbar", false);
            }
        },
        setScreenSize({ dispatch }) {
            dispatch("setSize");
            window.addEventListener("resize", () => {
                dispatch("setSize");
            });
        },
        setScreenOffsets({ state, dispatch, commit }) {
            if (state.offsetsSet) {
                return;
            }
            commit("setOffsetsSet", true);
            dispatch("setHeaderBarOffset");
            dispatch("setFixedNavbar");
            window.addEventListener("scroll", () => {
                dispatch("setHeaderBarOffset");
                dispatch("setFixedNavbar");
            });
            window.addEventListener("resize", () => {
                dispatch("setHeaderBarOffset");
                dispatch("setFixedNavbar");
            });
        },
    },
    getters: {
        size: state => state.size,
        headerBarOffset: state => state.headerBarOffset,
        fixedNavbar: state => state.fixedNavbar,
    },
};
