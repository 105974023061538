<template>
    <div class="wrapper" :class="{ active: active, clickable: navItem.clickable }" :id="specId">
        <div class="unwrap">
            <a
                href="#"
                @click.prevent="clickLink"
                target="_self"
                class="bldc-link default"
                :aria-label="navItem.label"
                :tabindex="navItem.clickable ? 0 : -1"
                :class="{ gray: !navItem.clickable, black: navItem.clickable && navItem.status !== true }"
            >
                <!-- <span v-if="navItem.status !== true && !navItem.clickable" class="empty-icon"></span> -->
                {{ navItem.label }}
                <bldc-icon
                    v-if="navItem.status === true && !active"
                    class="delta hydrated"
                    :icon="showIcon"
                    :fontSize="16"
                    :class="{ 'blue-icon': !!icon }"
                ></bldc-icon>
            </a>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "subNavigationLink",
    mixins: [],
    props: {
        navItem: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        specId: {
            type: String,
            required: false,
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            // items: "navigation/items",
            currentStep: "navigation/currentStep",
            isDone: "navigation/isDone",
        }),

        showIcon: function() {
            if (this.icon) {
                return this.icon;
            }
            return this.navItem.status === true ? `check` : `delta_rechts`;
        },
    },
    methods: {
        clickLink: function() {
            if (this.navItem.clickable === true) {
                this.$emit(`clicked`);
            }
        },
    },
    created() {},
};
</script>
<style lang="scss" scoped="true">
.wrapper {
    margin: 0 -1.5rem;
    .unwrap {
        margin: 0.2rem 2rem;
    }
    &.active {
        background-color: #eef7fb;
    }
    &.clickable:hover {
        background-color: #eef7fb;
    }

    .bldc-link {
        cursor: auto;
        font-family: ROregular, Verdana, Arial, sans-serif;
        font-weight: 400;
        font-size: 1rem;
        color: black;
        text-decoration: unset;
        line-height: 1.5rem;
        margin: 0;
        padding-left: 2.5rem;
        display: flex;
        padding-bottom: 10px;
        padding-top: 10px;

        bldc-icon {
            display: flex;
            align-self: end;
            position: relative;
            margin-left: auto;
            color: #39870c !important;
            margin-top: 4px;
            margin-right: 0;
        }

        .blue-icon {
            color: #39870c !important;
        }

        &.default {
            display: flex;
            align-items: center;
        }

        &.black {
            color: rgb(0, 0, 0);

            bldc-icon {
                color: rgb(0, 0, 0);
            }
        }

        &.gray {
            color: #767676;

            bldc-icon {
                color: #767676;
            }
        }
    }
    &.clickable {
        .bldc-link {
            cursor: pointer;
            &:hover {
                color: rgb(21, 66, 115);

                bldc-icon {
                    color: rgb(21, 66, 115);
                }
            }
            // &.default {
            //     &:hover {
            //         bldc-icon.delta {
            //             left: 0.25rem;
            //         }
            //     }
            // }
            &.black {
                &:hover {
                    color: rgb(21, 66, 115);
                }
            }

            &.gray {
                &:hover {
                    color: rgb(134, 134, 134);
                }
            }
        }
    }
}
</style>
