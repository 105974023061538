import errorMessage from "./messages";

export default enumValues => {
    return value => {
        const errors = [];

        if (!enumValues.includes(value)) {
            errors.push(errorMessage("enum_value_not_exists", { values: enumValues.join(", ") }));
        }

        return errors;
    };
};
