<template>
    <div class="vue-form-generator" v-if="schema != null">
        <fieldset v-if="schema.fields" :is="tag">
            <template v-for="(field, index) in fields">
                <form-group
                    :key="'form-fi-' + index"
                    v-if="fieldVisible(field)"
                    :vfg="vfg"
                    :field="field"
                    :errors="errors"
                    :model="model"
                    :options="options"
                    @validated="onFieldValidated"
                    @model-updated="onModelUpdated"
                ></form-group
            ></template>
        </fieldset>
        <div
            v-for="(groupedGroup, groupedGroupsIndex) in groupedGroups"
            :key="'group-gr-' + groupedGroupsIndex"
            :class="{ 'scroll-container': groupedGroup.scroll }"
            v-on:scroll="scrollGroup($event, 'grouped-group-' + groupedGroupsIndex)"
            :ref="'grouped-group-' + groupedGroupsIndex"
        >
            <a
                href="#"
                class="scroll-arrow left"
                :aria-label="$t('global.scroll_naar_links')"
                v-if="
                    groupedGroup.scroll &&
                        screenSize === 'sm' &&
                        showScrollArrow('grouped-group-' + groupedGroupsIndex, true)
                "
                @click.prevent="scrollGroupButton('grouped-group-' + groupedGroupsIndex, true)"
            >
                <bldc-icon icon="delta_links" font-size="24" />
            </a>
            <a
                href="#"
                class="scroll-arrow right"
                :aria-label="$t('global.scroll_naar_rechts')"
                v-if="
                    groupedGroup.scroll &&
                        screenSize === 'sm' &&
                        showScrollArrow('grouped-group-' + groupedGroupsIndex, false)
                "
                @click.prevent="scrollGroupButton('grouped-group-' + groupedGroupsIndex, false)"
            >
                <bldc-icon icon="delta_rechts" font-size="24" />
            </a>
            <template v-for="(group, grIndex) in groupedGroup.groups">
                <fieldset
                    :key="'form-gr-' + grIndex + '-' + groupedGroupsIndex"
                    :is="tag"
                    :class="getFieldRowClasses(group)"
                >
                    <div v-if="group.legend" class="legend-head">
                        {{ group.legend }}
                    </div>
                    <template v-for="(field, index) in group.fields">
                        <form-group
                            :key="'form-gr-' + index"
                            v-if="fieldVisible(field)"
                            :vfg="vfg"
                            :field="field"
                            :statics="statics"
                            :errors="errors"
                            :model="model"
                            :options="options"
                            @validated="onFieldValidated"
                            @model-updated="onModelUpdated"
                        ></form-group
                    ></template>
                </fieldset>
            </template>
        </div>
    </div>
</template>

<script>
import * as objGet from "lodash/get";
import forEach from "lodash/forEach";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import isArray from "lodash/isArray";
import formMixin from "../mixins/formMixin.js";
import formGroup from "./formGroup.vue";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
    name: "formGenerator",
    components: { formGroup },
    mixins: [formMixin],
    props: {
        schema: Object,

        statics: Object,

        model: Object,

        options: {
            type: Object,
            default() {
                return {
                    validateAfterLoad: false,
                    validateAfterChanged: false,
                    fieldIdPrefix: "",
                    validateAsync: false,
                    validationErrorClass: "error",
                    validationSuccessClass: "",
                };
            },
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        isNewModel: {
            type: Boolean,
            default: false,
        },

        tag: {
            type: String,
            default: "fieldset",
            validator: function(value) {
                return value.length > 0;
            },
        },
    },

    data() {
        return {
            vfg: this,
            errors: [], // Validation errors
            scrollGroups: {},
        };
    },

    computed: {
        ...mapGetters({
            screenSize: "screen/size",
        }),
        fields() {
            let res = [];
            if (this.schema && this.schema.fields) {
                forEach(this.schema.fields, field => {
                    if (!this.multiple || field.multi === true) res.push(field);
                });
            }

            return res;
        },
        groupedGroups() {
            let res = [];
            let grouped = [];
            if (this.schema && this.schema.groups) {
                forEach(this.schema.groups.slice(0), (group, groupIndex) => {
                    if (!this.isMultiColumn(group)) {
                        res.push({ scroll: false, groups: [group] });
                        return;
                    }

                    grouped.push(group);

                    if (!this.isNextPreviousMultiColumn(group, groupIndex, this.schema.groups, true)) {
                        res.push({ scroll: true, groups: grouped });
                        grouped = [];
                    }
                });
            }

            return res;
        },
    },

    watch: {
        // new model loaded
        model: function(newModel, oldModel) {
            if (oldModel === newModel)
                // model property changed, skip
                return;

            if (newModel != null) {
                this.$nextTick(() => {
                    // Model changed!
                    if (this.options.validateAfterLoad === true && this.isNewModel !== true) {
                        this.validate();
                    } else {
                        this.clearValidationErrors();
                    }
                });
            }
        },
        "$route.fullPath": function() {
            forEach(this.scrollGroups, (scrollGroup, scrollGroupsKey) => {
                if (this.$refs[scrollGroupsKey] && this.$refs[scrollGroupsKey][0]) {
                    this.$refs[scrollGroupsKey][0].scrollLeft = 0;
                }
            });
            Vue.set(this, "scrollGroups", {});
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.model) {
                // First load, running validation if neccessary
                if (this.options.validateAfterLoad === true && this.isNewModel !== true) {
                    this.validate();
                } else {
                    this.clearValidationErrors();
                }
            }
        });
    },

    methods: {
        // Get visible prop of field
        fieldVisible(field) {
            if (isFunction(field.visible)) return field.visible.call(this, this.model, field, this);

            if (isNil(field.visible)) return true;

            return field.visible;
        },

        // Child field executed validation
        onFieldValidated(res, errors, field) {
            // Remove old errors for this field
            this.errors = this.errors.filter(e => e.field !== field.schema);

            if (!res && errors && errors.length > 0) {
                // Add errors with this field
                forEach(errors, err => {
                    this.errors.push({
                        field: field.schema,
                        error: err,
                    });
                });
            }

            let isValid = this.errors.length === 0;
            this.$emit("validated", isValid, this.errors, this);
        },

        onModelUpdated(newVal, schema) {
            this.$emit("model-updated", newVal, schema);
        },

        // Validating the model properties
        validate(isAsync = null) {
            if (isAsync === null) {
                isAsync = objGet(this.options, "validateAsync", false);
            }
            this.clearValidationErrors();

            let fields = [];
            let results = [];

            forEach(this.$children, child => {
                if (isFunction(child.validate)) {
                    fields.push(child.$refs.child); // keep track of validated children
                    results.push(child.validate(true));
                }
            });

            let handleErrors = errors => {
                let formErrors = [];
                forEach(errors, (err, i) => {
                    if (isArray(err) && err.length > 0) {
                        forEach(err, error => {
                            formErrors.push({
                                field: fields[i].schema,
                                error: error,
                            });
                        });
                    }
                });
                this.errors = formErrors;
                let isValid = formErrors.length === 0;
                this.$emit("validated", isValid, formErrors, this);
                return isAsync ? formErrors : isValid;
            };

            if (!isAsync) {
                return handleErrors(results);
            }

            return Promise.all(results).then(handleErrors);
        },

        // Clear validation errors
        clearValidationErrors() {
            this.errors.splice(0);

            forEach(this.$children, child => {
                child.clearValidationErrors();
            });
        },
    },
};
</script>

<style lang="scss">
.vue-form-generator {
    .hidden,
    .group-hidden {
        display: none !important;
    }
    .scroll-container {
        overflow-x: auto;

        .multi-column {
            min-width: 720px;
        }

        .scroll-arrow {
            display: none;

            @media only screen and (max-width: 720px) {
                display: flex;
            }
            align-items: center;
            justify-content: center;
            text-decoration: none;
            position: absolute;
            text-align: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            padding-left: 12px;
            color: white;
            background-color: rgba(143, 202, 231, 0.85);
            z-index: 15;
            font-size: 24px;
            margin-top: 3rem;
            &.left {
                left: 2px;
                width: 40px;
                padding-left: 10px;
            }
            &.right {
                right: 2px;
                width: 38px;
                padding-left: 12px;
            }
        }
    }
    fieldset {
        border: none;
        background-color: #fff;
        margin: 1rem 0 0.5rem;
        padding: 1rem 0.75rem 0;
        &:first-child {
            margin-top: 0;
        }
        legend {
            display: none;
        }
        .legend-head {
            padding-bottom: 1rem;
            padding-top: 0.5rem;
            width: 100%;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
            font-family: RObold, "Segoe UI", "Helvetica Neue", sans-serif;
            font-weight: normal;
            white-space: normal;
            margin: 0px;
            color: rgb(0, 0, 0);
        }
        &.group-hidden {
            display: none;
        }
        &.hide-border-top {
            margin-top: 0;
            padding-top: 0;
        }
        &.hide-border-bottom {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &.multiple-fields-50,
        &.multiple-fields-25,
        &.multiple-fields-33,
        &.multiple-fields-75-25,
        &.multiple-fields-66-33 {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 0rem 0.75rem;
            .form-group {
                margin-bottom: 0.75rem;
                &.remove-group-bucket-label {
                    width: 4% !important;
                }
                .form-control {
                    width: 90% !important;
                }
            }
            .add-group-label {
                flex-basis: 100%;
            }
        }

        &.multiple-fields-50 {
            .form-group {
                width: 48%;
            }
        }
        &.multiple-fields-25 {
            > .form-group {
                width: 24%;
                .help-box {
                    width: calc(100% + 276%);
                    position: relative;
                }
            }

            > .form-group ~ .form-group {
                width: 24%;
                z-index: 1;
                position: relative;
            }
        }
        &.multiple-fields-33 {
            > .form-group {
                width: 32%;
                .help-box {
                    width: calc(100% + 180%);
                    position: relative;
                }
            }

            > .form-group ~ .form-group {
                width: 32%;
                z-index: 1;
                position: relative;
            }
        }
        &.multiple-fields-75-25 {
            > .form-group {
                width: 72%;
                .label-html {
                    line-height: 1.7;
                }
                .help-box {
                    width: calc(100% + 25%);
                    position: relative;
                }
            }
            > .form-group ~ .form-group {
                width: 24%;
                z-index: 1;
                position: relative;
            }
        }
        &.multiple-fields-66-33 {
            > .form-group {
                width: 64%;
                .label-html {
                    line-height: 1.7;
                }
                .help-box {
                    width: calc(100% + 40%);
                    position: relative;
                }
            }
            > .form-group ~ .form-group {
                width: 32%;
                z-index: 1;
                position: relative;
            }
        }
        &.repeatModel-header {
            padding-top: 1.25rem;
        }
    }
    .field-hidden {
        display: none;
    }
    .form-group {
        margin-bottom: 1.25rem;
        z-index: 10;
        position: relative;

        // &:last-child {
        //     margin-bottom: 0.25rem;
        // }
        label {
            font: 400 1rem / 1.375rem RObold, "Segoe UI", "Helvetica Neue", sans-serif;
            margin-top: 0.6rem;
            display: block;
            div {
                margin: auto 0;
            }
            .label-html {
                margin-right: 5px;
            }
            .center-label {
                display: block;
                text-align: center;
                max-width: 90%;
            }
            .styled-label-html {
                font: 400 1rem / 1.375rem ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
            }
        }
        &.remove-group-label,
        &.add-group-label {
            label {
                a {
                    font: 400 1rem / 1.375rem RObold, "Segoe UI", "Helvetica Neue", sans-serif;
                    color: rgb(1, 104, 155);
                    text-decoration: none;
                }
            }
        }
        &.add-group-label {
            label {
                a {
                    &:before {
                        font-size: 1rem / 1.375rem;
                        font-style: normal;
                        margin-right: 0.2rem;
                        font-feature-settings: "liga";
                        font-family: bldiconfont !important;
                        font-weight: normal !important;
                        content: "plus";
                        display: block;
                        float: left;
                        margin-top: -1px;
                        margin-left: 2px;
                    }
                }
            }
        }
        &.remove-group-label {
            label {
                a {
                    &:before {
                        font-size: 1rem / 1.375rem;
                        font-style: normal;
                        margin-right: 0.2rem;
                        font-feature-settings: "liga";
                        font-family: bldiconfont !important;
                        font-weight: normal !important;
                        content: "verwijderen";
                        display: block;
                        float: left;
                        margin-top: -1px;
                        margin-left: 2px;
                    }
                }
            }
        }
        // .field-wrap {
        //     max-width: 100%;
        //     height: 100%;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        // }
        .form-control {
            font: 400 1rem / 1.375rem ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
            width: fit-content;
            max-width: 90%;
            height: 46px;
            padding: 0.75rem 1rem;
            box-shadow: rgb(204 204 204) 0px 0px 0px 1px inset;
            border: none;
            box-sizing: border-box;
            border-radius: 0px;
            transition: height 0.5s ease-in-out 0s;
            margin: 0px;
            &:focus {
                outline: rgb(0, 123, 199) solid 2px;
                outline-offset: 2px;
                box-shadow: rgb(1 104 155) 0px 0px 0px 1px inset;
            }
            &:disabled {
                background-color: rgb(243, 243, 243);
                color: #545454;
            }
            &.currency-input {
                padding-left: 40px;
                text-align: right;
            }
        }
        .radio-list {
            margin-bottom: -8px;
            label {
                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                display: inline-block;
                // position: relative;
                overflow: hidden;
                background-color: transparent;
                padding: 0.8125rem;
                margin-right: 1rem;
                // width: auto;
                cursor: pointer;
                border: 1px solid rgb(143, 202, 231);

                .label-value {
                    font-size: 1rem;
                    font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
                    line-height: 1.375rem;
                    display: flex;
                    // margin: 0.5rem 0px;
                    margin-left: 1.5rem;
                }
                .radio-input {
                    position: relative;
                    height: 1rem;
                    width: 1rem;
                    display: block;
                    float: left;
                    border: 0.125rem solid rgb(105, 105, 105);
                    border-radius: 50%;
                    background-color: rgb(255, 255, 255);
                    text-align: center;
                    &:hover {
                        border-color: rgb(1, 104, 155);
                    }

                    .radio-input-inner {
                        transition: all 0.25s ease-in-out 0s;
                        background-color: #ffffff;
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        display: block;
                        opacity: 0;
                        margin: 0.25rem auto;
                    }
                }
                &.is-checked {
                    background-color: rgba(143, 202, 231, 0.2);
                    .radio-input {
                        border-color: rgb(1, 104, 155);
                        background-color: rgb(1, 104, 155);

                        .radio-input-inner {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
                &.is-focussed {
                    outline: rgb(0, 123, 199) solid 2px;
                    outline-offset: 2px;
                    box-shadow: rgb(1 104 155) 0px 0px 0px 1px inset;
                }
            }
        }

        &.error {
            .form-control {
                box-shadow: rgb(213 43 30) 0px 0px 0px 1px inset !important;
            }
            .radio-list {
                label {
                    .radio-input {
                        border-color: rgb(213, 43, 30) !important;
                    }
                }
            }
            .radio-list {
                label {
                    border-color: rgb(213, 43, 30) !important;
                }
            }
        }
        .errors {
            &.help-block {
                max-width: 90%;
            }
        }
    }
    .help-box {
        margin-top: 12px;
        display: block;
        background-color: rgb(221, 239, 248);
        width: calc(100% - 4.5rem);
        max-width: 700px;
        padding: 12px;
        z-index: 2000;
        position: relative;
        font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
    }
    .help-box:before {
        position: absolute;
        margin-top: -20px;
        width: 0;
        height: 0;
        content: " ";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgb(221, 239, 248);
    }
    .help-box-close {
        display: block;
        float: right;
        cursor: pointer;
        padding: 0.7rem;
        border: 1px solid transparent;
        margin: -9px -10px;
        background-color: transparent;
    }
    .help-box-close:hover {
        border: 1px solid rgb(1, 104, 155);
    }

    .help-box-open {
        display: inline-block;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        box-sizing: border-box;
        color: rgb(1, 104, 155);
        width: 1.125rem;
        height: 1.125rem;
        outline: none;
        border-radius: 0px;
        border: 1px solid rgb(143, 202, 231);
        background-color: white;
        border-color: rgb(1, 104, 155);
        text-decoration: none;
        text-align: center;
        &.is-open {
            background-color: rgb(1, 104, 155);
            color: white;
        }
        &:before {
            line-height: 1.25;
            display: block;
            font-size: 12px;
            font-style: normal;
            font-feature-settings: "liga";
            font-family: bldiconfont !important;
            font-weight: normal !important;
            content: "help";
            margin: 0 auto;
        }
        &:focus {
            outline: rgb(0, 123, 199) solid 2px;
            outline-offset: 2px;
            box-shadow: rgb(1 104 155) 0px 0px 0px 1px inset;
        }
    }
    .remove-group-bucket-label {
        max-height: 46px;
        .label-html {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        a {
            text-decoration: none;
            display: block;
            &:before {
                font-size: 20px;
                font-style: normal;
                font-feature-settings: "liga";
                font-family: bldiconfont !important;
                font-weight: normal !important;
                content: "verwijderen";
                text-decoration: none;
                color: #01689b;
                text-align: center;
            }
        }
    }
    .text-red {
        color: red;
    }
    .text-orange {
        color: orange;
    }
}
</style>
