import errorMessage from "./messages";

export default value => {
    const message = errorMessage("license_plate_invalid");

    if (typeof value === "undefined" || value === null) {
        return [];
    }

    // if (value.indexOf("-") >= 0 && !new RegExp("^[a-zA-Z0-9]{1,3}-[a-zA-Z0-9]{1,3}-[a-zA-Z0-9]{1,3}$").test(value)) {
    //     return [message];
    // }

    value = value.replace(/-/g, "");
    let re = new RegExp("^(?=(.*[a-zA-Z]){2,})(?=(.*[0-9]){2,}).{6}$");
    let reOnlyAlphaNummeric = new RegExp("^[a-zA-Z0-9]{6}$");

    if (!re.test(value) || !reOnlyAlphaNummeric.test(value)) {
        return [message];
    } else {
        return [];
    }
};
