import convertStringDateToMilliseconds from "../date/convertStringDateToMilliseconds";
import calculations from "../calculations";
import groupModelValue from "../../helpers/model/groupModelValue";

export default (model, field, fieldModels, parameters) => {
    let result = 0;
    if (fieldModels[0] && fieldModels[1]) {
        result = calculations.hlpmwpontautkilometersperperiode(
            convertStringDateToMilliseconds(groupModelValue(fieldModels[0], model, field)),
            convertStringDateToMilliseconds(groupModelValue(fieldModels[1], model, field)),
            parameters.kilometers,
        );
    }

    return result > 0 ? result : 0;
};
