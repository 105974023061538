import convertStringDateToMilliseconds from "../date/convertStringDateToMilliseconds";
export default (model, fieldModels, parameters) => {
    let result = undefined;
    fieldModels.forEach(fieldModel => {
        if (parameters.years && model[fieldModel]) {
            // if value is four numbers i is a year, value + years
            if (new RegExp("^[0-9]{4}$").test(model[fieldModel])) {
                result = model[fieldModel] - parameters.years;
            }

            // if value is a date
            if (new RegExp("^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)[0-9]{4}$").test(model[fieldModel])) {
                const newDate = new Date(convertStringDateToMilliseconds(model[fieldModel]));
                const currentYear = newDate.getFullYear();
                result = new Date(newDate.setFullYear(currentYear - parameters.years)).toLocaleDateString("nl-NL");
            }
        }
    });

    return result;
};
