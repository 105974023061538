import formulaHandler from "../modules/formulas/handler";
import Vue from "vue";
import union from "lodash/union";
import pull from "lodash/pull";

export default {
    methods: {
        addVisibilityRulesForGroups(group) {
            // visibility for group
            group.visible = (model, newGroup) => {
                if (!newGroup.customVisible && !newGroup.blockVisible) {
                    return true;
                }

                if (newGroup.blockVisible) {
                    if (
                        !this.schema.blockVisibleConditions ||
                        typeof this.schema.blockVisibleConditions[newGroup.blockVisible] === "undefined"
                    ) {
                        console.warn("BlockVisible '" + newGroup.blockVisible + "' is used but not defined.");
                    }
                }

                if (!newGroup.customVisible && newGroup.blockVisible) {
                    if (
                        !this.schema.blockVisibleConditions ||
                        typeof this.schema.blockVisibleConditions[newGroup.blockVisible] === "undefined"
                    ) {
                        return true;
                    }

                    newGroup.sharedVisible = this.schema.blockVisibleConditions[newGroup.blockVisible];
                }

                if (newGroup.customVisible && !newGroup.blockVisible) {
                    newGroup.sharedVisible = newGroup.customVisible;
                }

                if (newGroup.customVisible && newGroup.blockVisible) {
                    newGroup.sharedVisible = [
                        {
                            condition: "and",
                            terms: newGroup.customVisible,
                        },
                        {
                            condition: "and",
                            terms: this.schema.blockVisibleConditions[newGroup.blockVisible],
                        },
                    ];
                }

                return formulaHandler(model, null, newGroup, this.static, "sharedVisible");
            };
        },
        applyVisibilityRules(group, groupIndex) {
            // apply custom visibily rules to show hide group
            if (group.visible && group.visible(this.model, group) && !group.hideGroup) {
                let newStyleClasses = group.styleClasses;
                if (!newStyleClasses) {
                    newStyleClasses = [];
                }

                pull(newStyleClasses, "group-hidden");
                Vue.set(this.schema.groups[groupIndex], "styleClasses", newStyleClasses);
            } else {
                Vue.set(this.schema.groups[groupIndex], "styleClasses", union(group.styleClasses, ["group-hidden"]));
            }
        },
    },
};
