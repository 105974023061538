import Vue from "vue";
import getWindowOptions from "../modules/helpers/config/getWindowOptions";

export const defaultOptions = {
    debug: false,
    showFieldData: getWindowOptions().showIdUnderLabel || false,
};

export default {
    namespaced: true,
    state: () => defaultOptions,
    mutations: {
        setOption(state, { option, value }) {
            Vue.set(state, option, value);
        },
    },
    actions: {
        setOption({ commit }, optionValue) {
            commit("setOption", optionValue);
        },
    },
    getters: {
        debug: state => state.debug,
        showFieldData: state => state.showFieldData,
    },
};
