import amountDaysInPeriod from "../../formulas/date/amountDaysInPeriod";
import amountDaysInYear from "../../formulas/date/amountDaysInYear";
import getYearOfDate from "../date/getYearOfDate";
const hlpmwpontautkilometersperperiode = (wpontautstandper1, wpontautstandper2, kilometers) => {
    if (wpontautstandper1 && wpontautstandper2) {
        if (!kilometers) {
            kilometers = 500;
        }
        // 500 x (aantal dagen in {wpontautstandper} / aantal dagen in ({wpontautstandper} beginjaar), afgerond naar boven
        return Math.ceil(
            kilometers *
                (amountDaysInPeriod(wpontautstandper1, wpontautstandper2) /
                    amountDaysInYear(getYearOfDate(wpontautstandper1))),
        );
    }
    return 0;
};
export default hlpmwpontautkilometersperperiode;
