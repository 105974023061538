import isYearByDate from "../../helpers/validation/isYearByDate";
import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";

export default (model, field, parameters, staticData) => {
    if (!staticData.BDRGJAAR) {
        console.error("Fiancial year is not set.");
    }

    let result = false;

    parameters.fieldModels.forEach(fieldModel => {
        result =
            !isYearByDate(groupModelValue(fieldModel, model, field), staticData.BDRGJAAR) ||
            !isModelVisible(fieldModel, field);
    });

    return result;
};
