import errorMessage from "./messages";
import isDate from "validator/lib/isDate";

export default value => {
    const errors = [];

    if (!isDate(value)) {
        errors.push(errorMessage("gregorian_date_invalid_notation"));
    }

    return errors;
};
