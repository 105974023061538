import amountDaysInPeriod from "../../formulas/date/amountDaysInPeriod";
import amountDaysInYear from "../../formulas/date/amountDaysInYear";
import hlpmwpontautperiodetot5jr from "./hlpmwpontautperiodetot5jr";
import hlpmwpontautperiodevanaf5jr from "./hlpmwpontautperiodevanaf5jr";
import hlpmwpontautpercbijtvanaf5jr from "./hlpmwpontautpercbijtvanaf5jr";
import hlpmwpontautpercbijt from "./hlpmwpontautpercbijt";

const hlpmwpondautberprivegebr = (
    wpontautstandcata,
    wpontautpercbijt,
    wpontautstandper1,
    wpontautstandper2,
    wpontauttenaamgest,
    wpontautnaambegin60mnd,
    wpontauttoegel,
    wpontautzeroemissie,
    wpontautbouw,
    wpontautwaterstof,
    BDRGJAAR,
) => {
    let result = 0;

    if (wpontautstandcata && wpontautstandper1 && wpontautstandper2 && BDRGJAAR) {
        const hlpmwpontautpercbijtResult = hlpmwpontautpercbijt(
            wpontautpercbijt,
            wpontauttoegel,
            wpontautbouw,
            wpontautstandcata,
            wpontauttenaamgest,
            wpontautzeroemissie,
            wpontautwaterstof,
        );

        // 3 IS ID FROM wpontauttenaamgest (IN THIS CASE: 2015) > CHECK IF wpontauttenaamgest <> BDRGJAARMIN5
        if (wpontauttenaamgest !== 3 || wpontautnaambegin60mnd === 12) {
            result = Math.floor(
                wpontautstandcata *
                    hlpmwpontautpercbijtResult *
                    (amountDaysInPeriod(wpontautstandper1, wpontautstandper2) / amountDaysInYear(BDRGJAAR)),
            );
        } else if (wpontauttenaamgest === 3 && wpontautnaambegin60mnd !== 12) {
            // prettier-ignore
            result = Math.floor(
                wpontautstandcata *
                    ((hlpmwpontautpercbijtResult *
                        hlpmwpontautperiodetot5jr(wpontautstandper1, wpontautstandper2, wpontautnaambegin60mnd)) /
                        amountDaysInYear(BDRGJAAR) +
                        (hlpmwpontautpercbijtvanaf5jr(wpontautpercbijt, wpontautstandcata, wpontauttenaamgest, wpontautnaambegin60mnd) *
                            hlpmwpontautperiodevanaf5jr(wpontautstandper1, wpontautstandper2, wpontautnaambegin60mnd)) /
                            amountDaysInYear(BDRGJAAR)),
            );
        }
    }
    return result;
};

export default hlpmwpondautberprivegebr;
