let constantsFile = "";

export const setConstantsFile = file => {
    constantsFile = file;
};

export const getConstantsFile = () => {
    return constantsFile;
};

export const getConstants = () => {
    const parser = new DOMParser();
    let xmlDoc = parser.parseFromString(constantsFile, "text/xml");
    let xmlVariables = xmlDoc.getElementsByTagName("variable");
    const constants = {};

    xmlVariables.forEach(xmlVariable => {
        const varName = xmlVariable.getElementsByTagName("name")[0];
        const varValue = xmlVariable.getElementsByTagName("value")[0];

        if (!varName || !varValue) {
            console.warn("Name or value not set for variable: ", xmlVariable);
        } else {
            let value = varValue.innerHTML;
            const possibleNummberValue = String(value)
                .replace(/\./g, "")
                .replace(/,/g, ".");

            if (+possibleNummberValue === +possibleNummberValue) {
                // Value is number
                value = possibleNummberValue * 1;
            }
            constants[varName.innerHTML] = value;
        }
    });

    return constants;
};
