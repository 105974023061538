import forEach from "lodash/forEach";
import { getValue } from "./values";
import modeLog from "../../utils/modeLog";

const calculationsRules = [
    {
        calc: "plus",
        gelID: "119356-EB",
    },
    {
        calc: "min",
        gelID: "119356-SB",
    },
    {
        calc: "plus",
        gelID: "119343-EB",
    },
    {
        calc: "min",
        gelID: "119343-SB",
    },
    {
        calc: "plus",
        gelID: "118315",
    },
    {
        calc: "plus",
        gelID: "118320",
    },
    {
        calc: "plus",
        gelID: "119788",
    },
    {
        calc: "min",
        gelID: "118340",
    },
];

export default values => {
    let total = 0;
    let calcSting = "";

    forEach(calculationsRules, rule => {
        // console.log("HALLOO", rule, rule.calc, getValue(rule.gelID, values));
        if (rule.calc === "plus") {
            calcSting += "+ " + getValue(rule.gelID, values) + " (" + rule.gelID + ") ";
            total += getValue(rule.gelID, values);
        } else if (rule.calc === "min") {
            calcSting += "- " + getValue(rule.gelID, values) + " (" + rule.gelID + ") ";
            total -= getValue(rule.gelID, values);
        }
    });
    modeLog("equitygrowth", calcSting);

    total = Number(total).toFixed(2) * 1;

    return total;
};
