export default (model, field, parameters, staticData) => {
    let result = false;

    if (!parameters || typeof parameters.static === "undefined") {
        console.warn("staticExists is used but parameters.static is not set.");
    }

    if (typeof staticData[parameters.static] !== "undefined") {
        return true;
    }

    return result;
};
