import Vue from "vue";

export default {
    data() {
        return {
            displayValue: null,
            valueChangedInline: false,
            fromValueChange: false,
            fromDisplayValueChange: false,
        };
    },
    methods: {
        reformatValue: function() {
            if (
                this.schema.type === "year" ||
                this.schema.noReformat === true ||
                typeof this.displayValue === "undefined" ||
                this.displayValue === null
            ) {
                return;
            }

            // Remove the dots from the new value and split decemals by comma
            let newDisplayValue = this.displayValue.replace(/\./g, "").split(",");

            // set the thausand dots in de number without decimals
            newDisplayValue[0] = newDisplayValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

            if (newDisplayValue[1] && newDisplayValue[1].length === 1) {
                newDisplayValue[1] += "0";
            }

            // join the number and de deciamals
            this.setRawDisplayValue(String(newDisplayValue.join()));
        },
        setDisplayValue: function() {
            // transform the model value to a display value with thausand dots en comma decimal separator
            if (typeof this.value !== "undefined" && this.schema.noReformat !== true && this.schema.type !== "year") {
                const newDisplayValue = String(this.value)
                    .replace(/,/g, "")
                    .replace(/\./g, ",")
                    .split(",");

                newDisplayValue[0] = newDisplayValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                if (newDisplayValue[1] && newDisplayValue[1].length === 1) {
                    // newDisplayValue[1] += "0";
                }

                this.setRawDisplayValue(String(newDisplayValue.join()));
            } else if (
                typeof this.value !== "undefined" &&
                this.schema.noReformat === true &&
                this.schema.type === "year"
            ) {
                this.setRawDisplayValue(String(this.value));
            } else if (typeof this.value === "undefined" || this.value === null) {
                this.setRawDisplayValue(undefined);
            }
        },
        setRawDisplayValue: function(value) {
            const currencyInput = this.$refs.currencyInput;
            const isFocused = document.activeElement === this.$refs.currencyInput;
            if (currencyInput && value && isFocused) {
                const initLength = this.displayValue.length;
                const newLength = value.length;
                const lengthDifference = newLength - initLength;
                let cursorPosition = currencyInput.selectionStart;
                let newCursorPosition = cursorPosition + lengthDifference;

                if (newCursorPosition < 0) {
                    newCursorPosition = 0;
                }

                this.displayValue = value;
                Vue.nextTick(() => {
                    this.$refs.currencyInput.setSelectionRange(newCursorPosition, newCursorPosition);
                });
                return;
            }
            this.displayValue = value;
        },
        roundNumber: function(value, roundOption) {
            if (value === 0 || !roundOption) {
                return value;
            }
            if (roundOption.toLowerCase() === "up") {
                return Math.ceil(value);
            } else if (roundOption.toLowerCase() === "down") {
                return Math.floor(value);
            }
            return value;
        },
    },
    watch: {
        displayValue: function(newValue, oldValue) {
            if (typeof newValue !== "undefined" && newValue !== null && newValue !== oldValue) {
                // Check if new character is valid, else, remove it.
                if (!new RegExp("^[-+]?[0-9.,]*?$").test(newValue)) {
                    newValue = oldValue;

                    // set the new display value
                    this.setRawDisplayValue(newValue);
                }

                // set the model value with a real number
                if (!this.fromValueChange) {
                    if (this.schema.noReformat !== true && this.schema.type !== "year") {
                        if (this.displayValue === "") {
                            this.fromValueChange = true;
                            this.value = undefined;
                        } else {
                            this.fromValueChange = true;
                            if (this.displayValue === "-") {
                                return;
                            }
                            this.value = this.roundNumber(
                                String(this.displayValue)
                                    .replace(/\./g, "")
                                    .replace(/,/g, ".") * 1,
                                this.schema.round,
                            );
                        }
                    } else {
                        this.fromValueChange = true;
                        if (this.displayValue === "-") {
                            return;
                        }
                        this.value = this.roundNumber(this.displayValue * 1, this.schema.round);
                    }
                }
            }
            if ((typeof newValue === "undefined" || newValue === null) && !this.fromValueChange) {
                this.fromValueChange = true;
                this.value = undefined;
            }

            this.fromValueChange = false;
        },
        value: function(newValue) {
            if (!this.valueChangedInline) {
                // this.fromValueChange = true;
                if (typeof newValue === undefined || newValue === null) {
                    this.setRawDisplayValue(undefined);
                } else {
                    this.setDisplayValue();
                }
            }
        },
        schema: function() {
            this.setDisplayValue();
        },
    },
    created() {
        this.setDisplayValue();
    },
};
