import isInt from "validator/lib/isInt";
import errorMessage from "./messages";

export default value => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = [];

    if (!isInt(value + "")) {
        errors.push(errorMessage("no_decimals"));
    }

    return errors;
};
