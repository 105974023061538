<template>
    <div>
        <!-- v-if="!showIntroSlider" -->
        <main>
            <bldc-grid-column col="12">
                <bldc-heading level="1" id="page-title">{{ $t("introduction.introductie") }}</bldc-heading>
            </bldc-grid-column>
            <bldc-card
                type="content"
                :is-mobile="true"
                :elem-title="
                    $t('introduction.voorbereiden_ondernemingsgegevens_voor_aangifte_inkomstenbelasting', {
                        year: statics.BDRGJAAR,
                    })
                "
                :subtitle="
                    $store.getters['currentState/hasCurrentState'] && !$store.getters['currentState/downloaded']
                        ? $t('introduction.u_hebt_nog_niet_alle_stappen_afgerond')
                        : ''
                "
                expandable="true"
                expanded="true"
            >
                <div v-if="!$store.getters['currentState/hasCurrentState'] || !started">
                    <bldc-notification
                        disable-margin="true"
                        type="letop"
                        :elem-title="$t('introduction.ondernemingsgegevens_voorbereiden')"
                    >
                        <bldc-paragraph :margin="false">
                            {{ $t("introduction.nog_niet_begonnen_tekst") }}
                        </bldc-paragraph>
                    </bldc-notification>
                    <div class="button-container">
                        <bldc-button
                            type="primary"
                            :elem-title="$t('introduction.start_met_voorbereiden')"
                            style="float: right;"
                            @click="confirmShowTour"
                        ></bldc-button>
                    </div>
                </div>
                <div
                    v-if="
                        $store.getters['currentState/hasCurrentState'] &&
                            started &&
                            !$store.getters['currentState/downloaded']
                    "
                >
                    <bldc-notification
                        type="letop"
                        disable-margin="true"
                        :elem-title="
                            $t('introduction.de_winstaangifte', {
                                year: statics.BDRGJAAR,
                            })
                        "
                    >
                        <bldc-paragraph :margin="false">
                            {{ $t("introduction.nog_niet_alle_stappen_afgerond_tekst") }}
                            <div class="button-container" style="margin-top: 10px;">
                                <bldc-button
                                    type="primary"
                                    :elem-title="$t('global.doorgaan')"
                                    style="float: right;"
                                    @click="resumeDeclaration"
                                ></bldc-button>
                                <bldc-button
                                    type="secondary"
                                    :elem-title="$t('global.verwijderen')"
                                    style="float: right; margin-right: 1rem;"
                                    @click="confirmDeleteCurrentState"
                                ></bldc-button>
                            </div>
                        </bldc-paragraph>
                    </bldc-notification>
                </div>
                <div v-if="$store.getters['currentState/downloaded']">
                    <bldc-notification
                        type="succes"
                        :elem-title="
                            $t('introduction.de_winstaangifte', {
                                year: statics.BDRGJAAR,
                            })
                        "
                    >
                        <bldc-paragraph :margin="false">
                            <span style="line-height: 3.2rem;">
                                {{ $t("introduction.alle_stappen_doorlopen_tekst") }}
                            </span>
                            <div class="button-container">
                                <bldc-button
                                    type="secondary"
                                    :elem-title="$t('global.verwijderen')"
                                    style="float: right;"
                                    @click="confirmDeleteCurrentState"
                                ></bldc-button>
                                <bldc-button
                                    type="primary"
                                    :elem-title="$t('global.openen')"
                                    style="float: right; margin-right: 1rem;"
                                    @click="resumeDeclaration"
                                ></bldc-button>
                            </div>
                        </bldc-paragraph>
                    </bldc-notification>
                    <bldc-notification
                        type="letop"
                        :elem-title="$t('introduction.vergeet_niet_de_ondernemingsgegevens_nog_up_te_loaden')"
                        v-if="!$store.getters['currentState/finished']"
                    >
                        <bldc-paragraph :margin="false">
                            {{ $t("introduction.klaar_met_voorbereiding_tekst") }}
                        </bldc-paragraph>
                    </bldc-notification>
                    <bldc-notification
                        type="letop"
                        :elem-title="$t('introduction.maak_gebruik_van_de_speciale_belastingdienst_omgeving')"
                        v-if="!$store.getters['currentState/finished']"
                    >
                        <bldc-paragraph :margin="false">
                            {{ $t("introduction.gegevens_vooringevuld_in_mijn_belastingdienst") }}
                        </bldc-paragraph>
                    </bldc-notification>
                </div>
            </bldc-card>
        </main>

        <bldc-modal
            :visible="showDeleteConfirm"
            id="showDeleteConfirm"
            v-on:bldcModalConfirm="deleteCurrentState"
            v-on:bldcModalClose="notDeleteCurrentState"
            :buttons="
                JSON.stringify([
                    { type: 'secondary', label: $t('global.annuleren'), event: 'close' },
                    {
                        type: 'primary',
                        label: $t('global.ja'),
                        event: 'confirm',
                    },
                ])
            "
            modalId="showDeleteConfirm"
        >
            <bldc-paragraph>
                {{ $t("introduction.weet_u_zeker_dat_u_de_uw_ingevulde_gegevens_wilt_verwijderen") }}
            </bldc-paragraph>
        </bldc-modal>
        <bldc-modal
            :visible="showTourConfirm"
            id="showTourConfirm"
            v-on:bldcModalConfirm="startDeclaration(true)"
            v-on:bldcModalClose="startDeclaration(false)"
            :buttons="
                JSON.stringify([
                    { type: 'secondary', label: $t('tour.rondleiding_overslaan'), event: 'close' },
                    {
                        type: 'primary',
                        label: $t('tour.rondleiding_starten'),
                        event: 'confirm',
                    },
                ])
            "
            modalId="showTourConfirm"
        >
            <div style="text-align: center;">
                <img
                    :src="$t('tour.start_rondleiding_afbeelding')"
                    :alt="$t('tour.start_rondleiding_afbeelding_text')"
                    style="max-width: 250px; margin: 0.5rem auto;"
                />
            </div>
            <bldc-paragraph>
                {{ $t("tour.start_rondleiding_modal_tekst") }}
            </bldc-paragraph>
        </bldc-modal>
        <handle-forms ref="handleForms" :statics="statics" v-on:file-blob="saveFile" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import slider from "../components/slider";
import HandleForms from "../components/HandleForms";
import { saveAs } from "file-saver";
import { deleteCurrentState, saveCurrentState } from "../modules/singletons/currentState";
import getStatics from "../modules/singletons/statics";

export default {
    name: "intro",
    mixins: [],
    components: {
        HandleForms,
    }, //slider,
    data() {
        return {
            fileName: "",
            loadDownload: false,
            showDeleteConfirm: false,
            statics: getStatics(),
            showIntroSlider: true,
            showTourConfirm: false,
        };
    },
    computed: {
        ...mapGetters({
            rgsHeaders: "rgsLedger/headers",
            prevRoute: "navigation/prevRoute",
            nextRoute: "navigation/nextRoute",
            firstPossibleRoute: "navigation/firstPossibleRoute",
            showTour: "tour/showTour",
            started: "navigation/started",
        }),
    },
    methods: {
        confirmShowTour: function() {
            if (this.showTour === null) {
                this.showTourConfirm = true;
                return;
            }
            this.startDeclaration(this.$store.getters["tour/showTour"]);
        },
        startDeclaration: function(showTour) {
            this.showTourConfirm = false;
            this.$store.dispatch("tour/setShowTour", showTour);

            if (this.nextRoute.routeParams) {
                this.$router.push({ name: this.nextRoute.routeName, params: this.nextRoute.routeParams });
            } else {
                this.$router.push({ name: this.nextRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.nextRoute.routeName);
            this.$store.dispatch("navigation/setStarted", true);
            saveCurrentState();
            // this.$router.push({ name: `year-overview` });
        },
        resumeDeclaration: function() {
            if (this.firstPossibleRoute.routeParams) {
                this.$router.push({
                    name: this.firstPossibleRoute.routeName,
                    params: this.firstPossibleRoute.routeParams,
                });
            } else {
                this.$router.push({ name: this.firstPossibleRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.firstPossibleRoute.routeName);
        },
        confirmDeleteCurrentState: function() {
            this.showDeleteConfirm = true;
        },
        deleteCurrentState: function() {
            this.showDeleteConfirm = false;
            this.$store.dispatch("currentState/deleteCurrentState");
            deleteCurrentState();
        },
        notDeleteCurrentState: function() {
            this.showDeleteConfirm = false;
        },

        download: function() {
            this.loadDownload = true;
            this.$refs.handleForms.download();
        },

        saveFile: function(blob) {
            saveAs(blob, this.fileName);
            this.loadDownload = false;
        },
        sliderFinish: function() {
            this.showIntroSlider = false;
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch("navigation/setCurrentItem", `intro`);
        });
    },
    mounted() {
        if (this.$store.getters["currentState/hasCurrentState"]) {
            this.showIntroSlider = false;
        }
    },
};
</script>

<style lang="scss" scoped="true">
.button-container {
    height: 2rem;
}
.download-file {
    margin-top: 0.7rem;
    width: 100%;
    clear: both;
    display: block;
    float: left;
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;
    color: rgb(1, 104, 155);
    &:hover {
        color: rgb(21, 66, 115);
    }
    .file-name {
        float: left;
        margin-right: 6px;
    }
    .download-icon {
        display: block;
        margin-top: -3px;
        float: left;
    }
}
.slider-content {
    background-color: #ffffff;
    padding: 3rem 1rem 0.5rem;
    margin-bottom: 1rem;
}
</style>
