export default numberValue => {
    let value = numberValue;

    let valueParts = value
        .toFixed(0)
        .replace(/,/g, "")
        .replace(/\./g, ",")
        .split(",");

    valueParts[0] = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    value = String(valueParts.join());

    return value;
};
