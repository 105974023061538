<template>
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
        class="form-control"
        type="text"
        v-model="value"
        :id="getFieldID(schema)"
        :disabled="disabled"
        :maxlength="schema.max"
        :placeholder="schema.placeholder"
        :readonly="schema.readonly"
        v-attributes="'input'"
        :aria-label="hiddenLabel"
    />
</template>
<script>
import abstractField from "./abstractField";
import validatorBugFix from "./validatorBugFix";

export default {
    mixins: [abstractField, validatorBugFix],
};
</script>
