<template>
    <div :class="{ container: !isTotal, 'total-container': isTotal }" style="padding-left: 1.4rem">
        &euro;
        <span class="amount">
            {{ displayAmount }}
        </span>
    </div>
</template>
<script>
export default {
    name: "yearOverviewAmount",
    mixins: [],
    props: {
        amount: {
            type: Number,
            required: false,
            default: 0,
        },
        isTotal: {
            type: Boolean,
            required: false,
            default: false,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        displayAmount() {
            return this.amount.toLocaleString("nl-NL", this.localStringOptions);
        },
        localStringOptions() {
            if (this.rounded) {
                return { maximumFractionDigits: 2 };
            }
            return { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        },
    },
    methods: {
        // testje: function() {
        //     console.log("HOOI");
        // },
    },
    created() {},
};
</script>
<style lang="scss" scoped>
.container {
    padding-left: 10px;
}
.total-container {
    line-height: 1.5rem;
    font-size: 110%;
    margin: 0.625rem 0px;
}
.amount {
    display: block;
    float: right;
}
</style>
