<template>
    <span :id="getFieldID(schema)" :class="schema.fieldClasses" v-attributes="'label'">{{ value }}</span>
</template>

<script>
import abstractField from "./abstractField";

export default {
    mixins: [abstractField],
};
</script>

<style lang="scss"></style>
