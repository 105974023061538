import forEach from "lodash/forEach";
import { getValue } from "./values";

export default (mBmgAwaData, fromPosition, values, filter = null) => {
    let total = 0;

    forEach(mBmgAwaData, (gelIDmBmgAwaData, gelID) => {
        if (filter) {
            if (gelIDmBmgAwaData.position === fromPosition && gelID.endsWith(filter)) {
                total += Number(getValue(gelID, values)).toFixed(2) * 1;
                total = Number(total).toFixed(2) * 1;
            }
        } else {
            if (gelIDmBmgAwaData.position === fromPosition) {
                total += Number(getValue(gelID, values)).toFixed(2) * 1;
                total = Number(total).toFixed(2) * 1;
            }
        }
    });

    return total;
};
