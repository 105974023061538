import errorMessage from "./messages";

export default value => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = [];

    if (value <= 0) {
        errors.push(errorMessage("greater_or_equals_zero"));
    }

    return errors;
};
