let fieldComponents = {};

let coreFields = require.context("../../fields", false, /^\.\/field([\w-_]+)\.vue$/);

coreFields.keys().forEach(key => {
    let compName = key.replace(/^\.\//, "").replace(/\.vue/, "");
    fieldComponents[compName] = coreFields(key).default;
});

// if (process.env.FULL_BUNDLE) {
//     let optionalFields = require.context("../fields/optional", false, /^\.\/field([\w-_]+)\.vue$/);

//     forEach(optionalFields.keys(), key => {
//         let compName = key.replace(/^\.\//, "").replace(/\.vue/, "");
//         fieldComponents[compName] = optionalFields(key).default;
//     });
// }

module.exports = fieldComponents;
