import groupModelValue from "../../helpers/model/groupModelValue";

export default (model, field, fieldModels) => {
    let result = undefined;

    if (typeof fieldModels[0] === "undefined") {
        return result;
    }

    const fieldModelValue = groupModelValue(fieldModels[0], model, field);

    if (fieldModelValue) {
        result = Math.ceil(fieldModelValue);
    }

    return result;
};
