export default modelValue => {
    if (modelValue === 3) {
        return "35% van de taxatiewaarde van de auto";
    } else if (modelValue === 1) {
        return "25% van de cataloguswaarde van de auto";
    } else if (modelValue === 2) {
        return "het bedrag dat u normaal gesproken moet verrekenen";
    } else {
        return "";
    }
};
