<template>
    <div class="box" role="complementary">
        <bldc-task-step-indicator
            :steps="JSON.stringify(navigationItems)"
            :current="currentStep"
            :isDone="isDone"
        ></bldc-task-step-indicator>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "navigation",
    mixins: [],
    props: {},
    components: {},
    data() {
        return {};
    },
    computed: {
        navigationItems: function() {
            return this.items.map(item => {
                item.label = this.$t("steps." + item.label);
                return item;
            });
        },

        ...mapGetters({
            items: "navigation/items",
            currentStep: "navigation/currentStep",
            isDone: "navigation/isDone",
        }),
    },
    methods: {},
    created() {},
};
</script>
<style lang="scss">
.box {
    margin-bottom: 2.5rem;
}
</style>
