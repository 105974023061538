import { setEventListenersAreSet, getEventListenersAreSet } from "../modules/singletons/initEventListeners";
export default {
    methods: {
        initClickEvents: function(event) {
            if (event.target.getAttribute("data-add-group")) {
                event.preventDefault();
                this.addGroupOnClick(event);
            }
            if (event.target.getAttribute("data-remove-group")) {
                event.preventDefault();
                this.removeGroupOnClick(event);
            }
        },
        // add events on document
        addDocumentEvents: function() {
            if (!getEventListenersAreSet()) {
                document.addEventListener("focusin", this.focusIn);
                document.addEventListener("focusout", this.fieldValidationOnFocusOut);
                document.addEventListener("change", this.fieldValidationOnChange);
                document.addEventListener("click", this.initClickEvents);

                setEventListenersAreSet(true);
            }
        },

        // remove events on document
        removeDocumentEvents: function() {
            if (getEventListenersAreSet()) {
                document.removeEventListener("focusin", this.focusIn);
                document.removeEventListener("focusout", this.fieldValidationOnFocusOut);
                document.removeEventListener("change", this.fieldValidationOnChange);
                document.removeEventListener("click", this.initClickEvents);

                setEventListenersAreSet(false);
            }
        },
    },
};
