import debounce from "lodash/debounce";
import store from "../../store";

let currentStateFn;

export const setCurrentStateFn = currentStateCallbackFn => {
    currentStateFn = currentStateCallbackFn;
};

export const callCurrentStateFn = debounce(() => {
    currentStateFn(store.getters["currentState/getCurrentState"]);
}, 100);

export const saveCurrentState = () => {
    store.dispatch("currentState/setHasCurrentState", true);
    callCurrentStateFn();
};

export const deleteCurrentState = () => {
    store.dispatch("currentState/setHasCurrentState", false);
    callCurrentStateFn();
};
