import isBefore from "validator/lib/isBefore";
import isAfter from "validator/lib/isAfter";
import errorMessage from "./messages";
import statics from "../singletons/statics";
import valueFilter from "../helpers/model/valueFilter";

export default (value, field, model) => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = [];

    // eslint-disable-next-line no-useless-escape, prettier/prettier
    const re = new RegExp("^[0-9]{4}$");

    if (!re.test(value)) {
        errors.push(errorMessage("year_invalid_notation"));

        // return this error directly we can not transform this to a valid dat to following checks
        return errors;
    }

    let isCustomBeforeOrAfterDate = false;

    if (field && field.validatorParameters && field.validatorParameters.beforeYear) {
        const beforeYear = valueFilter(field.validatorParameters.beforeYear, statics(), model, field);
        if (isAfter(value + "", beforeYear + "")) {
            errors.push(errorMessage("year_after_before_date", { beforeYear: beforeYear }));
        }

        isCustomBeforeOrAfterDate = true;
    }

    if (field && field.validatorParameters && field.validatorParameters.afterYear) {
        const afterYear = valueFilter(field.validatorParameters.afterYear, statics(), model, field);
        if (isBefore(value + "", afterYear + "")) {
            errors.push(errorMessage("year_before_after_date", { afterYear: afterYear }));
        }

        isCustomBeforeOrAfterDate = true;
    }

    if (!isCustomBeforeOrAfterDate && value < 1901) {
        errors.push(errorMessage("year_before_after_date", { afterYear: 1901 }));
    }

    if (!isCustomBeforeOrAfterDate && value > 2200) {
        errors.push(errorMessage("year_after_before_date", { beforeYear: 2200 }));
    }

    return errors;
};
