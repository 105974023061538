import errorMessage from "./messages";

export default character => {
    const regex = "^[" + character + character.toLowerCase() + "][0-9]{9}$";

    return value => {
        if (typeof value === "undefined" || value === null) {
            return [];
        }
        const errors = [];

        const re = new RegExp(regex);

        if (!re.test(value)) {
            errors.push(errorMessage("report_number_invalid", { character: character }));
        }

        return errors;
    };
};
