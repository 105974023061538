import helpers from "../helpers";

export default config => {
    let result = "#";
    if (helpers.getConfig(config) && helpers.getConfig(config).getRequestEndpoint) {
        result = helpers.getConfig(config).getRequestEndpoint;
    }
    // add timestamp to ensure no caching
    result += `?${new Date().getTime()}`;
    return result;
};
