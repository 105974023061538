import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import valueFilter from "../../helpers/model/valueFilter";
import { notExistsWarning } from "../../singletons/registeredModels";

export default (model, field, parameters, staticData) => {
    let result = true;
    if (model && parameters && parameters.fieldModels && parameters.data) {
        if (!Array.isArray(parameters.data)) {
            console.warn("notEqualsData is used but parameters.data is not an array. Model: " + field.model);
            return;
        }

        const data = parameters.data;

        data.forEach((dataEntry, index) => {
            data[index] = valueFilter(dataEntry, staticData, model, field);
        });

        parameters.fieldModels.forEach(fieldModel => {
            notExistsWarning(fieldModel, field, "");
            if (data.includes(groupModelValue(fieldModel, model, field)) && isModelVisible(fieldModel, field)) {
                result = false;
            }
        });
    } else if (!parameters || !parameters.fieldModels || !parameters.data) {
        console.warn(
            "NotEqualsData is used but parameters are not correct. Expects fieldModels and data as parameters. Model: " +
                field.model,
        );
    }

    return result;
};
