import licensePlate from "./licensePlate";
import amountPosThirteen from "./amountPosThirteen";
import positiveNumber from "./positiveNumber";
import noDecimals from "./noDecimals";
import amountThirteen from "./amountThirteen";
import date from "./date";
// import hoursOfCooperationPartner from "./hoursOfCooperationPartner";
import percentageThree from "./percentageThree";
import reportNumber from "./reportNumber";
import required from "./required";
import year from "./year";
import custom from "./custom";
// import alphaNumericalLength from "./alphaNumericalLength";

export default {
    licensePlate: licensePlate,
    verplicht: required,
    required: required,
    bedrag13: amountThirteen,
    bedrag_pos13: amountPosThirteen,
    datum: date,
    jaar: year,
    // uren_meewerk_partner: hoursOfCooperationPartner,
    meldnr_e: reportNumber("E"),
    meldnr_m: reportNumber("M"),
    percentage3: percentageThree,
    kenteken: licensePlate,
    custom: custom,
    // "AN..70": alphaNumericalLength("an", 70, false),
    // "AN..200": alphaNumericalLength("an", 200, false),
    // AN10: alphaNumericalLength("an", 10, true),
    positief_nummer: positiveNumber,
    geen_decimalen: noDecimals,
};
