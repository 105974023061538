import valueFilter from "../../helpers/model/valueFilter";
import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import { notExistsWarning } from "../../singletons/registeredModels";

export default (model, field, parameters, staticData, showValueWarnings = true) => {
    let result = false;

    if (!parameters || typeof parameters.value === "undefined") {
        console.warn("equalsValue is used but parameters.value is not set. Field with model: " + field.model);
    } else if (Array.isArray(typeof parameters.value)) {
        console.warn("equalsValue is used but parameters.value is an array. Field with model: " + field.model);
    }

    if (!isModelVisible(parameters.value, field)) {
        return result;
    }

    const value = valueFilter(
        parameters.value,
        staticData,
        model,
        field,
        "Static",
        "Model",
        "RepeatModel",
        showValueWarnings,
    );

    parameters.fieldModels.forEach(fieldModel => {
        if (showValueWarnings) notExistsWarning(fieldModel, field, "");
        result = groupModelValue(fieldModel, model, field) === value && isModelVisible(fieldModel, field);
    });

    return result;
};
