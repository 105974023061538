import valueFilter from "../../helpers/model/valueFilter";
import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import { notExistsWarning } from "../../singletons/registeredModels";

export default (model, field, parameters, staticData) => {
    let result = false;

    if (!parameters || typeof parameters.value === "undefined") {
        console.warn("notIsRegex is used but parameters.value is not set. Field: " + field.model);
    }

    const value = valueFilter(parameters.value, staticData, model, field);

    const regex = RegExp(value);

    parameters.fieldModels.forEach(fieldModel => {
        notExistsWarning(fieldModel, field, "");
        result = !regex.test(groupModelValue(fieldModel, model, field)) || !isModelVisible(fieldModel, field);
    });

    return result;
};
