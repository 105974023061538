import statics from "./statics";
let registeredModels = [];
let registeredRepeatModels = {};
let showedErrors = [];

export const setRegisteredModels = newRegisteredModels => {
    registeredModels = newRegisteredModels;
};

export const setRegisteredRepeatModels = newRegisteredRepeatModels => {
    registeredRepeatModels = newRegisteredRepeatModels;
};

export const modelExists = model => {
    return registeredModels.includes(model);
};

export const repeatModelExists = (repeatModel, model) => {
    if (registeredRepeatModels[repeatModel] && Array.isArray(registeredRepeatModels[repeatModel])) {
        return registeredRepeatModels[repeatModel].includes(model);
    }
    return false;
};

export const notExistsWarning = (modelKey, field, modelAffix = "Model", groupAffix = "RepeatModel") => {
    let exists = false;
    if (
        (modelKey + "").endsWith(groupAffix) &&
        repeatModelExists(field.repeatableGroup, modelKey.replace(groupAffix, ""))
    ) {
        exists = true;
    }
    if ((modelKey + "").endsWith(modelAffix) && modelExists(modelKey)) {
        exists = true;
    }

    if (typeof statics()[modelKey] !== "undefined") {
        exists = true;
    }
    if (!exists) {
        if (field && field.model) {
            if (!showedErrors.includes(modelKey + field.model)) {
                // console.warn(
                //     "Variable '" + modelKey + "' in string not found used on field with model: " + field.model,
                // );
                showedErrors.push(modelKey + field.model);
            }
        } else if (field && field.refid) {
            if (!showedErrors.includes(modelKey + field.refid)) {
                // console.warn(
                //     "Variable '" + modelKey + "' in string not found used on field with refid: " + field.refid,
                // );
                showedErrors.push(modelKey + field.refid);
            }
        } else {
            if (!showedErrors.includes(modelKey) && !modelKey.endsWith("-CB") && !modelKey.endsWith("Rounded")) {
                showedErrors.push(modelKey);
                // console.warn("Variable '" + modelKey + "' in string not found used on field without model or refid");
            }
        }
    }
};
