import Vue from "vue";
import formulaHandler from "../modules/formulas/handler";
import calculations from "../modules/calculations/calculations";
import valueFilter from "../modules/helpers/model/valueFilter";
import issetValue from "../modules/helpers/validation/issetValue";
import groupModelValue from "../modules/helpers/model/groupModelValue";
import { notExistsWarning } from "../modules/singletons/registeredModels";
// import removeObservers from "../modules/utils/removeObservers";

export default {
    methods: {
        addRequiredValidator(field) {
            if (field.type === "label" || field.type === "submit" || field.type === "hidden") {
                return;
            }

            // Prevent for adding required validator for this field
            if (field.isNotRequired === true) {
                return;
            }

            if (typeof field.validator === "undefined") {
                field.validator = ["verplicht"];
                return;
            }

            if (typeof field.validator === "string" || field.validator instanceof String) {
                field.validator = [field.validator];
            }

            if (!field.validator.includes("verplicht") && !field.validator.includes("required")) {
                field.validator.unshift("verplicht");
            }
        },
        addVisibilityRules(field, group) {
            if (field.visible === false) {
                field.originalVisible = field.visible;
            }

            // visibility for model
            field.visible = (model, field) => {
                // force all fields are showed bij config
                if (window.bdForms && window.bdForms.config && window.bdForms.config.showAllFields) {
                    return true;
                }

                // if group not visible, field is not too
                if (!group.visible(this.model, group)) {
                    return false;
                }

                if (group.repeatModel && group.radioModel && field.originalModel === group.radioModel) {
                    const highestIndexes = this.getRepeatableGroupsIndexes(group.repeatModel);
                    if (highestIndexes.highestRepeatableIndex !== group.repeatableIndex) {
                        return false;
                    }
                }

                if (field.isAddLink) {
                    const highestIndexes = this.getRepeatableGroupsIndexes(group.repeatModel);
                    if (highestIndexes.highestRepeatableIndex !== group.repeatableIndex) {
                        return false;
                    }
                }

                if (field.originalVisible === false) {
                    return false;
                }

                if (!field.customVisible && !field.blockVisible) {
                    return true;
                }

                if (field.blockVisible) {
                    if (
                        !this.schema.blockVisibleConditions ||
                        typeof this.schema.blockVisibleConditions[field.blockVisible] === "undefined"
                    ) {
                        console.warn("BlockVisible '" + field.blockVisible + "' is used but not defined.");
                    }
                }

                let sharedVisible = [];

                if (!field.customVisible && field.blockVisible) {
                    if (
                        !this.schema.blockVisibleConditions ||
                        typeof this.schema.blockVisibleConditions[field.blockVisible] === "undefined"
                    ) {
                        return true;
                    }

                    sharedVisible = this.schema.blockVisibleConditions[field.blockVisible];
                }

                if (field.customVisible && !field.blockVisible) {
                    sharedVisible = field.customVisible;
                }

                if (field.customVisible && field.blockVisible) {
                    sharedVisible = [
                        {
                            condition: "and",
                            terms: [...field.customVisible],
                        },
                        {
                            condition: "and",
                            terms: [...this.schema.blockVisibleConditions[field.blockVisible]],
                        },
                    ];
                }

                const holder = {
                    sharedVisible: sharedVisible,
                };

                return formulaHandler(this.model, field, holder, this.static, "sharedVisible");
            };
        },
        trackModelVisibility(field) {
            // add unique event id to field if model isset
            if (typeof field.model !== "undefined") {
                Vue.set(this.modelVisibility, field.model, field.visible(this.model, field));
            }
        },
        noRefactorForYearField(field) {
            // add no reformat if field type year (uses number field)
            if (typeof field.type !== "undefined" && field.type === "year") {
                field.noReformat = true;
            }
        },
        addVisibilityRulesWithStaticValues(field) {
            // visibility for fields with an expected value
            if (
                field &&
                field.customDisabled &&
                field.customDisabled.static &&
                field.customDisabled.model &&
                this.static[field.customDisabled.static]
            ) {
                // field.visible = model => {
                let result = false;

                if (typeof groupModelValue(field.customDisabled.model, this.model, field) === "undefined") {
                    return result;
                }

                if (!field.customDisabled.operator) {
                    result =
                        groupModelValue(field.customDisabled.model, this.mode, field) !==
                        this.static[field.customDisabled.static];
                } else if (field.customDisabled.operator === "notEquals") {
                    result =
                        groupModelValue(field.customDisabled.model, this.model, field) !==
                        this.static[field.customDisabled.static];
                } else if (field.customDisabled.operator === "equals") {
                    result =
                        groupModelValue(field.customDisabled.model, this.model, field) ===
                        this.static[field.customDisabled.static];
                } else if (field.customDisabled.operator === "greaterThan") {
                    result =
                        groupModelValue(field.customDisabled.model, this.model, field) >
                        this.static[field.customDisabled.static];
                } else if (field.customDisabled.operator === "lessThan") {
                    result =
                        groupModelValue(field.customDisabled.model, this.model, field) <
                        this.static[field.customDisabled.static];
                }

                // console.log("komt hier", result);
                this.isSubmitButtonDisabled = result;

                //     return result;
                // };
            }
        },
        addConditionalValueRules(field) {
            let newValue = issetValue(field.defaultValue)
                ? valueFilter(field.defaultValue, this.static, this.model, field)
                : null;

            let issetNewValue = false;

            // values for model
            if (field.conditionalValues) {
                field.conditionalValues.forEach(conditionalValue => {
                    if (!issetNewValue && formulaHandler(this.model, field, conditionalValue, this.static, "terms")) {
                        if (conditionalValue.setValue) {
                            newValue = valueFilter(conditionalValue.setValue, this.static, this.model, field);
                        } else if (conditionalValue.setValueWithCalculation) {
                            newValue = calculations[conditionalValue.setValueWithCalculation.formula](
                                this.model,
                                field,
                                conditionalValue.setValueWithCalculation.parameters.fieldModels,
                                conditionalValue.setValueWithCalculation.parameters,
                                this.mutatedValuesUntil,
                            );
                        }
                        issetNewValue = true;
                    }
                });

                if (newValue !== null && typeof newValue !== "undefined") {
                    if (typeof field.repeatableGroup !== "undefined" && typeof field.repeatableIndex !== "undefined") {
                        if (typeof this.model[field.repeatableGroup] === "undefined") {
                            Vue.set(this.model, field.repeatableGroup, []);
                        }

                        if (typeof this.model[field.repeatableGroup][field.repeatableIndex] === "undefined") {
                            Vue.set(this.model[field.repeatableGroup], field.repeatableIndex, {});
                        }

                        Vue.set(
                            this.model[field.repeatableGroup][field.repeatableIndex],
                            field.originalModel,
                            this.replaceVariables(newValue, field),
                        );

                        return;
                    }

                    return Vue.set(this.model, field.model, this.replaceVariables(newValue, field));
                }

                if (
                    typeof field.repeatableGroup !== "undefined" &&
                    typeof field.repeatableIndex !== "undefined" &&
                    typeof this.model[field.repeatableGroup] !== "undefined" &&
                    typeof this.model[field.repeatableGroup][field.repeatableIndex] !== "undefined"
                ) {
                    if (typeof this.model[field.repeatableGroup] === "undefined") {
                        return;
                    }

                    return Vue.delete(this.model[field.repeatableGroup][field.repeatableIndex], field.originalModel);
                }
                return Vue.delete(this.model, field.model);
            }
        },
        addPresetValues(field) {
            if (field.presetValue) {
                let newValue = issetValue(field.presetValue)
                    ? valueFilter(field.presetValue, this.static, this.model, field)
                    : null;

                if (newValue !== null && typeof newValue !== "undefined" && field.presetValueSet !== true) {
                    if (typeof field.repeatableGroup !== "undefined" && typeof field.repeatableIndex !== "undefined") {
                        // Is group
                        if (typeof this.model[field.repeatableGroup] === "undefined") {
                            Vue.set(this.model, field.repeatableGroup, []);
                        }

                        if (typeof this.model[field.repeatableGroup][field.repeatableIndex] === "undefined") {
                            Vue.set(this.model[field.repeatableGroup], field.repeatableIndex, {});
                        }

                        if (
                            typeof this.model[field.repeatableGroup][field.repeatableIndex][field.originalModel] ===
                                "undefined" &&
                            typeof this.model[field.repeatableGroup][field.repeatableIndex]["presetValueSet"] ===
                                "undefined"
                        ) {
                            Vue.set(
                                this.model[field.repeatableGroup][field.repeatableIndex],
                                field.originalModel,
                                newValue,
                            );
                            Vue.set(this.model[field.repeatableGroup][field.repeatableIndex], "presetValueSet", true);
                        }

                        return;
                    }

                    // values for model
                    if (typeof field.model !== "undefined" && typeof this.model[field.model] === "undefined") {
                        return Vue.set(this.model, field.model, newValue);
                    }
                }
            }
        },
        // Replace variables
        replaceVariables(key, field) {
            if (!key || (typeof key !== "string" && !(key instanceof String))) {
                return key;
            }

            const variables = key.match(/#{\s*[\w.]+\s*}/g);

            if (!variables) {
                return key;
            }

            let newKey = key;

            variables.forEach(variable => {
                variable = variable.replace("#{", "").replace("}", "");

                const modelValue = groupModelValue(variable, this.model, field);

                if (typeof modelValue !== "undefined") {
                    newKey = newKey.replace("#{" + variable + "}", modelValue);
                } else if (typeof this.static[variable] !== "undefined") {
                    newKey = newKey.replace("#{" + variable + "}", this.static[variable]);
                } else {
                    notExistsWarning(variable, field, "");
                    newKey = newKey.replace("#{" + variable + "}", "");
                }
            });

            return newKey;
        },
        // add unique event id to field if model isset
        addFieldEventId(field) {
            if (typeof field.model !== "undefined") {
                field.attributes = {
                    input: { "data-event-id": field.model },
                };
            }
        },
        addConditionalOptionsRules(field) {
            // set the default values
            if (typeof field.originalValues === "undefined") {
                field.originalValues = field.values;
            }

            // set the default values
            let newValues = field.originalValues ? field.originalValues : [];

            // options for model
            if (field.conditionalOptions) {
                field.conditionalOptions.forEach(conditionalOption => {
                    if (formulaHandler(this.model, field, conditionalOption, this.static, "terms")) {
                        newValues = conditionalOption.setOptions;
                    }
                });

                // Check if current value exists or set to null
                const possibleValues = [];

                newValues.forEach(newValue => {
                    if (typeof newValue === "string" || newValue instanceof String) {
                        possibleValues.push(newValue);
                    } else if (typeof newValue.id !== "undefined") {
                        possibleValues.push(newValue.id);
                    } else {
                        console.warn(
                            "Conditional value found without regonizabele ID. On model: " + field.originalModel,
                        );
                    }
                });

                if (
                    typeof field.repeatableGroup !== "undefined" &&
                    typeof field.repeatableIndex !== "undefined" &&
                    typeof this.model[field.repeatableGroup] !== "undefined" &&
                    typeof this.model[field.repeatableGroup][field.repeatableIndex] !== "undefined"
                ) {
                    const currentValue = this.model[field.repeatableGroup][field.repeatableIndex][field.originalModel];
                    if (typeof currentValue !== "undefined" && !possibleValues.includes(currentValue)) {
                        Vue.set(
                            this.model[field.repeatableGroup][field.repeatableIndex],
                            field.originalModel,
                            undefined,
                        );
                    }
                } else {
                    const currentValue = this.model[field.originalModel];
                    if (typeof currentValue !== "undefined" && !possibleValues.includes(currentValue)) {
                        Vue.set(this.model, field.originalModel, undefined);
                    }
                }
            }

            field.values = newValues;
        },
        applyValues(field) {
            if (
                field.model &&
                field.customValue &&
                field.customValue.parameters &&
                field.customValue.parameters.fieldModels
            ) {
                if (typeof field.repeatableGroup !== "undefined" && typeof field.repeatableIndex !== "undefined") {
                    if (typeof this.model[field.repeatableGroup] === "undefined") {
                        Vue.set(this.model, field.repeatableGroup, []);
                    }
                    if (typeof this.model[field.repeatableGroup][field.repeatableIndex] === "undefined") {
                        Vue.set(this.model[field.repeatableGroup], field.repeatableIndex, {});
                    }

                    Vue.set(
                        this.model[field.repeatableGroup][field.repeatableIndex],
                        field.originalModel,
                        calculations[field.customValue.formula](
                            this.model,
                            field,
                            field.customValue.parameters.fieldModels,
                            field.customValue.parameters,
                            this.mutatedValuesUntil,
                        ),
                    );

                    return;
                }

                if (typeof calculations[field.customValue.formula] === "undefined") {
                    console.warn("calculation " + field.customValue.formula + " does not exists.");
                    return;
                }

                const calcValue = calculations[field.customValue.formula](
                    this.model,
                    field,
                    field.customValue.parameters.fieldModels,
                    field.customValue.parameters,
                    this.mutatedValuesUntil,
                );

                // set value to 0 when initializing
                Vue.set(this.model, field.model, calcValue);
            }
        },
    },
};
