import groupModelValue from "../modules/helpers/model/groupModelValue";
import VueFormGenerator from "../components/vueFormGenerator";
import alphaNumericalLength from "../modules/validators/alphaNumericalLength";
import requiredIf from "../modules/validators/requiredIf";
import Vue from "vue";

export default {
    methods: {
        // this method is called on focusout event on a field
        fieldValidationOnFocusOut: function(event) {
            this.fieldComponentLoop(fieldComponent => {
                const field = fieldComponent.field;

                // check if field exists and can be validated
                if (
                    field &&
                    field.attributes &&
                    field.attributes.input["data-event-id"] &&
                    field.attributes.input["data-event-id"] === event.target.dataset.eventId
                ) {
                    // if field does not have errors call component loop to validate field
                    if (fieldComponent.validate().length <= 0) {
                        this.fieldComponentLoop(this.fieldValidationIfHaveValue);
                    }
                }
            });
        },
        // this method is called on focusout event on a field
        fieldValidationOnChange: function(event) {
            if (event.target && event.target.type === "radio") {
                this.fieldValidationOnFocusOut(event);
            }
        },
        fieldValidationIfHaveValue: function(fieldComponent) {
            const field = fieldComponent.field;
            const fieldHasErrors = this.fieldHasErrors(fieldComponent);
            let fieldModel;

            // validate if field has a value or errors
            if (
                field &&
                typeof field.originalModel !== "undefined" &&
                typeof field.repeatableGroup !== "undefined" &&
                typeof field.repeatableIndex !== "undefined"
            ) {
                fieldModel = field.originalModel + "RepeatModel";
            } else if (field && typeof field.model !== "undefined") {
                fieldModel = field.model;
            }

            if (typeof groupModelValue(fieldModel, this.model, field) !== "undefined" || fieldHasErrors) {
                fieldComponent.validate();
            }
        },
        fieldHasErrors: function(fieldComponent) {
            let result = false;
            fieldComponent.errors.forEach(error => {
                if (error.field === fieldComponent.field) {
                    result = true;
                }
            });
            return result;
        },
        keepOneErrorPerField: function() {
            const knownFields = [];
            const newErrors = [];

            this.$refs.vfg.errors.forEach(fieldError => {
                if (knownFields.indexOf(fieldError.field) < 0) {
                    newErrors.push(fieldError);
                    knownFields.push(fieldError.field);
                }
            });

            this.$refs.vfg.errors = newErrors;
        },
        registerValidators: function() {
            const validators = {};
            this.registeredCustomValidators.forEach(key => {
                if (key.startsWith("an..")) {
                    const length = key.replace("an..", "") * 1;
                    validators[key] = alphaNumericalLength("an", length, false);
                } else if (key.startsWith("an")) {
                    const length = key.replace("an", "") * 1;
                    validators[key] = alphaNumericalLength("an", length, true);
                } else if (key.startsWith("a..")) {
                    const length = key.replace("a..", "") * 1;
                    validators[key] = alphaNumericalLength("a", length, false);
                } else if (key.startsWith("a")) {
                    const length = key.replace("a", "") * 1;
                    validators[key] = alphaNumericalLength("a", length, true);
                } else if (key.startsWith("n..")) {
                    const length = key.replace("n..", "") * 1;
                    validators[key] = alphaNumericalLength("n", length, false);
                } else if (key.startsWith("n")) {
                    const length = key.replace("n", "") * 1;
                    validators[key] = alphaNumericalLength("n", length, true);
                } else if (key.startsWith("verplichtAls")) {
                    validators[key] = requiredIf(key.replace("verplichtAls", ""));
                }
            });
            VueFormGenerator.install(Vue, { validators });
        },
    },
};
