import helpers from "../modules/helpers/helpers";
import Vue from "vue";

export default {
    methods: {
        addSubmitButton: function() {
            this.schema.groups.push({
                fields: [
                    {
                        type: "submit",
                        buttonText: helpers.getButtonText("submit", window.bdForms.config),
                        validateBeforeSubmit: true,
                        disabled: () => {
                            return this.isSubmitButtonDisabled;
                        },
                        onSubmit: this.submit,
                    },
                    {
                        type: "label",
                        label: this.schema.version || "",
                        styleClasses: "version-text",
                    },
                ],
            });

            Vue.set(this.schema, "groups", this.schema.groups);
        },
    },
};
