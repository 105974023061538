// REPLACED WITH JSON LOGIC REMOVE IN DEVELOP BRANCH
const hlpmonttrekkingprivegebruikauto = (wpontautafeigrek, wpontautkost, wpontautberprivg) => {
    let result = 0;
    if (wpontautkost >= 0 && wpontautafeigrek >= 0) {
        result = wpontautkost - wpontautafeigrek;
    } else if (wpontautberprivg >= 0 && wpontautafeigrek >= 0) {
        result = wpontautberprivg - wpontautafeigrek;
    }
    if (result < 0) {
        result = 0;
    }
    return result;
};
export default hlpmonttrekkingprivegebruikauto;
