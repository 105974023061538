export default {
    methods: {
        // duplicate a field and remove all programmatic dependencies
        duplicateField(field) {
            // copy field and remove observers
            const newField = JSON.parse(JSON.stringify(field));

            // restore original label
            if (typeof newField.originalLabel !== "undefined") {
                newField.label = newField.originalLabel;
                delete newField.originalLabel;
            }

            // restore original label
            if (typeof newField.originalVisible !== "undefined") {
                newField.visible = newField.originalVisible;
                delete newField.originalVisible;
            }

            // restore original values
            if (typeof newField.originalValues !== "undefined") {
                newField.values = newField.originalValues;
                delete newField.originalValues;
            }

            // delete attributes if exists
            if (typeof newField.attributes !== "undefined") {
                delete newField.attributes;
            }

            // set new model
            if (typeof newField.originalModel !== "undefined") {
                newField.model = newField.originalModel;
                delete newField.originalModel;
            }

            // delete repeatModel if exists
            if (typeof newField.repeatableGroup !== "undefined") {
                delete newField.repeatableGroup;
            }

            // delete repeatableIndex if exists
            if (typeof newField.repeatableIndex !== "undefined") {
                delete newField.repeatableIndex;
            }

            // delete preset value for next fields (preset values are only for first rows)
            if (typeof newField.presetValue !== "undefined") {
                delete newField.presetValue;
            }

            return newField;
        },
        // deuplicate a group and remove all programmatic dependencies
        duplicateGroup(group) {
            // copy group and remove observers
            const newGroup = JSON.parse(JSON.stringify(group));

            // delete repeatable index if set
            if (typeof newGroup.repeatableIndex !== "undefined") {
                delete newGroup.repeatableIndex;
            }

            // delete style classes
            if (typeof newGroup.styleClasses !== "undefined") {
                delete newGroup.styleClasses;
                if (group.originalStyleClasses) {
                    newGroup.styleClasses = group.originalStyleClasses;
                }
            }

            // delete linkAdded
            if (typeof newGroup.linkAdded !== "undefined") {
                delete newGroup.linkAdded;
            }

            // delete linkAdded
            if (typeof newGroup.removeLinkAdded !== "undefined") {
                delete newGroup.removeLinkAdded;
            }

            // duplicate en clean up fields
            if (typeof newGroup.fields !== "undefined") {
                const newFields = [];
                newGroup.fields.forEach(field => {
                    if (!field.isRemoveLink && !field.isAddLink) {
                        if (newGroup.radioModel && newGroup.radioModel === field.model) {
                            field.isRadioModel = true;
                        }
                        newFields.push(this.duplicateField(field));
                    }
                });
                newGroup.fields = newFields;
            }

            return newGroup;
        },
    },
};
