let modelVisibility = {};

export const setModelVisibility = newModelVisibility => {
    modelVisibility = newModelVisibility;
};

export const isModelVisible = (model, field) => {
    const modelAffix = "Model";
    const groupAffix = "RepeatModel";
    let newModel = model;
    if (field && String(model).endsWith(groupAffix)) {
        newModel = newModel.substring(0, newModel.lastIndexOf(groupAffix));

        newModel = field.repeatableGroup + "[" + field.repeatableIndex + "]." + newModel;
    } else if (String(model).endsWith(modelAffix)) {
        newModel = newModel.substring(0, newModel.lastIndexOf(modelAffix));
    }

    if (typeof modelVisibility[newModel] === "undefined") {
        return true;
    }

    return modelVisibility[newModel];
};

export default () => {
    return modelVisibility;
};
