export const getValue = (gelID, values) => {
    if (values[gelID]) {
        return values[gelID];
    }
    return null;
};

export const setValue = (gelID, value, values) => {
    values[gelID] = value;

    return values;
};
