import { parseDocument, parseHeaders, parseRecords } from "../modules/parsers/rgsParser";
import rgsMapper, { getAllGelIds } from "../modules/utils/rgsMapper";
import pickBy from "lodash/pickBy";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";
import profit from "../modules/calculations/rgs/profit";
import assets from "../modules/calculations/rgs/assets";
import round from "../modules/calculations/rgs/round";
import liabilities from "../modules/calculations/rgs/liabilities";
import errors from "../data/errors";
import modeLog from "../modules/utils/modeLog";
import equitygrowth from "../modules/calculations/rgs/equitygrowth";
import maskCheck from "../modules/calculations/rgs/maskCheck";
import Vue from "vue";
import getValuesBeforeValue from "../modules/utils/getValuesBeforeValue";
import removeObservers from "../modules/utils/removeObservers";
import mutate, { getMutationDelta } from "../modules/utils/mutate";
import isUndefined from "lodash/isUndefined";

export default {
    namespaced: true,
    state: () => ({
        headers: {
            Timestamp: null,
            FinancialYear: null,
            RSIN: null,
            KVKNumber: null,
            VatNumber: null,
            CompanyName: null,
            SoftwarePackage: null,
            EmailaddressReceived: null,
            RgsVersion: null,
        },
        records: [],
        values: {},
        oldValues: {},
        rawValues: {},
        mappedRules: {},
        titles: {},
        mutations: {},
        mutationKeys: ["rgs_rounding"],
        mutationGroups: [{ name: "rgs_rounding", keys: ["rgs_rounding"] }],
        gelIds: [],
    }),
    mutations: {
        setHeaders(state, headers) {
            Vue.set(state, "headers", headers);
        },
        setRecords(state, records) {
            Vue.set(state, "records", records);
        },
        setValues(state, values) {
            Vue.set(state, "values", values);
        },
        setOldValues(state, oldValues) {
            Vue.set(state, "oldValues", oldValues);
        },
        setMappedRules(state, mappedRules) {
            Vue.set(state, "mappedRules", mappedRules);
        },
        setGelIds(state, gelIds) {
            Vue.set(state, "gelIds", gelIds);
        },
        setRawValues(state, values) {
            Vue.set(state, "rawValues", values);
        },
        setTitles(state, titles) {
            Vue.set(state, "titles", titles);
        },
        setMutationKeys(state, mutationKeys) {
            Vue.set(state, "mutationKeys", mutationKeys);
        },
        setMutationGroups(state, mutationGroups) {
            Vue.set(state, "mutationGroups", mutationGroups);
        },
        setMutationsByKey(state, { mutationKey, values }) {
            Vue.set(state.mutations, mutationKey, values);
        },
    },
    actions: {
        parseRgs({ commit }, { xmlString, filterRgsData, rgsConstants }) {
            return new Promise((resolve, reject) => {
                const parsedDoc = parseDocument(xmlString, errors);

                if (parsedDoc.error) {
                    return reject(parsedDoc.error);
                }

                const xmlDoc = parsedDoc.doc;

                // Get and check headers
                const parsedHeaders = parseHeaders(xmlDoc, rgsConstants, errors);

                if (parsedHeaders.error) {
                    return reject(parsedHeaders.error);
                }

                const headers = parsedHeaders.headers;

                // Get and check records
                const parsedRecords = parseRecords(xmlDoc, filterRgsData, errors);

                if (parsedRecords.error) {
                    return reject(parsedRecords.error);
                }

                const records = parsedRecords.records;

                // Commit headers and records
                commit("setHeaders", headers);
                commit("setRecords", records);

                resolve();
            });
        },

        mapRgs({ commit, getters, dispatch }, { rgsMappingData, filterRgsData }) {
            return new Promise(resolve => {
                commit("setRawValues", rgsMapper(rgsMappingData, getters.records));
                commit("setValues", rgsMapper(rgsMappingData, getters.records));
                dispatch("compareRgsValues");
                const mappedRules = rgsMapper(rgsMappingData, getters.records, true, filterRgsData);
                commit("setMappedRules", mappedRules);
                const allGelIds = getAllGelIds(rgsMappingData);
                allGelIds.push("507413-EB");
                allGelIds.push("520517-EB");
                commit("setGelIds", allGelIds);

                const titles = {};
                forEach(rgsMappingData, (gelObject, gelID) => {
                    titles[gelID] = gelObject.name;
                });
                commit("setTitles", titles);

                resolve();
            });
        },

        checkMappedRgs({ getters }, { mBmgAwaData, mutationKey }) {
            return new Promise((resolve, reject) => {
                let values = removeObservers(getters.values);

                if (mutationKey) {
                    values = removeObservers(getters.getMutationsWith(mutationKey));
                }

                // CHECK if values match masks
                const maskCheckResult = maskCheck(mBmgAwaData, values, errors);
                if (maskCheckResult !== true) {
                    return reject(maskCheckResult);
                }

                // CHECK assets	= liabilities +/+ profit
                modeLog("assets: ", assets(mBmgAwaData, values));
                modeLog("liabilities: ", liabilities(mBmgAwaData, values));
                modeLog("profit: ", profit(mBmgAwaData, values));

                const liabilitiesProfit =
                    Number(liabilities(mBmgAwaData, values) + profit(mBmgAwaData, values)).toFixed(2) * 1;

                if (Number(assets(mBmgAwaData, values)).toFixed(2) * 1 !== liabilitiesProfit) {
                    return reject(errors(110)); // Error: RGS code not balanced
                }

                // CHECK equitygrowth = 0
                modeLog("equitygrowth: ", equitygrowth(values));
                if (equitygrowth(values) !== 0) {
                    return reject(errors(111, { equityGrowth: equitygrowth(values) }));
                }

                if (!values["119474-DB"] && values["119474-DB"] !== 0) {
                    return reject(errors(124));
                }

                if (!values["119356-EB"] && values["119356-EB"] !== 0) {
                    return reject(errors(126));
                }

                if (!values["119356-SB"] && values["119356-SB"] !== 0) {
                    return reject(errors(125));
                }

                resolve();
            });
        },

        roundRgsValues({ getters, dispatch }, mBmgAwaData) {
            return new Promise(resolve => {
                const roundingMutationKey = "rgs_rounding";
                let values = removeObservers(getters.getMutationsUntil(roundingMutationKey));
                const roundedValues = round(mBmgAwaData, values);
                // values = removeObservers(getters.getMutationsUntil(roundingMutationKey));
                dispatch("saveByMutationKey", { mutationKey: roundingMutationKey, values: roundedValues });
                resolve();
            });
        },

        assignFormValues({ getters, dispatch }) {
            return new Promise(resolve => {
                dispatch("formValues/assignValues", { wondnaam: getters.headers["CompanyName"] }, { root: true });
                resolve();
            });
        },

        saveByMutationKey({ commit, getters }, { mutationKey, values }) {
            const newValues = {};

            forEach(getters.gelIds, gelId => {
                if (!isUndefined(values[gelId])) {
                    newValues[gelId] = values[gelId];
                }
            });

            // const currentValues = removeObservers(getters.getMutationsUntil(mutationKey));
            const currentValues = removeObservers(getters.values);

            const mutationDelta = getMutationDelta(currentValues, newValues);

            commit("setMutationsByKey", { mutationKey, values: mutationDelta });
        },

        setByMutationKey({ commit }, { mutationKey, values }) {
            commit("setMutationsByKey", { mutationKey, values });
        },

        setMutationKeyInGroup({ commit, getters }, { mutationGroup, mutationKey }) {
            const mutationGroups = removeObservers(getters.mutationGroups);

            const groupIndex = findIndex(
                mutationGroups,
                currentMutationGroup => currentMutationGroup.name === mutationGroup,
            );

            if (groupIndex < 0) {
                mutationGroups.push({
                    name: mutationGroup,
                    keys: [mutationKey],
                });
            } else {
                mutationGroups[groupIndex].keys.push(mutationKey);
            }

            commit("setMutationGroups", mutationGroups);
        },

        addMutationKey({ commit, getters }, mutationKey) {
            const newMutationKeys = getters.mutationKeys;

            newMutationKeys.push(mutationKey);

            commit("setMutationKeys", newMutationKeys);
        },

        setYearNumbersFormCurrentState({ commit }, yearNumbers) {
            commit("setOldValues", yearNumbers);
        },

        compareRgsValues({ getters, dispatch }) {
            const values = removeObservers(getters.values);
            const oldValues = removeObservers(getters.oldValues);

            if (!isEqual(values, oldValues)) {
                dispatch("navigation/resetYearOverviewState", null, { root: true });
                dispatch("navigation/resetCheckState", null, { root: true });
            }
        },
    },
    getters: {
        headers: state => state.headers,
        records: state => state.records,
        values: state => state.values,
        oldValues: state => state.oldValues,
        mappedRules: state => state.mappedRules,
        gelIds: state => state.gelIds,
        rawValues: state => state.rawValues,
        mutations: state => state.mutations,
        mutationKeys: state => state.mutationKeys,
        mutationGroups: state => state.mutationGroups,
        ebValues: state => pickBy(state.values, (value, key) => key.endsWith("-EB")),
        sbValues: state => pickBy(state.values, (value, key) => key.endsWith("-SB")),
        dbValues: state => pickBy(state.values, (value, key) => key.endsWith("-DB")),
        ecgValues: state =>
            pickBy(state.values, (value, key) => !key.endsWith("-DB") && !key.endsWith("-SB") && !key.endsWith("-EB")),
        getValue: state => {
            return gelID => {
                if (state.values[gelID]) {
                    return state.values[gelID];
                }
                return null;
            };
        },
        rgsCodeValue: (state, getters) => {
            const rgsCodeValue = {};

            forEach(getters.records, record => {
                rgsCodeValue[record.RgsCode + "-CB"] = Number(record.ClosingBalance);
                rgsCodeValue[record.RgsCode + "-OB"] = Number(record.OpeningBalance);
            });
            return rgsCodeValue;
        },
        getTitle: state => {
            return gelID => {
                gelID = gelID.replace("-DB", "");
                gelID = gelID.replace("-SB", "");
                gelID = gelID.replace("-EB", "");
                if (state.titles[gelID]) {
                    return state.titles[gelID];
                }
                return null;
            };
        },
        getInitialValueByGelId: (state, getters) => {
            return gelId => {
                return {
                    key: "initial_value",
                    name: "year_overview.initial_value",
                    translate: true,
                    value: getters.rawValues[gelId] ? removeObservers(getters.rawValues[gelId]) : 0,
                };
            };
        },
        getMutationsByGelId: (state, getters, rootState, rootGetters) => {
            return gelId => {
                const gelIdMutations = [];
                const mutationKeys = removeObservers(getters.mutationKeys);

                forEach(mutationKeys, mutationKey => {
                    const mutationsByKey = getters.getMutataionsByKey(mutationKey);

                    if (mutationsByKey[gelId]) {
                        let normalizedMutationKey = mutationKey;
                        let name = mutationKey;
                        const mutationObject = {
                            value: removeObservers(mutationsByKey[gelId]),
                        };

                        if (mutationKey.includes("Form")) {
                            normalizedMutationKey = mutationKey.replace("Form", "");
                            name = rootGetters["navigation/mutationName"](normalizedMutationKey);
                            mutationObject.translate = false;
                        } else {
                            name = "mutations." + normalizedMutationKey;
                            mutationObject.translate = true;
                        }

                        mutationObject.name = name;
                        mutationObject.key = normalizedMutationKey;

                        gelIdMutations.push(mutationObject);
                    }
                });

                return gelIdMutations;
            };
        },

        hasMutataionsByGelId: (state, getters) => {
            return gelId => {
                const mutations = getters.getMutationsByGelId(gelId);

                return !isEmpty(mutations);
            };
        },
        getMutataionGroupsByGelId: (state, getters) => {
            return gelId => {
                const newGroups = [];
                const mutations = getters.getMutationsByGelId(gelId);
                if (isEmpty(mutations)) {
                    return [];
                }

                mutations.forEach(mutation => {
                    const groupIndex = findIndex(getters.mutationGroups, currentMutationGroup =>
                        currentMutationGroup.keys.includes(mutation.key),
                    );

                    if (groupIndex < 0) {
                        console.warn("Form " + mutation.key + " is not assignable to a mutation group");
                        return;
                    }

                    const group = getters.mutationGroups[groupIndex];

                    const newGroupIndex = findIndex(newGroups, newGroup => newGroup.key === group.name);

                    if (newGroupIndex < 0) {
                        newGroups.push({
                            name: "mutation_groups." + group.name,
                            key: group.name,
                            translate: true,
                            value: mutation.value,
                        });
                    } else {
                        newGroups[newGroupIndex].value =
                            (newGroups[newGroupIndex].value + mutation.value).toFixed(2) * 1;
                    }
                });

                return newGroups;
            };
        },

        getMutataionsByKey: (state, getters) => {
            return mutationKey => {
                return getters.mutations[mutationKey] ?? {};
            };
        },
        getMutationsUntil: (state, getters) => {
            return mutationKey => {
                let values = removeObservers(getters.values);
                const mutationsKeysBefore = getValuesBeforeValue(mutationKey, removeObservers(getters.mutationKeys));
                forEach(mutationsKeysBefore, mutationsKeyBefore => {
                    const mutationValues = removeObservers(getters.getMutataionsByKey(mutationsKeyBefore));
                    values = mutate(values, mutationValues);
                });
                return values;
            };
        },
        getMutationsWith: (state, getters) => {
            return mutationKey => {
                let values = removeObservers(getters.values);
                const mutationsKeysBefore = getValuesBeforeValue(mutationKey, removeObservers(getters.mutationKeys));
                mutationsKeysBefore.push(mutationKey);
                forEach(mutationsKeysBefore, mutationsKeyBefore => {
                    const mutationValues = removeObservers(getters.getMutataionsByKey(mutationsKeyBefore));
                    values = mutate(values, mutationValues);
                });

                return values;
            };
        },
        getWithAllMutations: (state, getters) => {
            return () => {
                let values = removeObservers(getters.values);
                const mutationsKeysBefore = removeObservers(getters.mutationKeys);
                forEach(mutationsKeysBefore, mutationsKeyBefore => {
                    const mutationValues = removeObservers(getters.getMutataionsByKey(mutationsKeyBefore));
                    values = mutate(values, mutationValues);
                });

                return values;
            };
        },
    },
};
