import forEach from "lodash/forEach";
import find from "lodash/find";
import mapValues from "lodash/mapValues";
import isEmpty from "lodash/isEmpty";
import difference from "lodash/difference";
import { DOMParser, DOMImplementation } from "xmldom";
import filter from "lodash/filter";
import merge from "lodash/merge";
import alphaNumericalLength from "../validators/alphaNumericalLength";
import amountPosThirteen from "../validators/amountPosThirteen";
import year from "../validators/staticYear";
import bothReportNumbers from "../validators/bothReportNumbers";
import gregorianDate from "../validators/gregorianDate";
import enumValues from "../validators/enumValues";
import maxInteger from "../validators/maxInteger";
import errorMessage from "../validators/messages";
// import ecgXsd from "../../data/ecg.xsd";
import errors from "../../data/errors";
import isArray from "lodash/isArray";

const XSD_ELEMENT_NAME = "element";
const XSD_ROOT_ELEMENT = "schema";

const validators = {
    date_Type: gregorianDate,
    "Indicatie_Type-Indicatie_Enum_Type": enumValues(["J", "N"]),
    "Indicatie_Type-IndicatieJ_type": enumValues(["J"]),
    int13Var_Type: alphaNumericalLength("n", 13, false),
    int1Fixed_Type: alphaNumericalLength("n", 1, true),
    int2Var_Type: alphaNumericalLength("n", 2, false),
    int4Var_Type: alphaNumericalLength("n", 4, false),
    int9Fixed_Type: alphaNumericalLength("n", 9, true),
    intPos13Var_Type: amountPosThirteen,
    intPos3VarMax100_Type: maxInteger(100),
    Jaar_Type: year,
    Meldnr_Type: bothReportNumbers,
    strA1Fixed_Type: alphaNumericalLength("a", 1, true),
    strA4Fixed_Type: alphaNumericalLength("a", 4, true),
    strAN200Var_Type: alphaNumericalLength("an", 200, false),
    strAN20Var_Type: alphaNumericalLength("an", 20, false),
    strAN30Var_Type: alphaNumericalLength("an", 30, false),
    strAN6Fixed_Type: alphaNumericalLength("an", 6, true),
    strAN70Var_Type: alphaNumericalLength("an", 70, false),
    strAN8Fixed_Type: alphaNumericalLength("an", 8, true),
};

/**
 * Get XML document from parsed string or clean document.
 *
 * @param Document|null xmlDoc
 */
export const getDocument = (xmlDoc = null, options = null) => {
    if (xmlDoc !== null) {
        let parser = new DOMParser();
        if (options) {
            parser = new DOMParser(options);
        }

        return parser.parseFromString(xmlDoc, "text/xml");
    }

    // If no document given, return empty document
    return new DOMImplementation().createDocument();
};

/**
 * Get simplified element data.
 *
 * @param Element element
 */
export const getElementData = element => {
    const elementData = {
        name: element.getAttribute("name"),
        repeatable: false,
        maxRepeatable: 0,
        minRepeatable: 1,
        required: true,
        id: null,
        type: null,
    };

    // Is the element a repeatable element (maxOccurs > 1)
    // By W3C XSD standard default value of maxOccurs is 1
    if (element.hasAttribute("maxOccurs") && +element.getAttribute("maxOccurs") > 1) {
        elementData.repeatable = true;
        elementData.maxRepeatable = +element.getAttribute("maxOccurs");
    }

    // Is the element not required (minOccurs === 0)
    // By W3C XSD standard default value of minOccurs is 1
    if (element.hasAttribute("minOccurs") && +element.getAttribute("minOccurs") === 0) {
        elementData.required = false;
    }

    if (element.hasAttribute("minOccurs")) {
        elementData.minRepeatable = +element.getAttribute("minOccurs");
    }

    if (element.hasAttribute("id")) {
        elementData.id = element.getAttribute("id");
    }

    if (element.hasAttribute("type")) {
        elementData.type = element.getAttribute("type");
    }

    return elementData;
};

/**
 * Get simplified XSD element in object tree.
 *
 * @param Document xsdDoc
 * @param Array arr
 */
export const getChildElements = (xsdDoc, arr = []) => {
    forEach(xsdDoc.childNodes, childNode => {
        if (childNode.localName === XSD_ELEMENT_NAME) {
            const element = getElementData(childNode);
            // if (element.repeatable) {
            //     console.log("GROUP", element.id.replace("ID", ""));
            // }

            element.childs = getChildElements(childNode);

            // if (element.repeatable) {
            //     console.log("ENDGROUP");
            // }

            // if (element.childs.length <= 0) {
            //     console.log(element.id.replace("ID", ""));
            // }

            arr.push(element);
        } else {
            merge(arr, getChildElements(childNode));
        }
    });

    return arr;
};

/**
 * Validate and get data from xml.
 *
 * @param Document xmlDoc
 * @param Object xsdElements
 * @param Object obj
 * @param Array err
 */
export const getXmlValues = (xmlDoc, xsdElements, obj = {}, err = []) => {
    const existingXMLTags = filter(mapValues(xmlDoc.childNodes, "tagName"), tagName => tagName && tagName !== "xml");
    const existingXSDTags = filter(mapValues(xsdElements, "name"), tagName => tagName && tagName !== "xml");
    const existingTagsDifference = difference(existingXMLTags, existingXSDTags);

    if (existingTagsDifference.length > 0) {
        err.push(errorMessage("xml_unexpected_tags_found", { names: existingTagsDifference.join(", ") }));
    }

    forEach(xsdElements, xsdElement => {
        if (xsdElement.repeatable) {
            const xmlRepeatableElements = xmlDoc.getElementsByTagName(xsdElement.name);
            if (xsdElement.required && xmlRepeatableElements.length <= 0) {
                err.push(errorMessage("required_tag_does_not_exists", { name: xsdElement.name }));
            }

            if (xmlRepeatableElements.length <= 0) {
                return;
            }

            obj[xsdElement.id.replace("ID", "")] = [];

            forEach(xmlRepeatableElements, xmlRepeatableElement => {
                const groupValues = getXmlValues(xmlRepeatableElement, xsdElement.childs);
                err = err.concat(groupValues.err);
                obj[xsdElement.id.replace("ID", "")].push(groupValues.obj);
            });

            return;
        }

        const xmlElement = xmlDoc.getElementsByTagName(xsdElement.name)[0];

        if (xsdElement.required && !xmlElement) {
            err.push(errorMessage("required_tag_does_not_exists", { name: xsdElement.name }));
        }

        if (!xmlElement) {
            return;
        }

        if (xsdElement.childs.length > 0) {
            const childValues = getXmlValues(xmlElement, xsdElement.childs, obj);
            err = err.concat(childValues.err);
            return;
        }

        if (isEmpty(xmlElement.childNodes)) {
            err.push(errorMessage("required_value_does_not_exists", { name: xsdElement.name }));
            return;
        }

        if (!xsdElement.type) {
            console.warn("No type set in ECG XSD for: ", xsdElement);
        }

        const validator = validators[xsdElement.type](xmlElement.childNodes[0].nodeValue);

        if (validator.length > 0) {
            err.push(errorMessage("xml_element_has_errors", { name: xsdElement.name, errors: validator.join(" ") }));
        }

        obj[xsdElement.id.replace("ID", "")] = xmlElement.childNodes[0].nodeValue;
    });

    return { obj, err };
};

/**
 * Get data form XML with XSD.
 *
 * @param Document xmlDoc
 * @param Document xsdDoc
 */
export const getDataFromXml = (xmlDoc, xsdDoc) => {
    // Get the root element
    const xsdRootElement = find(xsdDoc.childNodes, childNode => {
        return childNode.localName === XSD_ROOT_ELEMENT;
    });

    // Get simplified object for XSD
    const xsdElements = getChildElements(xsdRootElement);

    const { obj, err } = getXmlValues(xmlDoc, xsdElements);

    if (err.length > 0) {
        throw errors(113, { errors: err.join(" | ") }, false);
    }

    return obj;
};

/**
 * Read and get data from ECG XML
 *
 * @param String xmlString
 */
export const readEcgXml = (xmlString, ecgXsd) => {
    return getDataFromXml(getDocument(xmlString), getDocument(ecgXsd));
};

/**
 * Rewrite gel ids to models.
 *
 * @param Object valuesObject
 * @param Object postKeyModelObject
 */
export const rewriteModelKeys = (valuesObject, postKeyModelObject) => {
    const newValuesObject = {};

    forEach(valuesObject, (valueObject, valuesObjectKey) => {
        if (isArray(valueObject)) {
            if (postKeyModelObject[valuesObjectKey]) {
                forEach(valueObject, valueGroupObject => {
                    if (!newValuesObject[valuesObjectKey]) {
                        newValuesObject[valuesObjectKey] = [];
                    }
                    newValuesObject[valuesObjectKey].push(
                        rewriteModelKeys(valueGroupObject, postKeyModelObject[valuesObjectKey]),
                    );
                });
            }
        } else {
            if (postKeyModelObject[valuesObjectKey]) {
                newValuesObject[postKeyModelObject[valuesObjectKey]] = valueObject;
            } else {
                newValuesObject[valuesObjectKey] = valueObject;
            }
        }
    });

    return newValuesObject;
};
