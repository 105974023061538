<template>
    <div v-if="!hideForm && showForm">
        <div id="form" v-bind:style="hasScroll" style="margin-bottom: 1REM;">
            <vue-form-generator
                v-on:model-updated="update"
                :schema="schema"
                :statics="statics"
                :model="model"
                :options="formOptions"
                @validated="validated"
                ref="vfg"
            ></vue-form-generator>

            <!-- Test post values display block -->
            <fieldset class="valid" v-if="postedData">
                <legend>Posted</legend>
                <a href="#" v-on:click.prevent="postedData = null">Reset</a>
                <br /><br />
                <pre>
                    {{ JSON.stringify(postedData, null, 4) }}
                </pre>
            </fieldset>
        </div>
        <form-footer-navigation
            ref="footerNavigation"
            :is-changed="isChanged"
            :buttonDisabled="isSubmitButtonDisabled"
            v-on:invalid-accountancy="$emit('invalid-accountancy')"
            v-on:click-next="submit"
            v-on:click-prev="navPrevious"
        ></form-footer-navigation>
    </div>
</template>

<script>
// import core files adn modules
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/dist/border.css";
// import "vue-form-generator/dist/vfg.css";
import Vue from "vue";
import VueFormGenerator from "./components/vueFormGenerator";
import formFooterNavigation from "./components/formFooterNavigation";
import removeObservers from "./modules/utils/removeObservers";
import filter from "lodash/filter";
import isUndefined from "lodash/isUndefined";
import forEach from "lodash/forEach";
// import merge from "lodash/merge";

// import mixins to keep app.vue clean
import loops from "./mixins/loops";
import fieldManipulators from "./mixins/fieldManipulators";
import groupManipulators from "./mixins/groupManipulators";
import formAdditions from "./mixins/formAdditions";
import postDataManipulators from "./mixins/postDataManipulators";
import events from "./mixins/events";
import validator from "./mixins/validator";
import singletons from "./mixins/singletons";
import duplicate from "./mixins/duplicate";
import repeatGroups from "./mixins/repeatGroups";
import constants from "./mixins/constants";
import replaceValues from "./mixins/values";

// import validators to use in Vue Form Generator
import validators from "./modules/validators/validators";

// import helpers
import helpers from "./modules/helpers/helpers";

// Import custom fields
import fieldCurrency from "./fields/fieldCurrency.vue";
import fieldHidden from "./fields/fieldHidden.vue";
import fieldDate from "./fields/fieldDate.vue";
import fieldLicensePlate from "./fields/fieldLicensePlate.vue";
import fieldNumber from "./fields/fieldNumber.vue";

// singleton setters/getters
// import { setStatics } from "./modules/singletons/statics";
import { setCustomValidatorMessages } from "./modules/validators/messages";
import { setPercentageOfAdditions } from "./modules/singletons/percentageOfAdditions";
import { getForm } from "./modules/singletons/jsonForms";
// import { getTaxCheck } from "./modules/singletons/jsonTaxChecks";
import { getConstantsFile } from "./modules/singletons/constantsFile";
import { getHelpTextsList, getCustomHelpTextsList } from "./modules/singletons/helpTextsList";
import { saveCurrentState } from "./modules/singletons/currentState";
import { getMBmgAwaData } from "./modules/singletons/mBmgAwaData";
import profit from "./modules/calculations/rgs/profit";
import { fireStatus } from "./modules/singletons/statusFn";

// use custom field components for Vue Form Generator
Vue.component("fieldCurrency", fieldCurrency);
Vue.component("fieldHidden", fieldHidden);
Vue.component("fieldDate", fieldDate);
Vue.component("fieldLicensePlate", fieldLicensePlate);
Vue.component("fieldNumber", fieldNumber);
Vue.component("fieldYear", fieldNumber);

// use Vue Form Generator plugin
Vue.use(VueFormGenerator, { validators });

// init data
const initialState = () => {
    return {
        model: window.bdForms && window.bdForms.model ? window.bdForms.model : {},
        schema: {
            groups: [],
            BRDGJAAR: 0,
            percentageBijtellingen: [],
        },
        formOptions: {
            validateAfterChanged: false,
        },
        showForm: false,
        emitEvent: false,
        isSubmitButtonDisabled: false,
        isChanged: false,
        postedData: null,
        fieldById: {},
        customValidatorErrors: window.bdForms && window.bdForms.validatorErrors ? window.bdForms.validatorErrors : {},
        modelVisibility: {},
        repeatableRadioModels: {},
        helpTextContainer: {},
        errorsShowed: [],
        helpTextList: [],
        customHelpTextList: [],
        registeredModels: [],
        registeredRepeatModels: {},
        registeredCustomValidators: [],
    };
};

export default {
    name: "bdform",
    props: {
        jsonForm: {
            type: String,
            required: true,
        },
        mutatedValues: {
            type: Object,
            required: false,
            default: () => {},
        },
        mutatedValuesUntil: {
            type: Object,
            required: false,
            default: () => {},
        },
        hideForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        isTaxCheck: {
            type: Boolean,
            required: false,
            default: false,
        },
        emitData: {
            type: Boolean,
            required: false,
            default: false,
        },
        static: {
            type: Object,
            required: false,
            default: () => {},
        },
        statics: {
            type: Object,
            required: false,
            default: () => {},
        },
        helpTextTitle: {
            type: String,
            required: false,
        },
        helpTextSubTitle: {
            type: String,
            required: false,
        },
    },
    mixins: [
        loops,
        fieldManipulators,
        groupManipulators,
        formAdditions,
        postDataManipulators,
        events,
        validator,
        singletons,
        duplicate,
        repeatGroups,
        constants,
        replaceValues,
    ],
    components: {
        "vue-form-generator": VueFormGenerator.component,
        formFooterNavigation,
    },
    data() {
        return initialState();
    },
    computed: {
        hasScroll: function() {
            const formHeight = helpers.getFormHeight(window.bdForms);
            if (formHeight.length > 0) {
                return [{ height: formHeight }, { "overflow-y": "scroll" }];
            }
            return [];
        },
    },
    methods: {
        // this method is called after the json is loaded
        init: function(firstInit = false) {
            // DEPRECATED: add BRDGJAAR to statics and validation
            this.static.BRDGJAAR = this.$data.schema.BRDGJAAR;

            // add statics to singleton
            // setStatics(this.static);

            // set percentage of additions to singleton
            if (this.schema.percentageBijtellingen) {
                setPercentageOfAdditions(this.schema.percentageBijtellingen);
            }

            // add custom validation messages
            setCustomValidatorMessages(this.customValidatorErrors);

            // add submit button at end of form
            // this.addSubmitButton();

            // run group init loop
            this.groupLoop(this.groupInit);

            // run field init loop
            this.fieldLoop(this.fieldInit);

            // if first init add groups when neccesary
            if (firstInit) {
                this.initNewGroupsByPrefilledData();
            }

            // run first update
            this.update();
        },

        // within initializing this method is called for each field
        fieldInit: function(field, group, fieldIndex, groupIndex) {
            // set repeatable group models
            this.rewriteRepeatableGroupModels(field, group, fieldIndex, groupIndex);

            // apply visibility based on formula
            this.addVisibilityRules(field, group);

            // add validator required for each field  169
            // this.addRequiredValidator(field);

            // adding field event id
            this.addFieldEventId(field);

            // add no reformat flag to field with type year (uses number field)
            // this.noRefactorForYearField(field);

            // apply visiblity for fields with expected values
            this.addVisibilityRulesWithStaticValues(field);

            // Add condition options for select and radio
            this.addConditionalOptionsRules(field);

            // track the visibilty of the models
            this.trackModelVisibility(field);

            // apply values with calculations
            this.applyValues(field);

            // set conditional (default) values.
            this.addConditionalValueRules(field);

            // set preset values.
            this.addPresetValues(field);

            // apply dynamic text labels
            // this.updateLabel(field);

            // update values
            this.updateValues(field);
        },

        // within initializing this method is called for each group
        groupInit: function(group, groupIndex) {
            // add repeatable group function
            this.initRepeatableGroup(group, groupIndex);

            // add the visibility function for groups
            this.addVisibilityRulesForGroups(group, groupIndex);
        },

        // this method is called when model is updated
        update: function() {
            // run group update loop
            this.groupLoop(this.groupUpdate);

            // run field update loop
            this.fieldLoop(this.fieldUpdate);

            // run group after update loop to apply change (for eg. visibility)
            this.groupLoop(this.groupAfterUpdate);

            // run field after update loop to apply change (for eg. labels)
            this.fieldLoop(this.fieldAfterUpdate);

            // if (this.emitEvent) {
            //     this.$emit("init-post-data", this.getPostData());
            // }
        },

        // when model is updated this method is called for each field
        fieldUpdate: function(field) {
            // Add condition options for select and radio
            this.addConditionalOptionsRules(field);

            // set conditional (default) values.
            this.addConditionalValueRules(field);

            // apply values with calculations
            this.applyValues(field);

            // set preset values.
            this.addPresetValues(field);

            // track the visibilty of the models
            this.trackModelVisibility(field);

            // add model and refid to field label if for tests
            // this.addModelAndRefIdToLabel(field);

            // apply visiblity for fields with expected values
            this.addVisibilityRulesWithStaticValues(field);
        },

        // when model is updated this method is called for each group
        groupUpdate: function(group, groupIndex) {
            // Make groups visible and hidden
            this.applyVisibilityRules(group, groupIndex);

            // add repeatable groups when updated a group trigger (radio)
            this.isAddGroupRadioTriggered(group);

            // remove and add class which make space fot link
            this.repeatableGroupClass(group, groupIndex);
        },

        // this method is called after the field update loop for each field
        fieldAfterUpdate: function(field) {
            // update values
            this.updateValues(field);
            // set conditional (default) values.
            this.addConditionalValueRules(field);
            // apply values with calculations
            this.applyValues(field);
            // set preset values.
            this.addPresetValues(field);
            // apply dynamic text labels
            // this.updateLabel(field);
        },

        // when model is updated this method is called for each group after update
        groupAfterUpdate: function(group, groupIndex) {
            // Make groups visible and hidden
            this.applyVisibilityRules(group, groupIndex);

            // add repeatable groups when updated a group trigger (radio)
            this.isAddGroupRadioTriggered(group);

            // remove and add class which make space fot link
            this.repeatableGroupClass(group, groupIndex);
        },

        // this method is called when model is validated
        validated: function() {
            // show one validation error per field
            this.keepOneErrorPerField();
        },

        // this method is called when the JSON schema is laoded, to register data from it.
        registerData: function(schema, callback) {
            const registeredModels = [];
            const registeredRepeatModels = {};
            const registeredCustomValidators = [];

            if (schema.groups) {
                schema.groups.forEach(group => {
                    if (group.repeatModel) {
                        registeredRepeatModels[group.repeatModel] = [];
                        if (typeof this.model[group.repeatModel] === "undefined") {
                            Vue.set(this.model, group.repeatModel, []);
                        }
                    }

                    if (group.fields) {
                        group.fields.forEach(field => {
                            if (field.model) {
                                if (group.repeatModel) {
                                    if (registeredRepeatModels[group.repeatModel].includes(field.model)) {
                                        // console.warn("Model '" + field.model + "' is used multiple times.");
                                    }
                                    registeredRepeatModels[group.repeatModel].push(field.model);
                                } else {
                                    if (registeredModels.includes(field.model)) {
                                        // console.warn("Model '" + field.model + "' is used multiple times.");
                                    }
                                    registeredModels.push(field.model);
                                }
                            }

                            if (field.validator) {
                                if (typeof field.validator === "string" || field.validator instanceof String) {
                                    field.validator = [field.validator];
                                }

                                field.validator.forEach(validator => {
                                    if (/^(an|a|n)(\.\.|)[0-9]{1,6}$/.test(validator)) {
                                        registeredCustomValidators.push(validator);
                                    }
                                    if (validator.startsWith("verplichtAls")) {
                                        registeredCustomValidators.push(validator);
                                    }
                                });
                            }
                        });
                    }
                });
            }

            this.setRegisteredModels(registeredModels);
            this.setRegisteredRepeatModels(registeredRepeatModels);

            Vue.set(this, "registeredModels", registeredModels);
            Vue.set(this, "registeredRepeatModels", registeredRepeatModels);
            Vue.set(this, "registeredCustomValidators", registeredCustomValidators);
            this.registerValidators();
            callback();
        },
        // Get the post data
        getPostData: function() {
            let data = {};

            // filter the post data
            data = this.manipulatePostData();

            return { formKey: this.jsonForm, data };
        },

        // when model is updated this method is called for each field
        submit: function(force = false) {
            // filter the post data
            // model = this.manipulatePostData();
            if (!force) {
                if (!this.$refs.vfg.validate()) {
                    return;
                }
            }

            this.removeDocumentEvents();

            const formValues = this.addUnusedGroups(removeObservers(this.model));

            // Update values in global state
            this.$store.dispatch("formValues/assignValues", removeObservers(formValues));

            this.$store.dispatch("rgsLedger/saveByMutationKey", {
                mutationKey: this.jsonForm + "Form",
                values: removeObservers(this.model),
            });

            if (this.hideForm === false && force === false) {
                fireStatus("in_progress");
                this.$store.dispatch("navigation/setFormStatus", { formKey: this.jsonForm, status: true });
                this.$store.dispatch("formValues/removeFormConflicts", this.jsonForm);
            }

            if (this.$store.getters["navigation/lastFormKey"] === this.jsonForm) {
                const totalProfit = removeObservers(profit(getMBmgAwaData(), this.mutatedValuesUntil));

                this.$store.dispatch("rgsLedger/setByMutationKey", {
                    mutationKey: "process_result",
                    values: { "119356-EB": totalProfit },
                });
            }

            this.setModelVisibility({});
            this.setRegisteredModels([]);
            this.setRegisteredRepeatModels({});

            if (!force) {
                document.activeElement.blur();
                saveCurrentState();
                this.$refs.footerNavigation.navNext();
            }
        },
        focusIn: function(event) {
            if (this.hideForm === false) {
                let isInForm = false;
                let isHelpButton = false;
                let x = event.target;

                this.isChanged = true;

                if (x.classList && x.classList.contains("help-box-open")) {
                    isHelpButton = true;
                }

                while ((x = x.parentNode)) {
                    if (x.id == "form") isInForm = true;
                    if (x.classList && x.classList.contains("help-box-open")) {
                        isHelpButton = true;
                    }
                }

                if (isInForm && !isHelpButton) {
                    this.$store.dispatch("navigation/setFormStatus", {
                        formKey: this.jsonForm,
                        status: false,
                    });
                }
            }
        },
        navPrevious: function() {
            this.submit(true);
            this.removeDocumentEvents();
            this.$refs.footerNavigation.navPrev();
        },
        getValuesWithoutUnusedGroups: function(jsonForm) {
            const formValues = removeObservers(this.$store.getters["formValues/values"]);
            jsonForm.schema.groups.forEach(group => {
                if (group.repeatModelId && group.repeatModel && formValues[group.repeatModel]) {
                    formValues[group.repeatModel] = filter(
                        formValues[group.repeatModel],
                        formValueGroup => formValueGroup.repeatModelId === group.repeatModelId,
                    );
                } else if (!group.repeatModelId && group.repeatModel && formValues[group.repeatModel]) {
                    formValues[group.repeatModel] = filter(formValues[group.repeatModel], formValueGroup =>
                        isUndefined(formValueGroup.repeatModelId),
                    );
                }
            });

            return formValues;
        },
        addUnusedGroups: function(formValues) {
            let stateValues = removeObservers(this.$store.getters["formValues/values"]);

            this.groupLoop(group => {
                let stateGroupValues = [];
                if (group.repeatModelId && group.repeatModel && stateValues[group.repeatModel]) {
                    stateGroupValues = filter(
                        stateValues[group.repeatModel],
                        stateValueGroup =>
                            stateValueGroup.repeatModelId !== group.repeatModelId ||
                            isUndefined(stateValueGroup.repeatModelId),
                    );
                } else if (!group.repeatModelId && group.repeatModel && stateValues[group.repeatModel]) {
                    stateGroupValues = filter(
                        formValues[group.repeatModel],
                        formValueGroup => formValueGroup && !isUndefined(formValueGroup.repeatModelId),
                    );
                }
                forEach(stateGroupValues, stateGroupValue => {
                    if (isUndefined(formValues[group.repeatModel])) {
                        formValues[group.repeatModel] = [];
                    }
                    formValues[group.repeatModel].push(stateGroupValue);
                });
            });

            return formValues;
        },
        loadForm: function(currentForm) {
            // reset form
            Vue.set(this, "model", {});
            this.showForm = false;
            const initialStateData = initialState();
            Object.keys(initialStateData).forEach(k => Vue.set(this, k, initialStateData[k]));
            this.setModelVisibility({});
            this.setRegisteredModels([]);
            this.setRegisteredRepeatModels({});

            // add event listener to focusout to do field validation
            if (!this.hideForm) {
                this.addDocumentEvents();
            }

            let jsonForm;

            if (this.isTaxCheck) {
                // jsonForm = removeObservers(getTaxCheck(currentForm));
            } else {
                jsonForm = removeObservers(getForm(currentForm));
            }

            const formValues = removeObservers(this.getValuesWithoutUnusedGroups(jsonForm));
            // const rgsLedgerValues = removeObservers(this.mutatedValues ?? {});

            // Set model data
            // Vue.set(this, "model", removeObservers(merge({}, formValues, rgsLedgerValues)));
            Vue.set(this, "model", removeObservers(formValues));

            // Set constants and helptext list
            Vue.set(this, "helpTextList", getHelpTextsList());
            Vue.set(this, "customHelpTextList", getCustomHelpTextsList());
            this.setConstantsFormXml(getConstantsFile());

            this.registerData(jsonForm.schema, () => {
                this.emitEvent = true;

                // set json schema
                this.$data.schema = jsonForm.schema;

                // call init method to initialize
                this.init(true);

                this.showForm = true;

                if (this.emitEvent && this.emitData) {
                    if (this.$store.getters["navigation/lastFormKey"] === this.jsonForm) {
                        const totalProfit = removeObservers(profit(getMBmgAwaData(), this.mutatedValuesUntil));

                        this.$store.dispatch("rgsLedger/setByMutationKey", {
                            mutationKey: "process_result",
                            values: { "119356-EB": totalProfit },
                        });
                    }

                    this.$emit("init-post-data", this.getPostData());
                }
            });
        },
        setCurrentFormOrTaxCheck: function() {
            // reset form
            if (!this.hideForm) {
                if (this.isTaxCheck) {
                    // this.$store.dispatch("navigation/setCurrentTaxCheck", this.jsonForm);
                } else {
                    this.$store.dispatch("navigation/setCurrentForm", this.jsonForm);
                }
            }
        },
    },
    created() {
        this.loadForm(this.jsonForm);
        this.setCurrentFormOrTaxCheck();
    },
    watch: {
        jsonForm: function(newForm) {
            const helpBoxes = document.querySelectorAll(".help-box");
            helpBoxes.forEach(helpBox => helpBox.remove());
            this.loadForm(newForm);
            this.setCurrentFormOrTaxCheck();
        },
    },
};
</script>

<style>
/* #form {
    background-color: #ffffff;
} */
</style>
