import hlpmtotaalonttrekkingautoaang from "./car/hlpmtotaalonttrekkingautoaang";
import hlpmwpontautkilometersperperiode from "./car/hlpmwpontautkilometersperperiode";
import hlpmwpondautberprivegebr from "./car/hlpmwpondautberprivegebr";
import wpontauttot from "./car/wpontauttot";
import hlpmwpontautperiodevanaf5jr from "./car/hlpmwpontautperiodevanaf5jr";
import hlpmwpontautperiodetot5jr from "./car/hlpmwpontautperiodetot5jr";
import getYearOfDate from "./date/getYearOfDate";
import hlpmonttrekkingprivegebruikauto from "./car/hlpmonttrekkingprivegebruikauto";
import sumOfFields from "./values/sumOfFields";
import sumOfStatics from "./values/sumOfStatics";
import brgdYearFirstOfNextMonth from "./values/brgdYearFirstOfNextMonth";
import sumOfGroupFields from "./values/sumOfGroupFields";
import minusSumOfFields from "./values/minusSumOfFields";
import minusSumOfStatics from "./values/minusSumOfStatics";
import multiplyField from "./values/multiplyField";
import addYears from "./values/addYears";
import sumOfFieldAndResult from "./values/sumOfFieldAndResult";
import percentOfField from "./values/percentOfField";
import fieldMinusValue from "./values/fieldMinusValue";
import fieldPlusValue from "./values/fieldPlusValue";
import roundDown from "./values/roundDown";
import roundUp from "./values/roundUp";
import substractYears from "./values/substractYears";
import kilometersExemptionPerPeriod from "./values/kilometersExemptionPerPeriod";
import hlpmwpontautdatumkentplus15jr from "./car/hlpmwpontautdatumkentplus15jr";
import wpontautpercbijtValues from "./values/wpontautpercbijtValues";
import privateUseCalculation from "./values/privateUseCalculation";

const calculations = {
    hlpmpontautnaambegin60mndherl: brgdYearFirstOfNextMonth,
    hlpmtotaalonttrekkingautoaang,
    hlpmwpontautkilometersperperiode,
    hlpmwpondautberprivegebr,
    wpontauttot,
    sumOfFieldAndResult,
    hlpmwpontautperiodevanaf5jr,
    hlpmwpontautperiodetot5jr,
    getYearOfDate,
    hlpmonttrekkingprivegebruikauto,
    sumOfFields,
    sumOfStatics,
    minusSumOfFields,
    minusSumOfStatics,
    hlpmwpontautdatumkentplus15jr,
    wpontautpercbijtValues,
    addYears,
    percentOfField,
    multiplyField,
    fieldMinusValue,
    fieldPlusValue,
    roundDown,
    roundUp,
    substractYears,
    vrijstellingKilometersPerPeriode: kilometersExemptionPerPeriod,
    berkeningPriveGebruik: privateUseCalculation,
    berekeningPriveGebruik: privateUseCalculation,
    sumOfGroupFields,
};
export default calculations;
