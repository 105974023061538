import Vue from "vue";

import { saveCurrentState } from "../modules/singletons/currentState";
import { closePlugin } from "../modules/singletons/closePlugin";
export default {
    namespaced: true,
    state: () => ({
        showModal: false,
        preSave: false,
    }),
    mutations: {
        setShowModal(state, showModal) {
            Vue.set(state, "showModal", showModal);
        },
        setPreSave(state, preSave) {
            Vue.set(state, "preSave", preSave);
        },
    },
    actions: {
        setShowModal({ commit }, showModal) {
            commit("setShowModal", showModal);
        },
        save({ commit }) {
            commit("setShowModal", true);

            setTimeout(function() {
                saveCurrentState();
            }, 500);
            setTimeout(function() {
                commit("setShowModal", false);
            }, 2000);
        },
        preSave({ commit }, preSave) {
            commit("setPreSave", preSave);
        },
        saveAndLeave({ commit, rootGetters }) {
            commit("setShowModal", true);
            if (rootGetters["navigation/started"]) {
                setTimeout(function() {
                    saveCurrentState();
                }, 500);
            }
            setTimeout(function() {
                closePlugin();
            }, 2000);
        },
    },
    getters: {
        showModal: state => state.showModal,
        preSave: state => state.preSave,
    },
};
