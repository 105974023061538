import statics from "../../singletons/statics";

const hlpmwpontautpercbijtvanaf5jr = (
    wpontautpercbijt,
    wpontautstandcata,
    wpontauttenaamgest,
    wpontautnaambegin60mnd,
) => {
    // is 0,07 als
    // {wpontauttenaamgest} = {BDRGJAARMIN5} EN {wpontautnaambegin60mnd} <> 12 EN
    // {wpontautpercbijt} = 1 en {wpontautstandcata} <= {BDRGBIJTAUTOMAXEMISSIE}
    if (
        wpontauttenaamgest === 3 &&
        wpontautnaambegin60mnd !== 12 &&
        wpontautpercbijt === 4 &&
        wpontautstandcata <= statics().BDRGBIJTAUTOMAXEMISSIE
    ) {
        return 0.07;
    }

    // Is (((0,07 * {BDRGBIJTAUTOMAXEMISSIE}) + (({wpontautstandcata} -/- {BDRGBIJTAUTOMAXEMISSIE}) * 0,25))/{wpontautstandcata})/100 als
    // {wpontauttenaamgest} = {BDRGJAARMIN5} EN {wpontautnaambegin60mnd} <> 12 EN {wpontautpercbijt} = 1 en {wpontautstandcata} > {BDRGBIJTAUTOMAXEMISSIE}
    if (
        wpontauttenaamgest === 3 &&
        wpontautnaambegin60mnd !== 12 &&
        wpontautpercbijt === 4 &&
        wpontautstandcata > statics().BDRGBIJTAUTOMAXEMISSIE
    ) {
        return (
            // prettier-ignore
            (0.07 * statics().BDRGBIJTAUTOMAXEMISSIE + ((wpontautstandcata - statics().BDRGBIJTAUTOMAXEMISSIE) * 0.25)) /
            wpontautstandcata
        );
    }

    // Is 0,25 als {wpontauttenaamgest} = {BDRGJAARMIN5} EN {wpontautnaambegin60mnd} <> 12 EN {wpontautpercbijt} <> 1
    if (wpontauttenaamgest === 3 && wpontautnaambegin60mnd !== 12 && wpontautpercbijt !== 4) {
        return 0.25;
    }
};
export default hlpmwpontautpercbijtvanaf5jr;
