import getPostRequestEndpoint from "./config/getPostRequestEndpoint";
import getGetRequestEndpoint from "./config/getGetRequestEndpoint";
import getConfig from "./config/getConfig";
import getModel from "./model/getModel";
import getRedirectLocation from "./config/getRedirectLocation";
import getFormHeight from "./config/getFormHeight";
import stringWithSpecialCharacters from "./fields/stringWithSpecialCharacters";
import replaceStringWithValue from "./string/replaceStringWithValue";
import wpontautexcprivLabel from "./wpontautexcpriv/wpontautexcprivLabel";
import getButtonText from "./config/getButtonText";

const helpers = {
    getPostRequestEndpoint,
    getGetRequestEndpoint,
    getConfig,
    getModel,
    getRedirectLocation,
    getFormHeight,
    stringWithSpecialCharacters,
    replaceStringWithValue,
    wpontautexcprivLabel,
    getButtonText,
};

export default helpers;
