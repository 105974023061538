import Vue from "vue";
export default {
    namespaced: true,
    state: () => ({
        hasCurrentState: false,
        downloaded: false,
        downloadedDate: null,
        finished: false,
        showTour: null,
    }),
    mutations: {
        setHasCurrentState(state, hasCurrentState) {
            Vue.set(state, "hasCurrentState", hasCurrentState);
        },
        setDownloaded(state, downloaded) {
            Vue.set(state, "downloaded", downloaded);
        },
        setDownloadedDate(state, downloadedDate) {
            Vue.set(state, "downloadedDate", downloadedDate);
        },
        setFinished(state, finished) {
            Vue.set(state, "finished", finished);
        },
    },
    actions: {
        setShowTour({ dispatch }, showTour) {
            dispatch("tour/setShowTour", showTour, { root: true });
        },
        setShowedTours({ dispatch }, showedTours) {
            dispatch("tour/setShowedTours", showedTours, { root: true });
        },
        setFormState({ dispatch }, formState) {
            dispatch("navigation/setFormState", formState, { root: true });
        },
        setYearOverviewState({ dispatch }, yearOverviewState) {
            dispatch("navigation/setYearOverviewState", yearOverviewState, { root: true });
        },
        setCheckState({ dispatch }, checkState) {
            dispatch("navigation/setCheckState", checkState, { root: true });
        },
        setStarted({ dispatch }, started) {
            dispatch("navigation/setStarted", started, { root: true });
        },
        resetFormState({ dispatch }) {
            dispatch("navigation/resetFormState", null, { root: true });
        },
        resetCheckState({ dispatch }) {
            dispatch("navigation/resetCheckState", null, { root: true });
        },
        resetYearOverviewState({ dispatch }) {
            dispatch("navigation/resetYearOverviewState", null, { root: true });
        },
        resetStarted({ dispatch }) {
            dispatch("navigation/resetStarted", null, { root: true });
        },
        setHasCurrentState({ commit }, hasCurrentState) {
            commit("setHasCurrentState", hasCurrentState);
        },
        setDownloaded({ commit }, downloaded) {
            commit("setDownloaded", downloaded);
        },
        setDownloadedDate({ commit }, downloadedDate) {
            commit("setDownloadedDate", downloadedDate);
        },
        setFinished({ commit }, finished) {
            commit("setFinished", finished);
        },
        setEcgFilledSet({ dispatch }, filledSet) {
            dispatch("formValues/setValues", filledSet, { root: true });
        },
        resetEcgFilledSet({ dispatch }) {
            dispatch("formValues/setValues", {}, { root: true });
            dispatch("rgsLedger/assignFormValues", null, { root: true });
        },
        setEcgPrefilledSet({ dispatch }, prefilledSet) {
            dispatch("formValues/setPrefilledValues", prefilledSet, { root: true });
        },
        resetEcgPrefilledSet({ dispatch }) {
            dispatch("formValues/resetPrefilledValues", {}, { root: true });
        },
        setConflicts({ dispatch }, conflicts) {
            dispatch("formValues/setConflicts", conflicts, { root: true });
        },
        resetConflics({ dispatch }) {
            dispatch("formValues/setConflicts", {}, { root: true });
        },
        resetShowTour({ dispatch }) {
            dispatch("tour/setShowTour", false, { root: true });
        },
        resetShowedTours({ dispatch }) {
            dispatch("tour/setShowedTours", [], { root: true });
        },
        setYearNumbers({ dispatch }, yearNumbers) {
            dispatch("rgsLedger/setYearNumbersFormCurrentState", yearNumbers, { root: true });
        },
        setCurrentState({ dispatch, commit }, currentState) {
            if (currentState.hasCurrentState) {
                dispatch("setFormState", currentState.formState);
                dispatch("setYearOverviewState", currentState.compareState);
                dispatch("setCheckState", currentState.checkState);
                dispatch("setYearNumbers", currentState.yearNumbers);
                dispatch("setStarted", currentState.started);
                dispatch("setEcgFilledSet", currentState.ecgFilledSet);
                if (currentState.conflicts) {
                    dispatch("setConflicts", currentState.conflicts);
                }
                dispatch("setEcgPrefilledSet", currentState.ecgPrefilledSet);
            }
            commit("setHasCurrentState", currentState.hasCurrentState);
            commit("setDownloaded", currentState.downloaded);
            commit("setFinished", currentState.finished);
            dispatch("setShowTour", currentState.showTour);
            dispatch("setShowedTours", currentState.showedTours);
            commit("setDownloadedDate", currentState.downloadedDate);
        },
        deleteCurrentState({ dispatch, commit }) {
            dispatch("resetFormState");
            dispatch("resetCheckState");
            dispatch("resetStarted");
            dispatch("resetYearOverviewState");
            dispatch("resetEcgFilledSet");
            dispatch("resetConflics");
            dispatch("resetEcgPrefilledSet");
            dispatch("resetShowedTours");
            dispatch("resetShowTour");
            commit("setHasCurrentState", false);
            commit("setDownloaded", false);
            commit("setDownloadedDate", null);
            commit("setFinished", false);
        },
    },
    getters: {
        downloaded: state => state.downloaded,
        downloadedDate: state => state.downloadedDate,
        finished: state => state.finished,
        showTour: (state, getters, rootState, rootGetters) => {
            return rootGetters["tour/showTour"];
        },
        showedTours: (state, getters, rootState, rootGetters) => {
            return rootGetters["tour/showedTours"];
        },
        hasCurrentState: state => state.hasCurrentState,
        getFormState: (state, getters, rootState, rootGetters) => {
            return rootGetters["navigation/getFormState"];
        },
        getYearOverviewState: (state, getters, rootState, rootGetters) => {
            return rootGetters["navigation/getYearOverviewState"];
        },
        getCheckState: (state, getters, rootState, rootGetters) => {
            return rootGetters["navigation/getCheckState"];
        },
        getStarted: (state, getters, rootState, rootGetters) => {
            return rootGetters["navigation/started"];
        },
        getEcgFilledSet: (state, getters, rootState, rootGetters) => {
            return rootGetters["formValues/getCurrentStateValues"];
        },
        getConflicts: (state, getters, rootState, rootGetters) => {
            return rootGetters["formValues/conflicts"];
        },
        getEcgPrefilledSet: (state, getters, rootState, rootGetters) => {
            return rootGetters["formValues/preFilledValues"];
        },
        getYearNumbers: (state, getters, rootState, rootGetters) => {
            return rootGetters["rgsLedger/values"];
        },
        getCurrentState: (state, getters) => {
            return {
                started: getters.getStarted,
                formState: getters.getFormState,
                compareState: getters.getYearOverviewState,
                checkState: getters.getCheckState,
                ecgFilledSet: getters.getEcgFilledSet,
                ecgPrefilledSet: getters.getEcgPrefilledSet,
                yearNumbers: getters.getYearNumbers,
                hasCurrentState: getters.hasCurrentState,
                downloaded: getters.downloaded,
                downloadedDate: getters.downloadedDate,
                finished: getters.finished,
                conflicts: getters.getConflicts,
                showTour: getters.showTour,
                showedTours: getters.showedTours,
            };
        },
    },
};
