// There is a bug which prevent custom validators for custom fields, this mixin will solve this issue
import VueFormGenerator from "../components/vueFormGenerator";
import * as arrayUniq from "lodash/uniq";
import * as objGet from "lodash/get";
import forEach from "lodash/forEach";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import customValidators from "../modules/validators/validators";

export default {
    methods: {
        convertValidator(validator) {
            const validators = Object.assign(VueFormGenerator.validators, customValidators);

            if (isString(validator)) {
                if (validators[validator] != null) return validators[validator];
                else {
                    console.warn(`'${validator}' is not a validator function!`);
                    return null; // caller need to handle null
                }
            }
            return validator;
        },
        // Overwrite default validate function cause bug in Vue Form Generator
        validate(calledParent) {
            this.clearValidationErrors();
            let validateAsync = objGet(this.formOptions, "validateAsync", false);

            let results = [];

            if (this.schema.validator && this.schema.readonly !== true && this.disabled !== true) {
                let validators = [];
                if (!isArray(this.schema.validator)) {
                    validators.push(this.convertValidator(this.schema.validator).bind(this));
                } else {
                    forEach(this.schema.validator, validator => {
                        validators.push(this.convertValidator(validator).bind(this));
                    });
                }

                forEach(validators, validator => {
                    if (validateAsync) {
                        results.push(validator(this.value, this.schema, this.model));
                    } else {
                        let result = validator(this.value, this.schema, this.model);
                        if (result && isFunction(result.then)) {
                            result.then(err => {
                                if (err) {
                                    this.errors = this.errors.concat(err);
                                }
                                let isValid = this.errors.length === 0;
                                this.$emit("validated", isValid, this.errors, this);
                            });
                        } else if (result) {
                            results = results.concat(result);
                        }
                    }
                });
            }

            let handleErrors = errors => {
                let fieldErrors = [];
                forEach(arrayUniq(errors), err => {
                    if (isArray(err) && err.length > 0) {
                        fieldErrors = fieldErrors.concat(err);
                    } else if (isString(err)) {
                        fieldErrors.push(err);
                    }
                });
                if (isFunction(this.schema.onValidated)) {
                    this.schema.onValidated.call(this, this.model, fieldErrors, this.schema);
                }

                let isValid = fieldErrors.length === 0;
                if (!calledParent) {
                    this.$emit("validated", isValid, fieldErrors, this);
                }
                this.errors = fieldErrors;
                return fieldErrors;
            };

            if (!validateAsync) {
                return handleErrors(results);
            }

            return Promise.all(results).then(handleErrors);
        },
    },
};
