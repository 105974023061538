import awaXsd from "../../data/awa-2020.xsd";
import { DOMParser, DOMImplementation, XMLSerializer } from "xmldom";
import forEach from "lodash/forEach";
import format from "xml-formatter";

// Get this constants of config object in future
const XSD_ELEMENT_NAME = "element";
const XML_ROOT_ELEMENT = "AWA2020";
const XML_DEFINITION = `<?xml version="1.0" encoding="utf-8"?>`;
const XML_AWA_NAMESAPACE = "http://xml.belastingdienst.nl/schemas/AWA/2020/01";
const XML_AWA_VERSION = "1.0";
const XML_AWA_INSTANCE = "http://www.w3.org/2001/XMLSchema-instance";
const XML_SCHEMA_LOCATION = "http://xml.belastingdienst.nl/schemas/AWA/2020/01";

/**
 * Return root (AWA) element attributes.
 */
const getRootAttributes = () => [
    {
        key: "xmlns",
        value: XML_AWA_NAMESAPACE,
    },
    {
        key: "Version",
        value: XML_AWA_VERSION,
    },
    {
        key: "xmlns:xsi",
        value: XML_AWA_INSTANCE,
    },
    {
        key: "xsi:schemaLocation",
        value: XML_SCHEMA_LOCATION,
    },
];

/**
 * Get XML document from parsed string or clean document.
 *
 * @param Document|null xmlDoc
 */
export const getDocument = (xmlDoc = null, options = null) => {
    if (xmlDoc !== null) {
        let parser = new DOMParser();
        if (options) {
            parser = new DOMParser(options);
        }
        return parser.parseFromString(xmlDoc, "text/xml");
    }

    // If no document given, return empty document
    return new DOMImplementation().createDocument();
};

/**
 * Get simplified element data.
 *
 * @param Element element
 */
export const getElementData = element => {
    const elementData = {
        name: element.getAttribute("name"),
        repeatable: false,
        maxRepeatable: 0,
        minRepeatable: 1,
        required: true,
        id: null,
        type: null,
    };

    // Is the element a repeatable element (maxOccurs > 1)
    // By W3C XSD standard default value of maxOccurs is 1
    if (element.hasAttribute("maxOccurs") && +element.getAttribute("maxOccurs") > 1) {
        elementData.repeatable = true;
        elementData.maxRepeatable = +element.getAttribute("maxOccurs");
    }

    // Is the element not required (minOccurs === 0)
    // By W3C XSD standard default value of minOccurs is 1
    if (element.hasAttribute("minOccurs") && +element.getAttribute("minOccurs") === 0) {
        elementData.required = false;
    }

    if (element.hasAttribute("minOccurs")) {
        elementData.minRepeatable = +element.getAttribute("minOccurs");
    }

    if (element.hasAttribute("id")) {
        elementData.id = element.getAttribute("id");
    }

    if (element.hasAttribute("type")) {
        elementData.type = element.getAttribute("type");
    }

    return elementData;
};

/**
 * Loop throug element parent elements return an array with
 * simplified element data objects (exludes current element).
 *
 * @param Element element
 * @param Array container
 */
export const getElementParents = (element, container) => {
    if (!element.parentNode) {
        return container;
    }

    if (element.parentNode.localName === XSD_ELEMENT_NAME) {
        container.push(getElementData(element.parentNode));
    }

    return getElementParents(element.parentNode, container);
};

/**
 * Get the element path in an array with simplified element
 * data objects (includes current element).
 *
 * @param Element element
 */
export const getElementPath = element => {
    const container = [];

    container.push(getElementData(element));

    // Reverse the array to get the highest parent first
    return getElementParents(element, container).reverse();
};

/**
 * Get element path by element ID.
 *
 * @param string id
 * @param Document xmlDoc
 */
const getElementPathById = (id, xmlDoc) => getElementPath(xmlDoc.getElementById(id));

/**
 * Initialize document with root (AWA) element.
 *
 * @param Document xmlDoc
 * @param String rootName
 * @param Array attributes
 */
const documentInit = (xmlDoc, rootName, attributes) => {
    const rootElement = xmlDoc.createElement(rootName);

    attributes.forEach(attribute => {
        rootElement.setAttribute(attribute.key, attribute.value);
    });

    xmlDoc.appendChild(rootElement);
};

/**
 * Create XML element in parent element from key: value object.
 *
 * @param Array elementPathArray
 * @param Mixed value
 * @param Element parentElement
 * @param Document xmlDoc
 */
const createElementsFromFlatArray = (elementPathArray, value, parentElement, xmlDoc) => {
    forEach(elementPathArray, (elementPath, index) => {
        if (Object.is(elementPathArray.length - 1, index)) {
            // Is last iteration (current element by id to create)
            const newElement = xmlDoc.createElement(elementPath.name);

            // If value not isset it is a repeatable group element
            if (value !== null) {
                newElement.appendChild(xmlDoc.createTextNode(value));
            }

            parentElement = parentElement.appendChild(newElement);
            return;
        }

        // Check if element already exists, if it is take it, do not create it
        if (parentElement.getElementsByTagName(elementPath.name).length > 0) {
            parentElement = parentElement.getElementsByTagName(elementPath.name)[0];
            return;
        }

        // Create the element
        parentElement = parentElement.appendChild(xmlDoc.createElement(elementPath.name));
    });

    return parentElement;
};

/**
 * Creat the element from object in the XML document.
 *
 * @param Object values
 * @param Document xmlDoc
 * @param Document xsdDoc
 */
const createElements = (values, xmlDoc, xsdDoc) => {
    // Loop through key; value object
    forEach(values, (value, id) => {
        // If value is an array, it is a repeatable group.
        if (Array.isArray(value)) {
            // Loop through the repeatable groups
            forEach(value, childObject => {
                // Create new repeatable group element
                const parentElement = createElementsFromFlatArray(getElementPathById(id, xsdDoc), null, xmlDoc, xsdDoc);

                // Loop through the group values and give group element as parentElement
                forEach(childObject, (childValue, childId) => {
                    createElementsFromFlatArray(
                        [getElementData(xsdDoc.getElementById(childId))],
                        childValue,
                        parentElement,
                        xmlDoc,
                    );
                });
            });

            return;
        }

        // Normal key: value, create element
        createElementsFromFlatArray(getElementPathById(id, xsdDoc), value, xmlDoc, xmlDoc);
    });
};

/**
 * Serialize the XML Document object and prefix with XML definition.
 *
 * @param Document xmlDoc
 */
const createXMLString = xmlDoc => {
    return XML_DEFINITION + "\n" + format(new XMLSerializer().serializeToString(xmlDoc), { collapseContent: true });
};

const getFormattedObject = (values, idNameObject) => {
    const formattedObject = {};
    forEach(idNameObject, (value, key) => {
        const id = key.substr(2);
        if (typeof value === "object") {
            // is repeatable group
            if (typeof values[id] !== "undefined") {
                formattedObject[key] = [];
                forEach(values[id], groupObject => {
                    formattedObject[key].push(getFormattedObject(groupObject, value));
                });
            }

            return;
        }

        if (typeof values[id] !== "undefined") {
            if (typeof values[id] === "string" || values[id] instanceof String) {
                formattedObject[key] = values[id]
                    .replace(/</g, "{{REPLACELT}}")
                    .replace(/>/g, "{{REPLACEGT}}")
                    .replace(/'/g, "{{REPLACEAPOS}}")
                    .replace(/"/g, "{{REPLACEQUOT}}")
                    .replace(/&/g, "{{REPLACEAMP}}");
            } else {
                formattedObject[key] = values[id];
            }
        }
    });

    return formattedObject;
};

/**
 * Create XML with values and XSD
 * @param Object values
 * @param String xsd
 */
const objectToXMLDocument = (values, xsd) => {
    // Get XML document from XSD string as generation template
    const xsdDoc = getDocument(xsd);

    // Get clean XML Document
    let xmlDoc = getDocument();

    // Initialize document with AWA root element
    documentInit(xmlDoc, XML_ROOT_ELEMENT, getRootAttributes());

    const formattedObject = getFormattedObject(values, getIdNameObject(xsdDoc));

    // Add XML elements form object values
    createElements(formattedObject, xmlDoc, xsdDoc);

    return xmlDoc;
};

/**
 * Get XML string (file content) from values object.
 *
 * @param Object values
 */
export const getXMLString = values => {
    const xmlString = createXMLString(objectToXMLDocument(values, awaXsd));

    return xmlString
        .replace(/{{REPLACELT}}/g, "&lt;")
        .replace(/{{REPLACEGT}}/g, "&gt;")
        .replace(/{{REPLACEAPOS}}/g, "&apos;")
        .replace(/{{REPLACEQUOT}}/g, "&quot;")
        .replace(/{{REPLACEAMP}}/g, "&amp;");
};

/**
 * Get the object from XSD with id => name values.
 *
 * @param Document xsdDoc
 * @param Object obj
 */
export const getIdNameObject = (xsdDoc, obj = {}) => {
    forEach(xsdDoc.childNodes, childNode => {
        if (childNode.hasAttribute) {
            const element = getElementData(childNode);

            if (element.id !== null) {
                if (element.repeatable) {
                    obj[element.id] = getIdNameObject(childNode);
                    return;
                }

                if (childNode.hasAttribute("type")) {
                    const element = getElementData(childNode);
                    obj[element.id] = element.name;
                }
            }
        }

        getIdNameObject(childNode, obj);
    });

    return obj;
};

/**
 * Get data object of XML with XSD
 *
 * @param Document xml
 * @param Document xsd
 */
export const xmlToObject = (xml, xsd, removePrefix = true) => {
    const xsdObject = getIdNameObject(getDocument(xsd), {});
    const xmlDoc = getDocument(xml);
    const valueObject = {};

    forEach(xsdObject, (value, key) => {
        if (typeof value === "object") {
            const repeatableArray = [];
            forEach(value, (reapeatValue, reapeatKey) => {
                const foundRepeatTags = xmlDoc.getElementsByTagName(reapeatValue);
                forEach(foundRepeatTags, (foundRepeatTag, index) => {
                    if (!repeatableArray[index]) {
                        repeatableArray[index] = {};
                    }

                    const newReapeatKey = removePrefix ? reapeatKey.substr(2) : reapeatKey;
                    repeatableArray[index][newReapeatKey] = foundRepeatTag.childNodes[0].nodeValue;
                });
            });
            if (repeatableArray.length > 0) {
                const newKey = removePrefix ? key.substr(2) : key;
                valueObject[newKey] = repeatableArray;
            }
        } else {
            const foundTags = xmlDoc.getElementsByTagName(value);
            if (foundTags.length > 0) {
                const newKey = removePrefix ? key.substr(2) : key;
                valueObject[newKey] = foundTags[0].childNodes[0].nodeValue;
            }
        }
    });

    return valueObject;
};
