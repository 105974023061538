import errorMessage from "./messages";
import issetValue from "../helpers/validation/issetValue";

export default (value, field) => {
    let label = field.label;

    if (field.labelWithoutHelp) {
        label = field.labelWithoutHelp;
    }

    if (field.hiddenLabel) {
        label = field.hiddenLabel;
    }

    if (field.validatorParameters && field.validatorParameters.label) {
        label = field.validatorParameters.label;
    }

    if (!issetValue(value)) {
        return [errorMessage("required_empty", { fieldLabel: label })];
    }

    return [];
};
