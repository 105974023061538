import Vue from "vue";
import findIndex from "lodash/findIndex";
import findLastIndex from "lodash/findLastIndex";
import find from "lodash/find";
import forEach from "lodash/forEach";
import isUndefined from "lodash/isUndefined";
import { navigationConditionsHandler } from "../modules/formulas/handler";
// import isEqual from "lodash/isEqual";

export default {
    namespaced: true,
    state: () => ({
        items: [
            {
                icon: "home",
                label: "starten",
                routeName: "intro",
                clickable: true,
            },
            {
                icon: "",
                label: "vergelijken",
                routeName: "year-overviews",
                yearOverviewRoute: true,
            },
            {
                icon: "",
                label: "aanvullende_vragen",
                routeName: "forms",
                formRoute: true,
            },
            {
                icon: "",
                label: "controleren",
                routeName: "checks",
                checkRoute: true,
            },
            {
                icon: "download",
                label: "downloaden",
                routeName: "download",
            },
        ],
        formItems: [],
        yearOverviewItems: [
            {
                sheet: "profit-and-loss-account",
                tag: "winst_en_verliesrekening",
                numbersTag: "winstenverlies",
                status: false,
                clickable: false,
            },
            { sheet: "balance-assets", tag: "balans_activa", numbersTag: "activa", status: false, clickable: false },
            {
                sheet: "balance-liabilities",
                tag: "balans_passiva",
                numbersTag: "passiva",
                status: false,
                clickable: false,
            },
            {
                sheet: "profit-calculation",
                tag: "profit_calculation",
                numbersTag: "prive",
                status: false,
                clickable: false,
            },
        ],
        checkItems: [
            {
                sheet: "profit-and-loss-account",
                tag: "winst_en_verliesrekening",
                numbersTag: "winstenverlies",
                status: false,
                clickable: false,
            },
            { sheet: "balance-assets", tag: "balans_activa", numbersTag: "activa", status: false, clickable: false },
            {
                sheet: "balance-liabilities",
                tag: "balans_passiva",
                numbersTag: "passiva",
                status: false,
                clickable: false,
            },
            {
                sheet: "profit-calculation",
                tag: "profit_calculation",
                numbersTag: "prive",
                status: false,
                clickable: false,
            },
            {
                sheet: "tax-profit",
                tag: "tax_profit",
                numbersTag: "tax_profit",
                status: false,
                clickable: false,
            },
        ],
        currentItem: "intro",
        currentForm: "intro",
        currentYearOverview: "intro",
        currentCheck: "intro",
        sliderShowed: false,
        started: false,
        // currentTaxCheck: "intro",
    }),
    mutations: {
        setItems(state, items) {
            Vue.set(state, "items", items);
        },
        setSliderShowed(state, sliderShowed) {
            Vue.set(state, "sliderShowed", sliderShowed);
        },
        setFormItems(state, formItems) {
            Vue.set(state, "formItems", formItems);
        },
        setCurrentForm(state, currentForm) {
            Vue.set(state, "currentForm", currentForm);
        },
        setCurrentYearOverview(state, currentYearOverview) {
            Vue.set(state, "currentYearOverview", currentYearOverview);
        },
        setYearOverviewItems(state, yearOverviewItems) {
            Vue.set(state, "yearOverviewItems", yearOverviewItems);
        },
        setCheckItems(state, checkItems) {
            Vue.set(state, "checkItems", checkItems);
        },
        setCurrentCheck(state, currentCheck) {
            Vue.set(state, "currentCheck", currentCheck);
        },
        setCurrentItem(state, currentItem) {
            Vue.set(state, "currentItem", currentItem);
        },
        setStarted(state, started) {
            Vue.set(state, "started", started);
        },
    },
    actions: {
        initForm({ commit }, jsonItems) {
            return new Promise(resolve => {
                const formItems = [];

                jsonItems.forEach(jsonItem => {
                    if (jsonItem.header) {
                        formItems.push(jsonItem);
                        return;
                    }
                    jsonItem.routeName = "form";
                    jsonItem.status = false;
                    jsonItem.clickable = false;
                    formItems.push(jsonItem);
                });

                commit("setFormItems", formItems);
                resolve();
            });
        },
        setCurrentForm({ commit }, currentForm) {
            commit("setCurrentForm", currentForm);
        },
        setStarted({ commit }, started) {
            commit("setStarted", started);
        },
        setSliderShowed({ commit }, sliderShowed) {
            commit("setSliderShowed", sliderShowed);
        },
        setCurrentYearOverview({ commit }, currentYearOverview) {
            commit("setCurrentYearOverview", currentYearOverview);
        },
        setCurrentCheck({ commit }, currentCheck) {
            commit("setCurrentCheck", currentCheck);
        },
        setCurrentItem({ commit }, currentItem) {
            currentItem = currentItem === "form" ? "forms" : currentItem;
            currentItem = currentItem === "year-overview" ? "year-overviews" : currentItem;
            currentItem = currentItem === "check" ? "checks" : currentItem;
            commit("setCurrentItem", currentItem);
        },

        // Set form status
        setFormStatus({ commit, getters, dispatch }, { formKey, status }) {
            const newFormItems = [];

            getters.allFormItems.forEach(formItem => {
                if (formItem.header) {
                    newFormItems.push(formItem);
                    return;
                }
                const newFormItem = formItem;
                if (formItem.form === formKey) {
                    newFormItem.status = status;
                }

                newFormItems.push(newFormItem);
            });

            commit("setFormItems", newFormItems);

            dispatch("currentState/setDownloaded", false, { root: true });
            dispatch("currentState/setFinished", false, { root: true });
        },

        setFormState({ dispatch }, formItems) {
            forEach(formItems, (status, formKey) => {
                dispatch("setFormStatus", { status, formKey });
            });
        },

        resetFormState({ dispatch, getters }) {
            forEach(getters.formItems, formItem => {
                dispatch("setFormStatus", { status: false, formKey: formItem.form });
            });
        },

        // Set year overview status
        setYearOverviewStatus({ commit, getters }, { sheet, status }) {
            const newYearOverviewItems = [];

            getters.yearOverviewItems.forEach(yearOverviewItem => {
                const newYearOverviewItem = yearOverviewItem;

                if (newYearOverviewItem.sheet === sheet) {
                    newYearOverviewItem.status = status;
                }

                newYearOverviewItems.push(newYearOverviewItem);
            });

            commit("setYearOverviewItems", newYearOverviewItems);
        },

        setYearOverviewState({ dispatch }, yearOverviewItems) {
            forEach(yearOverviewItems, (status, sheet) => {
                dispatch("setYearOverviewStatus", { status, sheet });
            });
        },

        resetYearOverviewState({ dispatch, getters }) {
            forEach(getters.yearOverviewItems, yearOverviewItem => {
                dispatch("setYearOverviewStatus", { status: false, sheet: yearOverviewItem.sheet });
            });
        },

        resetStarted({ commit }) {
            commit("setStarted", false);
        },

        // Set check item status
        setCheckStatus({ commit, getters }, { sheet, status }) {
            const newCheckItems = [];

            getters.checkItems.forEach(checkItem => {
                const newCheckItem = checkItem;

                if (newCheckItem.sheet === sheet) {
                    newCheckItem.status = status;
                }

                newCheckItems.push(newCheckItem);
            });

            commit("setCheckItems", newCheckItems);
        },

        setCheckState({ dispatch }, checkItems) {
            forEach(checkItems, (status, sheet) => {
                dispatch("setCheckStatus", { status, sheet });
            });
        },

        resetCheckState({ dispatch, getters }) {
            forEach(getters.checkItems, checkItem => {
                dispatch("setCheckStatus", { status: false, sheet: checkItem.sheet });
            });
        },
    },
    getters: {
        items: state => state.items,
        sliderShowed: state => state.sliderShowed,
        currentYearOverview: state => state.currentYearOverview,
        currentCheck: state => state.currentCheck,
        yearOverviewItems: state => state.yearOverviewItems,
        checkItems: state => state.checkItems,
        allFormItems: state => state.formItems,
        started: state => state.started,

        mutationName: state => {
            return formKey => {
                const formItemByKey = find(state.formItems, formItem => {
                    return formItem.form === formKey;
                });
                if (formItemByKey) {
                    return formItemByKey.mutationName;
                }
                return false;
            };
        },

        formItems: state => {
            return state.formItems.filter(formItem => !formItem.header);
        },

        navItems: (state, getters) => {
            const navItems = [];
            getters.items.forEach(item => {
                if (item.yearOverviewRoute) {
                    getters.yearOverviewItems.forEach(yearOverviewItem => {
                        navItems.push(yearOverviewItem);
                    });
                } else if (item.formRoute) {
                    getters.allFormItems.forEach(formItem => {
                        navItems.push(formItem);
                    });
                } else if (item.checkRoute) {
                    getters.checkItems.forEach(checkItem => {
                        navItems.push(checkItem);
                    });
                } else {
                    navItems.push(item);
                }
            });

            return navItems;
        },
        // taxCheckItems: state => state.taxCheckItems,
        currentForm: state => state.currentForm,
        // currentTaxCheck: state => state.currentTaxCheck,
        currentItem: state => state.currentItem,
        currentItemIndex: (state, getters) => {
            return findIndex(getters.items, item => {
                if (getters.currentItem === "form") {
                    return item.routeName === "forms";
                }
                if (getters.currentItem === "year-overview") {
                    return item.routeName === "year-overviews";
                }
                if (getters.currentItem === "check") {
                    return item.routeName === "checks";
                }
                if (getters.currentItem === "tax-profit") {
                    return item.routeName === "tax-profit";
                }
                return item.routeName === getters.currentItem;
            });
        },
        currentFormObject: (state, getters) => {
            return form => {
                return find(getters.formItems, item => {
                    return item.form === form;
                });
            };
        },
        currentYearOverviewObject: (state, getters) => {
            return sheet => {
                return find(getters.yearOverviewItems, item => {
                    return item.sheet === sheet;
                });
            };
        },
        currentCheckObject: (state, getters) => {
            return sheet => {
                return find(getters.checkItems, item => {
                    return item.sheet === sheet;
                });
            };
        },
        nextRoute: (state, getters) => {
            let currentIndex = findIndex(getters.handledItems, item => item.routeName === getters.currentItem);

            if (getters.currentItem === "checks" && getters.currentCheck === "tax-profit") {
                currentIndex = findIndex(getters.handledItems, item => item.routeName === "tax-profit");
            }

            const currentItem = getters.items[getters.currentItemIndex];

            if (currentItem && currentItem.formRoute === true) {
                currentIndex = findIndex(
                    getters.handledItems,
                    item => item.form === getters.currentForm && item.routeName === "form",
                );
            }

            if (currentItem && currentItem.yearOverviewRoute === true) {
                currentIndex = findIndex(
                    getters.handledItems,
                    item => item.sheet === getters.currentYearOverview && item.routeName === "year-overview",
                );
            }

            if (currentItem && currentItem.checkRoute === true) {
                currentIndex = findIndex(
                    getters.handledItems,
                    item => item.sheet === getters.currentCheck && item.routeName === "check",
                );

                if (getters.currentCheck === "tax-profit") {
                    currentIndex = findIndex(
                        getters.handledItems,
                        item => item.sheet === getters.currentCheck && item.routeName === "tax-profit",
                    );
                }
            }

            if (!getters.handledItems[currentIndex + 1]) {
                return null;
            }

            return getters.handledItems[currentIndex + 1];
        },
        prevRoute: (state, getters) => {
            let currentIndex = findIndex(getters.handledItems, item => item.routeName === getters.currentItem);
            if (getters.currentItem === "checks" && getters.currentCheck === "tax-profit") {
                currentIndex = findIndex(getters.handledItems, item => item.routeName === "tax-profit");
            }
            const currentItem = getters.items[getters.currentItemIndex];

            if (currentItem && currentItem.formRoute === true) {
                currentIndex = findIndex(
                    getters.handledItems,
                    item => item.form === getters.currentForm && item.routeName === "form",
                );
            }

            if (currentItem && currentItem.yearOverviewRoute === true) {
                currentIndex = findIndex(
                    getters.handledItems,
                    item => item.sheet === getters.currentYearOverview && item.routeName === "year-overview",
                );
            }

            if (currentItem && currentItem.checkRoute === true) {
                currentIndex = findIndex(
                    getters.handledItems,
                    item => item.sheet === getters.currentCheck && item.routeName === "check",
                );
                if (getters.currentCheck === "tax-profit") {
                    currentIndex = findIndex(
                        getters.handledItems,
                        item => item.sheet === getters.currentCheck && item.routeName === "tax-profit",
                    );
                }
            }

            if (!getters.handledItems[currentIndex - 1]) {
                return null;
            }

            return getters.handledItems[currentIndex - 1];
        },
        currentStep: (state, getters) => {
            const currentIndex = findIndex(getters.items, item => item.routeName === getters.currentItem);

            return currentIndex + 1;
        },
        isDone: (state, getters) => {
            const currentIndex = findIndex(getters.items, item => item.routeName === getters.currentItem);
            return currentIndex === getters.items.length - 1;
        },
        firstPossibleRoute: (state, getters) => {
            if (!getters.started) {
                return { routeName: "intro" };
            }
            let firstIndex = findIndex(
                getters.handledItems,
                item => !isUndefined(item.status) && item.status === false,
            );

            if (firstIndex < 0) {
                firstIndex = findLastIndex(
                    getters.handledItems,
                    item => !isUndefined(item.status) && item.status === true,
                );
                return getters.handledItems[firstIndex + 1];
            }

            return getters.handledItems[firstIndex];
        },
        handledItems: (state, getters, rootState, rootGetters) => {
            const items = [];
            getters.items.forEach(item => {
                if (item.formRoute === true) {
                    getters.formItems.forEach(formItem => {
                        if (formItem.header) {
                            return false;
                        }
                        if (formItem.conditions && formItem.conditions !== true) {
                            if (window.bdForms && window.bdForms.config && window.bdForms.config.showAllForms) {
                                // Show all forms, do not check conditions
                            } else if (
                                !navigationConditionsHandler(rootGetters["formValues/values"], formItem.conditions)
                            ) {
                                return;
                            }
                        }
                        formItem.routeParams = { form: formItem.form };
                        items.push(formItem);
                    });
                } else if (item.yearOverviewRoute === true) {
                    getters.yearOverviewItems.forEach(yearOverviewItem => {
                        yearOverviewItem.routeParams = { sheet: yearOverviewItem.sheet };
                        yearOverviewItem.routeName = "year-overview";
                        items.push(yearOverviewItem);
                    });
                } else if (item.checkRoute === true) {
                    getters.checkItems.forEach(checkItem => {
                        checkItem.routeParams = { sheet: checkItem.sheet };
                        checkItem.routeName = "check";
                        if (checkItem.tag === "tax_profit") {
                            checkItem.routeName = "tax-profit";
                        }
                        items.push(checkItem);
                    });
                } else {
                    items.push(item);
                }
            });

            return items;
        },
        handledYearOverviewItems: (state, getters) => {
            const items = [];
            let nextClickable = getters.started;
            // let counter = 1;

            getters.yearOverviewItems.forEach(yearOverviewItem => {
                if (yearOverviewItem.status === true || nextClickable) {
                    // || counter === 1
                    yearOverviewItem.clickable = true;
                } else {
                    yearOverviewItem.clickable = false;
                }

                if (yearOverviewItem.status !== true && nextClickable) {
                    nextClickable = false;
                }

                yearOverviewItem.routeParams = { sheet: yearOverviewItem.sheet };
                yearOverviewItem.routeName = "year-overview";
                items.push(yearOverviewItem);

                // counter++;
            });

            return items;
        },
        handledCheckItems: (state, getters) => {
            const items = [];
            let nextClickable = getters.allFormsSet;

            getters.checkItems.forEach(checkItem => {
                if (checkItem.status === true || nextClickable) {
                    checkItem.clickable = true;
                } else {
                    checkItem.clickable = false;
                }

                if (checkItem.status !== true && nextClickable) {
                    nextClickable = false;
                }
                checkItem.routeParams = { sheet: checkItem.sheet };
                checkItem.routeName = "check";
                if (checkItem.tag === "tax_profit") {
                    checkItem.routeName = "tax-profit";
                }
                items.push(checkItem);
            });

            return items;
        },

        handledFormItems: (state, getters, rootState, rootGetters) => {
            const items = [];
            let nextClickable = getters.allYearOverviewSet;
            // let counter = 1;

            state.formItems.forEach(formItem => {
                if (formItem.header) {
                    if (items[items.length - 1] && items[items.length - 1].header) {
                        items[items.length - 1] = formItem;
                    } else {
                        items.push(formItem);
                    }
                    return;
                }
                if (formItem.conditions && formItem.conditions !== true) {
                    if (window.bdForms && window.bdForms.config && window.bdForms.config.showAllForms) {
                        // Show all forms, do not check conditions
                    } else if (!navigationConditionsHandler(rootGetters["formValues/values"], formItem.conditions)) {
                        return;
                    }
                }

                if (formItem.status === true || nextClickable) {
                    // || counter === 1
                    formItem.clickable = true;
                } else {
                    formItem.clickable = false;
                }
                if (formItem.status !== true && nextClickable) {
                    nextClickable = false;
                }
                formItem.routeParams = { form: formItem.form };
                // counter++;
                items.push(formItem);
            });

            return items;
        },

        allFormsSet: (state, getters) => {
            let allFormsSetValue = true;
            const formKeys = getters.handledFormItems
                .filter(handledFormItem => !handledFormItem.header)
                .map(handledFormItem => handledFormItem.form);
            // const taxCheckKeys = getters.handledTaxCheckItems.map(handledTaxCheckItem => handledTaxCheckItem.form);
            const formState = getters.getFormState;
            forEach(formKeys, formKey => {
                if (formState[formKey] !== true) {
                    allFormsSetValue = false;
                }
            });

            return allFormsSetValue;
        },

        canAccessRoute: (state, getters) => {
            return route => {
                let canAccess = true;
                let itemFound = false;

                if (route.name === "intro" && getters.started) {
                    itemFound = true;
                }

                if (!getters.started) {
                    canAccess = false;
                    itemFound = true;
                }

                if (route.name === "custom") {
                    itemFound = true;
                    canAccess = true;
                }

                if (route.name === "licenses") {
                    itemFound = true;
                    canAccess = true;
                }

                forEach(getters.yearOverviewItems, yearOverviewItem => {
                    if (itemFound) return;
                    if (
                        route.name === "year-overview" &&
                        route.params &&
                        yearOverviewItem.sheet === route.params.sheet
                    ) {
                        itemFound = true;
                        return;
                    } else if (!yearOverviewItem.status) {
                        canAccess = false;
                        itemFound = true;
                    }
                });

                forEach(getters.handledFormItems, formItem => {
                    if (formItem.header) return;
                    if (itemFound) return;

                    if (route.name === "form" && route.params && formItem.form === route.params.form) {
                        itemFound = true;
                        return;
                    } else if (!formItem.status) {
                        canAccess = false;
                        itemFound = true;
                    }
                });

                forEach(getters.checkItems, checkItem => {
                    if (itemFound) return;
                    if (route.name === "check" && route.params && checkItem.sheet === route.params.sheet) {
                        itemFound = true;
                        return;
                    } else if (route.name === "tax-profit") {
                        itemFound = true;
                        return;
                    } else if (!checkItem.status) {
                        canAccess = false;
                        itemFound = true;
                    }
                });

                if (route.name === "download") {
                    itemFound = true;
                }

                return canAccess;
            };
        },

        allYearOverviewSet: (state, getters) => {
            let allYearOverviewSetValue = true;

            forEach(getters.yearOverviewItems, yearOverviewItem => {
                if (yearOverviewItem.status !== true && !yearOverviewItem.header) {
                    allYearOverviewSetValue = false;
                }
            });

            return allYearOverviewSetValue;
        },

        allCheckSet: (state, getters) => {
            let allCheckSetValue = true;

            forEach(getters.checkItems, checkItem => {
                if (checkItem.status !== true && !checkItem.header) {
                    allCheckSetValue = false;
                }
            });

            return allCheckSetValue;
        },

        getFormState: state => {
            const formItems = {};
            // const taxCheckItems = {};

            state.formItems.forEach(formItem => {
                if (formItem.header) {
                    return;
                }
                formItems[formItem.form] = formItem.status;
            });

            return formItems;
        },

        getYearOverviewState: state => {
            const yearOverviewItems = {};

            state.yearOverviewItems.forEach(yearOverviewItem => {
                if (yearOverviewItem.header) {
                    return;
                }
                yearOverviewItems[yearOverviewItem.sheet] = yearOverviewItem.status;
            });

            return yearOverviewItems;
        },

        getCheckState: state => {
            const checkItems = {};

            state.checkItems.forEach(checkItem => {
                if (checkItem.header) {
                    return;
                }
                checkItems[checkItem.sheet] = checkItem.status;
            });

            return checkItems;
        },

        lastFormKey: (state, getters, rootState, rootGetters) => {
            let lastFormKey;
            state.formItems.forEach(formItem => {
                if (formItem.conditions && formItem.conditions !== true) {
                    if (window.bdForms && window.bdForms.config && window.bdForms.config.showAllForms) {
                        // Show all forms, do not check conditions
                    } else if (!navigationConditionsHandler(rootGetters["formValues/values"], formItem.conditions)) {
                        return;
                    }
                }
                lastFormKey = formItem.form;
            });

            return lastFormKey;
        },
    },
};
