import Vue from "vue";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
// import isEqual from "lodash/isEqual";
import pickBy from "lodash/pickBy";
import { readEcgXml, rewriteModelKeys } from "../modules/utils/xmlReader";
import removeObservers from "../modules/utils/removeObservers";
import { getPostKeyModelObject, getModelFieldObject } from "../modules/singletons/jsonForms";
import ecgMapper, { ecgChangedMessages, mergeConflicts } from "../modules/utils/ecgMapper";
import { getConstants } from "../modules/singletons/constantsFile";
import errors from "../data/errors";
import ecgXsd from "../data/ecg.xsd";
import { appConstants } from "../data/constants";

export default {
    namespaced: true,
    state: () => ({
        values: {},
        preFilledValues: {},
        conflicts: {},
    }),
    mutations: {
        setValues(state, values) {
            Vue.set(state, "values", values);
        },
        setConflicts(state, conflicts) {
            Vue.set(state, "conflicts", conflicts);
        },
        setPrefilledValues(state, preFilledValues) {
            Vue.set(state, "preFilledValues", preFilledValues);
        },
        setValue(state, { key, value }) {
            Vue.set(state.values, key, value);
        },
        setPrefilledValue(state, { key, value }) {
            Vue.set(state.preFilledValues, key, value);
        },
    },
    actions: {
        setConflictedValues({ commit, getters, rootGetters }) {
            if (!getters.values["wondnaam"] && rootGetters["rgsLedger/headers"]["CompanyName"]) {
                commit("setValue", { key: "wondnaam", value: rootGetters["rgsLedger/headers"]["CompanyName"] });
            }
        },
        setValues({ commit }, values) {
            commit("setValues", values);
        },
        setConflicts({ commit }, conflicts) {
            commit("setConflicts", conflicts);
        },
        setPrefilledValues({ commit }, preFilledValues) {
            commit("setPrefilledValues", preFilledValues);
        },
        assignValues({ commit, rootGetters }, values) {
            forEach(values, (value, valueKey) => {
                if (rootGetters["rgsLedger/gelIds"].includes(valueKey)) {
                    return;
                }

                if (isArray(value)) {
                    value = compact(value);
                }
                commit("setValue", { key: valueKey, value });
            });
        },
        assignPrefilledValues({ commit }, values) {
            forEach(values, (value, key) => {
                commit("setPrefilledValue", { key, value });
                commit("setValue", { key, value });
            });
        },
        removeFormConflicts({ commit, getters }, formKey) {
            const conflicts = removeObservers(getters.conflicts);
            if (conflicts[formKey]) {
                delete conflicts[formKey];
            }

            commit("setConflicts", conflicts);
        },
        resetPrefilledValues({ commit, getters }) {
            forEach(getters.preFilledValues, (value, key) => {
                commit("setValue", { key, value });
            });
        },
        parseEcg({ getters, commit, dispatch }, xmlString) {
            return new Promise((resolve, reject) => {
                let ecgObject;
                try {
                    ecgObject = readEcgXml(xmlString, ecgXsd);
                } catch (err) {
                    return reject(err);
                }

                ecgObject = rewriteModelKeys(ecgObject, getPostKeyModelObject());

                if (+getConstants().BDRGJAAR !== +ecgObject[117245]) {
                    reject(
                        errors(113, { errors: "AangifteBelastingjaar should be: " + getConstants().BDRGJAAR }, false),
                    );
                }

                if (!isEmpty(getters.preFilledValues)) {
                    const modelFieldObject = getModelFieldObject();

                    const changed = ecgChangedMessages(
                        modelFieldObject,
                        ecgObject,
                        removeObservers(getters.preFilledValues),
                        removeObservers(getters.values),
                    );

                    const mergedConflicts = mergeConflicts(
                        removeObservers(changed),
                        removeObservers(getters.conflicts),
                        modelFieldObject,
                    );

                    const changedFormKeys = Object.keys(mergedConflicts);

                    forEach(changedFormKeys, formKey => {
                        dispatch("navigation/setFormStatus", { status: false, formKey }, { root: true });
                    });

                    commit("setConflicts", mergedConflicts);
                }

                const valuesObject = ecgMapper(
                    ecgObject,
                    removeObservers(getters.preFilledValues),
                    removeObservers(getters.values),
                );

                commit("setValues", valuesObject);
                commit("setPrefilledValues", ecgObject);
                resolve();
            });
        },
    },
    getters: {
        values: state => removeObservers(state.values),
        conflicts: state => state.conflicts,
        formConflicts: state => {
            return formKey => {
                return state.conflicts[formKey];
            };
        },
        preFilledValues: state => state.preFilledValues,
        messageData: state => {
            const returnObj = {};
            if (state.values[117271]) {
                returnObj[117271] = state.values[117271];
            }
            if (state.values[117245]) {
                returnObj[117245] = state.values[117245];
            }
            if (state.values[117243]) {
                returnObj[117243] = state.values[117243];
            }
            if (state.values[641755]) {
                returnObj[641755] = state.values[641755];
            }
            if (state.values[641753]) {
                returnObj[641753] = state.values[641753].substr(0, 19) + "_awi_" + appConstants.version;
            }
            if (state.values[641754]) {
                returnObj[641754] = state.values[641754];
            }

            return returnObj;
        },
        getCurrentStateValues: (state, getters, rootState, rootGetters) => {
            const yearNumberKeys = Object.keys(rootGetters["rgsLedger/rawValues"]);
            return pickBy(getters.values, (value, key) => !yearNumberKeys.includes(key));
        },
    },
};
