import amountDaysInPeriod from "../../formulas/date/amountDaysInPeriod";
import hlpmpontautnaambegin60mndherl from "./hlpmpontautnaambegin60mndherl";
import statics from "../../singletons/statics";

const hlpmwpontautperiodevanaf5jr = (wpontautstandperB, wpontautstandperE, wpontautnaambegin60mnd) => {
    const autnaambegin60mndherl = hlpmpontautnaambegin60mndherl(wpontautnaambegin60mnd, statics().BDRGJAAR);

    if (autnaambegin60mndherl === 0 || wpontautstandperE <= autnaambegin60mndherl.getTime()) {
        return 0;
    }

    if (
        autnaambegin60mndherl &&
        wpontautstandperE > autnaambegin60mndherl.getTime() &&
        wpontautstandperB >= autnaambegin60mndherl.getTime()
    ) {
        return amountDaysInPeriod(wpontautstandperB, wpontautstandperE);
    }

    if (
        autnaambegin60mndherl &&
        wpontautstandperE > autnaambegin60mndherl.getTime() &&
        wpontautstandperB < autnaambegin60mndherl.getTime()
    ) {
        return amountDaysInPeriod(autnaambegin60mndherl.getTime(), wpontautstandperE);
    }

    // anders nul
    return 0;
};

export default hlpmwpontautperiodevanaf5jr;
