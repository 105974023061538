import convertStringDateToMilliseconds from "../../calculations/date/convertStringDateToMilliseconds";
import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";

export default (model, field, parameters) => {
    if (
        model &&
        parameters &&
        parameters.fieldModels &&
        model[parameters.fieldModels[0]] &&
        model[parameters.fieldModels[1]] &&
        isModelVisible(parameters.fieldModels[0], field) &&
        isModelVisible(parameters.fieldModels[1], field)
    ) {
        const startDate = convertStringDateToMilliseconds(groupModelValue(parameters.fieldModels[0], model, field));
        const endDate = convertStringDateToMilliseconds(groupModelValue(parameters.fieldModels[1], model, field));
        const yearStartDate = new Date(startDate).getFullYear();
        const yearEndDate = new Date(endDate).getFullYear();

        if (!parameters.inverse) {
            return startDate > endDate || yearStartDate !== yearEndDate;
        }

        return !(startDate > endDate || yearStartDate !== yearEndDate);
    }
    return false;
};
