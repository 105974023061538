<template>
    <div class="form-group" :class="getFieldRowClasses(field)">
        <!-- label -->
        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
        <label v-if="fieldTypeHasLabel(field)" :for="getFieldID(field)" :class="field.labelClasses">
            <div v-if="field.type !== 'label' || !field.labelStyle">
                <span class="label-html" v-html="getLabelHtml(field.label)"></span>
                <a
                    href="#"
                    class="help-box-open"
                    :class="{ 'is-open': helpToggle }"
                    v-if="whichHelp(field) !== false"
                    :aria-label="$t('global.help_voor_invoer_veld')"
                    @click.stop.prevent="toggleHelp()"
                ></a>
            </div>
            <div v-if="field.type === 'label' && field.labelStyle && field.labelStyle !== 'center'">
                <bldc-notification :type="field.labelStyle" elem-title="">
                    <span class="label-html styled-label-html" v-html="getLabelHtml(field.label)"></span>
                    <a
                        href="#"
                        class="help-box-open"
                        :class="{ 'is-open': helpToggle }"
                        :aria-label="$t('global.help_voor_label')"
                        v-if="whichHelp(field) !== false"
                        @click.prevent="toggleHelp()"
                    ></a>
                </bldc-notification>
            </div>
            <div v-if="field.type === 'label' && field.labelStyle && field.labelStyle === 'center'">
                <span class="label-html center-label" v-html="getLabelHtml(field.label)"></span>
            </div>
            <div v-if="option('showFieldData')" class="text-orange">
                <span v-if="field.model">Model: {{ field.model }}</span>
                <span v-if="field.model && field.refid"> - </span>
                <span v-if="field.refid">RefId: {{ field.refid }}</span>
            </div>
        </label>

        <!-- input -->
        <div class="field-wrap">
            <component
                ref="child"
                :is="getFieldType(field)"
                :vfg="vfg"
                :disabled="fieldDisabled(field)"
                :model="model"
                :schema="field"
                :formOptions="options"
                :hidden-label="fieldTypeHasLabel(field) ? null : field.hiddenLabel"
                @model-updated="onModelUpdated"
                @validated="onFieldValidated"
            ></component>
        </div>

        <!-- help -->
        <div class="help-box" v-if="helpToggle">
            <button class="help-box-close" @click.prevent="helpToggle = !helpToggle">
                <bldc-icon aria-label="Close" icon="sluiten" class="hydrated"></bldc-icon>
            </button>
            <div ref="helpText" v-html="getHelp(field)"></div>
        </div>

        <!-- error -->
        <div v-if="fieldErrors(field).length > 0" class="errors help-block">
            <!-- <span v-for="(error, index) in fieldErrors(field)" :key="index" v-html="error"></span> -->
            <template v-for="(error, index) in fieldErrors(field)">
                <bldc-validation elem-title="" :key="index">
                    <bldc-paragraph disable-margin="true" size="s">
                        <span v-html="error" />
                    </bldc-paragraph>
                </bldc-validation>
            </template>
        </div>
    </div>
</template>
<script>
import * as objGet from "lodash/get";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import { slugifyFormID } from "../modules/utils/schema";
import formMixin from "../mixins/formMixin.js";
import utilsMixin from "../mixins/utilsMixin.js";
import Vue from "vue";
import fieldComponents from "../modules/utils/fieldsLoader.js";
import { getHelpTextsList, getCustomHelpTextsList } from "../modules/singletons/helpTextsList";
import getHelpText from "../modules/utils/getHelpText";
import tippy from "tippy.js";
import groupModelValue from "../modules/helpers/model/groupModelValue";
// import formatNumber from "../modules/utils/formatNumber";
import { notExistsWarning } from "../modules/singletons/registeredModels";

export default {
    name: "form-group",
    components: fieldComponents,
    mixins: [formMixin, utilsMixin],
    props: {
        vfg: {
            type: Object,
            required: true,
        },
        model: Object,
        statics: Object,
        options: {
            type: Object,
        },
        field: {
            type: Object,
            required: true,
        },
        errors: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            helpTextList: [],
            customHelpTextList: [],
            loadedHelpTexts: {},
            helpToggle: false,
        };
    },
    methods: {
        // On model update shoot event
        onModelUpdated(newVal, schema) {
            this.$emit("model-updated", newVal, schema);
        },
        // Should field type have a label?
        fieldTypeHasLabel(field) {
            if (isNil(field.label)) return false;
            if (!field.label) return false;
            return true;
        },
        // Get the field id
        getFieldID(schema) {
            const idPrefix = objGet(this.options, "fieldIdPrefix", "");
            return slugifyFormID(schema, idPrefix);
        },
        // Get type of field 'field-xxx'. It'll be the name of HTML element
        getFieldType(fieldSchema) {
            return "field-" + fieldSchema.type;
        },
        // Field Validation
        onFieldValidated(res, errors, field) {
            this.$emit("validated", res, errors, field);
        },
        validate(calledParent) {
            return this.$refs.child.validate(calledParent);
        },
        fieldErrors(field) {
            return this.errors.filter(e => e.field === field).map(item => this.getLabelHtml(item.error));
        },
        clearValidationErrors() {
            if (this.$refs.child) {
                return this.$refs.child.clearValidationErrors();
            }
        },
        // Field Help text
        addTippyToHelp() {
            tippy(this.$refs.helpText.querySelectorAll(".tooltip"), {
                theme: "light",
                trigger: "mouseenter focus click",
            });
        },
        toggleHelp() {
            this.helpToggle = !this.helpToggle;
            if (this.helpToggle) {
                Vue.nextTick(() => {
                    this.addTippyToHelp();
                });
            }
        },
        loadHelp(refId) {
            getHelpText(refId)
                .then(helpElement => {
                    Vue.set(this.loadedHelpTexts, refId, helpElement);
                    Vue.nextTick(() => {
                        this.addTippyToHelp();
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getHelp(field) {
            if (this.whichHelp(field) === "customHelpTextList" || this.whichHelp(field) === "helpTextList") {
                if (this.loadedHelpTexts[field.refid]) {
                    return this.loadedHelpTexts[field.refid].innerHTML;
                }
                this.loadHelp(field.refid);
                return this.$t("global.laden");
            } else if (this.whichHelp(field) === "customHelp") {
                return field.customHelp;
            } else if (this.whichHelp(field) === "help") {
                return field.help;
            }
            return "";
        },
        whichHelp(field) {
            if (field.refid && !field.noRefHelpText && this.customHelpTextList.indexOf(field.refid) >= 0) {
                return "customHelpTextList";
            } else if (field.refid && !field.noRefHelpText && this.helpTextList.indexOf(field.refid) >= 0) {
                return "helpTextList";
            } else if (field.customHelp) {
                return "customHelp";
            } else if (field.help) {
                return "help";
            }

            return false;
        },
        // Label
        getLabelHtml(fieldLabel) {
            let label = fieldLabel;
            const labelVariables = label.match(/#{\s*[\w-[\].]+\s*}/g);

            if (!labelVariables) {
                return label;
            }

            labelVariables.forEach(variable => {
                let nummeric = false;
                let value;

                variable = variable.replace("#{", "").replace("}", "");

                value = groupModelValue(variable, this.model, this.field);

                if (isUndefined(value) && !isUndefined(this.statics[variable])) {
                    value = this.statics[variable];
                }

                if (isNumber(value) && !variable.startsWith("BDRGJAAR")) {
                    nummeric = true;
                }

                if (!isUndefined(value) && nummeric) {
                    value = this.formatNumber(value);
                }

                if (isUndefined(value)) {
                    notExistsWarning(variable, this.field, "");
                    label = label.replace("#{" + variable + "}", "");
                    return;
                }

                label = label.replace("#{" + variable + "}", value);
            });

            return label;
        },
    },
    created() {
        Vue.set(this, "helpTextList", getHelpTextsList());
        Vue.set(this, "customHelpTextList", getCustomHelpTextsList());
    },
};
</script>
<style lang="scss"></style>
