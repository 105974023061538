<template>
    <div>
        <bldc-paragraph margin="false">
            <div id="overview-switch">
                <div class="question-button">
                    <div class="question-wrapper" tabindex="0" @click="modal = true" @keyup.enter="modal = true">
                        <bldc-icon icon="help" font-size="12"></bldc-icon>
                    </div>
                </div>
                <div class="switch-label">
                    {{ label }}
                </div>
                <a
                    href="#"
                    class="switch"
                    @click.prevent="$emit('input', !value)"
                    @ketdown.enter.prevent="$emit('input', !value)"
                >
                    <span class="slider" :class="{ active: value }"></span>
                    <span class="sr-only">Zet aan</span>
                </a>
            </div>
        </bldc-paragraph>
        <bldc-modal
            :visible="modal"
            id="helpModal"
            v-on:bldcModalClose="modal = false"
            :buttons="JSON.stringify([{ type: 'secondary', label: $t('global.sluiten'), event: 'close' }])"
            modalId="helpModal"
        >
            <bldc-paragraph>
                {{ helpText }}
            </bldc-paragraph>
        </bldc-modal>
    </div>
</template>
<script>
export default {
    name: "yoSwitch",
    mixins: [],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        helpText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            modal: false,
        };
    },
};
</script>
<style lang="scss" scoped>
.question-button {
    border: 1px solid rgb(1, 104, 155);
    height: 16px;
    width: 16px;
    text-align: center;
    margin-top: 4px;
    float: left;
    margin-right: 5px;
    cursor: pointer;
    flex-shrink: 0;
    margin-top: 5px;

    .question-wrapper {
        margin-top: -6px;
        color: rgb(1, 104, 155);
    }
}
.switch-label {
    display: inline-block;
    margin-right: 5px;
}
.switch {
    position: relative;
    display: block;
    float: right;
    width: 58px;
    height: 28px;

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
            position: absolute;
            border-radius: 50%;
            content: "";
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        &.active {
            background-color: rgb(1, 104, 155);
            box-shadow: 0 0 1px rgb(1, 104, 155);

            &:before {
                -webkit-transform: translateX(30px);
                -ms-transform: translateX(30px);
                transform: translateX(30px);
            }
        }
    }
}
</style>
