import errorMessage from "./messages";

export default (alphaNummeric, length, requiredLength = false) => {
    return value => {
        if (typeof value === "undefined" || value === null) {
            return [];
        }
        const errors = [];

        value = value + "";

        let startLenght = 1;

        if (requiredLength !== false) {
            startLenght = length;
        }

        if (alphaNummeric === "a" && (new RegExp("<|>").test(value) || value.length > length)) {
            if ((requiredLength && value.length !== length) || requiredLength) {
                // lenght is not valid (alpha)
                errors.push(
                    errorMessage("alpha_numerical_invalid_required_length", {
                        requiredValue: "letters",
                        lenght: length,
                    }),
                );
            } else if ((!requiredLength && value.length > length) || !requiredLength) {
                // lenght is not valid (alpha)
                errors.push(
                    errorMessage("alpha_numerical_invalid_max_length", {
                        requiredValue: "letters",
                        lenght: length,
                    }),
                );
            }
        } else if (
            alphaNummeric === "n" &&
            !new RegExp("^[-+]?[0-9.,]{" + startLenght + "," + length + "}$").test(value)
        ) {
            const numberValue = value.replace(/[.,+-]/g, "");
            if ((requiredLength && numberValue.length !== length) || requiredLength) {
                // lenght is not valid (alpha)
                errors.push(
                    errorMessage("alpha_numerical_invalid_required_length", {
                        requiredValue: "cijfers",
                        lenght: length,
                    }),
                );
            } else if ((!requiredLength && numberValue.length > length) || !requiredLength) {
                // lenght is not valid (alpha)
                errors.push(
                    errorMessage("alpha_numerical_invalid_max_length", {
                        requiredValue: "cijfers",
                        lenght: length,
                    }),
                );
            }
        } else if (alphaNummeric === "an" && (new RegExp("<|>").test(value) || value.length > length)) {
            if ((requiredLength && value.length !== length) || requiredLength) {
                // lenght is not valid (alpha)
                errors.push(
                    errorMessage("alpha_numerical_invalid_required_length", {
                        requiredValue: "letters en cijfers",
                        lenght: length,
                    }),
                );
            } else if ((!requiredLength && value.length > length) || !requiredLength) {
                // lenght is not valid (alpha)
                errors.push(
                    errorMessage("alpha_numerical_invalid_max_length", {
                        requiredValue: "letters en cijfers",
                        lenght: length,
                    }),
                );
            }
        }

        return errors;
    };
};
