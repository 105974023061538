import convertStringDateToMilliseconds from "../../calculations/date/convertStringDateToMilliseconds";
import valueFilter from "../../helpers/model/valueFilter";
import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import dateToGregorianNotation from "../../helpers/transformation/dateToGregorianNotation";

export default (model, field, parameters, staticData) => {
    let result = false;

    if (!isModelVisible(parameters.value, field)) {
        return result;
    }

    const value = valueFilter(parameters.value, staticData, model, field);

    if (model && parameters && parameters.fieldModels && parameters.date && value) {
        const dateStringToTimestamp = new Date(dateToGregorianNotation(valueFilter(parameters.date)));

        // check if expectation is met
        if (
            convertStringDateToMilliseconds(groupModelValue(parameters.fieldModels[0], model, field)) >
                dateStringToTimestamp ===
                value &&
            isModelVisible(parameters.value, field) &&
            isModelVisible(parameters.fieldModels[0], field)
        ) {
            result = true;
        }
    }

    if (model && parameters && parameters.fieldModels && !parameters.date && parameters.afterFirstDayInYear && value) {
        const yearValue = valueFilter(parameters.afterFirstDayInYear, staticData, model, field);
        const regexYear = new RegExp("^[0-9]{4}$");
        if (field && !regexYear.test(yearValue)) {
            console.warn("beforeLastDayInYear is not a valid year in field with model: " + field.model);
        }

        const dateStringToTimestamp = new Date(dateToGregorianNotation("01-01-" + yearValue));

        // check if expectation is met
        if (
            convertStringDateToMilliseconds(groupModelValue(parameters.fieldModels[0], model, field)) >
                dateStringToTimestamp ===
                value &&
            isModelVisible(parameters.value, field) &&
            isModelVisible(parameters.fieldModels[0], field)
        ) {
            result = true;
        }
    }

    return result;
};
