import errorMessage from "./messages";
import alphaNumericalLength from "./alphaNumericalLength";

export default value => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = alphaNumericalLength("n", 3, false)(value);

    if (value > 100) {
        errors.push(errorMessage("percentage_three_greater_than_100"));
    }

    if (value < 0) {
        errors.push(errorMessage("percentage_three_less_than_0"));
    }

    return errors;
};
