import { isModelVisible } from "../../singletons/modelVisibility";

export default (model, field, fieldModels) => {
    if (!model[fieldModels[0]]) {
        return 0;
    }

    let isAnyValue = false;

    let value = 0;

    const entries = Object.entries(model[fieldModels[0]]);

    for (const [key, group] of entries) {
        if (
            typeof model[fieldModels[0]][key] !== "undefined" &&
            typeof model[fieldModels[0]][key][fieldModels[1]] !== "undefined" &&
            isModelVisible(fieldModels[0] + "[" + key + "]." + fieldModels[1], field)
        ) {
            isAnyValue = true;
            value = value + group[fieldModels[1]];
        }
    }

    if (!isAnyValue) {
        return undefined;
    }

    return value;
};
