import groupModelValue from "../modules/helpers/model/groupModelValue";
import valueFilter from "../modules/helpers/model/valueFilter";
import { notExistsWarning } from "../modules/singletons/registeredModels";
// import Vue from "vue";

export default {
    methods: {
        // Update the labels
        updateValues(field) {
            if (field.type !== "select" && field.type !== "radios") {
                return;
            }

            let valueProperty = "value";
            if (field.type === "select") {
                valueProperty = "id";
            }

            if (typeof field.values !== "undefined") {
                field.values = this.replaceValuesVariables(field.values, field, valueProperty);
            }
        },

        // Replace statics and models in values object
        replaceValuesVariables(values, field, valueProperty = "value") {
            const newValues = [];
            values.forEach(value => {
                if (!value.originalName) {
                    value.originalName = value.name;
                }
                value.name = this.replaceValuesNameVariables(value.originalName, field);

                if (!value.originalValue) {
                    value.originalValue = value[valueProperty];
                }

                const newValue = this.replaceValuesValueVariables(value.originalValue, field);

                value[valueProperty] = typeof newValue !== "undefined" ? newValue : value.originalValue;

                newValues.push(value);
            });

            return newValues;
        },

        // Replace statics and models in name of values object
        replaceValuesNameVariables(originalName, field) {
            const originalNameVariables = originalName.match(/#{\s*[\w.]+\s*}/g);

            if (!originalNameVariables) {
                return originalName;
            }

            let newName = originalName;

            originalNameVariables.forEach(variable => {
                variable = variable.replace("#{", "").replace("}", "");

                const modelValue = groupModelValue(variable, this.model, field);

                if (typeof modelValue !== "undefined") {
                    newName = newName.replace("#{" + variable + "}", modelValue);
                } else if (typeof this.static[variable] !== "undefined") {
                    newName = newName.replace("#{" + variable + "}", this.static[variable]);
                } else {
                    notExistsWarning(variable, field, "");
                    newName = newName.replace("#{" + variable + "}", "");
                }
            });

            return newName;
        },

        // Replace statics and models in name of value/id object
        replaceValuesValueVariables(originalValue, field) {
            let variable = originalValue;
            return valueFilter(variable, this.static, this.model, field);
        },
    },
};
