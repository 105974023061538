import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import { notExistsWarning } from "../../singletons/registeredModels";

export default (model, field, parameters) => {
    let result = true;

    if (!parameters || typeof parameters.fieldModels === "undefined") {
        console.warn("notEqualsAnyValue is used but parameters.fieldModels is not set. Field: " + field.model);
    }

    parameters.fieldModels.forEach(fieldModel => {
        notExistsWarning(fieldModel, field, "");
        if (
            (groupModelValue(fieldModel, model, field) ||
                groupModelValue(fieldModel, model, field) === 0 ||
                typeof groupModelValue(fieldModel, model, field) === "boolean") &&
            isModelVisible(fieldModel, field)
        ) {
            result = false;
        }
    });
    return result;
};
