import groupModelValue from "../../helpers/model/groupModelValue";
import hlpmpontautnaambegin60mndherl from "../car/hlpmpontautnaambegin60mndherl";
import statics from "../../singletons/statics";

export default (model, field, fieldModels) => {
    const month = groupModelValue(fieldModels[0], model, field);
    const brgdYear = hlpmpontautnaambegin60mndherl(month, statics().BDRGJAAR);

    if (!brgdYear) {
        return undefined;
    }

    return (
        ("0" + brgdYear.getDate()).slice(-2) +
        "-" +
        ("0" + brgdYear.getMonth()).slice(-2) +
        "-" +
        brgdYear.getFullYear()
    );
};
