<template>
    <div class="container-number-input">
        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
        <input
            class="form-control"
            type="text"
            v-model="displayValue"
            :id="getFieldID(schema)"
            :disabled="disabled"
            :maxlength="schema.max"
            :placeholder="schema.placeholder"
            :readonly="schema.readonly"
            @blur="reformatValue"
            ref="numberField"
            v-attributes="'input'"
            :aria-label="hiddenLabel"
        />
        <span v-if="schema.affix" class="affix">
            {{ schema.affix }}
        </span>
    </div>
</template>

<script>
import abstractField from "./abstractField";
import validatorBugFix from "./validatorBugFix";
import reformatNumber from "./reformatNumber";

export default {
    mixins: [abstractField, validatorBugFix, reformatNumber],
};
</script>
<style lang="scss" scoped>
.container-number-input {
    display: inline-flex;
    align-items: center;
}
.form-control {
    padding-right: 38px !important;
}
.affix {
    margin-left: 7px;
    margin-left: -37px;
    font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
    font-size: 1.125rem;
    z-index: 1000;
    background-color: rgb(243, 243, 243);
    height: 44px;
    text-align: center;
    line-height: 46px;
    width: 36px;
}
</style>
