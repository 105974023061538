import required from "./required";
import groupModelValue from "../helpers/model/groupModelValue";

import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";

export default requiredIfModel => {
    return (value, field, model) => {
        if (
            !isUndefined(groupModelValue(requiredIfModel, model, field)) ||
            !isEmpty(groupModelValue(requiredIfModel, model, field))
        ) {
            return required(value, field);
        }

        return [];
    };
};
