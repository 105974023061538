import forEach from "lodash/forEach";
import { getValue, setValue } from "./values";
import liabilities from "./liabilities";
import profit from "./profit";
import assets from "./assets";
import equitygrowth from "./equitygrowth";
import modeLog from "../../utils/modeLog";

export default (mBmgAwaData, values) => {
    forEach(mBmgAwaData, (gelIDmBmgAwaData, gelID) => {
        const value = getValue(gelID, values);

        if (value !== null && (gelIDmBmgAwaData.round === "ROUNDDOWN" || gelIDmBmgAwaData.round === "ROUNDUP")) {
            const roundFn = gelIDmBmgAwaData.round === "ROUNDDOWN" ? Math.floor : Math.ceil;
            values = setValue(gelID, roundFn(value), values);
        }
    });

    const deltaB = liabilities(mBmgAwaData, values) + profit(mBmgAwaData, values) - assets(mBmgAwaData, values);

    modeLog("Delta B: ", deltaB);

    values["119356-EB"] = values["119356-EB"] - deltaB;

    const deltaW = equitygrowth(values);

    modeLog("Delta W: ", deltaW);

    if (deltaW > 0) {
        if (typeof values["118340"] !== "undefined") {
            values["118340"] = values["118340"] + deltaW;
        } else {
            values["118340"] = deltaW;
        }
    }

    if (deltaW < 0) {
        if (typeof values["118315"] !== "undefined") {
            values["118315"] = values["118315"] - deltaW;
        } else {
            values["118315"] = 0 - deltaW;
        }
    }

    return values;
};
