import splitGroupModel from "../../utils/splitGroupModel";
export default (modelKey, model, field, groupAffix = "RepeatModel") => {
    if (
        field !== null &&
        modelKey &&
        (modelKey + "").endsWith(groupAffix) &&
        (field.multiplierGroup || field.repeatableGroup)
    ) {
        let groupModelKey = modelKey.substring(0, modelKey.lastIndexOf(groupAffix));

        if (model[field.multiplierGroup] && model[field.multiplierGroup][field.multiplierIndex]) {
            return model[field.multiplierGroup][field.multiplierIndex][groupModelKey];
        }

        if (model[field.repeatableGroup] && model[field.repeatableGroup][field.repeatableIndex]) {
            return model[field.repeatableGroup][field.repeatableIndex][groupModelKey];
        }

        return undefined;
    } else if (modelKey && modelKey.match(/[a-zA-Z0-9]*\[[0-9]\].[a-zA-Z0-9]*/g)) {
        if (typeof model[modelKey] !== "undefined") {
            return model[modelKey];
        }

        const { groupModel, groupModelIndex, groupmModelKey } = splitGroupModel(modelKey);

        if (
            model[groupModel] &&
            model[groupModel][groupModelIndex] &&
            model[groupModel][groupModelIndex][groupmModelKey]
        ) {
            return model[groupModel][groupModelIndex][groupmModelKey];
        }
    }

    return model[modelKey];
};
