import Vue from "vue";
import App from "./App.vue";
import vueCustomElement from "vue-custom-element";
import { defineCustomElements } from "@belastingdienst/bldc-components/dist/custom-elements";
import VueShepherd from "vue-shepherd";
import "./styles/shepherd.scss";

// import {
//     BldcGrid,
//     BldcGridColumn,
//     BldcGridRow,
//     BldcHeading,
//     BldcParagraph,
//     BldcNotification,
//     BldcCard,
//     BldcLink,
//     BldcLogo,
//     BldcButton,
//     BldcModal,
//     BldcTaskStepIndicator,
//     BldcIcon,
// } from "@belastingdienst/bldc-components/dist/custom-elements";

// import "../node_modules/@belastingdienst/rofonts/css/rofonts.css";
// import "../node_modules/@belastingdienst/bld-icon-font/dist/bldiconfont.css";
import store from "./store";
import filePaths from "./data/filePaths";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/bldc-\w*/];

const fontPromises = [
    new FontFace("bldiconfont", "url(" + filePaths().iconFontFile + ")", {}).load(),
    new FontFace("ROregular", "url(" + filePaths().roRegularFontFile + ")", {}).load(),
    new FontFace("ROitalic", "url(" + filePaths().roItalicFontFile + ")", {}).load(),
    new FontFace("RObold", "url(" + filePaths().roBoldFontFile + ")", {}).load(),
];

Promise.all(fontPromises).then(function(fonts) {
    fonts.forEach(font => {
        document.fonts.add(font);
    });
});

// window.customElements.define("bldc-grid", BldcGrid);
// window.customElements.define("bldc-grid-row", BldcGridRow);
// window.customElements.define("bldc-grid-column", BldcGridColumn);
// window.customElements.define("bldc-heading", BldcHeading);
// window.customElements.define("bldc-paragraph", BldcParagraph);
// window.customElements.define("bldc-notification", BldcNotification);
// window.customElements.define("bldc-card", BldcCard);
// window.customElements.define("bldc-logo", BldcLogo);
// window.customElements.define("bldc-button", BldcButton);
// window.customElements.define("bldc-modal", BldcModal);
// window.customElements.define("bldc-task-step-indicator", BldcTaskStepIndicator);
// window.customElements.define("bldc-icon", BldcIcon);
// window.customElements.define("bldc-link", BldcLink);
defineCustomElements();

App.store = store;

Vue.use(vueCustomElement);
Vue.use(VueShepherd);
Vue.customElement("bd-forms", App);
