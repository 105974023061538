// i18n-setup.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import filePaths from "./data/filePaths";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "nl",
    fallbackLocale: "nl",
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    // If the same language
    if (i18n.locale === lang && loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return axios.get(`${filePaths().pathToContent}/${lang}.json`).then(response => {
        const messages = response.data;
        i18n.setLocaleMessage(lang, messages);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });
}
