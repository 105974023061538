import Vue from "vue";
export default {
    namespaced: true,
    state: () => ({
        slides: false,
    }),
    mutations: {
        setSlides(state, slides) {
            Vue.set(state, "slides", slides);
        },
    },
    actions: {
        setSlides({ commit }, slides) {
            commit("setSlides", slides);
        },
    },
    getters: {
        slides: state => state.slides,
    },
};
