import { getScreenTour } from "../modules/singletons/tour";
import { saveCurrentState } from "../modules/singletons/currentState";
import { mapGetters } from "vuex";
// import forEach from "lodash/forEach";

export default {
    data() {
        return {
            shepherdTour: null,
        };
    },
    computed: {
        ...mapGetters({
            showTour: "tour/showTour",
            isTourShowed: "tour/isTourShowed",
        }),
    },
    watch: {
        showTour(newValue) {
            if (newValue) {
                this.startShepherd();
                return;
            }
            if (this.shepherdTour && !newValue) {
                this.stopShepherd();
            }
        },
    },
    methods: {
        initShepherd() {
            this.startShepherd();
        },
        startShepherd() {
            if (this.shepherdTour) {
                this.shepherdTour.complete();
                this.shepherdTour = null;
            }

            if (!this.$store.getters["tour/showTour"]) {
                return;
            }

            const screenTour = getScreenTour(this.$route.name, this.$route.params);

            if (!screenTour) {
                return;
            }

            if (this.isTourShowed(screenTour)) {
                return;
            }

            this.shepherdTour = this.$shepherd({
                useModalOverlay: true,
                defaultStepOptions: {
                    scrollTo: { behavior: "smooth", block: "center" },
                },
            });

            const selectors = [];

            screenTour.steps.forEach((step, index, stepsArray) => {
                let buttons = [];

                if (index === stepsArray.length - 1 && index === 0) {
                    // last step
                    buttons = [
                        {
                            text: this.$t("tour.stop"),
                            classes: "cancel",
                            action: this.stopShepherd,
                        },
                        {
                            text: screenTour.completeText ? screenTour.completeText : this.$t("tour.voltooien"),
                            classes: "ctc",
                            action: this.tempStopShepherd,
                        },
                    ];
                } else if (index === stepsArray.length - 1) {
                    // last step
                    buttons = [
                        {
                            text: this.$t("tour.stop"),
                            classes: "cancel",
                            action: this.stopShepherd,
                        },
                        {
                            text: this.$t("tour.vorige"),
                            classes: "secundary",
                            action: this.shepherdTour.back,
                        },
                        {
                            text: screenTour.completeText ? screenTour.completeText : this.$t("tour.voltooien"),
                            classes: "ctc",
                            action: this.tempStopShepherd,
                        },
                    ];
                } else if (index === 0) {
                    // first
                    buttons = [
                        {
                            text: this.$t("tour.stop"),
                            classes: "cancel",
                            action: this.stopShepherd,
                        },
                        {
                            text: this.$t("tour.volgende"),
                            classes: "primary",
                            action: this.shepherdTour.next,
                        },
                    ];
                } else {
                    buttons = [
                        {
                            text: this.$t("tour.stop"),
                            classes: "cancel",
                            action: this.stopShepherd,
                        },
                        {
                            text: this.$t("tour.vorige"),
                            classes: "secundary",
                            action: this.shepherdTour.back,
                        },
                        {
                            text: this.$t("tour.volgende"),
                            classes: "primary",
                            action: this.shepherdTour.next,
                        },
                    ];
                }

                selectors.push(step.elementSelector);

                const shepherdTour = this.shepherdTour;
                const tipTitlePrefix = this.$t("tour.titel_voorvoegsel");

                this.shepherdTour.addStep({
                    attachTo: {
                        element: document.querySelector(step.elementSelector),
                        on: step.position ? step.position : "bottom",
                    },
                    text: step.text,
                    popperOptions: {
                        modifiers: [{ name: "offset", options: { offset: [0, 12] } }],
                    },
                    buttons: buttons,
                    scrollTo: step.noScroll
                        ? false
                        : { behavior: "smooth", scrollTo: step.scrollTo ? step.scrollTo : "center" },
                    when: {
                        show() {
                            const stepEl = shepherdTour.currentStep.el;
                            const contentEl = stepEl.querySelector(".shepherd-content");
                            const containerEl = document.createElement("div");
                            const titleEl = document.createElement("div");
                            titleEl.classList.add("tip-title");
                            titleEl.innerText = `${tipTitlePrefix} ${step.title}`;
                            const counterEl = document.createElement("div");
                            counterEl.classList.add("tip-counter");
                            counterEl.innerText = `${index + 1}/${screenTour.steps.length}`;
                            containerEl.classList.add("custom-tip-header");
                            contentEl.insertBefore(containerEl, contentEl.firstChild);
                            containerEl.insertBefore(counterEl, containerEl.firstChild);
                            containerEl.insertBefore(titleEl, containerEl.firstChild);
                        },
                    },
                });
            });
            const showedTour = { route: screenTour.route };

            if (screenTour.params) {
                showedTour.params = screenTour.params;
            }

            this.$store.dispatch("tour/setShowedTour", showedTour);

            let intervalCounter = 0;

            let checkInterval = setInterval(() => {
                if (intervalCounter === 10) {
                    clearInterval(checkInterval);
                    console.error("One of the tour selectors is not found");
                    return;
                }

                if (this.checkSelectors(selectors)) {
                    this.shepherdTour.start();
                    setTimeout(() => {
                        window.dispatchEvent(new Event("resize"));
                    }, 500);
                    setTimeout(() => {
                        window.dispatchEvent(new Event("resize"));
                    }, 1000);

                    clearInterval(checkInterval);
                }

                intervalCounter++;
            }, 500);
        },
        checkSelectors(selectors) {
            let allReady = true;
            selectors.forEach(selector => {
                if (!document.querySelector(selector)) {
                    allReady = false;
                }
            });

            return allReady;
        },
        stopShepherd() {
            this.shepherdTour.complete();

            this.$store.dispatch("tour/setShowTour", false);
            saveCurrentState();
        },
        tempStopShepherd() {
            this.shepherdTour.complete();
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
