<template>
    <div>
        <main>
            <bldc-grid-column col="12">
                <bldc-heading level="1" id="page-title">{{ currentTitle }}</bldc-heading>
            </bldc-grid-column>
            <div v-if="conflicts" @click="openModal()" class="modal-button" @keydown.enter="openModal()">
                {{ modalButtonText }}
            </div>
            <json-form
                ref="forms"
                :json-form="$route.params.form"
                :static="statics"
                :statics="statics"
                :mutated-values="$store.getters['rgsLedger/values']"
                :mutated-values-until="$store.getters['rgsLedger/getMutationsUntil']($route.params.form + 'Form')"
            ></json-form>
        </main>
        <div style="z-index: 2;">
            <bldc-modal
                v-if="conflicts"
                :visible="conflictsToggle"
                id="conflictConfirm"
                v-on:bldcModalClose="closeModal()"
                :buttons="JSON.stringify([{ type: 'primary', label: 'Sluiten', event: 'close' }])"
                modalId="conflictConfirm"
            >
                <bldc-heading level="3">
                    {{ $t("forms.veranderingen_in_de_vooringevulde_waarden") }}
                </bldc-heading>
                <bldc-grid-row :containerColumns="12">
                    <bldc-grid-column col="6">
                        <strong>{{ $t("forms.veld") }}</strong>
                    </bldc-grid-column>
                    <bldc-grid-column col="3">
                        <strong>{{ $t("forms.oude_waarde") }}</strong>
                    </bldc-grid-column>
                    <bldc-grid-column col="3">
                        <strong>{{ $t("forms.nieuwe_waarde") }}</strong>
                    </bldc-grid-column>
                </bldc-grid-row>
                <bldc-grid-row
                    :containerColumns="12"
                    v-for="(conflict, index) in conflicts"
                    :key="index"
                    style="margin-bottom: 5px;"
                >
                    <bldc-grid-column col="6">
                        {{ conflict.hiddenLabel || conflict.label }}
                    </bldc-grid-column>
                    <bldc-grid-column col="3">
                        {{ conflict.oldValue || $t("forms.leeg") }}
                    </bldc-grid-column>
                    <bldc-grid-column col="3">
                        {{ conflict.newValue || $t("forms.leeg") }}
                    </bldc-grid-column>
                </bldc-grid-row>
            </bldc-modal>
        </div>
    </div>
</template>

<script>
import jsonForm from "../Form";
import getStatics from "../modules/singletons/statics";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
import { mapGetters } from "vuex";
import shepherd from "../mixins/shepherd";
import Vue from "vue";
import isNumber from "lodash/isNumber";
export default {
    name: "froms",
    mixins: [shepherd],
    components: {
        jsonForm,
    },
    data() {
        return {
            statics: getStatics(),
            conflictsToggle: true,
            saveAndLeaveToggle: false,
        };
    },
    computed: {
        ...mapGetters({
            preSave: "save/preSave",
        }),
        modalButtonText() {
            if (this.conflicts) {
                return "Formulier meldingen (" + this.conflicts.length + ")";
            }
            return 0;
        },
        currentTitle() {
            const currentFormObject = this.$store.getters["navigation/currentFormObject"](this.$route.params.form);

            if (currentFormObject) {
                return currentFormObject.label;
            }

            return this.$t("forms.fallback_titel");
        },
        invalidAccountancyButton() {
            const currentFormObject = this.$store.getters["navigation/currentFormObject"](this.$route.params.form);

            if (currentFormObject) {
                return currentFormObject.invalidAccountancyButton || false;
            }

            return false;
        },
        conflicts() {
            const conflicts = this.$store.getters["formValues/formConflicts"](this.$route.params.form);
            if (!conflicts || isEmpty(conflicts)) {
                return null;
            }

            let conflictsArray = [];

            forEach(conflicts, conflict => {
                if (conflict.fields) {
                    forEach(conflict.fields, groupFields => {
                        forEach(groupFields.groupChanges, groupField => {
                            const groupConflictObject = {};
                            groupConflictObject.label = this.replaceLabelVariables(groupField.field.label);
                            groupConflictObject.hiddenLabel = this.replaceLabelVariables(groupField.field.hiddenLabel);
                            groupConflictObject.oldValue = groupField.filledValue;
                            groupConflictObject.newValue = groupField.newPrefilledValue;
                            conflictsArray.push(groupConflictObject);
                        });
                    });
                    // is group
                    return;
                }

                conflictsArray.push({
                    label: this.replaceLabelVariables(conflict.field.label),
                    hiddenLabel: this.replaceLabelVariables(conflict.field.hiddenLabel),
                    oldValue: conflict.filledValue,
                    newValue: conflict.newPrefilledValue,
                });
            });

            return conflictsArray;
        },
    },
    methods: {
        closeModal: function() {
            this.conflictsToggle = false;
        },
        openModal: function() {
            this.conflictsToggle = true;
        },
        save: function() {
            this.$refs.forms.submit(true);
        },
        replaceLabelVariables(label) {
            if (!label) {
                return;
            }
            const labelVariables = label.match(/#{\s*[\w-[\].]+\s*}/g);

            if (!labelVariables) {
                return label;
            }

            let newLabel = label;

            labelVariables.forEach(variable => {
                variable = variable.replace("#{", "").replace("}", "");

                if (typeof this.statics[variable] !== "undefined") {
                    let staticValue = this.statics[variable];

                    if (isNumber(staticValue) && !variable.startsWith("BDRGJAAR")) {
                        const newStaticValue = staticValue
                            .toFixed(0)
                            .replace(/,/g, "")
                            .replace(/\./g, ",")
                            .split(",");

                        newStaticValue[0] = newStaticValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                        staticValue = String(newStaticValue.join());
                    }
                    newLabel = newLabel.replace("#{" + variable + "}", staticValue);
                } else {
                    newLabel = newLabel.replace("#{" + variable + "}", "[..]");
                }
            });

            return newLabel;
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch("navigation/setCurrentItem", `forms`);
        });
    },
    watch: {
        "$route.params.form": function() {
            Vue.nextTick(() => {
                this.initShepherd();
            });

            this.conflictsToggle = true;
        },
        preSave: function(newValue) {
            if (newValue) {
                this.save();
                this.$store.dispatch("save/preSave", false);
            }
        },
    },
    mounted: function() {
        this.$nextTick(() => {
            this.initShepherd();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.forms.removeDocumentEvents();
        next();
    },
};
</script>

<style lang="scss">
.forms {
    background-color: #ffffff;
}
.modal-button {
    background-color: rgb(255, 255, 255);
    color: rgb(1, 104, 155);
    border: 0.0625rem solid rgb(143, 202, 231);
    transition: border 0.4s ease 0s;
    font-family: RObold, Verdana, Arial, sans-serif;
    font-weight: 400;
    cursor: pointer;
    white-space: normal;
    cursor: pointer;
    position: relative;
    padding: 0px;
    text-align: center;
    width: calc(100% - 18px);
    padding: 0.5rem;
    margin-bottom: 10px;
}
</style>
