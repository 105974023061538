import component from "./formGenerator.vue";
import schema from "../modules/utils/schema";
import validators from "../modules/utils/validators.js";
import fieldComponents from "../modules/utils/fieldsLoader";
import abstractField from "../fields/abstractField";

const install = (Vue, options) => {
    Vue.component("VueFormGenerator", component);
    if (options && options.validators) {
        for (let key in options.validators) {
            if ({}.hasOwnProperty.call(options.validators, key)) {
                validators[key] = options.validators[key];
            }
        }
    }
};

export default {
    component,
    schema,
    validators,
    abstractField,
    fieldComponents,
    install,
};
