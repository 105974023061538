export const PATH_TO_DATA_DIRECTORY = "data";
export const PATH_TO_FORM_NAVIGATION_JSON = "static";
export const PATH_TO_HELP_NAVIGATION_JSON = "static";
export const PATH_TO_SLIDES_JSON = "static";
export const PATH_TO_TAX_CHECK_NAVIGATION_JSON = "static";
export const PATH_TO_RGS_MAPPING_JSON = "static";
export const PATH_M_BMG_AWA_DATA_JSON = "static";
export const PATH_FILTER_RGS_JSON = "static";
export const PATH_ERRORS_JSON = "static";
export const PATH_TO_YEAR_OVERVIEW_JSON = "static";
export const PATH_APPLICATION_JSON = "static";
export const PATH_TO_HELP_TEXTS = "help/olav";
export const PATH_TO_CUSTOM_HELP_TEXTS = "help/custom";
export const PATH_TO_CONSTANTS_FILE = "static";
export const PATH_TO_TOUR_FILE = "static";

export const FORM_NAVIGATION_JSON_FILE_NAME = "form-navigation.json";
export const HELP_NAVIGATION_JSON_FILE_NAME = "custom-navigation.json";
export const SLIDES_JSON_FILE_NAME = "slides.json";
export const TAX_CHECK_NAVIGATION_FILE_NAME = "tax-check-navigation.json";
export const RGS_MAPPING_FILE_NAME = "rgs-mapping.json";
export const M_BMG_AWA_DATA_FILE_NAME = "m-bmg-awa-data.json";
export const FILTER_RGS_FILE_NAME = "filter-rgs.json";
export const APPLICATION_FILE_NAME = "application.json";
export const ERRORS_FILE_NAME = "errors.json";
export const YEAR_OVERVIEW_FILE_NAME = "year-overview.json";
export const HELP_TEXTS_FILE_NAME = "list.json";
export const CUSTOM_HELP_TEXTS_FILE_NAME = "list.json";
export const CONSTANTS_FILE_FILE_NAME = "rhvariable.apj";
export const TOUR_FILE_NAME = "tour.json";

export const APP_MODE = process.env.NODE_ENV;

export const PATH_TO_FONT_DIRECTORY = "fonts";
export const ICON_FONT_FILE_NAME = "bldiconfont.woff";
export const RO_REGULAR_FONT_FILE_NAME = "ROsanswebtextregular.woff";
export const RO_ITALIC_FONT_FILE_NAME = "ROsanswebtextitalic.woff";
export const RO_BOLD_FONT_FILE_NAME = "ROsanswebtextbold.woff";

export const PATH_TO_CONTENT = "data/content";

export const YEAR = 2019;
export const DOWNLOAD_FILE_NAME = "automatische-winstaangifte-2019.xml";

export const RGS_LEDGER_VERSION = "2.x";
export const RGS_VERSION = "3.x";

// Used in AWA XML generation
export let messageData = {
    117271: 111111110, // IdentNrAangever
    117245: 2020, // AangifteBelastingjaar (fallback will be overwritten by BDRGJAAR)
    641753: "AABB", // NaamApplicatie
};

export const setMessageData = values => {
    messageData[117271] = values["IdentNrAangever"];
    messageData[641753] = values["NaamApplicatie"];
};

export let appConstants = {};

export const setAppConstants = values => {
    appConstants["version"] = values["version"];
    appConstants["introText"] = values["introText"];
};
