export default date => {
    const reDate = new RegExp("^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)[0-9]{4}$");
    const reGregDate = new RegExp("^[0-9]{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$");
    const reYear = new RegExp("^[0-9]{4}$");

    if (reDate.test(date)) {
        const splitDate = date.split("-");
        return splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    } else if (reYear.test(date) || reGregDate.test(date)) {
        return date;
    }

    console.error("Date " + date + " is not a valid dd-mm-jjjj date notations.");
};
