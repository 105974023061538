import hlpmwpontautpercbijttoegel from "./hlpmwpontautpercbijttoegel";
import hlpmwpontautpercbijttenaam from "./hlpmwpontautpercbijttenaam";
// import statics from "../../singletons/statics";

const hlpmwpontautpercbijt = (
    wpontautpercbijt,
    wpontauttoegel,
    wpontautbouw,
    wpontautstandcata,
    wpontauttenaamgest,
    wpontautzeroemissie,
    wpontautwaterstof,
) => {
    if (wpontauttoegel === 1 || wpontautbouw === 2) {
        return hlpmwpontautpercbijttenaam(wpontauttenaamgest, wpontautbouw, wpontautpercbijt, wpontautstandcata);
    }
    if (wpontauttoegel === 2 || wpontauttoegel === 3 || wpontauttoegel === 4 || wpontauttoegel === 5) {
        return hlpmwpontautpercbijttoegel(wpontauttoegel, wpontautzeroemissie, wpontautwaterstof, wpontautstandcata);
    }
};
export default hlpmwpontautpercbijt;
