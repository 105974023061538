<template>
    <div class="footer-navigation">
        <bldc-button
            v-if="nextRoute"
            type="primary"
            @click="clickNext"
            :elem-title="$t('global.volgende')"
            :disabled="buttonDisabled"
            style="float: right; margin-left: 1rem;"
        ></bldc-button>
        <bldc-button
            v-if="navPrev"
            type="secondary"
            @click="confirmPrev"
            :elem-title="$t('global.vorige')"
            style="float: right;"
        ></bldc-button>
        <!-- <bldc-modal
            :visible="prevConfirm"
            id="prevConfirm"
            v-on:bldcModalConfirm="$emit('click-prev')"
            v-on:bldcModalClose="modalClose"
            :buttons="
                JSON.stringify([
                    { type: 'secondary', label: $t('global.annuleren'), event: 'close' },
                    {
                        type: 'primary',
                        label: $t('global.ja'),
                        event: 'confirm',
                    },
                ])
            "
            modalId="prevConfirm"
        >
            <bldc-paragraph>
                {{ $t("forms.ingevulde_waardes_gaan_verloren_weet_u_het_zeker") }}
            </bldc-paragraph>
        </bldc-modal> -->
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "forms-footer-navigation",
    mixins: [],
    props: {
        buttonDisabled: Boolean,
        isChanged: Boolean,
    },
    components: {},
    data() {
        return {
            prevConfirm: false,
        };
    },
    computed: {
        ...mapGetters({
            prevRoute: "navigation/prevRoute",
            nextRoute: "navigation/nextRoute",
        }),
    },
    methods: {
        navNext: function() {
            if (this.nextRoute.routeParams) {
                this.$router.push({ name: this.nextRoute.routeName, params: this.nextRoute.routeParams });
            } else {
                this.$router.push({ name: this.nextRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.nextRoute.routeName);
        },
        navPrev: function() {
            this.prevConfirm = false;
            if (this.prevRoute.routeParams) {
                this.$router.push({ name: this.prevRoute.routeName, params: this.prevRoute.routeParams });
            } else {
                this.$router.push({ name: this.prevRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.prevRoute.routeName);
        },
        confirmPrev: function() {
            this.$emit("click-prev");
            // if (!this.isChanged) {
            //     this.$emit("click-prev");
            // } else {
            //     this.prevConfirm = true;
            // }
        },
        clickNext: function() {
            if (!this.buttonDisabled) {
                this.$emit("click-next");
            }
        },
        modalClose: function() {
            this.prevConfirm = false;
        },
    },
    created() {},
};
</script>
<style lang="scss">
.footer-navigation {
    padding-top: 0.2rem;
    margin-bottom: 3.5rem;
}
</style>
