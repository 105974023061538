let statusFn;

export const setStatusFn = status => {
    statusFn = status;
};

export const fireStatus = status => {
    if (statusFn) {
        statusFn(status);
    }
};
