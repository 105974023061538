import errorMessage from "./messages";
import isInt from "validator/lib/isInt";

export default max => {
    return value => {
        const errors = [];

        if (!isInt(value + "") && value <= max) {
            errors.push(errorMessage("not_integer_with_max", { max: max }));
        }

        return errors;
    };
};
