// Constant BDRGJAAR
const hlpmpontautnaambegin60mndherl = (wpontautnaambegin60mnd, BDRGJAAR) => {
    let result = 0;
    // calculating with months (1=jan, 2=feb etc.)
    if (wpontautnaambegin60mnd < 12) {
        result = new Date(BDRGJAAR, wpontautnaambegin60mnd, 1);
    } else if (wpontautnaambegin60mnd == 12) {
        result = new Date(BDRGJAAR + 1, 0, 1);
    }
    return result;
};
export default hlpmpontautnaambegin60mndherl;
