import Vue from "vue";

export default {
    namespaced: true,
    state: () => ({
        items: [],
    }),
    mutations: {
        setItems(state, items) {
            Vue.set(state, "items", items);
        },
    },
    actions: {
        setItems({ commit }, items) {
            commit("setItems", items);
        },
    },
    getters: {
        items: state => state.items,
    },
};
