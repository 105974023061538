let messages = {
    incorrect_characters: "U hebt onjuiste karakters ingevoerd, voer alleen cijfers in.",
    no_decimals: "U hebt cijfers achter de komma ingevuld. Vul alleen hele getallen in. U mag afronden in uw voordeel.",
    bedrag_pos13_greater_than_zero: "Bedrag mag niet negatief zijn.",
    date_invalid_notation: "U hebt een ongeldige datum ingevuld. Vul de datum in op de volgende manier: dd-mm-jjjj.",
    date_after_before_date:
        "U hebt een datum ingevuld die na {beforeDate} ligt. Vul een datum in die vóór of op {beforeDate} ligt.",
    date_before_after_date: "Vul een datum in die op of ná {afterDate} ligt.",
    license_plate_invalid: "Dit is geen geldig Nederlands kenteken.",
    percentage_three_greater_than_100: "U hebt een verkeerde waarde ingevuld, vul maximaal 100 in.",
    percentage_three_less_than_0: "U hebt een verkeerde waarde ingevuld, vul minimaal 0 in.",
    report_number_invalid:
        "U hebt een onjuist meldnummer ingevuld. Een meldnummer bestaat uit een {character} met 9 cijfers zonder spaties.",
    required_empty: "U hebt {fieldLabel} nog niet ingevuld.",
    year_invalid_notation: "U hebt een ongeldig jaar ingevuld. Vul het jaar in op de volgende manier: jjjj.",
    year_after_before_date:
        "U hebt een jaar ingevuld die na {beforeYear} ligt. Vul een jaar in die vóór of op {beforeYear} ligt.",
    year_before_after_date: "Vul een jaar in die op of ná {afterYear} ligt.",
    alpha_numerical_invalid_max_length:
        "U hebt een onjuiste waarde ingevoerd, voer maximaal {lenght} {requiredValue} in.",
    alpha_numerical_invalid_required_length:
        "U hebt een onjuiste waarde ingevoerd, voer exact {lenght} {requiredValue} in.",
    enum_value_not_exists: "U hebt een onjuiste waarde ingevoerd, voer een van de volgende waardes in: {values}.",
    not_integer_with_max: "De waarde dient een integer te zijn met een maximum van {max}.",
    gregorian_date_invalid_notation:
        "U hebt een ongeldige datum ingevuld. Vul de datum in op de volgende manier: jjjj-mm-dd.",
    required_tag_does_not_exists: "Element met tag {name} is verplicht.",
    required_value_does_not_exists: "Element met tag {name} is heeft geen waarde.",
    xml_element_has_errors: "Element {name} Fouten: {errors}.",
    both_report_numbers_invalid:
        "U hebt een onjuist meldnummer ingevuld. Een meldnummer bestaat uit een E of M met 9 cijfers zonder spaties.",
    xml_unexpected_tags_found: "Element(en) met tags {names} is/zijn niet toegestaan.",
    bedrag_pos13_no_decimals:
        "U hebt cijfers achter de komma ingevuld. Vul alleen hele getallen in. U mag afronden in uw voordeel.",
    ecg_xml_validation_errors: "Het ECG XML document is niet valide. Fouten: {errors}.",
    greater_or_equals_zero: "De waarde moet groter of gelijk zijn aan 0.",
};

const messageMapping = {
    bedrag_pos13_incorrect_characters: "incorrect_characters",
    bedrag13_incorrect_characters: "incorrect_characters",
    hours_of_cooperation_partner_incorrect_characters: "incorrect_characters",
    percentage_three_incorrect_characters: "incorrect_characters",

    bedrag_pos13_no_decimals: "no_decimals",
    bedrag13_no_decimals: "no_decimals",
};

export const setCustomValidatorMessages = customMessages => {
    // merge the custom messages
    messages = Object.assign(messages, customMessages);

    // map the messages to the duplicate messages in messageMapping
    const entries = Object.entries(messageMapping);

    for (const [key, value] of entries) {
        // Replace all keys with values
        messages[key] = messages[value];
    }

    // merge the messages again, so duplicate messages can be set specific
    messages = Object.assign(messages, customMessages);
};

export default (key, parameters) => {
    let message = "Validation error message not fount.";

    if (typeof messages[key] === "undefined") {
        console.error("Error message key: '" + key + "' not found.");
    }

    message = messages[key];

    if (parameters) {
        const entries = Object.entries(parameters);

        for (const [key, value] of entries) {
            // Replace all keys with values
            message = message.split("{" + key + "}").join(value);
        }
    }

    return message;
};
