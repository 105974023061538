import formulas from "./formulas";
import getStatics from "../singletons/statics";

const formulaEntryHandler = (model, field, formulaEntry, staticData, showValueWarnings = true) => {
    let result = true;
    let hasPassed = false;

    if (!formulaEntry.terms && typeof formulas[formulaEntry.formula] === "undefined") {
        if (field.model) {
            console.warn(
                "Formula: " + formulaEntry.formula + " does not exists, used in field with model: " + field.model,
            );
        }
        if (field.refid) {
            console.warn(
                "Formula: " + formulaEntry.formula + " does not exists, used in field with refid: " + field.refid,
            );
        }
    }

    if (formulaEntry.terms) {
        // Formula entry has child formulas
        formulaEntry.terms.forEach(formulaEntryChild => {
            if (!formulaEntryHandler(model, field, formulaEntryChild, staticData)) {
                result = false;
            } else if (formulaEntryChild.condition === "or") {
                hasPassed = true;
            }
        });
    } else if (!formulas[formulaEntry.formula](model, field, formulaEntry.parameters, staticData, showValueWarnings)) {
        result = false;
    } else if (formulaEntry.condition === "or") {
        hasPassed = true;
    }

    return hasPassed ? hasPassed : result;
};

const formulaHandler = (model, field, conditions, staticData, formulaEntryProperty, showValueWarnings = true) => {
    let result = true;
    let hasPassed = false;

    conditions[formulaEntryProperty].forEach(formulaEntry => {
        if (!formulaEntryHandler(model, field, formulaEntry, staticData, showValueWarnings)) {
            // hide field because formula said so
            result = false;
        } else if (formulaEntry.condition === "or") {
            // if the formula says it should show, check if the condition has "or" and exit function
            result = true;
            hasPassed = true;
            return;
        }
    });

    return hasPassed ? hasPassed : result;
};

export default formulaHandler;

export const navigationConditionsHandler = (values, conditions) => {
    return formulaHandler(
        values,
        {},
        {
            navConditions: conditions,
        },
        getStatics(),
        "navConditions",
        false,
    );
};
