import VueRouter from "vue-router";
import Vue from "vue";
import Intro from "./views/Intro";
import YearOverview from "./views/YearOverview";
import TaxProfit from "./views/TaxProfit";
import Forms from "./views/Forms";
import Custom from "./views/Custom";
import Licenses from "./views/Licenses";
// import TaxChecks from "./views/TaxChecks";
// import Check from "./views/Check";
import Download from "./views/Download";
import { loadLanguageAsync } from "@/locale";

Vue.use(VueRouter);

const routes = [
    { name: "intro", path: "/", component: Intro },
    { name: "year-overview", path: "/year-overview/:sheet", component: YearOverview },
    { name: "download", path: "/download", component: Download },
    { name: "tax-profit", path: "/check/tax-profit", component: TaxProfit },
    { name: "check", path: "/check/:sheet", component: YearOverview },
    { name: "form", path: "/form/:form", component: Forms },
    { name: "licenses", path: "/licenses", component: Licenses },
    { name: "custom", path: "/:slug", component: Custom },
];

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: "smooth" });
    },
});

router.beforeEach((to, from, next) => {
    loadLanguageAsync("nl").then(() => {
        next();
    });
});

router.afterEach(() => {
    //go-to-main-content
    let mainContentEl = document.getElementById("go-to-main-content");
    if (mainContentEl) {
        mainContentEl.focus();
    }
});

export default router;
