import Vue from "vue";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import removeObservers from "../modules/utils/removeObservers";

export default {
    namespaced: true,
    state: () => ({
        showTour: null,
        showedTours: [],
    }),
    mutations: {
        setShowTour(state, showTour) {
            Vue.set(state, "showTour", showTour);
        },
        setShowedTours(state, showedTours) {
            Vue.set(state, "showedTours", showedTours);
        },
    },
    actions: {
        setShowTour({ commit }, showTour) {
            if (showTour === false) {
                commit("setShowedTours", []);
            }

            commit("setShowTour", showTour);
        },
        setShowedTours({ commit }, showedTours) {
            commit("setShowedTours", showedTours);
        },
        setShowedTour({ commit, getters }, showedTour) {
            const showedTours = removeObservers(getters.showedTours);
            if (!getters.isTourShowed(showedTour)) {
                showedTours.push(showedTour);
            }
            commit("setShowedTours", showedTours);
        },
    },
    getters: {
        showTour: state => state.showTour,
        showedTours: state => state.showedTours,
        isTourShowed: state => {
            return tour => {
                return find(state.showedTours, showedTour => {
                    if (showedTour.route !== tour.route) {
                        return false;
                    }

                    if (!tour.params && !showedTour.params) {
                        return true;
                    }

                    if (tour.params && isEmpty(tour.params) && showedTour.params && isEmpty(showedTour.params)) {
                        return true;
                    }

                    if (isEqual(tour.params, showedTour.params)) {
                        return true;
                    }

                    return false;
                });
            };
        },
    },
};
