const wpontautpercbijtValues = (wpontautnaamg, wpontautnaamgeerste) => {
    let selectedIds = [];
    let selectedValues = [];
    const allValues = [
        { name: "0%", id: 1 },
        { name: "4%", id: 2 },
        { name: "7%", id: 3 },
        { name: "14%", id: 4 },
        { name: "15%", id: 5 },
        { name: "20%", id: 6 },
        { name: "21%", id: 7 },
        { name: "22%", id: 9 },
        { name: "25%", id: 8 },
    ];
    if (wpontautnaamg === 1 && wpontautnaamgeerste === true) {
        selectedIds = [1, 4, 6, 8];
    } else if (wpontautnaamg === 1 && wpontautnaamgeerste === false) {
        selectedIds = [8];
    } else if (wpontautnaamg === 2 && wpontautnaamgeerste === true) {
        selectedIds = [1, 4, 6, 8];
    } else if (wpontautnaamg === 2 && wpontautnaamgeerste !== true) {
        selectedIds = [8];
    } else if (wpontautnaamg === 2 && wpontautnaamgeerste === true) {
        selectedIds = [1, 4, 6, 8];
    } else if (wpontautnaamg === 3) {
        selectedIds = [1, 4, 6, 8];
    } else if (wpontautnaamg === 4) {
        selectedIds = [2, 3, 4, 6, 8];
    } else if (wpontautnaamg === 5) {
        selectedIds = [2, 3, 4, 6, 8];
    } else if (wpontautnaamg === 6) {
        selectedIds = [2, 5, 7, 8];
    } else if (wpontautnaamg === 7) {
        selectedIds = [2, 9];
    } else if (wpontautnaamg === 8) {
        selectedIds = [2, 9];
    } else {
        selectedIds = [1, 4, 6, 8];
    }

    selectedIds.forEach(selectedId => {
        allValues.forEach(value => {
            if (value.id === selectedId) {
                selectedValues.push(value);
            }
        });
    });
    return selectedValues;
};
export default wpontautpercbijtValues;
