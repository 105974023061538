<template>
    <main>
        <bldc-grid-column col="8" sm="12">
            <bldc-heading level="1" id="page-title">{{ $t("download.klaarzetten_voor_gebruik") }}</bldc-heading>
        </bldc-grid-column>
        <div :class="{ 'invalid-card': invalidCard }" id="download-step-one">
            <bldc-card
                type="content"
                expandable="true"
                expanded="true"
                :elem-title="$t('download.stap_1_download_uw_voorbereide_aangiftegegevens')"
            >
                <div>
                    <json-form
                        v-if="currentFormKey"
                        :json-form="currentFormKey"
                        :hide-form="true"
                        v-on:init-post-data="resolveFormData"
                        :isTaxCheck="isTaxCheck"
                        :static="statics"
                        :statics="statics"
                        :mutated-values="initRgsValues"
                        :mutated-values-until="mutationsUntil"
                        :emit-data="true"
                    ></json-form>
                    <bldc-grid-row :containerColumns="12">
                        <bldc-grid-column col="12">
                            <bldc-paragraph margin="false">
                                <div style="margin-bottom: 0.5rem;">
                                    {{ $t("download.download_bestand_tekst", { year: statics.BDRGJAAR }) }}
                                </div>
                                <bldc-file
                                    v-if="downloaded"
                                    download-only="true"
                                    :file-date="getDownloadedDate"
                                    @click.prevent="download"
                                    :file-name="fileName"
                                    is-removable="false"
                                />
                                <bldc-button
                                    v-if="!downloaded"
                                    type="cta"
                                    :elem-title="$t('download.bestand_downloaden')"
                                    @click.prevent="download"
                                ></bldc-button>
                            </bldc-paragraph>
                        </bldc-grid-column>
                    </bldc-grid-row>
                </div>
            </bldc-card>
        </div>
        <div v-if="invalidCard" style="margin-bottom: 0.5rem;">
            <bldc-validation elem-title="">
                <bldc-paragraph disable-margin="true" size="s">
                    {{ $t("download.error_bestand_niet_gedownload") }}
                </bldc-paragraph>
            </bldc-validation>
        </div>
        <div :class="{ 'minimal-opacity': !downloaded }">
            <bldc-card
                type="content"
                expandable="true"
                expanded="true"
                :elem-title="$t('download.stap_2_doe_aangifte_inkomstenbelasting')"
            >
                <bldc-grid-row :containerColumns="12">
                    <bldc-grid-column col="12">
                        <bldc-paragraph margin="false">
                            <bldc-notification type="succes" elem-title="">
                                {{ $t("download.bestand_gedownload_tekst", { file: fileName }) }}
                            </bldc-notification>
                            {{ $t("download.bestand_gedownload_info_tekst", { file: fileName }) }}
                        </bldc-paragraph>
                    </bldc-grid-column>
                </bldc-grid-row>
            </bldc-card>
        </div>
        <footer-navigation :show-close="true" v-on:close-plugin="setFinished" />
    </main>
</template>

<script>
import footerNavigation from "../components/footerNavigation";
import JsonForm from "../Form";
import Vue from "vue";
import { getXMLString } from "../modules/utils/xmlGenerator";
import { saveAs } from "file-saver";
import { messageData } from "../data/constants";
import forEach from "lodash/forEach";
import merge from "lodash/merge";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import getStatics from "../modules/singletons/statics";
import removeObservers from "../modules/utils/removeObservers";
import mutate from "../modules/utils/mutate";
import getValuesBeforeValue from "../modules/utils/getValuesBeforeValue";
import { saveCurrentState } from "../modules/singletons/currentState";
import { mapGetters } from "vuex";
import modeLog from "../modules/utils/modeLog";
import liabilities from "../modules/calculations/rgs/liabilities";
import assets from "../modules/calculations/rgs/assets";
import { getMBmgAwaData } from "../modules/singletons/mBmgAwaData";
import equitygrowth from "../modules/calculations/rgs/equitygrowth";
import profit from "../modules/calculations/rgs/profit";
import shepherd from "../mixins/shepherd";
import { fireStatus } from "../modules/singletons/statusFn";

export default {
    name: "download",
    mixins: [shepherd],
    components: {
        footerNavigation,
        JsonForm,
    },
    data() {
        return {
            invalidCard: false,
            loading: false,
            isTaxCheck: false,
            formData: {},
            formKeys: [],
            // taxCheckKeys: [],
            currentFormKey: null,
            currentResolve: null,
            waitForDownload: false,
            mutations: {},
            statics: getStatics(),
        };
    },
    computed: {
        ...mapGetters({
            firstPossibleRoute: "navigation/firstPossibleRoute",
            downloaded: "currentState/downloaded",
            downloadedDate: "currentState/downloadedDate",
            messageData: "formValues/messageData",
            rgsHeaders: "rgsLedger/headers",
        }),
        initRgsValues() {
            return removeObservers(this.$store.getters["rgsLedger/values"]);
        },
        getDownloadedDate() {
            if (this.downloadedDate) {
                return new Date(this.downloadedDate).toLocaleDateString("nl", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                });
            }
            return null;
        },
        fileName() {
            let values = removeObservers(this.$store.getters["formValues/values"]);
            let fileHash = removeObservers(values["wondnaam"]);
            fileHash = fileHash.replace(/[^a-zA-Z0-9 ]+/g, "");
            let fileName = removeObservers(this.messageData[117243]) + "_" + fileHash + "_";

            fileName = fileName.replace(/ +(?= )/g, "");
            fileName = fileName.replaceAll(" ", "-");
            const fileNameLength = fileName.length;

            if (fileName.length > 77) {
                const lengthDiff = 77 - fileNameLength;
                fileHash = fileHash.substring(0, fileHash.length + lengthDiff);
                fileName = removeObservers(this.messageData[117243]) + "_" + fileHash + "_";
                fileName = fileName.replaceAll(" ", "-");
            }

            if (this.downloadedDate) {
                fileName += this.getFileDate(new Date(this.downloadedDate));
            } else {
                fileName += this.getThisMoment(true);
            }

            fileName += ".xml";
            return fileName;
        },
        mutationsUntil() {
            let values = removeObservers(this.$store.getters["rgsLedger/values"]);
            Vue.set(
                this.mutations,
                "rgs_rounding",
                removeObservers(this.$store.getters["rgsLedger/getMutataionsByKey"]("rgs_rounding")),
            );

            const mutationsKeysBefore = getValuesBeforeValue(
                this.currentFormKey + "Form",
                removeObservers(this.$store.getters["rgsLedger/mutationKeys"]),
            );

            forEach(mutationsKeysBefore, mutationsKeyBefore => {
                const mutationValues = removeObservers(this.mutations[mutationsKeyBefore] ?? {});
                values = mutate(values, mutationValues);
            });

            return values;
        },
    },
    methods: {
        getFileDate(date) {
            const calcMonth = date.getMonth() * 1 + 1;
            const month = ("0" + calcMonth).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const year = date.getFullYear();
            const hours = ("0" + date.getHours()).slice(-2);
            const minutes = ("0" + date.getMinutes()).slice(-2);
            const seconds = ("0" + date.getSeconds()).slice(-2);

            return day + "-" + month + "-" + year + "_" + hours + "-" + minutes + "-" + seconds;
        },
        getThisMoment: function(file = false) {
            if (!file) {
                return new Date(Date.now()).toLocaleDateString("nl", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                });
            }

            return this.getFileDate(new Date(Date.now()));
        },
        setFinished: function() {
            if (!this.downloaded) {
                this.invalidCard = true;
                return;
            }
            this.$store.dispatch("currentState/setFinished", true);
            saveCurrentState();

            fireStatus("link_to_belastingdienst_opened");
            window.open(this.$t("download.naar_belastingdienst_knop_url"), "_blank");
        },
        runForm: function() {
            if (this.formKeys.length <= 0) {
                //  && this.taxCheckKeys.length <= 0
                this.isTaxCheck = false;
                this.loading = false;
                return;
            }

            let formKey;
            let keyAffix;

            if (this.formKeys.length > 0) {
                this.isTaxCheck = false;
                formKey = this.formKeys.shift();
                keyAffix = "Form";
            }
            // else if (this.taxCheckKeys.length > 0) {
            //     this.isTaxCheck = true;
            //     formKey = this.taxCheckKeys.shift();
            //     keyAffix = "TaxCheck";
            // }

            new Promise(resolve => {
                this.currentResolve = resolve;
                this.currentFormKey = formKey;
            }).then(formData => {
                if (formData.formKey === this.currentFormKey) {
                    let newFormData = {};
                    newFormData = this.mergeFormData(this.formData, formData.data.postKeyData);

                    Vue.set(this.mutations, this.currentFormKey + keyAffix, formData.data.rgsMutations);

                    Vue.set(this, "formData", newFormData);
                }
                this.currentFormKey = null;
                this.currentResolve = null;
                this.runForm();
            });
        },

        mergeFormData: function(formData, newFormData) {
            const currentFormData = removeObservers(formData);
            const newCurrentFormData = {};
            forEach(newFormData, (newFormDataValue, newFormDataKey) => {
                if (isObject(newFormDataValue)) {
                    if (!isArray(currentFormData[newFormDataKey])) {
                        currentFormData[newFormDataKey] = [];
                    }
                    forEach(newFormDataValue, newFormDataValueGroup => {
                        currentFormData[newFormDataKey].push(newFormDataValueGroup);
                    });
                } else {
                    currentFormData[newFormDataKey] = newFormDataValue;
                }
            });

            forEach(currentFormData, (currentFormDataValue, currentFormDataKey) => {
                if (this.$store.getters["rgsLedger/gelIds"].includes(currentFormDataValue)) {
                    return;
                }
                newCurrentFormData[currentFormDataKey] = currentFormDataValue;
            });

            return newCurrentFormData;
        },

        resolveFormData: function(formData) {
            this.currentResolve(formData);
        },

        download: function() {
            if (this.loading && this.waitForDownload) {
                return;
            }
            const formObjects = removeObservers(this.$store.getters["navigation/handledFormItems"]);

            // const taxCheckObjects = removeObservers(this.$store.getters["navigation/handledTaxCheckItems"]);
            this.formKeys = formObjects.filter(formObject => !formObject.header).map(formObject => formObject.form);
            // this.taxCheckKeys = taxCheckObjects
            //     .filter(taxCheckObject => taxCheckObject.form !== "inleiding")
            //     .map(taxCheckObject => taxCheckObject.form);
            this.loading = true;
            this.waitForDownload = true;
            this.runForm();
        },

        getMutatedData() {
            const formKeys = removeObservers(this.$store.getters["navigation/handledFormItems"]).map(
                formObject => formObject.form + "Form",
            );
            // const taxCheckKeys = removeObservers(this.$store.getters["navigation/handledTaxCheckItems"])
            //     .filter(taxCheckObject => taxCheckObject.form !== "inleiding")
            //     .map(taxCheckObject => taxCheckObject.form + "TaxCheck");

            let values = removeObservers(this.$store.getters["rgsLedger/values"]);
            Vue.set(
                this.mutations,
                "rgs_rounding",
                removeObservers(this.$store.getters["rgsLedger/getMutataionsByKey"]("rgs_rounding")),
            );

            Vue.set(
                this.mutations,
                "process_result",
                removeObservers(this.$store.getters["rgsLedger/getMutataionsByKey"]("process_result")),
            );

            forEach(removeObservers(this.$store.getters["rgsLedger/mutationKeys"]), mutationsKey => {
                if (
                    mutationsKey === "rgs_rounding" ||
                    mutationsKey === "process_result" ||
                    formKeys.includes(mutationsKey)
                ) {
                    // || taxCheckKeys.includes(mutationsKey)
                    // const mutationValues = removeObservers(this.mutations[mutationsKey] ?? {});
                    const mutationValues = removeObservers(
                        this.$store.getters["rgsLedger/getMutataionsByKey"](mutationsKey),
                    );

                    values = mutate(values, mutationValues);
                }
            });

            return values;
        },

        resumeDeclaration: function() {
            if (this.firstPossibleRoute.routeParams) {
                this.$router.push({
                    name: this.firstPossibleRoute.routeName,
                    params: this.firstPossibleRoute.routeParams,
                });
            } else if (this.firstPossibleRoute.routeName !== "download") {
                this.$router.push({ name: this.firstPossibleRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.firstPossibleRoute.routeName);
        },
        getTotalOfGroup(group, field) {
            const formValues = removeObservers(this.formData);
            let total = 0;
            if (isArray(formValues[group])) {
                total = formValues[group].map(o => o[field]).reduce((a, b) => a + b);
            }

            return total;
        },
        logResults: function(mutatedDataToLog) {
            const mutatedData = removeObservers(mutatedDataToLog);
            mutatedData[119788] = this.getTotalOfGroup(108728, 119788, mutatedData);
            mutatedData[118320] = this.getTotalOfGroup(108073, 118320, mutatedData);

            modeLog("liabilities", liabilities(getMBmgAwaData(), mutatedData));
            modeLog("assets", assets(getMBmgAwaData(), mutatedData));
            const calcEquitygrowth = equitygrowth(mutatedData);
            modeLog("equitygrowth", calcEquitygrowth);
            modeLog("result", profit(getMBmgAwaData(), mutatedData));
            let values = removeObservers(this.$store.getters["formValues/values"]);
            const bijtelling = typeof values["wpontauttot"] !== "undefined" ? values["wpontauttot"] : 0;
            modeLog("Bijtelling", bijtelling);
            modeLog("equitygrowth + bijtelling", calcEquitygrowth + bijtelling);
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!vm.$store.getters["navigation/allFormsSet"]) {
                return;
            }
            vm.$store.dispatch("navigation/setCurrentItem", `download`);
        });
    },

    watch: {
        loading: function(loading) {
            if (loading === false && this.waitForDownload) {
                const mutatedData = removeObservers(this.getMutatedData());

                this.logResults(mutatedData);

                this.$store.dispatch("currentState/setDownloaded", true);
                this.$store.dispatch("currentState/setDownloadedDate", new Date(Date.now()).toString());
                saveCurrentState();
                const userMessageData = removeObservers(this.$store.getters["formValues/messageData"]);
                const xmlString = getXMLString(
                    merge(
                        {},
                        { 117245: this.statics.BDRGJAAR },
                        messageData,
                        removeObservers(this.formData),
                        mutatedData,
                        userMessageData,
                    ),
                );
                var blob = new Blob([xmlString], { type: "text/xml" });
                saveAs(blob, this.fileName);
                this.waitForDownload = false;
                this.invalidCard = false;
                fireStatus("file_downloaded");
            }
        },
    },
    mounted: function() {
        this.$nextTick(() => {
            this.initShepherd();
        });
    },
};
</script>

<style lang="scss" scoped="true">
.download-file {
    margin-top: -8px;
    width: 100%;
    clear: both;
    display: block;
    float: left;
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;
    color: rgb(1, 104, 155);
    &:hover {
        color: rgb(21, 66, 115);
    }
    .file-name {
        float: left;
        margin-right: 6px;
    }
    .download-icon {
        display: block;
        margin-top: -3px;
        float: left;
    }
}
.this-moment {
    color: rgb(92, 92, 92);
}
.minimal-opacity {
    opacity: 0.25;
}
.invalid-card {
    border: 1px solid red;
    background-color: white;
}
</style>
