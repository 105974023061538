import isInt from "validator/lib/isInt";
import isDecimal from "validator/lib/isDecimal";
import forEach from "lodash/forEach";
// import filter from "lodash/filter";
import { DOMParser, DOMImplementation } from "xmldom";

const MIN_INPUT_YEAR = 1901;
const MAX_INPUT_YEAR = 2200;

function isYear(value) {
    return isInt(value, { min: MIN_INPUT_YEAR, max: MAX_INPUT_YEAR });
}

export const possibleHeaders = [
    { name: "Timestamp", required: true },
    { name: "Version", required: false },
    { name: "FinancialYear", required: true, validator: isYear },
    { name: "FinancialYearStart", required: false },
    { name: "FinancialYearEnd", required: false },
    { name: "RSIN", required: false },
    { name: "KVKNumber", required: false },
    { name: "VatNumber", required: false },
    { name: "CompanyName", required: true },
    { name: "SoftwarePackage", required: true },
    { name: "EmailaddressReceived", required: true },
    { name: "RgsVersion", required: true },
];

export const possibleRecordElements = [
    { name: "LedgerAccountId", required: true },
    { name: "LedgerDescription", required: true },
    { name: "RgsCode", required: true },
    { name: "ClosingBalance", required: true, validator: isDecimal },
    { name: "OpeningBalance", required: false, validator: isDecimal },
    { name: "ProfitLossLastYear", required: false, validator: isDecimal },
];

/**
 * Get XML document from parsed string or clean document.
 *
 * @param Document|null xmlDoc
 */
export const getDocument = (xmlDoc = null, options = null) => {
    if (xmlDoc !== null) {
        let parser = new DOMParser();
        if (options) {
            parser = new DOMParser(options);
        }
        return parser.parseFromString(xmlDoc, "text/xml");
    }

    // If no document given, return empty document
    return new DOMImplementation().createDocument();
};

export const countDecimals = value => {
    if (value % 1 != 0) return value.toString().split(".")[1].length;
    return 0;
};

/**
 * Validate and parse RGD ledger documents.
 *
 * @param Document xmlDoc
 */
export const parseDocument = (xmlString, errors) => {
    // Check if the input is a string
    if (!xmlString || typeof xmlString !== "string") {
        return errors(102, null, true); // ERROR: RGS ledger parse error, XML is not valid (no string given)
    }

    let parseError = null;

    // Parse error handler
    const parseErrorHandler = function(error) {
        parseError = errors(103, { error: error }, true); // ERROR: RGS ledger parse error, XML is not valid
    };

    const xmlDoc = getDocument(xmlString, {
        errorHandler: {
            warning: parseErrorHandler,
            error: parseErrorHandler,
            fatalError: parseErrorHandler,
        },
    });

    if (parseError) {
        return parseError;
    }

    return { doc: xmlDoc };
};

/**
 * Validate and parse headers from RGS ledger.
 *
 * @param Document xmlDoc
 */
export const parseHeaders = (xmlDoc, rgsConstants, errors) => {
    // Get and check headers
    const headers = xmlDoc.getElementsByTagName(`Header`);

    if (headers.length <= 0 || headers.length > 1) {
        return errors(104, null, true); // ERROR: RGS ledger parse error, Header is not set or set multiple times
    }

    // Process headers
    const header = headers[0];
    let invalidHeader = null;
    const validHeaders = {};

    forEach(possibleHeaders, possibleHeader => {
        const element = header.getElementsByTagName(possibleHeader.name)[0];

        if (!element) {
            if (possibleHeader.required && !invalidHeader) {
                invalidHeader = errors(105, { name: possibleHeader.name }, true); // ERROR: RGS ledger parse error, invalid headers found
            }
            return;
        }

        if (!element.childNodes[0] && !possibleHeader.validator) {
            return;
        }

        if (!element.childNodes[0] && possibleHeader.validator) {
            invalidHeader = errors(105, { name: possibleHeader.name }, true);
        }

        let headerValue;

        if (!invalidHeader) {
            headerValue = element.childNodes[0].nodeValue;

            if (possibleHeader.validator) {
                if (!possibleHeader.validator(headerValue)) {
                    if (!invalidHeader) {
                        invalidHeader = errors(105, { name: possibleHeader.name }, true); // ERROR: RGS ledger parse error, invalid headers found
                    }
                    return;
                }
            }
        }

        if (!invalidHeader && possibleHeader.name === "Version") {
            const semverFromConstant = rgsConstants.rgsLedgerVersion.replace("x", "");
            if (!headerValue.startsWith(semverFromConstant)) {
                invalidHeader = errors(119, { rgsLedgerVersion: rgsConstants.rgsLedgerVersion }, true); // ERROR: RGS ledger parse error, invalid ledger version
            }
        }

        if (!invalidHeader && possibleHeader.name === "RgsVersion") {
            if (!headerValue.startsWith("RGS")) {
                invalidHeader = errors(120, { rgsVersion: "RGS" + rgsConstants.rgsVersion }, true);
            } else {
                const semverFromConstant = "RGS" + rgsConstants.rgsVersion.replace("x", "");

                if (!headerValue.startsWith(semverFromConstant)) {
                    invalidHeader = errors(120, { rgsVersion: "RGS" + rgsConstants.rgsVersion }, true); // ERROR: RGS ledger parse error, invalid RGS version
                }
            }
        }

        if (!invalidHeader && possibleHeader.name === "FinancialYear") {
            if (+headerValue !== rgsConstants.year) {
                invalidHeader = errors(121, { year: rgsConstants.year }, true); // ERROR: RGS ledger parse error, invalid financial year
            }
        }

        if (!invalidHeader && possibleHeader.name === "FinancialYearEnd") {
            if (Date.parse(headerValue) !== Date.parse(rgsConstants.year + "-12-31")) {
                invalidHeader = errors(122, { date: rgsConstants.year + "-12-31" }, true); // ERROR: RGS ledger parse error, invalid financial year end
            }
        }

        validHeaders[possibleHeader.name] = headerValue;
    });

    if (invalidHeader) {
        return invalidHeader;
    }

    return { headers: validHeaders };
};

/**
 * Validate and parse records from RGS ledger.
 *
 * @param Document xmlDoc
 */
export const parseRecords = (xmlDoc, filterRgs, errors) => {
    // Get and check records
    const records = xmlDoc.getElementsByTagName(`Record`);

    if (records.length <= 0) {
        return errors(106, null, true); // ERROR: RGS ledger parse error, no records found
    }

    // Process records
    let invalidRecord = null;

    // Create filter list
    const acceptedRgsCodes = [];
    const declinedRgsCodes = [];
    forEach(filterRgs, (filterRgsObj, filterRgsKey) => {
        if (filterRgsObj.filter) {
            declinedRgsCodes.push(filterRgsKey);
            return;
        }
        acceptedRgsCodes.push(filterRgsKey);
    });

    const validRecords = [];
    let sumAllRecords = 0;
    let sumWRecords = 0;
    let sumBRecords = 0;
    let BEivKapProFound = false;
    let BEivKapProChildFound = false;

    forEach(records, (record, index) => {
        const newRecord = {};

        forEach(possibleRecordElements, possibleRecordElement => {
            const element = record.getElementsByTagName(possibleRecordElement.name)[0];

            if (!element) {
                if (possibleRecordElement.required && !invalidRecord) {
                    invalidRecord = errors(105, { name: possibleRecordElement.name }, true);
                }
                return;
            }

            if (possibleRecordElement.validator && !element.childNodes[0]) {
                if (!invalidRecord) {
                    invalidRecord = errors(107, { name: possibleRecordElement.name, record: index + 1 }, true);
                }
            }

            if (!element || !element.childNodes[0]) {
                return;
            }

            const recordElementValue = element.childNodes[0].nodeValue;

            if (possibleRecordElement.validator) {
                if (!possibleRecordElement.validator(recordElementValue)) {
                    if (!invalidRecord) {
                        invalidRecord = errors(107, { name: possibleRecordElement.name, record: index + 1 }, true);
                    }
                    return;
                }
            }

            newRecord[possibleRecordElement.name] = recordElementValue;
        });

        if (newRecord.ClosingBalance && countDecimals(newRecord.ClosingBalance) > 2) {
            invalidRecord = errors(
                127,
                { rgsCode: newRecord.RgsCode || "(no RGS code found)", record: index + 1, element: "ClosingBalance" },
                true,
            );
        }

        if (newRecord.OpeningBalance && countDecimals(newRecord.OpeningBalance) > 2) {
            invalidRecord = errors(
                127,
                { rgsCode: newRecord.RgsCode || "(no RGS code found)", record: index + 1, balance: "OpeningBalance" },
                true,
            );
        }

        if (newRecord.ProfitLossLastYear && countDecimals(newRecord.ProfitLossLastYear) > 2) {
            invalidRecord = errors(
                127,
                {
                    rgsCode: newRecord.RgsCode || "(no RGS code found)",
                    record: index + 1,
                    balance: "ProfitLossLastYear",
                },
                true,
            );
        }

        // Filter whitelisted RGS and cover debit/credit check
        if (
            !acceptedRgsCodes.includes(newRecord.RgsCode) &&
            !declinedRgsCodes.includes(newRecord.RgsCode) &&
            (+newRecord.ClosingBalance !== 0 || +newRecord.OpeningBalance !== 0)
        ) {
            if (!invalidRecord) {
                invalidRecord = errors(
                    108,
                    { rgsCode: newRecord.RgsCode || "(no RGS code found)", record: index + 1 },
                    true,
                );
            }
        } else if (
            !acceptedRgsCodes.includes(newRecord.RgsCode) &&
            declinedRgsCodes.includes(newRecord.RgsCode) &&
            (+newRecord.ClosingBalance !== 0 || +newRecord.OpeningBalance !== 0)
        ) {
            if (!invalidRecord) {
                invalidRecord = errors(
                    108,
                    { rgsCode: newRecord.RgsCode || "(no RGS code found)", record: index + 1 },
                    true,
                );

                const extendedInvalidRecord = { ...invalidRecord };

                // Add more details to error
                extendedInvalidRecord.error.rgsReason = {
                    ledgerAccountId: newRecord.LedgerAccountId,
                    ledgerDescription: newRecord.LedgerDescription,
                    rgsCode: newRecord.RgsCode,
                    reason: filterRgs[newRecord.RgsCode].reason,
                };

                invalidRecord = extendedInvalidRecord;
            }
        } else if (
            !acceptedRgsCodes.includes(newRecord.RgsCode) &&
            !declinedRgsCodes.includes(newRecord.RgsCode) &&
            (+newRecord.ClosingBalance === 0 || +newRecord.OpeningBalance === 0)
        ) {
            return;
        } else {
            if (filterRgs[newRecord.RgsCode].cover && !invalidRecord) {
                const errorObject = {
                    value: Number(newRecord.ClosingBalance).toFixed(2) * 1,
                    debit_credit: "credit",
                    rgsCode: newRecord.RgsCode,
                    record: index + 1,
                };

                if (filterRgs[newRecord.RgsCode].debit_credit === "C" && Number(newRecord.ClosingBalance) > 0) {
                    invalidRecord = errors(114, errorObject, true);
                }
                if (filterRgs[newRecord.RgsCode].debit_credit === "D" && Number(newRecord.ClosingBalance) < 0) {
                    errorObject.debit_credit = "debet";
                    invalidRecord = errors(114, errorObject, true);
                }
            }
        }

        if (newRecord.RgsCode && newRecord.RgsCode.startsWith("BEivKapPro")) {
            if (newRecord.RgsCode === "BEivKapPro") {
                BEivKapProFound = true;
            } else {
                BEivKapProChildFound = true;
            }

            if (BEivKapProFound && BEivKapProChildFound) {
                invalidRecord = errors(123, { rgsCode: "BEivKapPro" }, true);
            }
        }

        if ((!newRecord.RgsCode || newRecord.RgsCode.length < 10) && !invalidRecord) {
            invalidRecord = errors(116, { rgsCode: newRecord.RgsCode }, true);
        }

        sumAllRecords += Number(newRecord.ClosingBalance).toFixed(2) * 1;
        sumAllRecords = Number(sumAllRecords).toFixed(2) * 1;

        if (!invalidRecord && newRecord.RgsCode.startsWith("W")) {
            sumWRecords += Number(newRecord.ClosingBalance).toFixed(2) * 1;
            sumWRecords = Number(sumWRecords).toFixed(2) * 1;
        }
        if (!invalidRecord && newRecord.RgsCode.startsWith("B")) {
            sumBRecords += Number(newRecord.ClosingBalance).toFixed(2) * 1;
            sumBRecords = Number(sumBRecords).toFixed(2) * 1;
        }

        validRecords.push(newRecord);
    });

    if (invalidRecord) {
        return invalidRecord;
    }

    sumWRecords = sumWRecords * -1;
    sumWRecords = Number(sumWRecords).toFixed(2) * 1;

    // Check if sum of all B ClosingBalance is equal to all W ClosingBalance
    if (Number(sumWRecords).toFixed(2) * 1 !== Number(sumBRecords).toFixed(2) * 1) {
        return errors(117, null, true);
    }

    // Check if sum of all ClosingBalance is 0
    if (sumAllRecords !== 0) {
        return errors(101, null, true);
    }

    return { records: validRecords };
};
