var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main',[_c('bldc-grid-column',{attrs:{"col":"12"}},[_c('bldc-heading',{attrs:{"level":"1","id":"page-title"}},[_vm._v(_vm._s(_vm.$t("introduction.introductie")))])],1),_c('bldc-card',{attrs:{"type":"content","is-mobile":true,"elem-title":_vm.$t('introduction.voorbereiden_ondernemingsgegevens_voor_aangifte_inkomstenbelasting', {
                    year: _vm.statics.BDRGJAAR,
                }),"subtitle":_vm.$store.getters['currentState/hasCurrentState'] && !_vm.$store.getters['currentState/downloaded']
                    ? _vm.$t('introduction.u_hebt_nog_niet_alle_stappen_afgerond')
                    : '',"expandable":"true","expanded":"true"}},[(!_vm.$store.getters['currentState/hasCurrentState'] || !_vm.started)?_c('div',[_c('bldc-notification',{attrs:{"disable-margin":"true","type":"letop","elem-title":_vm.$t('introduction.ondernemingsgegevens_voorbereiden')}},[_c('bldc-paragraph',{attrs:{"margin":false}},[_vm._v(" "+_vm._s(_vm.$t("introduction.nog_niet_begonnen_tekst"))+" ")])],1),_c('div',{staticClass:"button-container"},[_c('bldc-button',{staticStyle:{"float":"right"},attrs:{"type":"primary","elem-title":_vm.$t('introduction.start_met_voorbereiden')},on:{"click":_vm.confirmShowTour}})],1)],1):_vm._e(),(
                    _vm.$store.getters['currentState/hasCurrentState'] &&
                        _vm.started &&
                        !_vm.$store.getters['currentState/downloaded']
                )?_c('div',[_c('bldc-notification',{attrs:{"type":"letop","disable-margin":"true","elem-title":_vm.$t('introduction.de_winstaangifte', {
                            year: _vm.statics.BDRGJAAR,
                        })}},[_c('bldc-paragraph',{attrs:{"margin":false}},[_vm._v(" "+_vm._s(_vm.$t("introduction.nog_niet_alle_stappen_afgerond_tekst"))+" "),_c('div',{staticClass:"button-container",staticStyle:{"margin-top":"10px"}},[_c('bldc-button',{staticStyle:{"float":"right"},attrs:{"type":"primary","elem-title":_vm.$t('global.doorgaan')},on:{"click":_vm.resumeDeclaration}}),_c('bldc-button',{staticStyle:{"float":"right","margin-right":"1rem"},attrs:{"type":"secondary","elem-title":_vm.$t('global.verwijderen')},on:{"click":_vm.confirmDeleteCurrentState}})],1)])],1)],1):_vm._e(),(_vm.$store.getters['currentState/downloaded'])?_c('div',[_c('bldc-notification',{attrs:{"type":"succes","elem-title":_vm.$t('introduction.de_winstaangifte', {
                            year: _vm.statics.BDRGJAAR,
                        })}},[_c('bldc-paragraph',{attrs:{"margin":false}},[_c('span',{staticStyle:{"line-height":"3.2rem"}},[_vm._v(" "+_vm._s(_vm.$t("introduction.alle_stappen_doorlopen_tekst"))+" ")]),_c('div',{staticClass:"button-container"},[_c('bldc-button',{staticStyle:{"float":"right"},attrs:{"type":"secondary","elem-title":_vm.$t('global.verwijderen')},on:{"click":_vm.confirmDeleteCurrentState}}),_c('bldc-button',{staticStyle:{"float":"right","margin-right":"1rem"},attrs:{"type":"primary","elem-title":_vm.$t('global.openen')},on:{"click":_vm.resumeDeclaration}})],1)])],1),(!_vm.$store.getters['currentState/finished'])?_c('bldc-notification',{attrs:{"type":"letop","elem-title":_vm.$t('introduction.vergeet_niet_de_ondernemingsgegevens_nog_up_te_loaden')}},[_c('bldc-paragraph',{attrs:{"margin":false}},[_vm._v(" "+_vm._s(_vm.$t("introduction.klaar_met_voorbereiding_tekst"))+" ")])],1):_vm._e(),(!_vm.$store.getters['currentState/finished'])?_c('bldc-notification',{attrs:{"type":"letop","elem-title":_vm.$t('introduction.maak_gebruik_van_de_speciale_belastingdienst_omgeving')}},[_c('bldc-paragraph',{attrs:{"margin":false}},[_vm._v(" "+_vm._s(_vm.$t("introduction.gegevens_vooringevuld_in_mijn_belastingdienst"))+" ")])],1):_vm._e()],1):_vm._e()])],1),_c('bldc-modal',{attrs:{"visible":_vm.showDeleteConfirm,"id":"showDeleteConfirm","buttons":JSON.stringify([
                { type: 'secondary', label: _vm.$t('global.annuleren'), event: 'close' },
                {
                    type: 'primary',
                    label: _vm.$t('global.ja'),
                    event: 'confirm',
                } ]),"modalId":"showDeleteConfirm"},on:{"bldcModalConfirm":_vm.deleteCurrentState,"bldcModalClose":_vm.notDeleteCurrentState}},[_c('bldc-paragraph',[_vm._v(" "+_vm._s(_vm.$t("introduction.weet_u_zeker_dat_u_de_uw_ingevulde_gegevens_wilt_verwijderen"))+" ")])],1),_c('bldc-modal',{attrs:{"visible":_vm.showTourConfirm,"id":"showTourConfirm","buttons":JSON.stringify([
                { type: 'secondary', label: _vm.$t('tour.rondleiding_overslaan'), event: 'close' },
                {
                    type: 'primary',
                    label: _vm.$t('tour.rondleiding_starten'),
                    event: 'confirm',
                } ]),"modalId":"showTourConfirm"},on:{"bldcModalConfirm":function($event){return _vm.startDeclaration(true)},"bldcModalClose":function($event){return _vm.startDeclaration(false)}}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"max-width":"250px","margin":"0.5rem auto"},attrs:{"src":_vm.$t('tour.start_rondleiding_afbeelding'),"alt":_vm.$t('tour.start_rondleiding_afbeelding_text')}})]),_c('bldc-paragraph',[_vm._v(" "+_vm._s(_vm.$t("tour.start_rondleiding_modal_tekst"))+" ")])],1),_c('handle-forms',{ref:"handleForms",attrs:{"statics":_vm.statics},on:{"file-blob":_vm.saveFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }