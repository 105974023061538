import forEach from "lodash/forEach";
import { getValue } from "./values";

export default (mBmgAwaData, values, errors) => {
    let error = false;
    forEach(mBmgAwaData, (gelIDmBmgAwaData, gelID) => {
        if (error !== false) {
            return;
        }
        const value = getValue(gelID, values);

        if (typeof value !== "undefined" && value !== null && gelIDmBmgAwaData.mask !== null) {
            const checkNumber = Number(gelIDmBmgAwaData.mask.replace(">=", ""));
            if (value < checkNumber) {
                error = errors(109, {
                    gelID: gelID,
                    name: gelIDmBmgAwaData.name,
                    value: value,
                    mask: gelIDmBmgAwaData.mask,
                });
            }
        }
    });

    if (error) {
        return error;
    }

    return true;
};
