import valueFilter from "../../helpers/model/valueFilter";
import usableValue from "../../helpers/validation/usableValue";

export default (model, field, parameters, staticData, showValueWarnings = true) => {
    let result = false;

    if (usableValue(parameters.than, field)) {
        if (!parameters.greater && !parameters.less && !parameters.equalOrGreater && !parameters.equalOrLess) {
            console.warn(
                "CompareValues with parameter 'than' expects one of the following parameters: greater, less, equalOrGreater or equalOrLess. Field with model: " +
                    field.model,
            );
        }

        const than = valueFilter(
            parameters.than,
            staticData,
            model,
            field,
            "Static",
            "Model",
            "RepeatModel",
            showValueWarnings,
        );

        if (usableValue(parameters.greater, field)) {
            result =
                valueFilter(
                    parameters.greater,
                    staticData,
                    model,
                    field,
                    "Static",
                    "Model",
                    "RepeatModel",
                    showValueWarnings,
                ) > than;
        }

        if (usableValue(parameters.less, field)) {
            result =
                valueFilter(
                    parameters.less,
                    staticData,
                    model,
                    field,
                    "Static",
                    "Model",
                    "RepeatModel",
                    showValueWarnings,
                ) < than;
        }

        if (usableValue(parameters.equalOrGreater, field)) {
            result =
                valueFilter(
                    parameters.equalOrGreater,
                    staticData,
                    model,
                    field,
                    "Static",
                    "Model",
                    "RepeatModel",
                    showValueWarnings,
                ) >= than;
        }

        if (usableValue(parameters.equalOrLess, field)) {
            result =
                valueFilter(
                    parameters.equalOrLess,
                    staticData,
                    model,
                    field,
                    "Static",
                    "Model",
                    "RepeatModel",
                    showValueWarnings,
                ) <= than;
        }
    }

    if (parameters.equal && !parameters.to) {
        console.warn(
            "CompareValues with parameter 'equal' expects the 'to' parameter. Field with model: " + field.model,
        );
    }
    if (parameters.to && !parameters.equal) {
        console.warn(
            "CompareValues with parameter 'to' expects the 'equal' parameter. Field with model: " + field.model,
        );
    }
    if (usableValue(parameters.equal, field) && usableValue(parameters.to, field)) {
        result =
            valueFilter(
                parameters.equal,
                staticData,
                model,
                field,
                "Static",
                "Model",
                "RepeatModel",
                showValueWarnings,
            ) ===
            valueFilter(parameters.to, staticData, model, field, "Static", "Model", "RepeatModel", showValueWarnings);
    }

    if (parameters.is && !parameters.not) {
        console.warn("CompareValues with parameter 'is' expects the 'not' parameter. Field with model: " + field.model);
    }
    if (parameters.not && !parameters.is) {
        console.warn("CompareValues with parameter 'not' expects the 'is' parameter. Field with model: " + field.model);
    }
    if (usableValue(parameters.is, field) && usableValue(parameters.not, field)) {
        result =
            valueFilter(
                parameters.is,
                staticData,
                model,
                field,
                "Static",
                "Model",
                "RepeatModel",
                showValueWarnings,
            ) !==
            valueFilter(parameters.not, staticData, model, field, "Static", "Model", "RepeatModel", showValueWarnings);
    }

    return result;
};
