<template>
    <json-form
        v-if="currentFormKey"
        :json-form="currentFormKey"
        :hide-form="true"
        v-on:init-post-data="resolveFormData"
        :isTaxCheck="isTaxCheck"
        :static="statics"
        :statics="statics"
        :mutated-values="initRgsValues"
        :mutated-values-until="mutationsUntil"
        :emit-data="true"
    ></json-form>
</template>

<script>
import JsonForm from "../Form";
import Vue from "vue";
import { getXMLString } from "../modules/utils/xmlGenerator";
import { messageData } from "../data/constants";
import forEach from "lodash/forEach";
import merge from "lodash/merge";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import removeObservers from "../modules/utils/removeObservers";
import mutate from "../modules/utils/mutate";
import getValuesBeforeValue from "../modules/utils/getValuesBeforeValue";

export default {
    name: "handleForms",
    mixins: [],
    components: {
        JsonForm,
    },
    props: ["statics"],
    data() {
        return {
            loading: false,
            isTaxCheck: false,
            formData: {},
            formPostData: {},
            formKeys: [],
            // taxCheckKeys: [],
            currentFormKey: null,
            currentResolve: null,
            waitForDownload: false,
            mutations: {},
        };
    },
    computed: {
        initRgsValues() {
            return removeObservers(this.$store.getters["rgsLedger/values"]);
        },
        mutationsUntil() {
            let values = removeObservers(this.$store.getters["rgsLedger/values"]);
            Vue.set(
                this.mutations,
                "rgs_rounding",
                removeObservers(this.$store.getters["rgsLedger/getMutataionsByKey"]("rgs_rounding")),
            );

            const mutationsKeysBefore = getValuesBeforeValue(
                this.currentFormKey + "Form",
                removeObservers(this.$store.getters["rgsLedger/mutationKeys"]),
            );

            forEach(mutationsKeysBefore, mutationsKeyBefore => {
                const mutationValues = removeObservers(this.mutations[mutationsKeyBefore] ?? {});
                values = mutate(values, mutationValues);
            });

            return values;
        },
    },
    methods: {
        runForm: function() {
            if (this.formKeys.length <= 0) {
                // && this.taxCheckKeys.length <= 0
                this.isTaxCheck = false;
                this.loading = false;
                this.$emit("finished", true);
                return;
            }

            let formKey;
            let keyAffix;

            if (this.formKeys.length > 0) {
                this.isTaxCheck = false;
                formKey = this.formKeys.shift();
                keyAffix = "Form";
            }

            new Promise(resolve => {
                this.currentResolve = resolve;
                this.currentFormKey = formKey;
            }).then(formData => {
                if (formData.formKey === this.currentFormKey) {
                    let newFormData = {};
                    newFormData = this.mergeFormData(this.formData, formData.data.postKeyData);
                    let newFormPostData = {};
                    newFormPostData = this.mergeFormData(this.formPostData, formData.data.postData);

                    this.$emit("form-post-key-data", {
                        formKey: this.currentFormKey,
                        mutations: formData.data.postKeyData,
                    });

                    this.$emit("form-post-data", {
                        formKey: this.currentFormKey,
                        mutations: formData.data.postData,
                    });

                    // console.log("mutations", this.currentFormKey, formData.data.rgsMutations);

                    Vue.set(this.mutations, this.currentFormKey + keyAffix, formData.data.rgsMutations);

                    this.$emit("form-mutations", {
                        formKey: this.currentFormKey,
                        mutations: formData.data.rgsMutations,
                        // isTaxCheck: this.isTaxCheck,
                    });

                    Vue.set(this, "formData", newFormData);
                    Vue.set(this, "formPostData", newFormPostData);
                }
                this.currentFormKey = null;
                this.currentResolve = null;
                this.runForm();
            });
        },

        mergeFormData: function(formData, newFormData) {
            const currentFormData = formData;
            forEach(newFormData, (newFormDataValue, newFormDataKey) => {
                if (isObject(newFormDataValue)) {
                    if (!isArray(currentFormData[newFormDataKey])) {
                        currentFormData[newFormDataKey] = [];
                    }
                    forEach(newFormDataValue, newFormDataValueGroup => {
                        currentFormData[newFormDataKey].push(newFormDataValueGroup);
                    });
                } else {
                    currentFormData[newFormDataKey] = newFormDataValue;
                }
            });
            return currentFormData;
        },

        resolveFormData: function(formData) {
            this.currentResolve(formData);
        },

        download: function() {
            if (this.loading && this.waitForDownload) {
                return;
            }

            Vue.set(this, "loading", false);
            Vue.set(this, "isTaxCheck", false);
            Vue.set(this, "formData", {});
            Vue.set(this, "formPostData", {});
            Vue.set(this, "formKeys", []);
            Vue.set(this, "currentFormKey", null);
            Vue.set(this, "currentResolve", null);
            Vue.set(this, "waitForDownload", false);
            Vue.set(this, "mutations", {});

            const formObjects = removeObservers(this.$store.getters["navigation/formItems"]);
            // const taxCheckObjects = removeObservers(this.$store.getters["navigation/taxCheckItems"]);
            this.formKeys = formObjects.map(formObject => formObject.form);
            // this.taxCheckKeys = taxCheckObjects
            //     .filter(taxCheckObject => taxCheckObject.form !== "inleiding")
            //     .map(taxCheckObject => taxCheckObject.form);
            this.loading = true;
            this.waitForDownload = true;
            this.runForm();
        },

        getMutatedData() {
            let values = removeObservers(this.$store.getters["rgsLedger/values"]);
            Vue.set(
                this.mutations,
                "rgs_rounding",
                removeObservers(this.$store.getters["rgsLedger/getMutataionsByKey"]("rgs_rounding")),
            );

            forEach(removeObservers(this.$store.getters["rgsLedger/mutationKeys"]), mutationsKey => {
                const mutationValues = removeObservers(this.mutations[mutationsKey] ?? {});
                values = mutate(values, mutationValues);
            });

            Vue.set(
                this.mutations,
                "process_result",
                removeObservers(this.$store.getters["rgsLedger/getMutataionsByKey"]("process_result")),
            );

            return values;
        },
    },

    watch: {
        loading: function(loading) {
            if (loading === false && this.waitForDownload) {
                this.$emit("final-post-key-data", this.formData);
                this.$emit("final-post-data", this.formPostData);
                const xmlString = getXMLString(
                    merge(
                        {},
                        messageData,
                        { 117245: this.statics.BDRGJAAR },
                        removeObservers(this.formData),
                        removeObservers(this.getMutatedData()),
                        removeObservers(this.$store.getters["formValues/messageData"]),
                    ),
                );
                var blob = new Blob([xmlString], { type: "text/xml" });
                this.waitForDownload = false;
                this.$emit("file-blob", blob);
            }
        },
    },
};
</script>
