import getStatics from "../../singletons/statics";

export default (model, field, fieldModels) => {
    const statics = getStatics();

    const filtered = fieldModels
        .filter(fieldModel => !!statics[fieldModel])
        .map(fieldModel => statics[fieldModel])
        .filter(value => value >= 0 || value < 0);

    if (filtered.length <= 0) {
        return undefined;
    }

    return filtered.reduce((acc, curr) => (acc.toFixed(2) * 1 - curr.toFixed(2) * 1).toFixed(2) * 1);
};
