import isNumeric from "validator/lib/isNumeric";
import isInt from "validator/lib/isInt";
import errorMessage from "./messages";
import alphaNumericalLength from "./alphaNumericalLength";

export default value => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = alphaNumericalLength("n", 13, false)(value);

    if (!isNumeric(value + "")) {
        errors.push(errorMessage("bedrag13_incorrect_characters"));
    }

    if (!isInt(value + "")) {
        errors.push(errorMessage("bedrag13_no_decimals"));
    }

    return errors;
};
