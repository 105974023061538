import modeLog from "../utils/modeLog";

let jsonForms = {};
let postKeyModel = {};
let modelFields = {};

export const setForm = (key, jsonForm) => {
    jsonForms[key] = jsonForm;
    setPostKeyModelFromForm(jsonForm, key);
};

export const getForm = key => {
    return jsonForms[key];
};

export const getAll = () => {
    return jsonForms;
};

export const getPostKeyModel = (key, group = null) => {
    if (group && postKeyModel[group]) {
        return postKeyModel[group][key];
    }
    return postKeyModel[key];
};

export const getPostKeyModelObject = () => {
    return postKeyModel;
};

export const getModelFieldObject = () => {
    return modelFields;
};

export const setPostKeyModelFromForm = (form, formKey = null) => {
    if (form.schema && form.schema.groups) {
        form.schema.groups.forEach(group => {
            if (group.fields) {
                if (group.repeatModel) {
                    const groupObject = {};
                    const groupFieldObject = {};

                    group.fields.forEach(field => {
                        if (field.postKey && field.model) {
                            if (groupObject[field.postKey] && groupObject[field.postKey] !== field.model) {
                                modeLog(
                                    "Double postKey with different model, use equal postKeys for ECG mapping",
                                    field.postKey,
                                    field.model,
                                    postKeyModel[field.postKey],
                                );
                            }
                            groupObject[field.postKey] = field.model;
                            groupFieldObject[field.model] = field;
                            // console.log(group.repeatModelPostKey ?? group.repeatModel, field.postKey);
                        }
                    });

                    if (
                        group.repeatModelPostKey &&
                        formKey !== "zakelijke-kosten-privedeel" // &&
                        // !postKeyModel[group.repeatModelPostKey] &&
                        // !modelFields[group.repeatModelPostKey]
                    ) {
                        postKeyModel[group.repeatModelPostKey] = groupObject;
                        modelFields[group.repeatModelPostKey] = { formKey: formKey, group: groupFieldObject };
                    } else if (formKey !== "zakelijke-kosten-privedeel") {
                        //if (!postKeyModel[group.repeatModel] && !modelFields[group.repeatModel])
                        postKeyModel[group.repeatModel] = groupObject;
                        modelFields[group.repeatModel] = { formKey: formKey, group: groupFieldObject };
                    }
                } else {
                    group.fields.forEach(field => {
                        if (field.postKey && field.model) {
                            if (postKeyModel[field.postKey] && postKeyModel[field.postKey] !== field.model) {
                                modeLog(
                                    "Double postKey with different model, use equal postKeys for ECG mapping",
                                    field.postKey,
                                    field.model,
                                    postKeyModel[field.postKey],
                                );
                            }
                            postKeyModel[field.postKey] = field.model;
                            modelFields[field.model] = { formKey: formKey, field };
                            // console.log(field.postKey);
                        }
                    });
                }
            }
        });
    }
};
