import dateToGregorianNotation from "../modules/helpers/transformation/dateToGregorianNotation";
import groupModelValue from "../modules/helpers/model/groupModelValue";
import getModelKey from "../modules/helpers/model/getModelKey";
import splitGroupModel from "../modules/utils/splitGroupModel";
import compact from "lodash/compact";
import forEach from "lodash/forEach";
import findKey from "lodash/findKey";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import removeObservers from "../modules/utils/removeObservers";
// import isUndefined from "lodash/isUndefined";

import { getMutationDelta } from "../modules/utils/mutate";

export default {
    methods: {
        manipulatePostData: function() {
            let newPostData = {};
            let newPostKeyData = {};
            let newRgsMutations = {};

            newRgsMutations = this.setValuesOnNewPostData(newRgsMutations, true);

            newPostData = this.setValuesOnNewPostData(newPostData);
            newPostKeyData = this.setValuesOnNewKeyPostData(newPostKeyData);

            newRgsMutations = this.getMutations(newRgsMutations);
            newPostData = this.removeNullabelGroupIndexes(newPostData);
            newPostKeyData = this.removeNullabelGroupIndexes(newPostKeyData);

            newPostKeyData = this.mergePostKeyGroups(newPostKeyData);

            return { postData: newPostData, postKeyData: newPostKeyData, rgsMutations: newRgsMutations };
        },

        isRgsGelId(gelId) {
            return this.$store.getters["rgsLedger/gelIds"].includes(gelId);
        },

        getMutations(newRgsMutations) {
            let values = removeObservers(this.mutatedValues ?? {});
            values = getMutationDelta(values, newRgsMutations);
            return values;
        },

        removeNullabelGroupIndexes(newPostData) {
            const entries = Object.entries(newPostData);

            for (const [key, value] of entries) {
                if (Array.isArray(value) && value.length > 1) {
                    newPostData[key] = compact(value);
                }
            }

            return newPostData;
        },

        setValuesOnNewPostData(newPostData, rgs = false) {
            // run field loop
            this.fieldLoop(field => {
                if (this.includeField(field)) {
                    if (
                        field &&
                        typeof field.originalModel !== "undefined" &&
                        typeof field.repeatableGroup !== "undefined" &&
                        typeof field.repeatableIndex !== "undefined" &&
                        !field.isRadioModel
                    ) {
                        if (
                            (this.isRgsGelId(field.originalModel) && !rgs) ||
                            (!this.isRgsGelId(field.originalModel) && rgs)
                        ) {
                            return;
                        }
                        if (rgs) {
                            return;
                        }

                        if (!newPostData[field.repeatableGroup]) {
                            newPostData[field.repeatableGroup] = [];
                        }

                        if (!newPostData[field.repeatableGroup][field.repeatableIndex]) {
                            newPostData[field.repeatableGroup][field.repeatableIndex] = {};
                        }

                        newPostData[field.repeatableGroup][field.repeatableIndex][
                            field.originalModel
                        ] = this.getFieldValue(field);
                    } else if (
                        field &&
                        typeof field.model !== "undefined" &&
                        field.model.match(/[a-zA-Z0-9]*\[[0-9]\].[a-zA-Z0-9]*/g)
                    ) {
                        const { groupModel, groupModelIndex, groupmModelKey } = splitGroupModel(field.model);

                        if ((this.isRgsGelId(groupmModelKey) && !rgs) || (!this.isRgsGelId(groupmModelKey) && rgs)) {
                            return;
                        }
                        if (rgs) {
                            return;
                        }

                        if (typeof newPostData[groupModel] === "undefined") {
                            newPostData[groupModel] = {};
                        }

                        if (!newPostData[groupModel][groupModelIndex]) {
                            newPostData[groupModel][groupModelIndex] = {};
                        }

                        newPostData[groupModel][groupModelIndex][groupmModelKey] = this.getFieldValue(field);
                    } else if (field && typeof field.model !== "undefined") {
                        if ((this.isRgsGelId(field.model) && !rgs) || (!this.isRgsGelId(field.model) && rgs)) {
                            return;
                        }

                        newPostData[field.model] = this.getFieldValue(field);
                    }
                }
            });

            return newPostData;
        },

        setValuesOnNewKeyPostData(newPostKeyData) {
            // run field loop
            this.fieldLoop(field => {
                if (this.includeField(field)) {
                    if (
                        field &&
                        typeof field.originalModel !== "undefined" &&
                        typeof field.repeatableGroup !== "undefined" &&
                        typeof field.repeatableIndex !== "undefined" &&
                        !field.isRadioModel
                    ) {
                        if (field.repeatableGroupPostKey && !newPostKeyData[field.repeatableGroup]) {
                            newPostKeyData[field.repeatableGroup] = {
                                postKey: field.repeatableGroupPostKey,
                                values: [],
                            };
                        } else if (!newPostKeyData[field.repeatableGroup]) {
                            newPostKeyData[field.repeatableGroup] = [];
                        }

                        if (
                            field.repeatableGroupPostKey &&
                            !newPostKeyData[field.repeatableGroup]["values"][field.repeatableIndex]
                        ) {
                            newPostKeyData[field.repeatableGroup]["values"][field.repeatableIndex] = {};
                        } else if (!newPostKeyData[field.repeatableGroup][field.repeatableIndex]) {
                            newPostKeyData[field.repeatableGroup][field.repeatableIndex] = {};
                        }

                        if (field.repeatableGroupPostKey && typeof field.postKey !== "undefined") {
                            newPostKeyData[field.repeatableGroup]["values"][field.repeatableIndex][
                                field.postKey
                            ] = this.getFieldValue(field);
                        } else if (typeof field.postKey !== "undefined") {
                            newPostKeyData[field.repeatableGroup][field.repeatableIndex][
                                field.postKey
                            ] = this.getFieldValue(field);
                        }
                    } else if (
                        field &&
                        typeof field.model !== "undefined" &&
                        field.model.match(/[a-zA-Z0-9]*\[[0-9]\].[a-zA-Z0-9]*/g)
                    ) {
                        const { groupModel, groupModelIndex, groupmModelKey } = splitGroupModel(field.model);
                        if (this.isRgsGelId(groupmModelKey)) {
                            return;
                        }

                        if (!newPostKeyData[groupModel]) {
                            newPostKeyData[groupModel] = {};
                        }

                        if (!newPostKeyData[groupModel][groupModelIndex]) {
                            newPostKeyData[groupModel][groupModelIndex] = {};
                        }

                        if (typeof field.postKey !== "undefined") {
                            newPostKeyData[groupModel][groupModelIndex][field.postKey] = this.getFieldValue(field);
                        }
                    } else if (field && typeof field.model !== "undefined") {
                        if (this.isRgsGelId(field.model)) {
                            return;
                        }

                        if (typeof field.postKey !== "undefined") {
                            newPostKeyData[field.postKey] = this.getFieldValue(field);
                        }
                    }
                }
            });

            return newPostKeyData;
        },

        mergePostKeyGroups(postKeyData) {
            const newPostKeyData = {};
            forEach(postKeyData, (postKeyDataObject, postKeyDataKey) => {
                if (isObject(postKeyDataObject) && typeof postKeyDataObject["postKey"] !== "undefined") {
                    if (!newPostKeyData[postKeyDataObject["postKey"]]) {
                        newPostKeyData[postKeyDataObject["postKey"]] = [];
                    }
                    forEach(postKeyDataObject["values"], value => {
                        const noKey = findKey(value, singleValue => !isEmpty(singleValue));
                        if (noKey) {
                            newPostKeyData[postKeyDataObject["postKey"]].push(value);
                        }
                    });
                } else {
                    newPostKeyData[postKeyDataKey] = postKeyDataObject;
                }
            });

            return newPostKeyData;
        },

        includeField: function(field) {
            // if not value exists on model, don not include field
            if (typeof groupModelValue(getModelKey(field), this.model, field) === "undefined") {
                return false;
            }

            return this.isFieldVisible(field) && this.doPost(field);
        },

        isFieldVisible: function(field) {
            if (field.visible) {
                if (!field.visible(this.model, field)) {
                    return false;
                }
            }

            return true;
        },

        doPost: function(field) {
            if (!field.doNotPost) {
                return true;
            }

            if (window.bdForms && window.bdForms.config.overruleDoNotPost) {
                return true;
            }

            return false;
        },

        // Transform date values before post
        getFieldValue: function(field) {
            const fieldModel = getModelKey(field);

            if (field.type === "date") {
                return dateToGregorianNotation(groupModelValue(fieldModel, this.model, field));
            }

            if (field.type === "licensePlate") {
                return groupModelValue(fieldModel, this.model, field).replace(/-/g, "");
            }

            return groupModelValue(fieldModel, this.model, field);
        },
    },
};
