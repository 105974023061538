import groupModelValue from "../../helpers/model/groupModelValue";
import { isModelVisible } from "../../singletons/modelVisibility";

export default (model, field, fieldModels) => {
    const filtered = fieldModels
        .filter(fieldModel => isModelVisible(fieldModel, field))
        .map(fieldModel => groupModelValue(fieldModel, model, field))
        .filter(value => value >= 0 || value < 0);

    if (filtered.length <= 0) {
        return undefined;
    }

    return filtered.reduce((acc, curr) => acc + curr);
};
