import groupModelValue from "../../helpers/model/groupModelValue";
import dateToGregorianNotation from "../../helpers/transformation/dateToGregorianNotation";

export default (model, field, fieldModels, parameters) => {
    let result = undefined;
    fieldModels.forEach(fieldModel => {
        const fieldModelValue = groupModelValue(fieldModel, model, field);
        if (parameters.years && fieldModelValue) {
            // if value is four numbers i is a year, value + years
            if (new RegExp("^[0-9]{4}$").test(fieldModelValue)) {
                result = fieldModelValue + parameters.years;
            }

            // if value is a date
            if (new RegExp("^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)[0-9]{4}$").test(fieldModelValue)) {
                const newDate = new Date(dateToGregorianNotation(fieldModelValue));
                const currentYear = newDate.getFullYear();
                const newDatePlusYears = new Date(newDate.setFullYear(currentYear + parameters.years));

                result =
                    ("0" + newDatePlusYears.getDate()).slice(-2) +
                    "-" +
                    ("0" + (newDatePlusYears.getMonth() + 1)).slice(-2) +
                    "-" +
                    newDatePlusYears.getFullYear();
            }
        }
    });

    return result;
};
