import amountDaysInPeriod from "../../formulas/date/amountDaysInPeriod";
import hlpmpontautnaambegin60mndherl from "./hlpmpontautnaambegin60mndherl";
import statics from "../../singletons/statics";
const hlpmwpontautperiodetot5jr = (wpontautstandper1, wpontautstandper2, wpontautnaambegin60mnd) => {
    let result = 0;

    const autnaambegin60mndherl = hlpmpontautnaambegin60mndherl(wpontautnaambegin60mnd, statics().BDRGJAAR);

    if (autnaambegin60mndherl === 0 || wpontautstandper1 >= autnaambegin60mndherl.getTime()) {
        return 0;
    }

    if (autnaambegin60mndherl) {
        if (wpontautstandper2 <= autnaambegin60mndherl.getTime()) {
            result = amountDaysInPeriod(wpontautstandper1, wpontautstandper2);
        } else if (wpontautstandper2 > autnaambegin60mndherl.getTime()) {
            result = amountDaysInPeriod(wpontautstandper1, autnaambegin60mndherl.getTime());
        }
    }

    // amountDaysInPeriod gives the exact days incl. the new date of the month
    // so we have to substract 1 day
    return result - 1;
};
export default hlpmwpontautperiodetot5jr;
