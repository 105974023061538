import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import { notExistsWarning } from "../../singletons/registeredModels";

export default (model, field, parameters, staticData, showValueWarnings = true) => {
    let result = false;

    if (!parameters) {
        console.warn("EqualsAnyValue is used but parameters are not found. Field: " + field.model);
    }

    parameters.fieldModels.forEach(fieldModel => {
        if (showValueWarnings) notExistsWarning(fieldModel, field, "");

        if (
            (groupModelValue(fieldModel, model, field) ||
                groupModelValue(fieldModel, model, field) === 0 ||
                typeof groupModelValue(fieldModel, model, field) === "boolean") &&
            isModelVisible(fieldModel, field)
        ) {
            result = true;
        } else {
            result = false;
            return;
        }
    });
    return result;
};
