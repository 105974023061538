import {
    PATH_TO_DATA_DIRECTORY,
    PATH_TO_FORM_NAVIGATION_JSON,
    PATH_TO_HELP_NAVIGATION_JSON,
    PATH_TO_TAX_CHECK_NAVIGATION_JSON,
    PATH_TO_RGS_MAPPING_JSON,
    PATH_M_BMG_AWA_DATA_JSON,
    PATH_FILTER_RGS_JSON,
    PATH_TO_HELP_TEXTS,
    PATH_TO_CUSTOM_HELP_TEXTS,
    PATH_TO_CONSTANTS_FILE,
    PATH_APPLICATION_JSON,
    FORM_NAVIGATION_JSON_FILE_NAME,
    HELP_NAVIGATION_JSON_FILE_NAME,
    TAX_CHECK_NAVIGATION_FILE_NAME,
    RGS_MAPPING_FILE_NAME,
    M_BMG_AWA_DATA_FILE_NAME,
    FILTER_RGS_FILE_NAME,
    APPLICATION_FILE_NAME,
    HELP_TEXTS_FILE_NAME,
    CUSTOM_HELP_TEXTS_FILE_NAME,
    CONSTANTS_FILE_FILE_NAME,
    PATH_TO_FONT_DIRECTORY,
    ICON_FONT_FILE_NAME,
    RO_REGULAR_FONT_FILE_NAME,
    RO_ITALIC_FONT_FILE_NAME,
    RO_BOLD_FONT_FILE_NAME,
    PATH_ERRORS_JSON,
    ERRORS_FILE_NAME,
    PATH_TO_SLIDES_JSON,
    SLIDES_JSON_FILE_NAME,
    YEAR_OVERVIEW_FILE_NAME,
    PATH_TO_YEAR_OVERVIEW_JSON,
    PATH_TO_TOUR_FILE,
    TOUR_FILE_NAME,
    PATH_TO_CONTENT,
} from "./constants";

export default () => {
    let pathToData = PATH_TO_DATA_DIRECTORY;
    let pathToFonts = PATH_TO_FONT_DIRECTORY;
    let pathToContent = PATH_TO_CONTENT;

    let config;

    if (window.bdForms && window.bdForms.config) {
        config = window.bdForms.config;
        if (window.bdForms.config.pathToData) {
            pathToData = window.bdForms.config.pathToData;
        }
        if (window.bdForms.config.pathToFonts) {
            pathToFonts = window.bdForms.config.pathToFonts;
        }
        if (window.bdForms.config.pathToContent) {
            pathToContent = window.bdForms.config.pathToContent;
        }
    }

    let pathToFormNavigation = pathToData + "/" + PATH_TO_FORM_NAVIGATION_JSON + "/";
    let pathToHelpNavigation = pathToData + "/" + PATH_TO_HELP_NAVIGATION_JSON + "/";
    let pathToSlides = pathToData + "/" + PATH_TO_SLIDES_JSON + "/";
    let pathToTaxCheckNavigation = pathToData + "/" + PATH_TO_TAX_CHECK_NAVIGATION_JSON + "/";
    let pathToRgsMapping = pathToData + "/" + PATH_TO_RGS_MAPPING_JSON + "/";
    let pathToMBmgAwaData = pathToData + "/" + PATH_M_BMG_AWA_DATA_JSON + "/";
    let pathToFilterRgs = pathToData + "/" + PATH_FILTER_RGS_JSON + "/";
    let pathToApplication = pathToData + "/" + PATH_APPLICATION_JSON + "/";
    let pathToErrors = pathToData + "/" + PATH_ERRORS_JSON + "/";
    let pathToYearOverview = pathToData + "/" + PATH_TO_YEAR_OVERVIEW_JSON + "/";
    let pathToHelpTexts = pathToData + "/" + PATH_TO_HELP_TEXTS + "/";
    let pathToCustomHelpTexts = pathToData + "/" + PATH_TO_CUSTOM_HELP_TEXTS + "/";
    let pathToConstantsFile = pathToData + "/" + PATH_TO_CONSTANTS_FILE + "/";
    let pathToTourFile = pathToData + "/" + PATH_TO_TOUR_FILE + "/";

    if (window.bdForms && window.bdForms.config) {
        pathToFormNavigation = config.pathToFormNavigation ? config.pathToFormNavigation : pathToFormNavigation;
        pathToHelpNavigation = config.pathToHelpNavigation ? config.pathToHelpNavigation : pathToHelpNavigation;
        pathToSlides = config.pathToSlides ? config.pathToSlides : pathToSlides;
        pathToTaxCheckNavigation = config.pathToTaxCheckNavigation
            ? config.pathToTaxCheckNavigation
            : pathToTaxCheckNavigation;
        pathToRgsMapping = config.pathToRgsMapping ? config.pathToRgsMapping : pathToRgsMapping;
        pathToMBmgAwaData = config.pathToMBmgAwaData ? config.pathToMBmgAwaData : pathToMBmgAwaData;
        pathToApplication = config.pathToApplication ? config.pathToApplication : pathToApplication;
        pathToFilterRgs = config.pathToFilterRgs ? config.pathToFilterRgs : pathToFilterRgs;
        pathToErrors = config.pathToErrors ? config.pathToErrors : pathToErrors;
        pathToYearOverview = config.pathToYearOverview ? config.pathToYearOverview : pathToYearOverview;
        pathToHelpTexts = config.pathToHelpTexts ? config.pathToHelpTexts : pathToHelpTexts;
        pathToCustomHelpTexts = config.pathToCustomHelpTexts ? config.pathToCustomHelpTexts : pathToCustomHelpTexts;
        pathToConstantsFile = config.pathToConstantsFile ? config.pathToConstantsFile : pathToConstantsFile;
        pathToTourFile = config.pathToTourFile ? config.pathToTourFile : pathToTourFile;
    }

    let formNavigationFileName = FORM_NAVIGATION_JSON_FILE_NAME;
    let helpNavigationFileName = HELP_NAVIGATION_JSON_FILE_NAME;
    let slidesFileName = SLIDES_JSON_FILE_NAME;
    let taxCheckNavigationFileName = TAX_CHECK_NAVIGATION_FILE_NAME;
    let rgsMappingFileName = RGS_MAPPING_FILE_NAME;
    let mBmgAwaDataFileName = M_BMG_AWA_DATA_FILE_NAME;
    let filterRgsFileName = FILTER_RGS_FILE_NAME;
    let applicationFileName = APPLICATION_FILE_NAME;
    let errorsFileName = ERRORS_FILE_NAME;
    let yearOverviewFileName = YEAR_OVERVIEW_FILE_NAME;
    let helpTextsFileName = HELP_TEXTS_FILE_NAME;
    let customHelpTextsFileName = CUSTOM_HELP_TEXTS_FILE_NAME;
    let constantsFileFileName = CONSTANTS_FILE_FILE_NAME;
    let tourFileName = TOUR_FILE_NAME;

    if (window.bdForms && window.bdForms.config) {
        slidesFileName = config.slidesFileName ? config.slidesFileName : slidesFileName;
        formNavigationFileName = config.formNavigationFileName ? config.formNavigationFileName : formNavigationFileName;
        helpNavigationFileName = config.helpNavigationFileName ? config.helpNavigationFileName : helpNavigationFileName;
        taxCheckNavigationFileName = config.taxCheckNavigationFileName
            ? config.taxCheckNavigationFileName
            : taxCheckNavigationFileName;
        rgsMappingFileName = config.rgsMappingFileName ? config.rgsMappingFileName : rgsMappingFileName;
        mBmgAwaDataFileName = config.mBmgAwaDataFileName ? config.mBmgAwaDataFileName : mBmgAwaDataFileName;
        filterRgsFileName = config.filterRgsFileName ? config.filterRgsFileName : filterRgsFileName;
        errorsFileName = config.errorsFileName ? config.errorsFileName : errorsFileName;
        yearOverviewFileName = config.yearOverviewFileName ? config.yearOverviewFileName : yearOverviewFileName;
        applicationFileName = config.applicationFileName ? config.applicationFileName : applicationFileName;
        helpTextsFileName = config.helpTextsFileName ? config.helpTextsFileName : helpTextsFileName;
        customHelpTextsFileName = config.customHelpTextsFileName
            ? config.customHelpTextsFileName
            : customHelpTextsFileName;
        constantsFileFileName = config.constantsFileFileName ? config.constantsFileFileName : constantsFileFileName;
        tourFileName = config.tourFileName ? config.tourFileName : tourFileName;
    }

    // FONTS
    let iconFontFile = pathToFonts + "/" + ICON_FONT_FILE_NAME;
    let roRegularFontFile = pathToFonts + "/" + RO_REGULAR_FONT_FILE_NAME;
    let roItalicFontFile = pathToFonts + "/" + RO_ITALIC_FONT_FILE_NAME;
    let roBoldFontFile = pathToFonts + "/" + RO_BOLD_FONT_FILE_NAME;

    if (window.bdForms && window.bdForms.config) {
        iconFontFile = config.iconFontFile ? config.iconFontFile : iconFontFile;
        roRegularFontFile = config.roRegularFontFile ? config.roRegularFontFile : roRegularFontFile;
        roItalicFontFile = config.roItalicFontFile ? config.roItalicFontFile : roItalicFontFile;
        roBoldFontFile = config.roBoldFontFile ? config.roBoldFontFile : roBoldFontFile;
    }

    return {
        pathToHelpTexts,
        pathToCustomHelpTexts,
        slides: pathToSlides + slidesFileName,
        formNavigation: pathToFormNavigation + formNavigationFileName,
        helpNavigation: pathToHelpNavigation + helpNavigationFileName,
        taxCheckNavigation: pathToTaxCheckNavigation + taxCheckNavigationFileName,
        rgsMapping: pathToRgsMapping + rgsMappingFileName,
        mBmgAwaData: pathToMBmgAwaData + mBmgAwaDataFileName,
        filterRgs: pathToFilterRgs + filterRgsFileName,
        errors: pathToErrors + errorsFileName,
        yearOverview: pathToYearOverview + yearOverviewFileName,
        helpTexts: pathToHelpTexts + helpTextsFileName,
        customHelpTexts: pathToCustomHelpTexts + customHelpTextsFileName,
        application: pathToApplication + applicationFileName,
        constantsFile: pathToConstantsFile + constantsFileFileName,
        tourFile: pathToTourFile + tourFileName,

        iconFontFile,
        roRegularFontFile,
        roItalicFontFile,
        roBoldFontFile,

        pathToContent,
    };
};
