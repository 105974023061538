<template>
    <div class="footer-navigation">
        <bldc-button
            v-if="invalidAccountancyButton"
            type="minimal"
            @click="$emit('invalid-accountancy')"
            :elem-title="$t('year_overview.dit_klopt_niet_ga_terug_naar_boekhouden')"
            style="float: left;"
            id="invalid-accountancy-button"
        ></bldc-button>
        <bldc-button
            v-if="showClose"
            type="primary"
            @click="closePlugin()"
            :elem-title="$t('download.naar_belastingdienst_knop_titel')"
            style="float: right; margin-left: 1rem;"
            icon="volgende"
            icon-pos="right"
        ></bldc-button>
        <bldc-button
            v-if="nextRoute"
            type="primary"
            @click="navNext"
            :elem-title="$t('global.volgende')"
            style="float: right; margin-left: 1rem;"
        ></bldc-button>
        <bldc-button
            v-if="prevRoute"
            type="secondary"
            @click="navPrev"
            :elem-title="$t('global.vorige')"
            style="float: right;"
        ></bldc-button>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { closePlugin } from "../modules/singletons/closePlugin";

export default {
    name: "footer-navigation",
    mixins: [],
    props: {
        showClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        invalidAccountancyButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            prevRoute: "navigation/prevRoute",
            nextRoute: "navigation/nextRoute",
        }),
    },
    methods: {
        navNext: function() {
            this.$emit("nav-next");
            if (this.nextRoute.routeParams) {
                this.$router.push({ name: this.nextRoute.routeName, params: this.nextRoute.routeParams });
            } else {
                this.$router.push({ name: this.nextRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.nextRoute.routeName);
        },
        navPrev: function() {
            this.$emit("nav-prev");
            if (this.prevRoute.routeParams) {
                this.$router.push({ name: this.prevRoute.routeName, params: this.prevRoute.routeParams });
            } else {
                this.$router.push({ name: this.prevRoute.routeName });
            }

            this.$store.dispatch("navigation/setCurrentItem", this.prevRoute.routeName);
        },

        closePlugin: function() {
            this.$emit("close-plugin");
        },
    },
    created() {},
};
</script>
<style lang="scss">
.footer-navigation {
    padding-top: 0.2rem;
    margin-bottom: 3.5rem;
}
</style>
