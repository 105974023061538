import valueFilter from "../../helpers/model/valueFilter";
import { isModelVisible } from "../../singletons/modelVisibility";

export default (model, field, parameters, staticData) => {
    let result = false;

    if (!parameters || typeof parameters.value === "undefined" || typeof parameters.fieldModels === "undefined") {
        console.warn("equalsValueAnyRepeatModel is used but parameters are not set correct. Field: " + field.model);
    }

    if (!parameters.fieldModels[0] || !parameters.fieldModels[1] || !model[parameters.fieldModels[0]]) {
        return result;
    }

    const newModel = parameters.fieldModels[0] + "[" + 0 + "]." + parameters.fieldModels[1];

    if (!isModelVisible(newModel, field)) {
        return result;
    }

    const value = valueFilter(parameters.value, staticData, model, field);

    const entries = Object.entries(model[parameters.fieldModels[0]]);

    for (const [key] of entries) {
        if (
            typeof model[parameters.fieldModels[0]][key][parameters.fieldModels[1]] !== "undefined" &&
            model[parameters.fieldModels[0]][key][parameters.fieldModels[1]] === value
        ) {
            result = true;
        }
    }

    return result;
};
