import isInt from "validator/lib/isInt";
import errorMessage from "./messages";
import alphaNumericalLength from "./alphaNumericalLength";

export default value => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = alphaNumericalLength("n", 13, false)(value);

    if (value < 0) {
        errors.push(errorMessage("bedrag_pos13_greater_than_zero"));
    }

    if (!isInt(value + "")) {
        errors.push(errorMessage("bedrag_pos13_no_decimals"));
    }

    return errors;
};
