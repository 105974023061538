import { isModelVisible } from "../../singletons/modelVisibility";
import groupModelValue from "../../helpers/model/groupModelValue";
import convertStringDateToMilliseconds from "../../calculations/date/convertStringDateToMilliseconds";

export default (model, field, parameters) => {
    if (
        !parameters ||
        typeof parameters.fieldModels[0] === "undefined" ||
        typeof parameters.fieldModels[1] === "undefined"
    ) {
        console.warn("newerThan is used but parameters.fieldModels is not set correct. Field: " + field.model);
    }

    if (!isModelVisible(parameters.fieldModels[0], field) || !isModelVisible(parameters.fieldModels[1], field)) {
        return false;
    }

    return (
        convertStringDateToMilliseconds(groupModelValue(parameters.fieldModels[0], model, field)) >
        convertStringDateToMilliseconds(groupModelValue(parameters.fieldModels[1], model, field))
    );
};
