import errorMessage from "./messages";

export default value => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = [];

    // eslint-disable-next-line no-useless-escape, prettier/prettier
    const re = new RegExp("^[0-9]{4}$");

    if (!re.test(value)) {
        errors.push(errorMessage("year_invalid_notation"));

        // return this error directly we can not transform this to a valid dat to following checks
        return errors;
    }

    if (value < 1901) {
        errors.push(errorMessage("year_before_after_date", { afterYear: 1901 }));
    }

    if (value > 2200) {
        errors.push(errorMessage("year_after_before_date", { beforeYear: 2200 }));
    }

    return errors;
};
