import statics from "../../singletons/statics";

//is ((({wpontautpercbijt}/100) * {BDRGBIJTAUTOMAXEMISSIE}) + (({wpontautstandcata} -/- {BDRGBIJTAUTOMAXEMISSIE}) * 25))/{wpontautstandcata})/100 als
// ({wpontauttenaamgest} = 1 OF {wpontautbouw} = 2) EN {wpontautpercbijt} = 2 EN {wpontautstandcata} > {BDRGBIJTAUTOMAXEMISSIE}
const hlpmwpontautpercbijttenaam = (wpontauttenaamgest, wpontautbouw, wpontautpercbijt, wpontautstandcata) => {
    if (
        (wpontauttenaamgest === 1 || wpontautbouw === 2) &&
        wpontautpercbijt === 7 &&
        wpontautstandcata > statics().BDRGBIJTAUTOMAXEMISSIE
    ) {
        // prettier-ignore
        return (
            (
                (
                    (wpontautpercbijt/100) * statics().BDRGBIJTAUTOMAXEMISSIE) +
                    (
                        (wpontautstandcata - statics().BDRGBIJTAUTOMAXEMISSIE) * 0.25
                    )
                ) /
            wpontautstandcata
        );
    } else {
        return wpontautpercbijt / 100;
    }
};

export default hlpmwpontautpercbijttenaam;
