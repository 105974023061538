<template>
    <div
        role="navigation"
        class="sidebar-wrapper"
        :style="calculatedStyles"
        id="sidebar-menu"
        aria-labelledby="sidebar-menu"
    >
        <div v-if="true" class="sidebar-navigation">
            <div
                class="toggle-header"
                :class="{ 'no-click': false, current: $route.name === 'intro' }"
                @click="clickSubNavigation('intro')"
                @keyup.enter="clickSubNavigation('intro')"
                :tabindex="started ? 0 : -1"
            >
                <div class="toggle-icon" :class="{ rotate: $route.name === 'intro' }">
                    <bldc-icon icon="delta_omlaag" font-size="16"></bldc-icon>
                </div>
                {{ $t("steps.starten") }}

                <bldc-icon
                    v-if="started && $route.name !== 'intro'"
                    icon="check"
                    :fontSize="16"
                    class="check-icon"
                ></bldc-icon>
            </div>
            <div class="sub-navigation open" :class="{ close: $route.name !== 'intro' }" v-if="$route.name === 'intro'">
                <sub-navigation-link
                    @clicked="navigate({ icon: 'home', label: 'starten', routeName: 'intro', clickable: true })"
                    :nav-item="{
                        icon: 'home',
                        label: $t('introduction.introductie'),
                        routeName: 'intro',
                        clickable: $route.name === 'intro',
                        status: started,
                    }"
                    :active="$route.name === 'intro'"
                />
            </div>

            <div
                class="toggle-header"
                :class="{ 'no-click': !started, current: $route.name === 'year-overview' }"
                @click="clickSubNavigation('yearOverview')"
                @keyup.enter="clickSubNavigation('yearOverview')"
                :tabindex="started ? 0 : -1"
            >
                <div class="toggle-icon" :class="{ rotate: $route.name === 'year-overview' }">
                    <bldc-icon icon="delta_omlaag" font-size="16"></bldc-icon>
                </div>
                {{ $t("steps.vergelijken") }}

                <bldc-icon
                    v-if="allYearOverviewSet && $route.name !== 'year-overview'"
                    icon="check"
                    :fontSize="16"
                    class="check-icon"
                ></bldc-icon>
            </div>

            <div
                class="sub-navigation open"
                :class="{ close: $route.name !== 'year-overview' }"
                v-if="$route.name === 'year-overview'"
            >
                <template v-for="navItem in yearOverviewNavItems">
                    <sub-navigation-link
                        v-if="!navItem.header"
                        @clicked="navigate(navItem)"
                        :key="navItem.form"
                        :nav-item="navItem"
                        :active="navItem.sheet === currentYearOverview && $route.name === 'year-overview'"
                    />
                    <div class="sub-header" v-if="navItem.header" :key="navItem.form">
                        {{ navItem.header }}
                    </div>
                </template>
            </div>

            <div
                class="toggle-header"
                @click="clickSubNavigation('forms')"
                :class="{ 'no-click': !allYearOverviewSet, current: $route.name === 'form' }"
                :tabindex="allYearOverviewSet ? 0 : -1"
                @keyup.enter="clickSubNavigation('forms')"
            >
                <div class="toggle-icon" :class="{ rotate: $route.name === 'form' }">
                    <bldc-icon icon="delta_omlaag" font-size="16"></bldc-icon>
                </div>
                {{ $t("steps.aanvullende_vragen") }}

                <bldc-icon
                    v-if="allFormsSet && $route.name !== 'form'"
                    icon="check"
                    :fontSize="16"
                    class="check-icon"
                ></bldc-icon>
            </div>

            <div class="sub-navigation open" :class="{ close: $route.name !== 'form' }" v-if="$route.name === 'form'">
                <template v-for="navItem in navItems">
                    <sub-navigation-link
                        v-if="!navItem.header"
                        @clicked="navigate(navItem)"
                        :key="navItem.form"
                        :nav-item="navItem"
                        :active="navItem.form === current && $route.name === 'form'"
                    />
                    <div class="sub-header" v-if="navItem.header" :key="navItem.form">
                        {{ navItem.header }}
                    </div>
                </template>
            </div>

            <div
                class="toggle-header"
                @click="clickSubNavigation('check')"
                :class="{ 'no-click': !allFormsSet || !allYearOverviewSet, current: isCheckRoute }"
                :tabindex="!allFormsSet || !allYearOverviewSet ? -1 : 0"
                @keyup.enter="clickSubNavigation('check')"
            >
                <div class="toggle-icon" :class="{ rotate: isCheckRoute }">
                    <bldc-icon icon="delta_omlaag" font-size="16"></bldc-icon>
                </div>
                {{ $t("steps.controleren") }}

                <bldc-icon
                    v-if="allCheckSet && !isCheckRoute"
                    icon="check"
                    :fontSize="16"
                    class="check-icon"
                ></bldc-icon>
            </div>

            <div class="sub-navigation open" :class="{ close: !isCheckRoute }" v-if="isCheckRoute">
                <template v-for="navItem in checkNavItems">
                    <sub-navigation-link
                        @clicked="navigate(navItem)"
                        :key="navItem.sheet"
                        :nav-item="navItem"
                        :active="navItem.sheet === currentCheck && isCheckRoute"
                    />
                </template>
            </div>

            <div
                class="toggle-header"
                :class="{ 'no-click': !allCheckSet || !allFormsSet, current: $route.name === 'download' }"
                @click="clickSubNavigation('download')"
                :tabindex="!allCheckSet || !allFormsSet ? -1 : 0"
                @keyup.enter="clickSubNavigation('download')"
            >
                <div class="toggle-icon" :class="{ rotate: $route.name === 'download' }">
                    <bldc-icon icon="delta_omlaag" font-size="16"></bldc-icon>
                </div>
                {{ $t("steps.downloaden") }}

                <bldc-icon
                    v-if="downloaded && $route.name !== 'download'"
                    icon="check"
                    :fontSize="16"
                    class="check-icon"
                ></bldc-icon>
            </div>
            <div
                class="sub-navigation open"
                :class="{ close: $route.name !== 'download' }"
                v-if="$route.name === 'download'"
            >
                <sub-navigation-link
                    @clicked="
                        navigate({
                            icon: 'download',
                            label: 'downloaden',
                            routeName: 'download',
                            clickable: true,
                            status: downloaded,
                        })
                    "
                    :nav-item="{
                        icon: 'download',
                        label: $t('steps.downloaden'),
                        routeName: 'download',
                        clickable: allCheckSet && allFormsSet,
                        status: finished,
                    }"
                    :active="$route.name === 'download'"
                />
            </div>
        </div>

        <div class="bottom-navigation">
            <navigation-link
                specId="tour-toggle"
                v-if="!isIntroduction"
                @clicked="toggleTour"
                :nav-item="{
                    label: showTour ? $t('tour.zet_rondleiding_uit') : $t('tour.zet_rondleiding_aan'),
                    icon: `opslaan`,
                    clickable: true,
                }"
                :active="false"
                icon="video"
            />
            <navigation-link
                v-if="!isIntroduction"
                @clicked="save"
                :nav-item="{ label: saveText || $t('global.opslaan'), icon: `opslaan`, clickable: true }"
                :active="false"
                icon="opslaan"
            />
            <navigation-link
                v-for="customItem in customItems"
                :key="customItem.slug"
                @clicked="customNavigate({ name: 'custom', params: { slug: customItem.slug } })"
                :nav-item="{ label: customItem.label, icon: customItem.icon, clickable: true }"
                :active="false"
                :icon="customItem.icon"
            />
            <navigation-link
                @clicked="close"
                :nav-item="{ label: $t('global.afsluiten'), icon: `afsluiten`, clickable: true }"
                :active="false"
                icon="afsluiten"
            />

            <div class="version">
                <bldc-paragraph :margin="false">{{ version }}</bldc-paragraph>
            </div>
        </div>
    </div>
</template>
<script>
import navigationLink from "./navigationLink";
import subNavigationLink from "./subNavigationLink";
import { mapGetters } from "vuex";
import { saveCurrentState } from "../modules/singletons/currentState";
import { appConstants } from "../data/constants";

// import Vue from "vue";
export default {
    name: "sidebar",
    mixins: [],
    props: {
        showMobileSidebar: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        navigationLink,
        subNavigationLink,
    },
    data() {
        return {
            saveText: null,
            toggles: {
                yearOverview: false,
                forms: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            formItems: "navigation/handledFormItems",
            yearOverviewItems: "navigation/handledYearOverviewItems",
            checkItems: "navigation/handledCheckItems",
            currentForm: "navigation/currentForm",
            currentYearOverview: "navigation/currentYearOverview",
            currentCheck: "navigation/currentCheck",
            allFormsSet: "navigation/allFormsSet",
            allYearOverviewSet: "navigation/allYearOverviewSet",
            allCheckSet: "navigation/allCheckSet",
            started: "navigation/started",
            downloaded: "currentState/downloaded",
            finished: "currentState/finished",
            showTour: "tour/showTour",
            customItems: "helpNavigation/items",
            headerBarOffset: "screen/headerBarOffset",
            screenSize: "screen/size",
        }),
        calculatedStyles() {
            return "top: " + this.headerBarOffset + "px; height: calc(100vh - " + this.headerBarOffset + "px);";
        },
        isIntroduction() {
            if (["custom", "licenses", "intro"].includes(this.$route.name)) {
                return true;
            }

            return false;
        },
        yearOverviewNavItems() {
            return this.yearOverviewItems.map(item => {
                item.label = this.$t("year_overview." + item.tag);
                return item;
            });
        },
        checkNavItems() {
            return this.checkItems.map(item => {
                item.label = this.$t("check." + item.tag);
                return item;
            });
        },
        navItems() {
            return this.formItems;
        },
        version() {
            return appConstants.version;
        },
        current() {
            return this.currentForm;
        },
        isCheckRoute() {
            return this.$route.name === "check" || this.$route.name === "tax-profit";
        },
    },
    methods: {
        toggleTour: function() {
            this.$store.dispatch("tour/setShowTour", !this.showTour);
            saveCurrentState();
        },
        save: function() {
            // this.$emit("save");
            this.$store.dispatch("save/preSave", true);
            this.$store.dispatch("save/save");
        },
        navigate: function(item) {
            this.$emit("clicked");
            this.$router.push({ name: item.routeName, params: item.routeParams }).catch(() => {});
        },
        customNavigate: function(item) {
            this.$emit("clicked");
            this.$router.push(item).catch(() => {});
        },
        close: function() {
            this.$emit("clicked");
            this.$emit("close");
            this.$store.dispatch("save/saveAndLeave");
        },
        clickSubNavigation: function(subnav) {
            if (subnav === "intro") {
                this.navigate({ routeName: "intro" });
            }

            if (subnav === "yearOverview" && this.started) {
                this.navigate(this.yearOverviewNavItems[0]);
            }

            if (subnav === "forms" && this.allYearOverviewSet) {
                const navItems = this.navItems.filter(navItem => !navItem.header);
                this.navigate(navItems[0]);
            }
            if (subnav === "check" && this.allFormsSet && this.allYearOverviewSet) {
                this.navigate(this.checkNavItems[0]);
            }

            if (subnav === "download" && this.allCheckSet && this.allFormsSet) {
                this.navigate({ routeName: "download" });
            }
        },
        getSubNavigationToggle: function(subnav) {
            return this.toggles[subnav];
        },
    },
    created() {},
};
</script>
<style lang="scss">
.box {
    margin-bottom: 2.5rem;
}
.version {
    margin-top: 1.5rem;
    color: #000000;
}
.bottom-navigation {
    padding: 0.6rem 2rem 0.6rem;
    background-color: #ffffff;
    @media only screen and (max-width: 991px) {
        padding: 0.6rem 0.5rem 0.6rem;
    }
}
.sidebar-wrapper {
    @media only screen and (max-width: 991px) {
        background-color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 275px;
        z-index: 200;
        overflow-y: auto;
    }
}
.sidebar-navigation {
    background-color: #ffffff;
    padding: 0.6rem 2rem 0.6rem;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 991px) {
        padding: 0.6rem 0.5rem 0.6rem;
        margin-bottom: 0rem;
        border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    }

    .sub-navigation {
        padding: 0;
        overflow: hidden;
        -webkit-transition: all 0.5s ease-in-out 0s;
        transition: all 0.5s ease-in-out 0s;
        height: auto;
        &.open {
            max-height: 999999px;
        }
        &.close {
            max-height: 0;
        }
        .sub-header {
            padding: 10px 0;
            padding-left: 1.75rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-bottom: 0.25rem;
            font-family: RObold, "Segoe UI", "Helvetica Neue", sans-serif;
            font-weight: normal;
            white-space: normal;
            margin: 0px;
            color: rgb(0, 0, 0);
        }
    }

    .toggle-header {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        padding: 10px 0;
        font-family: ROregular, "Segoe UI", "Helvetica Neue", sans-serif;
        font-weight: normal;
        white-space: normal;
        display: flex;
        // margin-bottom: 0.5rem;
        // margin-top: 0.5rem;
        color: rgb(0, 0, 0);
        cursor: pointer;
        &.current {
            background-color: #eef7fb;
        }
        &.no-click {
            color: #767676;
            cursor: auto;
        }
        .toggle-icon {
            display: inline-block;
            margin-right: 0.5rem;
            font-size: 16px;
            &.rotate {
                transform: rotateX(-180deg);
                transition: transform 0.5s ease-in-out;
            }
        }

        .check-icon {
            display: flex;
            align-self: end;
            position: relative;
            margin-left: auto;
            color: #39870c !important;
            margin-top: 4px;
            margin-right: 0.5rem;
        }
    }
}
</style>
