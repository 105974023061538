import filter from "lodash/filter";
import forEach from "lodash/forEach";

/**
 * Handle mappings rule
 *
 * @param object rule
 * @param array records
 */
export const mapRule = (rule, records, mappedRules, filterRgsData) => {
    // Get all records which start with rgsCode
    const rgsRecords = filter(records, record => {
        if (rule.rgsCode === "BEivKapPro") {
            return record.RgsCode === "BEivKapPro";
        }
        return record.RgsCode.startsWith(rule.rgsCode);
    });

    if (rgsRecords.length <= 0) {
        return null;
    }

    let value = 0;
    let issetValue = false;
    let openingValue = 0;
    let closingValue = 0;
    let allMappedRules = [];
    let valueSet = false;

    // Calculate rgs rocords
    rgsRecords.forEach(record => {
        if (record[rule.source] !== "") {
            issetValue = true;

            if (record[rule.source]) {
                value += Number(record[rule.source]);
                valueSet = true;
            }

            if (record["OpeningBalance"]) {
                openingValue += Number(record["OpeningBalance"]);
            }

            closingValue += Number(record["ClosingBalance"]);

            if (mappedRules) {
                const debitCredit = filterRgsData[record["RgsCode"]]
                    ? filterRgsData[record["RgsCode"]].debit_credit
                    : null;
                const mappedRuleValue = Number(record[rule.source] * rule.factor).toFixed(2) * 1;
                if (mappedRuleValue !== 0) {
                    allMappedRules.push({
                        key: record["LedgerDescription"],
                        id: record["LedgerAccountId"],
                        value: mappedRuleValue,
                        rgsCode: record["RgsCode"],
                        debitCredit: debitCredit,
                    });
                }
            }
        }
    });

    // If no values are set there is nothing, return with null
    if (!issetValue) {
        return null;
    }

    // Handle contitions before mapping
    if (rule.conditionTag === "map_decrease" && closingValue >= openingValue) {
        return null;
    }

    if (rule.conditionTag === "map_increase" && closingValue <= openingValue) {
        return null;
    }

    if (rule.conditionTag === "map_lower_zero" && value >= 0) {
        return null;
    }

    if (rule.conditionTag === "map_higher_zero" && value <= 0) {
        return null;
    }

    if (mappedRules) {
        return allMappedRules;
    }

    return valueSet ? Number(value * rule.factor).toFixed(2) * 1 : null;
};

/**
 * Handle RGS code mapping
 *
 * @param object mapObject
 * @param array records
 */
export default (mapObject, records, mappedRules = false, filterRgsData = null) => {
    const values = {};
    const newFilterRgsData = {};
    if (filterRgsData) {
        forEach(filterRgsData, (filterRgsDataObj, filterRgsDataKey) => {
            if (!filterRgsDataObj.filter) {
                newFilterRgsData[filterRgsDataKey] = filterRgsDataObj;
            }
        });
    }

    forEach(mapObject, (rgsMap, gelId) => {
        let amountEB = null;
        let amountSB = null;
        let amountDB = null;
        let amount = null;

        // Filter rules by target
        const ebRules = rgsMap.rules.filter(rule => rule.target && rule.target === "EB");
        const sbRules = rgsMap.rules.filter(rule => rule.target && rule.target === "SB");
        const dbRules = rgsMap.rules.filter(rule => rule.target && rule.target === "DB");
        const rules = rgsMap.rules.filter(rule => !rule.target);

        // Is target required (bool)
        let ebTarget = ebRules.length > 0;
        let sbTarget = sbRules.length > 0;
        let dbTarget = dbRules.length > 0;
        let target = rules.length > 0;

        // Handle rules per target
        ebRules.forEach(ebRule => {
            const newAmountEB = mapRule(ebRule, records, mappedRules, newFilterRgsData);
            if (mappedRules) {
                if (newAmountEB !== null) {
                    if (amountEB === null) {
                        amountEB = [];
                    }
                    newAmountEB.forEach(keyValue => {
                        amountEB.push(keyValue);
                    });
                }
            } else if (amountEB === null && newAmountEB !== null) {
                amountEB = newAmountEB;
            } else if (newAmountEB !== null) {
                amountEB += newAmountEB;
            }
        });

        sbRules.forEach(sbRule => {
            const newAmountSB = mapRule(sbRule, records, mappedRules, newFilterRgsData);

            if (mappedRules) {
                if (newAmountSB !== null) {
                    if (amountSB === null) {
                        amountSB = [];
                    }
                    newAmountSB.forEach(keyValue => {
                        amountSB.push(keyValue);
                    });
                }
            } else if (amountSB === null && newAmountSB !== null) {
                amountSB = newAmountSB;
            } else if (newAmountSB !== null) {
                amountSB += newAmountSB;
            }
        });

        dbRules.forEach(dbRule => {
            const newAmountDB = mapRule(dbRule, records, mappedRules, newFilterRgsData);
            if (mappedRules) {
                if (newAmountDB !== null) {
                    if (amountDB === null) {
                        amountDB = [];
                    }
                    newAmountDB.forEach(keyValue => {
                        amountDB.push(keyValue);
                    });
                }
            } else if (amountDB === null && newAmountDB !== null) {
                amountDB = newAmountDB;
            } else if (newAmountDB !== null) {
                amountDB += newAmountDB;
            }
        });

        rules.forEach(rule => {
            const newAmount = mapRule(rule, records, mappedRules, newFilterRgsData);
            if (mappedRules) {
                if (newAmount !== null) {
                    if (amount === null) {
                        amount = [];
                    }
                    newAmount.forEach(keyValue => {
                        amount.push(keyValue);
                    });
                }
            } else if (amount === null && newAmount !== null) {
                amount = newAmount;
            } else if (newAmount !== null) {
                amount += newAmount;
            }
        });

        // Round ammounts (needed for floating point precision)
        if (!mappedRules) {
            amountEB = amountEB !== null ? amountEB.toFixed(2) * 1 : null;
            amountSB = amountSB !== null ? amountSB.toFixed(2) * 1 : null;
            amountDB = amountDB !== null ? amountDB.toFixed(2) * 1 : null;
            amount = amount !== null ? amount.toFixed(2) * 1 : null;
        }

        // Handle delete zero conditions after mapping
        // console.log("gelId", gelId, ebTarget, amountEB, sbTarget, amountSB, dbTarget, amountDB);
        if (!mappedRules && rgsMap.conditionTag === "delete_zero") {
            // ebTarget = ebTarget && amountEB !== 0 && gelId !== "119356";
            // sbTarget = sbTarget && amountSB !== 0 && gelId !== "119356";
            // dbTarget = dbTarget && amountDB !== 0 && gelId !== "119474";
            ebTarget = ebTarget && amountEB !== 0;
            sbTarget = sbTarget && amountSB !== 0;
            dbTarget = dbTarget && amountDB !== 0;
            target = target && amount !== 0;
        }

        // Remove target if amount is null (nothing)
        ebTarget = ebTarget && amountEB !== null;
        sbTarget = sbTarget && amountSB !== null;
        dbTarget = dbTarget && amountDB !== null;
        target = target && amount !== null;

        // Add gel ids with values
        if (ebTarget) {
            values[gelId + `-EB`] = amountEB;
        }

        if (sbTarget) {
            values[gelId + `-SB`] = amountSB;
        }

        if (dbTarget) {
            values[gelId + `-DB`] = amountDB;
        }

        if (target) {
            values[gelId] = amount;
        }
    });

    return values;
};

/**
 * Get all possible gel ids from map object
 *
 * @param object mapObject
 */
export const getAllGelIds = mapObject => {
    const gelIds = [];
    forEach(mapObject, (rgsMap, gelId) => {
        // Filter rules by target
        const ebRules = rgsMap.rules.filter(rule => rule.target && rule.target === "EB");
        const sbRules = rgsMap.rules.filter(rule => rule.target && rule.target === "SB");
        const dbRules = rgsMap.rules.filter(rule => rule.target && rule.target === "DB");
        const rules = rgsMap.rules.filter(rule => !rule.target);

        // Add gel ids to geldIds array
        if (ebRules.length > 0) {
            gelIds.push(gelId + `-EB`);
        }

        if (sbRules.length > 0) {
            gelIds.push(gelId + `-SB`);
        }

        if (dbRules.length > 0) {
            gelIds.push(gelId + `-DB`);
        }

        if (rules.length > 0) {
            gelIds.push(gelId);
        }
    });

    return gelIds;
};
