import dateToGregorianNotation from "../helpers/transformation/dateToGregorianNotation";
import isBefore from "validator/lib/isBefore";
import isAfter from "validator/lib/isAfter";
import errorMessage from "./messages";
import statics from "../singletons/statics";
import valueFilter from "../helpers/model/valueFilter";
import isValidDate from "../utils/isValidDate";

export default (value, field, model) => {
    if (typeof value === "undefined" || value === null) {
        return [];
    }
    const errors = [];

    const re = new RegExp("^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)[0-9]{4}$");

    if (!re.test(value)) {
        errors.push(errorMessage("date_invalid_notation"));

        // return this error directly we can not transform this to a valid dat to following checks
        return errors;
    }

    const gregorianDate = dateToGregorianNotation(value);

    if (!isValidDate(gregorianDate)) {
        errors.push(errorMessage("date_invalid_notation"));

        // return this error directly we can not transform this to a valid dat to following checks
        return errors;
    }

    if (field.validatorParameters && field.validatorParameters.beforeDate) {
        const beforeDate = valueFilter(field.validatorParameters.beforeDate, statics(), model, field);
        if (isAfter(gregorianDate + "", dateToGregorianNotation(beforeDate) + "")) {
            errors.push(errorMessage("date_after_before_date", { beforeDate: beforeDate }));
        }
    }

    const regexYear = new RegExp("^[0-9]{4}$");

    if (field.validatorParameters && field.validatorParameters.beforeLastDayInYear) {
        const beforeLastDayInYear = valueFilter(field.validatorParameters.beforeLastDayInYear, statics(), model, field);

        if (field && !regexYear.test(beforeLastDayInYear)) {
            console.warn("beforeLastDayInYear is not a valid year in field with model: " + field.model);
        }

        const beforeLastDayInYearDate = "31-12-" + beforeLastDayInYear;
        if (isAfter(gregorianDate + "", dateToGregorianNotation(beforeLastDayInYearDate) + "")) {
            errors.push(errorMessage("date_after_before_date", { beforeDate: beforeLastDayInYearDate }));
        }
    }

    if (field.validatorParameters && field.validatorParameters.afterDate) {
        const afterDate = valueFilter(field.validatorParameters.afterDate, statics(), model, field);
        if (isBefore(gregorianDate + "", dateToGregorianNotation(afterDate) + "")) {
            errors.push(errorMessage("date_before_after_date", { afterDate: afterDate }));
        }
    }

    if (field.validatorParameters && field.validatorParameters.afterFirstDayInYear) {
        const afterFirstDayInYear = valueFilter(field.validatorParameters.afterFirstDayInYear, statics(), model, field);

        if (field && !regexYear.test(afterFirstDayInYear)) {
            console.warn("afterFirstDayInYear is not a valid year in field with model: " + field.model);
        }

        const afterFirstDayInYearDate = "01-01-" + afterFirstDayInYear;
        if (isBefore(gregorianDate + "", dateToGregorianNotation(afterFirstDayInYearDate) + "")) {
            errors.push(errorMessage("date_before_after_date", { afterDate: afterFirstDayInYearDate }));
        }
    }

    return errors;
};
