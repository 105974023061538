import forEach from "lodash/forEach";

let errors;

export const setErrors = errorsFromFile => {
    errors = errorsFromFile;
};

export default (code, params = null, wrapper = false) => {
    const errorObject = { ...errors[code] };

    if (!errorObject && code === "fallback") {
        return {
            title: "U kunt tijdelijk geen aangifte doen",
            message:
                "Door een technische storing is dit nu niet mogelijk. Onze excuses voor het ongemak. Probeer het later nog een keer.",
        };
    }

    if (!errorObject) {
        console.error("Non existing error defined");
    }

    if (params) {
        forEach(params, (value, key) => {
            errorObject.explain = errorObject.explain.replace(":" + key, value);
            errorObject.explain = errorObject.explain.replace(":" + key, value);
            errorObject.explain = errorObject.explain.replace(":" + key, value);

            if (errorObject.message) {
                errorObject.message = errorObject.message.replace(":" + key, value);
                errorObject.message = errorObject.message.replace(":" + key, value);
                errorObject.message = errorObject.message.replace(":" + key, value);
            }

            if (errorObject.title) {
                errorObject.title = errorObject.title.replace(":" + key, value);
                errorObject.title = errorObject.title.replace(":" + key, value);
                errorObject.title = errorObject.title.replace(":" + key, value);
            }
        });
    }

    if (wrapper) {
        return { error: errorObject };
    }

    return errorObject;
};
